import { useQuery } from 'react-query'
import { State } from '../hooks/useAppState'
import { getEmailRecipientsForActivities } from './CloudFunctionsApiHandler'
import { GetEmailRecipientsForActivitiesResponse } from '../../../pure-js/types/QueryParamTypes'

export enum QueryKeys {
  EMAIL_RECIPIENTS_FOR_ACTIVITIES = 'EMAIL_RECIPIENTS_FOR_ACTIVITIES'
}
const emptyResponse: GetEmailRecipientsForActivitiesResponse = {
  recipients: [],
  stats: {
    totalUniqueUsers: 0,
    perActivity: {}
  }
}
export const useEmailRecipientsForActivities = (
  { clientId, activityIds, statsOnly = false }: { clientId: string; activityIds: string[]; statsOnly?: boolean },
  state: State
) =>
  useQuery<GetEmailRecipientsForActivitiesResponse>(
    [QueryKeys.EMAIL_RECIPIENTS_FOR_ACTIVITIES, clientId, activityIds],
    () => {
      if (activityIds.length === 0) return emptyResponse
      return getEmailRecipientsForActivities({ clientId, activityIds, statsOnly }, state)
    },
    { enabled: !!clientId }
  )
