import { useEffect } from 'react'

const useCursorLoading = (isLoading: boolean) => {
  useEffect(() => {
    document.body.style.cursor = isLoading ? 'wait' : 'default'
    return () => {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])
}

export default useCursorLoading
