import { Drawer, IconButton, List, ListItemIcon, ListItemText, styled } from '@mui/material'
import RoutePath from '../../../pure-js/libs/RoutePath'

export type MenuItemType = {
  text: string
  icon: React.ReactElement
  path: RoutePath
}
export const DRAWER_WIDTH = 240
export const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  left: '8px',
  top: '20px',
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgb(139,92,246,0.5)',
  '&:hover': {
    backgroundColor: '#1d233a'
  },
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex'
  }
}))

export const MenuButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  left: '8px',
  top: '20px',
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  '&:hover': {
    backgroundColor: '#1d233a'
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const SidebarIcon = styled(ListItemIcon)({
  color: '#fff',
  justifyContent: 'center',
  minWidth: 0
})

export const SidebarText = styled(ListItemText)({
  color: '#fff',
  fontWeight: 500,
  fontSize: '1rem'
})

export const CustomList = styled(List)({
  marginTop: '60px'
})

// Styled Components
export const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  justifyContent: 'flex-start',
  backgroundColor: 'rgba(255, 255, 255, 0.05)'
})

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: { theme?: any; open: boolean }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    '& .MuiDrawer-paper': {
      position: 'fixed',
      backgroundColor: 'rgb(68, 1, 75)',
      color: '#fff',
      width: open ? DRAWER_WIDTH : theme.spacing(7),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: {
        width: DRAWER_WIDTH
      }
    }
  })
)
