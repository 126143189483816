import { EnrollmentUser } from './GrooverTypes'

export type StripeCheckoutCallbackQueryParams = {
  stripeSuccess?: string // "false" | "true"
}

export type FasadPhaseOneQueryParams = {
  externalUserId?: string
  type: FasadSection
  defaultPrompt?: string
}

export type ExternalSessionQueryParams = {
  externalSessionId: string
  enableLogOutPutToScreen?: string
}

export type GetActivePhasadDescriptions = {
  objectId: string
}

export type GetExternalSessionsQueryParams = Partial<{
  userId: number
  objectId: number
}>
// TODO CREATE TEST, should have a jaguar type for each section

export enum FasadSection {
  OVERVIEW = 'overview',
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  AREA = 'area'
}

export enum EmailType {
  PAYMENT_REMINDER = 'payment_reminder',
  REGISTRATION_APPROVED = 'registration_approved',
  PAYMENT_CONFIRMED = 'payment_confirmed',
  REGISTRATION_SAVED_NOT_APPROVED = 'registration_saved_not_approved'
}

export type VitecLandingQueryParams = {
  currentUserId: string // "HAN3294639236244479A4344C989A43B7AB"
  customerId: string // 'M20260'
  estateId: string // 'OBJ20260_2035692683'
  estateType: string // 'House'
  orderId: string // 'PORBFE9DFEEC6B64DABA8B799FF23078887'
  userId: string // 'HAN3294639236244479A4344C989A43B7AB'
}
export type LoginWithCustomerPageQueryParams = {
  uid: string
}

export type DevIdsQueryParams = Partial<{
  teamId: string
  uid: string
}>

export type SignedInQueryParams = {
  redirectToPlayWithOnboarding?: string
  teamCode?: string
}

export type AllAvailableQueryParams = LoginWithCustomerPageQueryParams | DevIdsQueryParams | SignedInQueryParams

export type GetActivitiesForClientRequest = {
  clientId: string
}

export type GetEnrollmentsForPeriodRequest = {
  clientId: string
  dateStart: string
  dateEnd: string
}

export type SendEnrollmentEmailRequest = {
  type: EmailType
  enrollmentId: string
}

export type SendEnrollmentEmailResponse = {
  type: EmailType
  message?: string
  success: boolean
}

export type ObfuscatedEnrollmentRequest = {
  enrollmentId: string
}

export type GetEnrollmentDataRequest = {
  enrollmentId: string
}

export type CreatePaymentIntentResponse = {
  clientSecret: string | null
  amount: number
  currency: string
}

export type CloudFunctionErrorResponse = {
  isError: boolean
  source: string
  message: string
}

export type GetActivePeriodsForUserRequest = {
  phone: string // phone is unique for a user
  clientId: string
}

export type GetActivePeriodsForUserResponse = string[]

export type GetEnrollmentUsersForClientRequest = {
  clientId: string
}

export type GetEnrollmentUsersForClientResponse = EnrollmentUser[]

export type EnrollmentActivityCheckinRequest = {
  enrollmentId: string
  activityId: string
  occurenceNo: number
  userId: string
  newState: boolean
}

export type GetEmailRecipientsForActivitiesRequest = {
  clientId: string
  activityIds: string[]
  statsOnly?: boolean
}

export type GetEmailRecipientsForActivitiesResponseItem = {
  id: string
  name: string
  email: string
}

export type GetEmailRecipientsForActivitiesResponse = {
  recipients: GetEmailRecipientsForActivitiesResponseItem[]
  stats: {
    totalUniqueUsers: number
    perActivity: Record<string, number>
  }
}

export type AddSsnForEnrollmentUserRequest = {
  clientId: string
  enrollmentId: string
  userType: 'mainUser' | 'partnerUser'
  ssn: string
}

export type CreateMailingRequest = {
  clientId: string
  subject: string
  replyToEmail: string
  replyToName: string
  body: string
  recipients: string[]
  activities: string[]
  overrideEmail?: string
  sendCopyToEmail?: string
}

export type onTaskMailingItemRequest = {
  mailingId: string
  enrollmentUserId: string
  overrideEmail?: string
}
