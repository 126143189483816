import { setUserId } from 'firebase/analytics'
import { User as AuthUser, onAuthStateChanged } from 'firebase/auth'
import invariant from 'invariant'
import React from 'react'
import { getEmailForFirebaseUser } from '../../../pure-js/libs/Common'
import { DevIdsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { User } from '../../../pure-js/types/User'
import { getUser, updateUser } from '../libs/DBApiHandler'
import { captureException } from '../libs/ErrorHelper'
import { analytics, auth } from '../libs/Firebase'
import { toBaseObject } from '../libs/Mapper'
import { SignInPageProps } from '../libs/SignInHelper'
import useQueryParams from '../libs/useQueryParams'
import useAppState, { State } from './useAppState'
import { useLogout } from './useLogout'
import { getUidFromQueryParams } from './useUser'
import { clearAndNavigateOut } from '../utils/loginHelper'
import { useNavigate } from './useNavigate'
import { useDeepLink } from './useDeepLink'

type Props = SignInPageProps & {
  onFinally: () => unknown
}

export default function useLogin(props: Props) {
  const { onFinally } = props
  const { state, setState } = useAppState()
  const [loading, setLoading] = React.useState(true)
  const logout = useLogout()
  const qp = useQueryParams<DevIdsQueryParams>()
  const navigate = useNavigate()
  const { clearDeepLink } = useDeepLink()

  const onLogin = (firebaseUser: AuthUser) =>
    getUser(getUidFromQueryParams(qp, firebaseUser) || firebaseUser.uid).then((user) => {
      const email = getEmailForFirebaseUser(firebaseUser)
      analytics && setUserId(analytics, firebaseUser.uid)

      return Promise.resolve(user)
        .then(async (u) => {
          if (u?.email) return u

          const user = {
            ...toBaseObject({ user: { id: firebaseUser.uid } } as State),
            id: firebaseUser.uid,
            email: email
          } as User

          await updateUser(user)

          return user
        })
        .then(async (user) => {
          invariant(user, '!user')
          invariant(user.id, '!user.id')

          const currentUser = auth.currentUser
          invariant(currentUser, 'currentUser')

          // Force refresh to get the latest custom claims
          await currentUser.getIdToken(true)
          const _state: State = { ...state, user, firebaseUser, isLoggedIn: true }
          setState(_state)
          return props.onLogin?.(_state)
        })
        .catch((e) => {
          logout()
          return Promise.reject(e)
        })
    })

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) =>
        Promise.resolve()
          .then(() => {
            if (!user && !!state.user.id) return logout()
            if (!user) return
            return Promise.resolve(setLoading(true))
              .then(() => onLogin(user))
              .finally(() => {
                onFinally()
              })
          })
          .catch((e) => {
            logout()
            clearAndNavigateOut(clearDeepLink, navigate)
            if (e?.message.includes('Missing or insufficient permissions.')) {
              return console.log({ e })
            }
            return captureException(new Error(`admin-web: useLogin / onAuthStateChanged: ${e.message}`))
          })
          .finally(() => setLoading(false)),
      captureException
    )
  }, [])

  return loading
}
