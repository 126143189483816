// Enhanced financial summary to include payment method stats
export interface FinancialSummary {
  totalAmount: number
  totalVAT: number
  paymentMethods: {
    swish: {
      count: number
      amount: number
    }
    manual: {
      count: number
      amount: number
    }
    other: {
      count: number
      amount: number
    }
  }
}

export interface ProcessedEnrollment {
  id: string
  createdDate: string // Formatted creation date
  originalCreatedDate: string // Raw creation date for filtering
  paymentDate: string // Formatted payment date
  originalPaymentDate: string // Raw payment date for filtering
  participant: string
  amount: number
  vat: number
  paymentMethod: string
  paymentOperator: string
  activities: number
  activityNames: string // Add activity names field
  reference: string
  paymentStatus: string
}

// Column configuration
export enum TableColumnId {
  CreatedDate = 'createdDate',
  PaymentDate = 'paymentDate',
  Participant = 'participant',
  Amount = 'amount',
  VAT = 'vat',
  PaymentMethod = 'paymentMethod',
  PaymentOperator = 'paymentOperator',
  //Activities = 'activities',
  ActivityNames = 'activityNames'
  // Reference = 'reference'
}

// Add enum for payment status filter
export enum PaymentStatusFilter {
  Paid = 'paid',
  Unpaid = 'unpaid',
  All = 'all',
  Swish = 'swish' // Add new Swish filter option
}

// Add enum for payment method filter
export enum PaymentMethodFilter {
  All = 'all',
  Swish = 'swish',
  Manual = 'manual',
  Other = 'other'
}

// Add enum for period options
export enum PeriodFilter {
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  AllTime = 'allTime',
  Custom = 'custom'
}

export interface TableColumn {
  id: TableColumnId
  width: string
  label: string
  getValue: (enrollment: ProcessedEnrollment) => string | number
}

// Format number with thousand separator (point)
export const formatNumber = (num: number): string => {
  return Math.floor(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// Add this function to calculate column totals
export const calculateColumnTotals = (enrollments: ProcessedEnrollment[]): Record<TableColumnId, number | string> => {
  const totals: Record<TableColumnId, number | string> = {
    [TableColumnId.CreatedDate]: '',
    [TableColumnId.PaymentDate]: '',
    [TableColumnId.Participant]: '',
    [TableColumnId.Amount]: 0,
    [TableColumnId.VAT]: 0,
    [TableColumnId.PaymentOperator]: '',
    [TableColumnId.PaymentMethod]: '',
    [TableColumnId.ActivityNames]: ''
  }

  enrollments.forEach((enrollment) => {
    totals[TableColumnId.Amount] = Number(totals[TableColumnId.Amount]) + enrollment.amount
    totals[TableColumnId.VAT] = Number(totals[TableColumnId.VAT]) + enrollment.vat
  })

  return totals
}

/**
 * Format date as YYYY-MM-DD for input fields
 */
export const formatDateForInput = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

/**
 * Get default dates for the current month (first and last day)
 */
export const getDefaultDates = () => {
  const now = new Date()
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

  return {
    start: formatDateForInput(startOfMonth),
    end: formatDateForInput(endOfMonth)
  }
}

/**
 * Get dates for last month (first and last day)
 */
export const getLastMonthDates = () => {
  const now = new Date()
  const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)

  return {
    start: formatDateForInput(startOfLastMonth),
    end: formatDateForInput(endOfLastMonth)
  }
}

/**
 * Format date string to consistent display format 'YYYY-MM-DD'
 * Safely handles timezone issues by extracting only the date part
 */
export const formatDisplayDate = (dateString: string): string => {
  if (!dateString || dateString === '-') return '-'

  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return '-'

    // Use ISO string split to avoid timezone issues
    return date.toISOString().split('T')[0]
  } catch (error) {
    console.error('Error formatting date:', error)
    return '-'
  }
}

/**
 * Check if a date is within a specified range, with timezone normalization
 * @param dateString The date to check (ISO format string)
 * @param startDate Start date of range (YYYY-MM-DD)
 * @param endDate End date of range (YYYY-MM-DD)
 * @returns Boolean indicating if the date is within range
 */
export const isDateInRange = (dateString: string, startDate: string | null, endDate: string | null): boolean => {
  // If date string is invalid, return false
  if (!dateString || dateString === '-') return false

  // If no start or end date provided, date is always in range
  if (!startDate && !endDate) return true

  try {
    // Parse the date to check
    const dateToCheck = new Date(dateString)
    if (isNaN(dateToCheck.getTime())) return false

    // Apply timezone normalization to get consistent date comparison
    dateToCheck.setMinutes(dateToCheck.getMinutes() + dateToCheck.getTimezoneOffset())

    // Parse range dates
    let start = startDate ? new Date(startDate) : null
    let end = endDate ? new Date(endDate) : null

    // Set start to beginning of day
    if (start) {
      start.setHours(0, 0, 0, 0)
    }

    // Set end date to end of day for inclusive filtering
    if (end) {
      end.setHours(23, 59, 59, 999)
    }

    const afterStart = !start || dateToCheck >= start
    const beforeEnd = !end || dateToCheck <= end

    return afterStart && beforeEnd
  } catch (error) {
    console.error('Error checking date range:', error)
    return false
  }
}
