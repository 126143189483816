import invariant from 'invariant'

const QR_DATA_DELIMITER = '/'
const QR_DATA_HASH_LENGHT = 3

export type QRType = 'visit' | 'payment' | 'unknown'

export type QRCodeCheckResult = 'valid' | 'expired' | 'invalid_data' | 'invalid_hash'

export type QRCheckResult = {
  success: boolean
  code: QRCodeCheckResult
  type: QRType
  id?: string
}

export type QRRequestBase = {
  type: QRType
}

export type QRRequestVisit = QRRequestBase & {
  type: 'visit'
  data: {
    enrollmentId: string
  }
}

export type QRRequestPayment = QRRequestBase & {
  type: 'payment'
  data: {
    userId: string
  }
}

export type QRRequest = QRRequestVisit | QRRequestPayment

const calculateDateString = (timestamp?: Date) => {
  const dateString = (timestamp || new Date()).toISOString().slice(0, 10).replace(/-/g, '')
  return dateString
}

const calculateHash = (data: string) => {
  return data
    .split('')
    .reduce((acc, char) => ((acc << 5) - acc + char.charCodeAt(0)) | 0, 0)
    .toString(36)
    .replace('-', '')
    .slice(0, QR_DATA_HASH_LENGHT)
    .toUpperCase()
}

const buildDataString = (request: QRRequest, dateString: string) => {
  switch (request.type) {
    case 'visit':
      invariant(request.data.enrollmentId, 'enrollmentId is required')
      return `v${QR_DATA_DELIMITER}${request.data.enrollmentId}${QR_DATA_DELIMITER}${dateString}`
    default:
      return 'INVALID'
  }
}

export const getQRCode = (request: QRRequest, timestamp?: Date) => {
  switch (request.type) {
    case 'visit': {
      const dateStamp = calculateDateString(timestamp)
      const qrData = buildDataString(request, dateStamp)
      const hash = calculateHash(qrData)
      return `${qrData}${QR_DATA_DELIMITER}${hash}`
    }

    default:
      return 'UNKOWN_QR_TYPE'
  }
}

export const checkQRCode = (qrData: string, currentTimestamp?: Date): QRCheckResult => {
  const checkTimestamp = calculateDateString(currentTimestamp)
  const [qrType, ...otherDataParts] = qrData.split(QR_DATA_DELIMITER)
  const otherData = otherDataParts.join(QR_DATA_DELIMITER)

  switch (qrType) {
    case 'v': {
      const [enrollmentId, dateStamp, hash] = otherData.split(QR_DATA_DELIMITER)
      const request: QRRequestVisit = {
        type: 'visit',
        data: { enrollmentId }
      }

      const data = buildDataString(request, dateStamp) // checkTimestamp)
      const expectedHash = calculateHash(data).split(QR_DATA_DELIMITER).pop()

      // Check data format
      if (!qrType || !enrollmentId || !dateStamp || !hash) {
        return {
          success: false,
          code: 'invalid_data',
          type: 'visit'
        }
      }

      // Check expiration
      // if (dateStamp !== checkTimestamp) {
      //   return { success: false, code: 'expired', type: 'visit' }
      // }

      // Check hash
      if (hash !== expectedHash) {
        return { success: false, code: 'invalid_hash', type: 'visit' }
      }

      return { success: true, code: 'valid', type: 'visit', id: enrollmentId }
    }

    default:
      return { success: false, code: 'invalid_data', type: 'unknown' }
  }
}
