import { useState, useEffect, useMemo } from 'react'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

export interface FilterOption {
  id: string
  label: string
  getAvailableOptions: (activities: Activity[]) => { value: string; label: string }[]
  getStorageKey: () => string
  filterPredicate: (activity: Activity, selectedValues: string[]) => boolean
}

interface UseActivityFilteringProps {
  activities: Activity[]
}

interface UseActivityFilteringReturn {
  filteredActivities: Activity[]
  filters: Record<string, string[]>
  filterOptions: FilterOption[]
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
  handleFilterToggle: (filterId: string, value: string) => void
  clearFilter: (filterId: string) => void
  getTotalActiveFiltersCount: () => number
  clearAllFilters: () => void
}

export const useActivityFiltering = ({ activities }: UseActivityFilteringProps): UseActivityFilteringReturn => {
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>([])

  const [filters, setFilters] = useState<Record<string, string[]>>(() => {
    return {
      city: JSON.parse(localStorage.getItem('communicationCityFilters') || '[]'),
      type: JSON.parse(localStorage.getItem('communicationTypeFilters') || '[]')
    }
  })

  const filterOptions: FilterOption[] = useMemo(
    () => [
      {
        id: 'city',
        label: getFigmaText(Texts.adminGeneralInputFieldsActivityCity),
        getAvailableOptions: (activities) => {
          const cities = activities
            .filter((activity) => activity.city)
            .map((activity) => activity.city)
            .filter((city, index, self) => city && self.indexOf(city) === index) as string[]
          return cities.map((city) => ({ value: city, label: city }))
        },
        getStorageKey: () => 'communicationCityFilters',
        filterPredicate: (activity, selectedValues) =>
          selectedValues.length === 0 || (!!activity.city && selectedValues.includes(activity.city))
      },
      {
        id: 'type',
        label: getFigmaText(Texts.adminGeneralInputFieldsActivityType),
        getAvailableOptions: (activities) => {
          const types = activities
            .map((activity) => activity.type)
            .filter((type, index, self) => type && self.indexOf(type) === index)
          return types.map((type) => ({
            value: type,
            label: type
          }))
        },
        getStorageKey: () => 'communicationTypeFilters',
        filterPredicate: (activity, selectedValues) =>
          selectedValues.length === 0 || selectedValues.includes(activity.type)
      }
    ],
    []
  )

  const handleFilterToggle = (filterId: string, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[filterId] || []
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value]

      return { ...prev, [filterId]: newValues }
    })
  }

  const clearFilter = (filterId: string) => {
    setFilters((prev) => ({ ...prev, [filterId]: [] }))
  }

  const clearAllFilters = () => {
    filterOptions.forEach((option) => clearFilter(option.id))
  }

  const getTotalActiveFiltersCount = () => {
    return Object.values(filters).reduce((count, values) => count + (values.length > 0 ? 1 : 0), 0)
  }

  const applyFilters = (activityList: Activity[]): Activity[] => {
    let filtered = [...activityList]

    // Apply each filter type
    filterOptions.forEach((option) => {
      const selectedValues = filters[option.id] || []
      if (selectedValues.length > 0) {
        filtered = filtered.filter((activity) => option.filterPredicate(activity, selectedValues))
      }
    })

    return filtered
  }

  // Update filtered activities when activities or filters change
  useEffect(() => {
    setFilteredActivities(applyFilters(activities))
  }, [activities, filters])

  // Save filter state to localStorage
  useEffect(() => {
    Object.entries(filters).forEach(([key, value]) => {
      const option = filterOptions.find((opt) => opt.id === key)
      if (option) {
        localStorage.setItem(option.getStorageKey(), JSON.stringify(value))
      }
    })
  }, [filters, filterOptions])

  return {
    filteredActivities,
    filters,
    filterOptions,
    setFilters,
    handleFilterToggle,
    clearFilter,
    getTotalActiveFiltersCount,
    clearAllFilters
  }
}
