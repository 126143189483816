export enum SlackChannels {
  OPS_ERRORS = 'ops-errors',
  DEBUG = 'debug',
  OPS_PAYMENT = 'ops-payment',
  OPS_APP = 'ops-app',
  OPS_SENDGRID = 'ops-sendgrid',
  OPS_NEWCUSTOMER = 'ops-newcustomer',
  OPS_SWISH = 'ops-swish',
  OPS_COMMUNICATION_SENDOUT = 'ops-communication-sendout',
  OPS_SMS = 'ops-sms',
  OPS_PAYMENT_REMINDER = 'ops-payment-reminder'
}

export enum SlackWebhooks {
  OPS_ERRORS_WEB = 'https://hooks.slack.com/services/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'
}
