import { Divider } from '@mui/material'
import { DrawerHeader, MenuItemType, StyledDrawer } from '../utils/sidebarHelper'
import { SidebarDesktopMenuList } from './SidebarDesktopMenuList'

export const SidebarDesktop: React.FC<{
  isOpen: boolean
  menuItems: MenuItemType[]
  currentPath: string
}> = ({ isOpen, menuItems, currentPath }) => (
  <StyledDrawer variant="permanent" open={isOpen}>
    <DrawerHeader />
    <Divider sx={{ backgroundColor: '#12172b' }} />
    <SidebarDesktopMenuList menuItems={menuItems} isOpen={isOpen} currentPath={currentPath} />
  </StyledDrawer>
)
