import React from 'react'

const SettingsPage: React.FC = () => {
  return (
    <div>
      <h1>Settings</h1>
      <p>Manage your application settings here.</p>
    </div>
  )
}

export default SettingsPage
