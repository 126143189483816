import { MailingTokensInput, MailingTokensObject } from '../types/MailingTypes'

export const getMailingTokens = ({ enrollmentUser, activities }: MailingTokensInput): MailingTokensObject => {
  return {
    user: enrollmentUser.name,
    coursenames: activities.map((activity) => activity.name).join(', ')
  }
}

export const replaceTokens = (text: string, tokens: MailingTokensObject): string => {
  let result = text
  Object.keys(tokens).forEach((key) => {
    result = result.replace(new RegExp(`{{@${key}}}`, 'g'), tokens[key])
  })
  return result
}
