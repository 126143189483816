import { Activity } from '../../../pure-js/types/GrooverTypes'

const RECENT_ACTIVITIES_KEY = 'recentActivities'
const MAX_RECENT = 3

export const saveRecentActivity = (activity: Activity) => {
  const stored = localStorage.getItem(RECENT_ACTIVITIES_KEY)
  const recent = stored ? JSON.parse(stored) : []

  // Remove if already exists and add to front
  const updated = [activity.id, ...recent.filter((id: string) => id !== activity.id)].slice(0, MAX_RECENT)
  localStorage.setItem(RECENT_ACTIVITIES_KEY, JSON.stringify(updated))
}

export const getRecentActivityIds = (): string[] => {
  const stored = localStorage.getItem(RECENT_ACTIVITIES_KEY)
  return stored ? JSON.parse(stored) : []
}
