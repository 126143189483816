import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { Client } from '../../../pure-js/types/GrooverTypes.js'
import { getClient } from '../libs/DBApiHandler'

interface ClientContextType {
  clientId: string
  setClientId: (id: string) => void
  reloadTrigger: number
  triggerReload: () => void
  clientData: Client | null
}

const ClientContext = createContext<ClientContextType>({
  clientId: '',
  setClientId: () => {},
  reloadTrigger: 0,
  triggerReload: () => {},
  clientData: null
})

interface ClientProviderProps {
  children: ReactNode
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const [clientId, setClientId] = useState<string>(() => {
    return localStorage.getItem('selectedClientId') || ''
  })

  const [reloadTrigger, setReloadTrigger] = useState<number>(0)
  const [clientData, setClientData] = useState<Client | null>(null)

  const triggerReload = () => {
    setReloadTrigger((prev) => prev + 1)
  }

  const handleSetClientId = (id: string) => {
    localStorage.setItem('selectedClientId', id)
    setClientId(id)
    triggerReload()
  }

  // Fetch client data when clientId changes or reloadTrigger is fired
  useEffect(() => {
    const fetchClientData = async () => {
      if (clientId) {
        try {
          const client = await getClient(clientId)
          setClientData(client || null)
        } catch (error) {
          console.error('Error fetching client data:', error)
          setClientData(null)
        }
      } else {
        setClientData(null)
      }
    }

    fetchClientData()
  }, [clientId, reloadTrigger])

  return (
    <ClientContext.Provider
      value={{
        clientId,
        setClientId: handleSetClientId,
        reloadTrigger,
        triggerReload,
        clientData
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClient = () => useContext(ClientContext)
