import { Texts } from '../../../pure-js/libs/Texts'
import { Enrollment, Activity, EnrollmentStatus } from '../../../pure-js/types/GrooverTypes'
import { getFigmaText } from '../libs/TextRepository'

// Type for daily registration data
export interface DailyRegistrationData {
  date: string
  count: number
  total: number
}

export const formatPayment = (payment?: number) => {
  if (payment === undefined || payment === null) {
    return 'N/A'
  }
  return Math.round(payment / 100)
}

// Utility function to add thousand separators with dots
export const formatNumberWithDots = (value: number | string): string => {
  if (typeof value === 'string') {
    if (isNaN(Number(value))) return value
    value = Number(value)
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// Return all revenue or only confirmed revenue for a registration
export const getAllRevenueForRegistration = (
  enrollments: Enrollment[],
  onlyConfirmed: boolean,
  activity?: Activity
) => {
  const filterEnrollments = enrollments.filter(
    (enrollment) => enrollment.payments && (!onlyConfirmed || enrollment.status === EnrollmentStatus.Confirmed)
  )

  const totalRevenue = filterEnrollments.reduce((total, enrollment) => {
    const enrollmentRevenue = enrollment.priceDetails
      .filter((item) => (activity ? item.activityId === activity.id : true))
      .reduce((paymentTotal, priceDetails) => paymentTotal + priceDetails.finalPrice, 0)
    return total + enrollmentRevenue
  }, 0)

  return formatPayment(totalRevenue)
}

export const getPaymentMethod = (enrollment: Enrollment): string => {
  let payment =
    enrollment.paymentStatus === 'paid'
      ? enrollment.payments.find((p) => p.type === 'pay')?.source
      : enrollment.priceDetails[0].paymentMethod || enrollment.priceDetails.map((pd) => pd.paymentMethod).find(Boolean)

  return payment === 'BankTransfer' || payment === 'manual' ? 'BG' : payment || 'unknown'
}

export const getAverageActiveCourseFillRate = (enrollments: Enrollment[], activities: Activity[]): string => {
  const totalSpots = activities.reduce((sum, activity) => sum + activity.maxAllowedParticipants, 0)
  const totalRegistrations = getAllEnrollmentsCount(enrollments)
  const averageFillRate = (totalRegistrations / totalSpots) * 100

  return averageFillRate.toFixed(0)
}

export const getParticipantTypeCount = (enrollments: Enrollment[], type: string, status: string): number => {
  return enrollments.reduce((count, enrollment) => {
    const mainUserCount = enrollment.mainUser?.type === type && enrollment.status === status ? 1 : 0
    const partnerUserCount = enrollment.partnerUser?.type === type && enrollment.status === status ? 1 : 0
    return count + mainUserCount + partnerUserCount
  }, 0)
}

export const getParticipantTypeChartDataForActivity = (enrollments: Enrollment[], activityId: string) => {
  //filter enrollments by activityId
  const filteredEnrollments = enrollments.filter((enrollment) => enrollment.activityIds?.includes(activityId))
  return getParticipantTypeChartData(filteredEnrollments)
}

export const getParticipantTypeChartData = (enrollments: Enrollment[]) => {
  const leaderCount = getParticipantTypeCount(enrollments, 'leader', EnrollmentStatus.Confirmed)
  const followerCount = getParticipantTypeCount(enrollments, 'follower', EnrollmentStatus.Confirmed)
  const singleCount = getParticipantTypeCount(enrollments, 'single', EnrollmentStatus.Confirmed)
  const bothCount = getParticipantTypeCount(enrollments, 'both', EnrollmentStatus.Confirmed)
  const followerPending = getParticipantTypeCount(enrollments, 'leader', EnrollmentStatus.Pending)
  const leaderPending = getParticipantTypeCount(enrollments, 'follower', EnrollmentStatus.Pending)

  // Use the same colors and names as in the EnrollmentType component
  return [
    { name: getFigmaText(Texts.adminGeneralStateAndTypesTypeLeader), value: leaderCount, color: '#002BC1' }, // Darker green for better contrast
    { name: getFigmaText(Texts.adminGeneralStateAndTypesTypeFollower), value: followerCount, color: '#B55152' }, // Darker orange-red for better contrast
    { name: getFigmaText(Texts.adminGeneralStateAndTypesTypeBoth), value: bothCount, color: '#D4A017' }, // Darker gold for better contrast
    { name: getFigmaText(Texts.adminGeneralStateAndTypesTypeSingle), value: singleCount, color: '#578A0F' }, // Darker gold for better contrast
    {
      name: getFigmaText(Texts.adminGeneralStateAndTypesStatePendingFollower),
      value: followerPending,
      color: '#C9C9C9'
    }, // Darker orange-red for better contrast
    { name: getFigmaText(Texts.adminGeneralStateAndTypesStatePendingLeader), value: leaderPending, color: '#C9C9C9' } // Darker gold for better contrast
  ].filter((item) => item.value > 0)
}

export const getAllPaidRevenueForRegistration = (enrollments: Enrollment[], activity?: Activity) => {
  const totalRevenue = enrollments
    .filter((enrollment) => enrollment.paymentStatus === 'paid')
    .reduce((total, enrollment) => {
      const enrollmentRevenue = enrollment.priceDetails
        .filter((item) => (activity ? item.activityId === activity.id : true))
        .reduce((sum, priceDetail) => sum + priceDetail.finalPrice, 0)
      return total + enrollmentRevenue
    }, 0)

  return formatPayment(totalRevenue)
}

export const getPaidRegistrationRatio = (enrollments: Enrollment[], activity?: Activity): string => {
  const calculatePaidAmount = (enrollment: Enrollment): number => {
    return enrollment.payments
      .filter((payment) => payment.type === 'pay')
      .reduce((sum, payment) => sum + payment.totalAmount, 0)
  }

  const calculateConfirmedAmount = (enrollment: Enrollment): number => {
    return enrollment.status === 'confirmed'
      ? enrollment.priceDetails.reduce((sum, price) => sum + price.finalPrice, 0)
      : 0
  }

  const totalPaidAmount = enrollments.reduce((sum, enrollment) => sum + calculatePaidAmount(enrollment), 0)

  const totalConfirmedAmount = enrollments.reduce((sum, enrollment) => sum + calculateConfirmedAmount(enrollment), 0)

  if (totalConfirmedAmount === 0) return '0%'

  const ratio = (totalPaidAmount / totalConfirmedAmount) * 100
  return `${ratio.toFixed(0)}%`
}

export const getAllEnrollmentsCount = (enrollments: Enrollment[], activityId?: string): number => {
  return enrollments.reduce((total, enrollment) => {
    // Filter activities if activityId is provided, otherwise include all
    const filteredActivities = activityId
      ? enrollment.activityIds?.filter((aId) => aId === activityId)
      : enrollment.activityIds

    const mainUserActivities = filteredActivities?.length || 0
    const partnerUserActivities = enrollment.partnerUser ? filteredActivities?.length || 0 : 0

    return total + mainUserActivities + partnerUserActivities
  }, 0)
}

export const getEnrollmentPrice = (enrollment: Enrollment, activity?: Activity): number =>
  enrollment.priceDetails
    .filter((pd) => !activity || pd.activityId === activity.id)
    .reduce((total, pd) => total + pd.finalPrice, 0)

const getEnrollmentPaidAmount = (enrollment: Enrollment): number =>
  enrollment.payments?.reduce((total, payment) => {
    return total + payment.totalAmount
  }, 0)

export const isEnrollmentPaid = (enrollment: Enrollment): boolean =>
  enrollment.paymentStatus === 'paid' || getEnrollmentPaidAmount(enrollment) === getEnrollmentPrice(enrollment)

export const getEnrollmentCountForActivity = (enrollments: Enrollment[], id: string, type?: string): number => {
  return enrollments
    .filter((e) => e.activityIds?.some((aId) => aId === id))
    .reduce((count, e) => {
      if (type) {
        const mainCount = e.mainUser.type === type ? 1 : 0
        const partnerCount = e.partnerUser && e.partnerUser.type === type ? 1 : 0
        return count + mainCount + partnerCount
      }
      return count + (e.partnerUser ? 2 : 1)
    }, 0)
}

export const getEnrollmentStatusText = (status: string): string => {
  switch (status.toLowerCase()) {
    case EnrollmentStatus.Pending:
      return getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusNotApproved)
    case EnrollmentStatus.Confirmed:
      return getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusApproved)
    case EnrollmentStatus.Active:
      return 'active'
    case EnrollmentStatus.Cancelled:
      return 'cancelled'
    default:
      return status
  }
}

/**
 * Renders enrollment status as JSX with a colored status dot based on enrollment status
 * @param status The enrollment status
 * @param showText Whether to show the status text or just the dot
 * @returns JSX element representing the status
 */
export const renderEnrollmentStatus = (status: string, showText: boolean = true) => {
  const statusText = showText ? getEnrollmentStatusText(status) : ''
  const className = `cell status ${status.toLowerCase()}`

  // Now returning an object with the className and text that can be directly used in JSX
  return { className, text: statusText }
}

export const getDirectionBasedOnScreenWidth = () => {
  return window.innerWidth <= 768 ? 'column' : 'row'
}

/**
 * Checks if an enrollment has any non-manual payments (e.g., Swish or Stripe)
 * @param enrollment The enrollment to check
 * @returns True if there are any non-manual payments, false otherwise
 */
export const hasNonManualPayments = (enrollment: Enrollment): boolean => {
  if (!enrollment.payments || enrollment.payments.length === 0) {
    return false
  }

  return enrollment.payments.some((payment) => payment.paymentMethod !== 'manual' && payment.type !== 'refund')
}

/**
 * Gets daily registration count from the earliest enrollment to today
 * @param enrollments List of enrollments
 * @param maxDays Maximum number of days to display (optional)
 * @returns Array of daily registration data
 */
export const getRegistrationsPerDay = (enrollments: Enrollment[], maxDays: number = 90): DailyRegistrationData[] => {
  if (!enrollments || enrollments.length === 0) {
    return []
  }

  // Find the earliest enrollment date
  const earliestDate = enrollments.reduce((earliest, enrollment) => {
    if (!enrollment.createdAt) return earliest

    const creationDate = new Date(enrollment.createdAt)
    return creationDate < earliest ? creationDate : earliest
  }, new Date())

  // Calculate days between earliest enrollment and today
  const endDate = new Date()
  const daysDifference = Math.ceil((endDate.getTime() - earliestDate.getTime()) / (1000 * 3600 * 24))

  // Use the smaller of actual days or maxDays
  const daysToUse = Math.min(daysDifference, maxDays)

  // Adjust start date based on the number of days we want to show
  const startDate = new Date()
  startDate.setDate(endDate.getDate() - daysToUse)

  // Ensure we don't go earlier than the earliest enrollment
  if (startDate < earliestDate) {
    startDate.setTime(earliestDate.getTime())
  }

  // Create a map of dates with zeroed counts
  const dateMap: { [key: string]: number } = {}
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0]
    dateMap[dateStr] = 0
  }

  // Count enrollments per day
  enrollments.forEach((enrollment) => {
    // Use createdAt date from enrollment
    const enrollmentDate = enrollment.createdAt ? new Date(enrollment.createdAt) : null

    if (enrollmentDate && enrollmentDate >= startDate && enrollmentDate <= endDate) {
      const dateStr = enrollmentDate.toISOString().split('T')[0]
      dateMap[dateStr] = (dateMap[dateStr] || 0) + (enrollment.partnerUser ? 2 : 1)
    }
  })

  // Convert to array and calculate running total
  let runningTotal = 0
  return Object.entries(dateMap)
    .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
    .map(([date, count]) => {
      runningTotal += count
      return {
        date,
        count,
        total: runningTotal
      }
    })
}

// Function to format date and time for the communication logs
export const formatDateTime = (dateString: string | undefined) => {
  if (!dateString) return 'N/A'
  const date = new Date(dateString)
  return date.toLocaleString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

/**
 * Returns the number of pending enrollments for a specific activity
 * @param enrollments All enrollments
 * @param activityId The activity ID to filter by
 * @returns The number of pending enrollments for the activity
 */
export const getPendingEnrollmentCountForActivity = (enrollments: Enrollment[], activityId: string): number => {
  return enrollments.filter(
    (enrollment) => enrollment.activityIds?.includes(activityId) && enrollment.status === EnrollmentStatus.Pending
  ).length
}
