import * as Auth from 'firebase/auth'
import invariant from 'invariant'
import React from 'react'
import eventEmitter, { Events } from '../../../pure-js/libs/EventEmitter'
import { SignInState, SignInSteps, SignInViewProps } from '../../../pure-js/types/SignInTypes'
import useAppState, { State } from '../hooks/useAppState'
import useLogin from '../hooks/useLogin'
import { useNavigate } from '../hooks/useNavigate'
import useResponsive from '../hooks/useResponsive'
import useSignInState from '../hooks/useSignInState'
import { signInWithPopup } from '../libs/FirebaseHelper'
import { DEFAULT_SIGN_IN_STATE, setAuthCookie, SignInPageProps } from '../libs/SignInHelper'
import * as SignInMachineHelper from '../libs/SignInMachine'
import { AppleAuthProvider, GoogleAuthProvider, MicrosoftAuthProvider } from '../libs/SignInProviders'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'
import AppContainer from '../AppContainer'
import SignInEnterCode from './SignInEnterCode'
import SignInLanding from './SignInLanding'
import SignInSignUp from './SignInSignUp'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { useDeepLink } from '../hooks/useDeepLink'

const SignInViewsDesktop = {
  [SignInSteps.LANDING]: SignInLanding,
  [SignInSteps.SIGN_UP]: SignInSignUp,
  [SignInSteps.ENTER_CODE]: SignInEnterCode
}

export default function SignIn(props: SignInPageProps) {
  const { isMobile } = useResponsive()
  const Views = isMobile ? SignInViewsDesktop : SignInViewsDesktop
  const { state, setState } = useAppState()
  const [isLoading, setIsLoading] = React.useState(false)
  const { signInState, setSignInState } = useSignInState()
  const navigate = useNavigate({ enablePreserveQueryParams: true })
  const { getDeepLink, clearDeepLink } = useDeepLink()
  const deepLink = getDeepLink()
  const loading = useLogin({
    onLogin: (state) => {
      if (!!deepLink && Object.keys(deepLink).length) {
        const target = `${deepLink.path}${deepLink.search || ''}`
        navigate(target)
        clearDeepLink()
      } else {
        navigate(RoutePath.DASHBOARD)
      }
      // setSignInState(DEFAULT_SIGN_IN_STATE)
    },
    onFinally: () => {
      setIsLoading(false)
    }
  })
  if (state.user.id) return <AppContainer />
  const onError = (err) => {
    if (signInState.step === SignInSteps.ENTER_CODE) throw err
    onUnhandledPromiseRejection(err)
    setSignInState(DEFAULT_SIGN_IN_STATE)
    eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }

  const signInWithAuthProvider = (authProvider: Auth.AuthProvider) =>
    Promise.resolve(setIsLoading(true))
      .then(() => signInWithPopup(authProvider))
      .then((response: any) => {
        // console.log('success login', { response })
        const { accessToken } = response.user.stsTokenManager
        setAuthCookie(accessToken, 30)
        const userDetails = {
          uid: response.user.uid,
          email: response.user.email,
          displayName: response.user.displayName,
          photoURL: response.user.photoURL
        }
        // TODO MAKE AN API CALL TO BE to get customer ID and other user data
        setState((prevState) => ({
          ...prevState,
          user: userDetails,
          isLoggedIn: true,
          firebaseUser: response.user // Full Firebase user object
        }))
        // console.log('Access token saved successfully:', accessToken)
      })

      .catch((err) => {
        onError(err)
        setIsLoading(false)
      })

  const signInViewProps: SignInViewProps = {
    isLoading: isLoading || loading,
    signInState: signInState,
    onClickBack: () => {
      switch (signInState.step) {
        default:
          return setSignInState(DEFAULT_SIGN_IN_STATE)
      }
    },
    onTakeMeBack: () => setSignInState(DEFAULT_SIGN_IN_STATE),
    onLoginWithGoogle: () => signInWithAuthProvider(GoogleAuthProvider).catch(onError),
    onLoginWithMicrosoft: () => signInWithAuthProvider(MicrosoftAuthProvider).catch(onError),
    onLoginWithApple: () => signInWithAuthProvider(AppleAuthProvider).catch(onError),
    onClickSignUp: () => setSignInState({ ...signInState, step: SignInSteps.SIGN_UP }),
    onPressContinue: (signInState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => {
          return SignInMachineHelper.onPressContinue(signInState, state, { navigate })
        })
        .then((signInState: SignInState) => {
          setSignInState(signInState)
        })
        .catch(onError)
        .finally(() => setIsLoading(false))
  }

  const component: React.FC<SignInViewProps> = Views[signInViewProps.signInState.step]
  invariant(component, `Cant find Onboarding Step for %s`, signInViewProps.signInState.step)

  return React.createElement(component, signInViewProps)
}
