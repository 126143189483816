import { useContext } from 'react'
import { FeatureFlagsContext, FlagNames } from '../libs/FeatureFlags'
import { storage } from '../../../admin-web/src/libs/Firebase'

const FeatureFlagsAdminPage = ({ storageKey }: { storageKey: string }) => {
  const { flags, setFlagValue } = useContext(FeatureFlagsContext)

  const renderFlagControl = (key: string, flag: any) => {
    // Handle boolean flag values
    if (typeof flag.value === 'boolean') {
      return (
        <button
          onClick={() => setFlagValue(key as FlagNames, !flag.value)}
          style={{
            ...ButtonStyle,
            backgroundColor: flag.value ? '#4caf50' : '#f5f5f5',
            color: flag.value ? 'white' : 'black'
          }}
        >
          {flag.value ? 'Enabled' : 'Disabled'}
        </button>
      )
    }
    // Handle enum/select values
    else if (Array.isArray(flag.possibleValues)) {
      return (
        <select value={flag.value} onChange={(e) => setFlagValue(key as FlagNames, e.target.value)} style={SelectStyle}>
          {flag.possibleValues.map((val: any) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </select>
      )
    }

    // Default case - just show the value
    return <span style={ValueDisplayStyle}>{String(flag.value)}</span>
  }

  return (
    <div style={FeatureFlagsAdminStyle}>
      <h2>Feature Flags</h2>
      <div>
        <div style={FeatureFlagsAdminStyle}>
          <h3>Toggle feature flags on and off.</h3>
          <p>
            Current environment: <span style={chipStyle}>{process.env.NODE_ENV}</span>
          </p>
        </div>
        {Object.entries(flags)
          .filter(([key, flag]) => flag.name !== undefined)
          .map(([key, flag]) => (
            <div key={key} style={{ borderTop: '1px solid black', padding: '8px 0' }}>
              <div>
                <h3>{flag.name}</h3>
                <p>{flag.description}</p>
                <p>
                  Environment: <span style={chipStyle}>{flag.environment || 'all'}</span>
                  <br />
                  Feature key: <span style={chipStyle}>{key}</span>
                </p>
                <p>
                  Value: <span style={chipStyle}>{String(flag.value)}</span> (default:{' '}
                  <span style={chipStyle}>{String(flag.defaultValue)}</span>)
                </p>
              </div>
              <div style={FlagControlStyle}>{renderFlagControl(key, flag)}</div>
            </div>
          ))}
        <div style={{ borderTop: '1px solid black', padding: '8px 0', color: 'red' }}>
          <br />
          <br />
          <br />
          <h3>Clear</h3>
          <p>
            This will reset all feature flags to their default values.
            <br />
            (local storage key: {storageKey})
          </p>
          <div style={FlagControlStyle}>
            <button
              style={{ ...ButtonStyle, backgroundColor: 'red', color: 'white' }}
              onClick={() => localStorage.removeItem(storageKey)}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const FeatureFlagsAdminStyle = {
  backgroundColor: '#fff',
  width: '500px',
  margin: '0 auto'
}

const FlagControlStyle = {
  display: 'flex',
  flexDirection: 'column' as const,
  gap: '8px',
  minWidth: '120px'
}

const ButtonStyle = {
  backgroundColor: '#f5f5f5',
  color: 'black',
  cursor: 'pointer'
}

const SelectStyle = {
  color: '#000',
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  backgroundColor: 'white',
  minWidth: '100%'
}

const ValueDisplayStyle = {
  padding: '8px',
  border: '1px solid #eee',
  borderRadius: '4px',
  backgroundColor: '#f9f9f9',
  display: 'inline-block'
}

const chipStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 10px',
  borderRadius: '16px',
  backgroundColor: '#e0e0e0',
  fontSize: '0.8125rem',
  lineHeight: '1.25',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '4px',
  transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'pointer',
  border: 'none',
  '&:hover': {
    backgroundColor: '#cacaca'
  }
} as const

export default FeatureFlagsAdminPage
