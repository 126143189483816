import { ListItem } from '@mui/material'
import { CustomList, MenuItemType, SidebarIcon, SidebarText } from '../utils/sidebarHelper'
import { Link } from 'react-router-dom'

export const SidebarMobileMenuList: React.FC<{
  menuItems: MenuItemType[]
  currentPath: string
  onItemClick: () => void
}> = ({ menuItems, currentPath, onItemClick }) => (
  <CustomList>
    {menuItems.map((item) => (
      <ListItem
        key={item.text}
        component={Link}
        to={item.path}
        onClick={onItemClick}
        sx={{
          minHeight: 48,
          px: 2.5,
          backgroundColor: currentPath === item.path ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          borderRight: currentPath === item.path ? '3px solid #8b5cf6' : 'none',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
          }
        }}
      >
        <SidebarIcon sx={{ mr: 3 }}>{item.icon}</SidebarIcon>
        <SidebarText primary={item.text} />
      </ListItem>
    ))}
  </CustomList>
)
