import React, { useState, useEffect } from 'react'
import { Activity, Enrollment, EnrollmentUserRequest } from '../../../pure-js/types/GrooverTypes'
import styled from '@emotion/styled'
import Box from './Box'
import EnrollmentType from './EnrollmentType'
import { Checkbox, CircularProgress } from '@mui/material'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { CustomTextField } from '../styles/AdminPageStyles'
import MenuItem from '@mui/material/MenuItem'

// Define a type for the local checkin data
type CheckinData = {
  enrollmentId: string
  userId: string
  activityId: string
  occurenceNo: number
  isChecked: boolean
}

interface CheckinListProps {
  enrollments: Enrollment[]
  onUserClick: (enrollmentId: string, activityId: string) => void
  activity: Activity
  onCheckinChange?: (enrollmentId: string, userId: string, occurenceNo: number, checked: boolean) => Promise<boolean>
}

const CheckinList: React.FC<CheckinListProps> = ({ enrollments, onUserClick, activity, onCheckinChange }) => {
  // State for tracking selected occurrence
  const [selectedOccurrence, setSelectedOccurrence] = useState<number>(0)
  const [occurrences, setOccurrences] = useState<{ date: string; value: number }[]>([])
  // Local state for tracking checkins without modifying the enrollment data
  const [checkinState, setCheckinState] = useState<CheckinData[]>([])
  // Track pending operations to prevent duplicate requests
  const [pendingOperations, setPendingOperations] = useState<Set<string>>(new Set())

  // Initialize occurrences when activity is found
  useEffect(() => {
    if (activity) {
      // Generate dates for each occurrence
      const dates = Array.from({ length: activity.noOfOccasions }, (_, i) => ({
        date: dayjs(activity.startDate)
          .add(7 * i, 'day')
          .format('YYYY-MM-DD'),
        value: i
      }))
      setOccurrences(dates)

      // Find the best occurrence to select by default:
      // 1. Today's date if it exists
      // 2. Or the next future date if today is not an occurrence
      // 3. Or the first occurrence if all are in the past
      const today = dayjs().format('YYYY-MM-DD')
      const todayOccurrence = dates.find((d) => d.date === today)

      if (todayOccurrence) {
        // If today is an occurrence, select it
        setSelectedOccurrence(todayOccurrence.value)
      } else {
        // Find the next future occurrence
        const now = dayjs()
        const futureOccurrences = dates.filter((d) => dayjs(d.date).isAfter(now))

        if (futureOccurrences.length > 0) {
          // Sort by date and get the closest future date
          const nextOccurrence = futureOccurrences.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))[0]
          setSelectedOccurrence(nextOccurrence.value)
        } else {
          // If all occurrences are in the past, select the latest one
          const latestOccurrence = dates.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))[0]
          setSelectedOccurrence(latestOccurrence.value)
        }
      }
    }
  }, [activity])

  // Initialize checkin state from enrollment data
  useEffect(() => {
    initializeCheckinState()
  }, [enrollments, selectedOccurrence, activity])

  // Initialize checkin state from enrollments
  const initializeCheckinState = () => {
    const checkins: CheckinData[] = []

    console.log('enrollments', enrollments)
    enrollments.forEach((enrollment) => {
      // Process main user
      const mainUserIsChecked =
        enrollment.checkins?.some(
          (c) =>
            c.userId === enrollment.mainUser.id && c.activityId === activity.id && c.occurenceNo === selectedOccurrence
        ) || false

      checkins.push({
        enrollmentId: enrollment.id,
        userId: enrollment.mainUser.id,
        activityId: activity.id,
        occurenceNo: selectedOccurrence,
        isChecked: mainUserIsChecked
      })

      // Process partner user if exists
      if (enrollment.partnerUser) {
        const partnerUserIsChecked =
          enrollment.checkins?.some(
            (c) =>
              c.userId === enrollment.partnerUser?.id &&
              c.activityId === activity.id &&
              c.occurenceNo === selectedOccurrence
          ) || false

        checkins.push({
          enrollmentId: enrollment.id,
          userId: enrollment.partnerUser.id,
          activityId: activity.id,
          occurenceNo: selectedOccurrence,
          isChecked: partnerUserIsChecked
        })
      }
    })

    setCheckinState(checkins)
  }

  // Calculate total number of individual participants (main + partner users)
  const getTotalParticipants = (enrollments: Enrollment[]): number => {
    return enrollments.reduce((sum, enrollment) => {
      // Count main user
      let count = 1
      // Count partner user if exists
      if (enrollment.partnerUser) {
        count += 1
      }
      return sum + count
    }, 0)
  }

  // Calculate total number of checkins across all enrollments
  const getTotalCheckins = (enrollments: Enrollment[]): number => {
    return enrollments.reduce((sum, enrollment) => {
      // Only count checkins for the selected occurrence and current activity
      const checkins = enrollment.checkins || []
      const matchingCheckins = checkins.filter(
        (c) => c.occurenceNo === selectedOccurrence && c.activityId === activity.id
      )
      return sum + matchingCheckins.length
    }, 0)
  }

  // Handle occurrence selection change
  const handleOccurrenceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOccurrence(Number(event.target.value))
  }

  // Generate a unique key for a checkin operation
  const getOperationKey = (enrollmentId: string, userId: string, occurenceNo: number) =>
    `${enrollmentId}-${userId}-${activity.id}-${occurenceNo}`

  // Handle checkbox change
  const handleCheckboxChange = async (enrollmentId: string, userId: string, checked: boolean) => {
    if (!onCheckinChange) return

    const operationKey = getOperationKey(enrollmentId, userId, selectedOccurrence)

    // If this operation is already in progress, ignore
    if (pendingOperations.has(operationKey)) return

    // Update local state immediately for better UX
    setCheckinState((prev) =>
      prev.map((item) =>
        item.enrollmentId === enrollmentId && item.userId === userId && item.occurenceNo === selectedOccurrence
          ? { ...item, isChecked: checked }
          : item
      )
    )

    // Mark this operation as pending
    setPendingOperations((prev) => new Set(prev).add(operationKey))

    try {
      // Call the parent component's handler to update backend
      const success = await onCheckinChange(enrollmentId, userId, selectedOccurrence, checked)

      if (!success) {
        // If backend update fails, revert the local state
        setCheckinState((prev) =>
          prev.map((item) =>
            item.enrollmentId === enrollmentId && item.userId === userId && item.occurenceNo === selectedOccurrence
              ? { ...item, isChecked: !checked } // Revert to previous state
              : item
          )
        )
        toast.error('Failed to update check-in status')
      }
    } catch (error) {
      // If there's an exception, revert the local state
      setCheckinState((prev) =>
        prev.map((item) =>
          item.enrollmentId === enrollmentId && item.userId === userId && item.occurenceNo === selectedOccurrence
            ? { ...item, isChecked: !checked } // Revert to previous state
            : item
        )
      )
      toast.error('Failed to update check-in status')
    } finally {
      // Remove this operation from pending set
      setPendingOperations((prev) => {
        const updated = new Set(prev)
        updated.delete(operationKey)
        return updated
      })
    }
  }

  // Create a flattened list of users from enrollments
  const flattenedUsers = enrollments.flatMap((enrollment) => {
    const users: {
      userId: string
      userName: string
      userType: EnrollmentUserRequest['type']
      enrollmentId: string
      checkedIn: boolean
      paidStatus: string
    }[] = [
      {
        userId: enrollment.mainUser.id,
        userName: enrollment.mainUser.name,
        userType: enrollment.mainUser.type,
        enrollmentId: enrollment.id,
        // This just shows generic checkin status, not for specific occurrence
        checkedIn: !!enrollment.checkins?.find((x) => x.userId === enrollment.mainUser?.id),
        paidStatus: enrollment.paymentStatus
      }
    ]

    if (enrollment.partnerUser) {
      users.push({
        userId: enrollment.partnerUser.id,
        userName: enrollment.partnerUser.name,
        userType: enrollment.partnerUser.type,
        enrollmentId: enrollment.id,
        checkedIn: !!enrollment.checkins?.find((x) => x.userId === enrollment.partnerUser?.id),
        paidStatus: enrollment.paymentStatus
      })
    }

    return users
  })

  const sortedUsers = flattenedUsers.sort((a, b) => a.userName.localeCompare(b.userName))

  // Get checkin state for a specific user
  const getUserCheckinState = (enrollmentId: string, userId: string): boolean => {
    const checkin = checkinState.find(
      (c) => c.enrollmentId === enrollmentId && c.userId === userId && c.occurenceNo === selectedOccurrence
    )
    return checkin?.isChecked || false
  }

  // Check if operation is pending
  const isOperationPending = (enrollmentId: string, userId: string): boolean => {
    const operationKey = getOperationKey(enrollmentId, userId, selectedOccurrence)
    return pendingOperations.has(operationKey)
  }

  return (
    <Box direction="column" fullWidth gap="16px">
      {activity && (
        <OccurrenceSelectContainer>
          <CustomTextField
            select
            label={getFigmaText(Texts.adminGeneralInputFieldsCheckinOccurenceLabel)}
            value={selectedOccurrence.toString()}
            onChange={(e) => handleOccurrenceChange(e as any)}
            fullWidth
          >
            {occurrences.map((occ, index) => {
              const isToday = occ.date === dayjs().format('YYYY-MM-DD')

              return (
                <MenuItem key={index} value={occ.value.toString()}>
                  {occ.date}
                  {isToday ? ' (Today)' : ''}
                </MenuItem>
              )
            })}
          </CustomTextField>
        </OccurrenceSelectContainer>
      )}
      <CheckinStats>
        {formatText(getFigmaText(Texts.adminGeneralCheckinPageCheckinStats), [
          getTotalCheckins(enrollments),
          getTotalParticipants(enrollments)
        ])}
      </CheckinStats>
      <Box direction="column" fullWidth gap="8px">
        {sortedUsers.map((item, index) => {
          const isChecked = item.userId ? getUserCheckinState(item.enrollmentId, item.userId) : false
          const isPending = item.userId ? isOperationPending(item.enrollmentId, item.userId) : false

          return (
            <UserRow key={`${item.enrollmentId}-${index}`} direction="row" fullWidth>
              <Box
                direction="row"
                align="center"
                gap="8px"
                fullWidth
                onClick={() => onUserClick(item.enrollmentId, activity.id)}
              >
                <EnrollmentType partnerType={item.userType} />
                <UserName>{item.userName}</UserName>
              </Box>
              <StatusContainer direction="row" gap="8px" align="center">
                <CheckboxContainerBox align="center" justify="center" isPending={isPending}>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => {
                      e.stopPropagation() // Prevent row click
                      if (!isPending && onCheckinChange && item.userId) {
                        handleCheckboxChange(item.enrollmentId, item.userId, e.target.checked)
                      }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    disabled={isPending || !onCheckinChange}
                  />
                  {isPending && (
                    <CircularProgressBox align="center" justify="center">
                      <CircularProgress size={40} thickness={10} />
                    </CircularProgressBox>
                  )}
                </CheckboxContainerBox>
                <StatusDot status={item.paidStatus === 'paid' ? 'green' : 'yellow'} />
              </StatusContainer>
            </UserRow>
          )
        })}
      </Box>
    </Box>
  )
}

const CheckinStats = styled.p`
  color: white;
  margin: 0;
  padding: 8px;
`

const UserRow = styled(Box)`
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const UserName = styled.span`
  font-size: 1.1rem;
`

const StatusContainer = styled(Box)`
  min-width: 120px; // Increased to accommodate checkbox
  justify-content: flex-end;
`

const CheckboxContainerBox = styled(Box)<{ isPending: boolean }>`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: ${(props) => (props.isPending ? 'wait' : 'pointer')};
  opacity: ${(props) => (props.isPending ? 0.5 : 1)};
`

const CircularProgressBox = styled(Box)`
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
`

const StatusIndicator = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StatusDot = styled.div<{ status: 'green' | 'yellow' }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.status === 'green' ? '#4CAF50' : '#FFC107')};
  flex-shrink: 0;
`

const OccurrenceSelectContainer = styled.div`
  width: 100%;
`

export default CheckinList
