import React, { useState } from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import { DailyRegistrationData } from '../utils/enrollmentUtils'
import { getFigmaText, formatText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

interface DashboardCardProps {
  icon: React.ReactElement
  title: string
  value: string | number
  subtitle?: string
  chart?: React.ReactNode
  chartData?: DailyRegistrationData[]
}

const DashboardCard: React.FC<DashboardCardProps> = ({ icon, title, value, subtitle = '', chart, chartData }) => {
  const renderChart = () => {
    // If both chart and chartData are provided, show LineChart on top and chart below
    if (chart && chartData && chartData.length > 0) {
      return (
        <>
          <LineChart data={chartData} totalCount={value} />
          <ChartContainer>{chart}</ChartContainer>
        </>
      )
    }

    // If only chart is provided
    if (chart) {
      return <ChartContainer>{chart}</ChartContainer>
    }

    // If only chartData is provided
    if (chartData && chartData.length > 0) {
      return <LineChart data={chartData} totalCount={value} />
    }

    // If neither chart nor chartData is provided
    return (
      <Box fullHeight align="center" justify="center" fullWidth>
        <Box fullWidth>
          <DashboardCardValue>{value}</DashboardCardValue>
          {subtitle && <DashboardCardSubTitle>{subtitle}</DashboardCardSubTitle>}
        </Box>
      </Box>
    )
  }

  return (
    <DashboardCardInstance fullWidth>
      <Box direction="column" fullHeight fullWidth>
        <Box direction="row" gap="1rem">
          <IconWrapper>{icon}</IconWrapper>
          <HeaderText>{title}</HeaderText>
        </Box>
        {renderChart()}
      </Box>
    </DashboardCardInstance>
  )
}

interface LineChartProps {
  data: DailyRegistrationData[]
  totalCount?: string | number
}

const LineChart: React.FC<LineChartProps> = ({ data, totalCount }) => {
  const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number; date: string; count: number }>({
    visible: false,
    x: 0,
    y: 0,
    date: '',
    count: 0
  })

  if (!data || data.length === 0) return null

  // Calculate max value for scaling
  const maxCount = Math.max(...data.map((item) => item.total))

  // Create points for the polyline
  const svgHeight = 80
  const svgWidth = 220
  const topPadding = 10 // Add padding to the top to prevent truncation

  // Generate datapoints for both drawing and interaction
  // Remove any margins to make the chart extend fully from left to right
  const dataPoints = data.map((item, index) => {
    const x = (index / (data.length - 1)) * svgWidth
    // Adjust the y calculation to account for top padding
    const y = topPadding + (svgHeight - topPadding) - (item.total / maxCount) * (svgHeight - topPadding)
    return {
      x,
      y,
      date: item.date,
      count: item.total,
      dailyCount: item.count
    }
  })

  const points = dataPoints.map((point) => `${point.x},${point.y}`).join(' ')

  // Use the provided total count (from getAllEnrollmentsCount) if available
  const displayedTotal = totalCount !== undefined ? totalCount : data.length > 0 ? data[data.length - 1].total : 0

  // Calculate date range for display
  const startDate = data.length > 0 ? new Date(data[0].date) : new Date()
  const endDate = data.length > 0 ? new Date(data[data.length - 1].date) : new Date()

  // Format dates
  const formatDate = (date: Date | string): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date
    return dateObj.toLocaleDateString('sv-SE', { day: 'numeric', month: 'short' })
  }

  const dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`

  // Simpler approach to handle mouse movement
  const handleMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    const svgRect = e.currentTarget.getBoundingClientRect()
    const mouseX = e.clientX - svgRect.left
    const percentage = mouseX / svgRect.width

    // Find the closest data point index
    const index = Math.min(Math.floor(percentage * data.length), data.length - 1)

    if (index >= 0 && index < dataPoints.length) {
      const point = dataPoints[index]
      setTooltip({
        visible: true,
        x: point.x,
        y: point.y,
        date: point.date,
        count: point.dailyCount
      })
    }
  }

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false })
  }

  return (
    <ChartWrapper>
      <ChartTotal>
        <TotalLabel>{dateRangeText}</TotalLabel>

        <TotalValue>{displayedTotal}</TotalValue>
      </ChartTotal>

      <div
        style={{
          position: 'relative',
          width: '100%',
          margin: 0,
          padding: 0
        }}
      >
        <svg
          width="100%"
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          preserveAspectRatio="none" // This ensures the SVG stretches to fill container
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: 'crosshair',
            display: 'block' // Removes any whitespace below the SVG
          }}
        >
          {/* Gradient area under the line */}
          <defs>
            <linearGradient id="chartGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgba(255, 255, 255, 0.3)" />
              <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
            </linearGradient>
          </defs>

          {/* Area fill - adjust to include top padding */}
          <path d={`M0,${svgHeight} ${points} ${svgWidth},${svgHeight} Z`} fill="url(#chartGradient)" />

          {/* Line */}
          <polyline points={points} fill="none" stroke="white" strokeWidth="2" />

          {/* Tooltip marker and line */}
          {tooltip.visible && (
            <>
              <line
                x1={tooltip.x}
                y1={0}
                x2={tooltip.x}
                y2={svgHeight}
                stroke="rgba(255,255,255,0.5)"
                strokeWidth="1"
                strokeDasharray="3,3"
              />
              <circle cx={tooltip.x} cy={tooltip.y} r="4" fill="white" />
            </>
          )}
        </svg>

        {/* Tooltip */}
        {tooltip.visible && (
          <Tooltip
            style={{
              left: `${(tooltip.x / svgWidth) * 100}%`,
              transform: 'translateX(-50%)'
            }}
          >
            <TooltipDate>{formatDate(tooltip.date)}</TooltipDate>
            <TooltipCount>
              {formatText(getFigmaText(Texts.componentsKpIsGraphRegistrationMouseOver), [tooltip.count])}
            </TooltipCount>
          </Tooltip>
        )}
      </div>
    </ChartWrapper>
  )
}

// Styled Components
const DashboardCardInstance = styled(Box)`
  max-width: 400px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 1rem;
  flex: 1;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(224, 184, 215, 0.31);
  @media (max-width: 768px) {
    background: none;
    border: none;
    flex: 1 1 100%;
    min-width: 100%;
  }
`

const IconWrapper = styled(Box)`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderText = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
`

const ChartContainer = styled(Box)`
  width: 100%;
`

const ChartWrapper = styled(Box)`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  margin-bottom: 10px;
`

const ChartTotal = styled(Box)`
  position: absolute;
  left: 10px;
  top: 5px;
  text-align: left;
`

const TotalValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
`

const TotalLabel = styled.div`
  font-size: 0.75rem;
  color: #ccc;
`

const DashboardCardValue = styled(Box)`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
    font-size: 1.2rem;
  }
`

const DashboardCardSubTitle = styled.p`
  font-size: 1rem;
  color: #ccc;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

const Tooltip = styled.div`
  position: absolute;
  top: -25px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  pointer-events: none;
  z-index: 10;
  white-space: nowrap;
`

const TooltipDate = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`

const TooltipCount = styled.div`
  color: #ccc;
`

export default DashboardCard
