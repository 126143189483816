import React from 'react'
import { FormGroup, FormControlLabel, Checkbox, InputLabel } from '@mui/material'
import Box from './Box'
import styled from '@emotion/styled'
import { getFigmaText, formatText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { Check } from '@mui/icons-material'
import { Discount } from '../../../pure-js/types/DiscountTypes'

interface DiscountSelectorProps {
  discounts: Discount[]
  selectedDiscountIds: string[]
  onDiscountToggle: (discountId: string) => void
  readOnly?: boolean
}

const formatDiscountValue = (discount: Discount): string => {
  if (!discount.calculationType || !discount.value) return ''
  return discount.calculationType === 'percentage' ? `${discount.value}%` : `${(discount.value / 100).toFixed(0)} SEK`
}

const DiscountSelector: React.FC<DiscountSelectorProps> = ({
  discounts,
  selectedDiscountIds,
  onDiscountToggle,
  readOnly = false
}) => {
  return (
    <Container direction="column" fullWidth justify="space-between">
      <Box direction="row" fullWidth justify="space-between">
        <InputLabel size="small" style={{ fontSize: '14px', marginBottom: '-6px', marginLeft: '22px' }}>
          {getFigmaText(Texts.adminGeneralInputFieldsActivityDiscounts)}
        </InputLabel>
        <InputLabel size="small" style={{ fontSize: '14px', marginBottom: '-6px', marginRight: '0px' }}>
          {getFigmaText(Texts.adminGeneralInputFieldsActivityDiscountCanBeCombinedLabel)}
        </InputLabel>
      </Box>
      <DiscountInput direction="column" fullWidth>
        <FormGroup>
          {discounts.map((discount) => (
            <Box direction="row" fullWidth key={discount.id}>
              <Box direction="column" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedDiscountIds.includes(discount.id)}
                      onChange={() => !readOnly && onDiscountToggle(discount.id)}
                      disabled={readOnly}
                    />
                  }
                  label={
                    <DiscountLabel direction="row" fullWidth justify="space-between" style={{ marginBottom: 0 }}>
                      <span>{discount.name}</span>
                      {discount.type !== 'multi_course_matrix' && (
                        <DiscountValue>{formatDiscountValue(discount)}</DiscountValue>
                      )}
                    </DiscountLabel>
                  }
                />
                {/* Add sub discounts here */}
                {discount.type === 'multi_course_matrix' && discount.courseDiscounts && (
                  <SubDiscountInfo fullWidth>
                    {discount.courseDiscounts.map((cd) => (
                      <div key={`${discount.id}-${cd.course}`}>
                        {formatText(getFigmaText(Texts.componentsPublicDiscountCssPublicActivityDiscountSubRule), [
                          `${cd.course} : ${discount.calculationType === 'amount' ? (cd.discount / 100).toFixed(0) + ' SEK' : cd.discount + '%'}`
                        ])}
                      </div>
                    ))}
                  </SubDiscountInfo>
                )}
              </Box>

              {/* Show if the discount can be combined with other discounts */}
              <CanBeCombinedCheckbox>{discount.canBeCombined && <Check />}</CanBeCombinedCheckbox>
            </Box>
          ))}
          {discounts.length === 0 && <NoDiscountsText>"Inga rabatter konfigurerade"</NoDiscountsText>}
        </FormGroup>
      </DiscountInput>
    </Container>
  )
}

const CanBeCombinedCheckbox = styled(Box)`
  width: 80px;
`

const Container = styled(Box)``

const DiscountInput = styled(Box)`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin: 12px 8px;
  padding: 8px;
  width: 100%;

  .MuiFormGroup-root {
    width: 100%;
  }

  .MuiFormControlLabel-root {
    width: 100%;
    margin: 0;
  }

  .MuiCheckbox-root {
    padding: 2px;
  }
`

const DiscountLabel = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: white;
`

const DiscountValue = styled('span')`
  color: rgba(255, 255, 255, 0.7);
  margin-left: 16px;
`

const SubDiscountInfo = styled(Box)`
  margin: 0;
  margin-left: 32px;
  font-size: 0.85em;
  color: rgba(255, 255, 255, 0.6);
`

const NoDiscountsText = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  font-size: 14px;
`

export default DiscountSelector
