import { collection, query, where } from 'firebase/firestore'
import { db } from '../libs/Firebase.js'
import { useFirestoreQuery } from './useFirestoreQuery'
import { Collections } from '../../../pure-js/libs/Collections'
import { Enrollment, EnrollmentStatus } from '../../../pure-js/types/GrooverTypes'

export const useEnrollmentsForClient = (clientId: string | null) => {
  if (!clientId)
    return {
      status: 'idle',
      data: undefined,
      error: 'clientId is not set',
      isLoading: false
    }
  return useFirestoreQuery<Enrollment[]>(
    query(
      collection(db, `${Collections.CLIENTS}/${clientId}/${Collections.ENROLLMENTS}`),
      where('status', '!=', EnrollmentStatus.Deleted)
      // Removed 'select' as it is not exported by 'firebase/firestore'
    ),
    {
      fnName: 'useEnrollmentsForClient',
      enabled: !!clientId
    }
  )
}
