import { Enrollment, EnrollmentPublic, VatData } from '../types/GrooverTypes'

export const obfuscateEmail = (email: string) => {
  const [user, domain] = email.split('@')
  return `${user.slice(0, 3)}...@${domain}`
}

export const obfuscateName = (name: string) => {
  const firstThreeCharsOfFirstName = name.slice(0, 3)
  const firstThreeCharsOfLastName = name.split(' ')[1]?.slice(0, 3) ?? ''

  if (!firstThreeCharsOfLastName) return `${firstThreeCharsOfFirstName}...`

  return `${firstThreeCharsOfFirstName}... ${firstThreeCharsOfLastName}...`
}

export const obfuscatePhoneNumber = (phoneNumber: string) => {
  const lastThreeDigits = phoneNumber.slice(-3)

  return `*****${lastThreeDigits}`
}

export const obfuscateSSN = (ssn: string) => `${ssn.slice(0, 2)}***`

export const obfuscateEnrollment = (enrollment: Enrollment) => {
  const obfuscatedMainUser = {
    ...enrollment.mainUser,
    name: obfuscateName(enrollment.mainUser.name),
    email: obfuscateEmail(enrollment.mainUser.email),
    phone: obfuscatePhoneNumber(enrollment.mainUser.phone),
    nonObfuscatedName: enrollment.mainUser.name
  }
  if (enrollment.mainUser.ssn) obfuscatedMainUser.ssn = obfuscateSSN(enrollment.mainUser.ssn)

  const obfuscatedParnerUser = enrollment.partnerUser
    ? {
        ...enrollment.partnerUser,
        name: obfuscateName(enrollment.partnerUser.name),
        email: obfuscateEmail(enrollment.partnerUser.email),
        phone: obfuscatePhoneNumber(enrollment.partnerUser.phone),
        nonObfuscatedName: enrollment.partnerUser.name
      }
    : undefined
  if (obfuscatedParnerUser && enrollment.partnerUser?.ssn)
    obfuscatedParnerUser.ssn = obfuscateSSN(enrollment.partnerUser.ssn)

  return {
    ...enrollment,
    mainUser: obfuscatedMainUser,
    partnerUser: obfuscatedParnerUser
  }
}

export const getTotalUnpaidPaymentsAmount = (enrollment: Enrollment) => {
  const totalPrice = enrollment.priceDetails.reduce((total, pd) => {
    return total + pd.finalPrice
  }, 0)
  const totalPaid = enrollment.payments.reduce((total, payment) => {
    return total + payment.totalAmount
  }, 0)

  return totalPrice - totalPaid
}

export const getCurrencyFromEnrollment = (enrollment: Enrollment) => 'SEK'

export const convertEnrollmentToEnrollmentPublic = (enrollment: Enrollment): EnrollmentPublic => {
  return {
    id: enrollment.id,
    createdAt: enrollment.createdAt,
    paymentStatus: enrollment.paymentStatus,
    ocr: enrollment.ocr,
    status: enrollment.status
  }
}

export const getVatFromBruttoPrice = (brutto: number, vat: number) => {
  const vatCalc = (brutto * vat) / (100 + vat)
  const vatValue = Math.round(vatCalc * 100) / 100

  return vatValue
}

export const calculateEnrollmentVATTotal = (enrollment: Enrollment): number => {
  const activities = enrollment.activities || []
  const vatAmount = enrollment.priceDetails.reduce((sum, pd) => {
    const activityVat = activities.find((activity) => activity.id === pd.activityId)?.vat || 0
    sum += getVatFromBruttoPrice(pd.finalPrice, activityVat) / 100
    sum = Math.round(sum * 100) / 100
    return sum
  }, 0)
  return Number(vatAmount.toFixed(2))
}

export const calculateEnrollmentVATGroups = (enrollment: Enrollment): VatData => {
  const activities = enrollment.activities || []
  const vat = enrollment.priceDetails.reduce((vatData, pd) => {
    const activityVat = activities.find((activity) => activity.id === pd.activityId)?.vat || 0
    if (!vatData[activityVat]) vatData[activityVat] = 0
    vatData[activityVat] += getVatFromBruttoPrice(pd.finalPrice, activityVat) / 100
    vatData[activityVat] = Math.round(vatData[activityVat] * 100) / 100
    return vatData
  }, {} as VatData)

  return vat
}
