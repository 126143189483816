import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import {
  Dashboard,
  People,
  Settings,
  ChevronLeft,
  Menu,
  CropFree,
  CurrencyExchange,
  Paid,
  ChevronRight,
  AlternateEmail
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import config from '../Config'
import { MenuButton, MenuItemType, MobileMenuButton } from '../utils/sidebarHelper'
import { SidebarMobile } from './SidebarMobile'
import UserMenu from './UserMenu'
import { SidebarDesktop } from './SidebarDesktop'
import { useClient } from '../context/ClientContext'

interface SidebarProps {
  onClientChange?: (clientId: string) => void
}

const Sidebar: React.FC<SidebarProps> = ({ onClientChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { clientId, setClientId } = useClient()

  const toggleSidebar = () => setIsOpen((prev) => !prev)
  const closeSidebar = () => setIsOpen(false)

  const menuItems: MenuItemType[] = [
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuDashboard),
      icon: <Dashboard />,
      path: RoutePath.DASHBOARD
    },
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuEnrollments),
      icon: <People />,
      path: RoutePath.ALLENROLLMENTS
    },
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuSettings),
      icon: <Settings />,
      path: RoutePath.EDIT_CLIENT
    },
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuKommunikation),
      icon: <AlternateEmail />,
      path: RoutePath.COMMUNICATION
    },
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuWebIntegration),
      icon: <CropFree />,
      path: RoutePath.ACTIVITY_IFRAME_CONFIGURATOR
    },
    {
      text: getFigmaText(Texts.adminGeneralAdminMenuMainMenuFinancial),
      icon: <CurrencyExchange />,
      path: RoutePath.FINANCIALS_OVERVIEW
    }
  ].filter((item): item is MenuItemType => !!item)

  const handleClientIdSelect = (newClientId: string) => {
    setClientId(newClientId)
    onClientChange?.(newClientId)
  }
  return (
    <>
      <MenuButton onClick={toggleSidebar}>{isOpen ? <ChevronLeft /> : <ChevronRight />}</MenuButton>

      {!isOpen ? (
        <MobileMenuButton onClick={toggleSidebar}>
          <Menu />
        </MobileMenuButton>
      ) : null}
      {isOpen || !isMobile ? <UserMenu onClientIdSelect={handleClientIdSelect} initialClientId={clientId} /> : null}

      {isMobile ? (
        <SidebarMobile isOpen={isOpen} menuItems={menuItems} currentPath={location.pathname} onClose={closeSidebar} />
      ) : (
        <SidebarDesktop isOpen={isOpen} menuItems={menuItems} currentPath={location.pathname} />
      )}
    </>
  )
}

export default Sidebar
