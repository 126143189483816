declare global {
  interface Array<T> {
    /**
     * Filters array for unique values and joins them with ' / '
     * @param capitalize If true, capitalizes the first letter of each value
     * @returns A string of unique values joined with ' / '
     */
    onlyUniqueJoin(capitalize?: boolean): string
  }
}

export const onlyUnique = (value: string | undefined, index: number, self: (string | undefined)[]) => {
  return self.indexOf(value) === index
}

if (!Array.prototype.onlyUniqueJoin) {
  Array.prototype.onlyUniqueJoin = function (this: (string | undefined)[], capitalize: boolean = false) {
    const uniqueValues = this.filter(onlyUnique)

    if (capitalize) {
      const capitalizedValues = uniqueValues.map((value) => {
        if (!value) return value
        return value.charAt(0).toUpperCase() + value.slice(1)
      })
      return capitalizedValues.join(' / ')
    }

    return uniqueValues.join(' / ')
  }
}
