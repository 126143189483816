import React, { useEffect, useState } from 'react'
import { Box, Paper, Popper, styled } from '@mui/material'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { getClient } from '../libs/DBApiHandler'
import DiscountSelector from './DiscountSelector'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from './FigmaText'

interface ActivityDetailModalProps {
  activity: Activity | null
  anchorEl: HTMLElement | null
  onClose: () => void
  clientId: string
}

const ActivityDetailModal: React.FC<ActivityDetailModalProps> = ({ activity, anchorEl, onClose, clientId }) => {
  const [client, setClient] = useState<Client | null>(null)
  const open = Boolean(anchorEl) && Boolean(activity)

  useEffect(() => {
    const fetchClient = async () => {
      if (clientId) {
        try {
          const clientData = await getClient(clientId)
          if (clientData) {
            setClient(clientData)
          }
        } catch (error) {
          console.error('Error fetching client:', error)
        }
      }
    }

    if (open) {
      fetchClient()
    }
  }, [clientId, open])

  // No render if no activity or anchor element
  if (!activity || !anchorEl) return null

  return (
    <StyledPopper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]}
    >
      <ModalContent>
        {activity.bannerUrl && (
          <BannerContainer>
            <BannerImage src={activity.bannerUrl} alt={activity.name} />
          </BannerContainer>
        )}

        <ModalSection>
          <ModalTitle>{activity.name}</ModalTitle>

          <DetailSection>
            <DetailGrid>
              <DetailItem>
                <DetailLabel>
                  <FigmaText textKey={Texts.adminGeneralInputFieldsActivityStyle} />
                </DetailLabel>
                <DetailValue>{activity.style}</DetailValue>
              </DetailItem>
              {activity.price > 0 && (
                <DetailItem>
                  <DetailLabel>
                    <FigmaText textKey={Texts.adminGeneralInputFieldsActivityPrice} />
                  </DetailLabel>
                  <DetailValue>{activity.price / 100} kr</DetailValue>
                </DetailItem>
              )}
            </DetailGrid>
          </DetailSection>

          {client && activity.discountIds && activity.discountIds.length > 0 && (
            <DiscountSection>
              <DiscountSelector
                discounts={client.discounts}
                selectedDiscountIds={activity.discountIds || []}
                onDiscountToggle={() => {}}
                readOnly
              />
            </DiscountSection>
          )}
        </ModalSection>
      </ModalContent>
    </StyledPopper>
  )
}

const StyledPopper = styled(Popper)`
  z-index: 1300;
  width: 400px;
  max-width: 90vw;
`

const ModalContent = styled(Paper)`
  padding: 0;
  background-color: #1f1f1f;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
`

const BannerContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`

const BannerImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ModalSection = styled(Box)`
  padding: 16px;
`

const ModalTitle = styled('h2')`
  margin: 0 0 12px 0;
  font-size: 18px;
  font-weight: 600;
`

const SectionTitle = styled('h3')`
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
`

const DescriptionSection = styled(Box)`
  margin-bottom: 16px;
  max-height: 150px;
  overflow-y: auto;
`

const DescriptionContent = styled(Box)`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);

  img {
    max-width: 100%;
    height: auto;
  }
`

const DetailSection = styled(Box)`
  margin-bottom: 16px;
`

const DetailGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`

const DetailItem = styled(Box)`
  margin-bottom: 8px;
`

const DetailLabel = styled(Box)`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 2px;
`

const DetailValue = styled(Box)`
  font-size: 14px;
`

const DiscountSection = styled(Box)`
  margin-top: 16px;
`

export default ActivityDetailModal
