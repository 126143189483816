import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import useAppState from '../hooks/useAppState'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Activity,
  ActivityEnrollmentStatus,
  ActivityStatus,
  Client,
  EnrollmentStatus,
  OccationInstance
} from '../../../pure-js/types/GrooverTypes'
import { getActivity, getClient, upsertActivity } from '../libs/DBApiHandler'
import {
  Button,
  CircularProgress,
  InputLabel,
  Checkbox,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import Box from '../components/Box'
import { White } from '../../../pure-js/libs/Colors'
import toast from 'react-hot-toast'
import JoditEditor from 'jodit-react'
import { uploadFileToStorage } from '../libs/StorageHelper'
import { format, add } from 'date-fns'
import '../resources/css/EditActivity.css'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import DiscountSelector from '../components/DiscountSelector'
import { COURSELY_PUBLIC_MEDIA_URL } from '../../../pure-js/web'
import {
  ImageContainer,
  BannerImage,
  CustomTextField,
  CheckBoxLabel,
  SectionHeader,
  Section,
  MainContainer,
  SpinnerOverlay,
  StickyFooter,
  UploadButton,
  PageContainer
} from '../styles/AdminPageStyles'
import styled from '@emotion/styled'
import FigmaText from '../components/FigmaText'
import { saveRecentActivity } from '../utils/recentActivitiesStorage'
import { sv } from 'date-fns/locale'

const EditActivityPage: React.FC = () => {
  const { state } = useAppState()
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [client, setClient] = useState<Client | null>(null)
  const [activity, setActivity] = useState<Activity | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [userCanSave, setUserCanSave] = useState<boolean>(false)
  const editorRef = useRef<any>(null)
  const [bannerUploadProgress, setBannerUploadProgress] = useState<number>(0)
  const [bannerDownloadUrl, setBannerDownloadUrl] = useState<string>('')
  const [bannerFilePath, setBannerFilePath] = useState<string>('')
  const [isUploadingBanner, setIsUploadingBanner] = useState(false)
  const [selectedOccation, setSelectedOccation] = useState<OccationInstance | null>(null)
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({})
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()
  const { activityId } = useParams<{ activityId: string }>()

  const auth = getAuth()
  const currentUser = auth.currentUser

  const vatValues: Activity['vat'][] = [0, 6, 12, 25]

  const editorConfig = useMemo(
    () => ({
      height: 300,
      buttons: [
        'source',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'link',
        'image',
        'video',
        '|',
        'align',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'fullsize'
      ],
      imageDefaultWidth: 500, // Set default image width
      uploader: {
        insertImageAsBase64URI: true,
        processPastedFiles: true,
        processDropFiles: true,
        defaultHandlerSuccess: function (this: any, resp: any) {
          const jodit = this.jodit || this
          if (resp.files && resp.files.length) {
            resp.files.forEach((file: string) => {
              jodit.selection.insertImage(file, {
                style: {
                  'max-width': '500px',
                  height: 'auto'
                }
              })
            })
          }
        },
        processFileName: (fileName: string) => fileName,
        isSuccess: (resp: any) => resp.success,
        getMessage: (resp: any) => resp.message,
        process: (resp: any) => resp.files || [],
        error: (e: any) => {
          console.error('Image upload error:', e)
          toast.error('Failed to upload image')
        }
      },
      enableDragAndDropFileToEditor: true,
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      style: {
        background: '#ffffff',
        color: '#000000'
      },
      controls: {
        video: {
          icon: 'video',
          tooltip: 'Insert YouTube',
          popup: (editor: any, current: any, control: any, close: () => void) => {
            const form = editor.create.fromHTML(`
              <form class="jodit-form">
                <input required type="url" placeholder="Enter YouTube URL"/>
                <button type="submit">Insert</button>
              </form>
            `)

            form.addEventListener('submit', (e: Event) => {
              e.preventDefault()
              const input = form.querySelector('input')
              if (input) {
                const url = input.value
                // Convert URL to embed format if needed
                const videoId = url.match(
                  /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i
                )?.[1]
                if (videoId) {
                  const embedHtml = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                  editor.selection.insertHTML(embedHtml)
                }
              }
              close()
            })

            return form
          }
        }
      }
    }),
    []
  )

  const sanitizeLinks = (content: string): string => {
    // Don't add https to mailto links, but ensure other links are absolute
    return content.replace(/href="(?!http|mailto:)/g, 'href="https://')
  }

  const fetchClient = async (clientId: string) => {
    try {
      const clientData = await getClient(clientId)
      if (!clientData) {
        toast.error('Client not found', {
          duration: 2000,
          position: 'top-center'
        })
      }
      return clientData
    } catch (err) {
      console.error('Failed to fetch client:', err)
      toast.error('Failed to fetch client data', {
        duration: 2000,
        position: 'top-center'
      })
    }
  }

  const fetchActivity = async () => {
    try {
      setIsLoading(true)
      if (!activityId) {
        setError('Activity ID is missing')
        return
      }

      const activityData = await getActivity(activityId)
      if (!activityData) {
        toast.error('Activity not found', {
          duration: 2000,
          position: 'top-center'
        })
        return
      }

      // Fetch client data after successful activity fetch
      if (activityData.clientId) {
        const clientData = await fetchClient(activityData.clientId)
        setClient(clientData ?? null)
      }

      setActivity(activityData)
    } catch (err) {
      toast.error('Failed to fetch data!', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const checkUserCanSave = async () => {
    const activityClientId = activity?.clientId || ''
    const userClientIds = (state.user?.authCustomClaims?.clientIds as string[]) || []
    setUserCanSave(userClientIds.includes(activityClientId) && currentUser !== null)
  }

  useEffect(() => {
    checkUserCanSave()
  }, [state, activity, currentUser])

  useEffect(() => {
    fetchActivity()
    checkUserCanSave()
  }, [activityId])

  const handleReturn = () => {
    navigate(-1) // Navigate back
  }

  const handleInputChange = (field: keyof Activity, value: any) => {
    if (activity) {
      setActivity({ ...activity, [field]: value })
    }
  }

  const validateActivityForActiveStatus = (
    activity: Activity
  ): { errors: string[]; fieldErrors: Record<string, string> } => {
    const validationErrors: string[] = []
    const fieldErrorsMap: Record<string, string> = {}

    // Define validation rules with their corresponding error message keys
    const validationRules = [
      {
        field: 'price',
        isValid: () => !!activity.startDate && activity.price > 0,
        errorTextKey: Texts.adminGeneralPagesEditActivityAcitivityValidationPrice
      },
      {
        field: 'startDate',
        isValid: () => !!activity.startDate,
        errorTextKey: Texts.adminGeneralPagesEditActivityActivityValidationStartDate
      },
      {
        field: 'time',
        isValid: () => !!activity.time,
        errorTextKey: Texts.adminGeneralPagesEditActivityActivityValidationTime
      },
      {
        field: 'name',
        isValid: () => !!activity.name && activity.name.trim() !== '',
        errorTextKey: Texts.adminGeneralPagesEditActivityActivityValidationTitel
      },
      {
        field: 'period',
        isValid: () => !!activity.period,
        errorTextKey: Texts.adminGeneralPagesEditActivityActivityValidationPeriod
      },
      {
        field: 'maxAllowedParticipants',
        isValid: () => !!activity.maxAllowedParticipants && activity.maxAllowedParticipants > 0,
        errorTextKey: Texts.adminGeneralPagesEditActivityActivityValidationMaxEnrollment
      },
      // Required numeric fields for Active status
      {
        field: 'length',
        isValid: () => !!activity.length && activity.length > 0,
        errorTextKey: Texts.adminGeneralPagesEditActivityAcitivityValidationLength
      },
      {
        field: 'noOfOccasions',
        isValid: () => !!activity.noOfOccasions && activity.noOfOccasions > 0,
        errorTextKey: Texts.adminGeneralPagesEditActivityAcitivityValidationNoOfOccations
      }
    ]

    // Process all validation rules
    validationRules.forEach((rule) => {
      if (!rule.isValid()) {
        const errorMsg = getFigmaText(rule.errorTextKey)
        validationErrors.push(errorMsg)
        fieldErrorsMap[rule.field] = errorMsg
      }
    })

    return { errors: validationErrors, fieldErrors: fieldErrorsMap }
  }

  const handleSave = async (shouldClose: boolean = false) => {
    if (!activity) return

    try {
      setError(null)
      setIsSaving(true)

      // Clear previous field errors
      setFieldErrors({})

      // Only validate fields if status is Active
      let updatedActivity = { ...activity }
      let errors: string[] = []
      let fieldErrors: Record<string, string> = {}

      if (updatedActivity.status === ActivityStatus.Active) {
        const statusValidation = validateActivityForActiveStatus(updatedActivity)
        errors = statusValidation.errors
        fieldErrors = statusValidation.fieldErrors
      }

      if (errors.length > 0) {
        // Set field errors
        setFieldErrors(fieldErrors)

        // Show all validation errors in a single toast
        toast.error(
          <div>
            <strong>{getFigmaText(Texts.adminGeneralPagesEditActivityActivityValidationHeader)}</strong>
            <ul style={{ margin: '5px 0 0 20px', padding: 0 }}>
              {errors.map((error, index) => (
                <li key={index} style={{ marginBottom: '3px' }}>
                  {error}
                </li>
              ))}
            </ul>
          </div>,
          {
            duration: 5000,
            position: 'top-center',
            style: { maxWidth: '400px' }
          }
        )

        // Don't save the activity at all if validation fails
        setIsSaving(false)
        return // Exit the function early without saving
      }

      // Continue with saving only if validation passes
      updatedActivity = {
        ...updatedActivity,
        description: updatedActivity.description // No processing needed, save as is
      }

      await upsertActivity(updatedActivity)
      saveRecentActivity(updatedActivity)

      setIsSaving(false)
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsSaveSuccesful), {
        duration: 2000,
        position: 'top-center'
      })

      if (shouldClose) {
        handleReturn()
      }
    } catch (err) {
      toast.error('Failed to save activity!', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  const handleDiscountToggle = (discountId: string) => {
    if (!activity) return

    const currentDiscounts = activity.discountIds || []
    const newDiscounts = currentDiscounts.includes(discountId)
      ? currentDiscounts.filter((id) => id !== discountId)
      : [...currentDiscounts, discountId]

    handleInputChange('discountIds', newDiscounts)
  }

  const handleBannerUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activity) return
    const file = event.target.files?.[0]
    if (!file) return

    try {
      setIsUploadingBanner(true)
      setBannerUploadProgress(0)

      const result = await uploadFileToStorage(file, `activityBanners/${activity.id}/banner`, (progress) => {
        setBannerUploadProgress(progress)
      })

      const newBannerUrl = `${COURSELY_PUBLIC_MEDIA_URL}/${result.filePath}`
      setBannerDownloadUrl(result.downloadUrl)
      setBannerFilePath(result.filePath)

      // Update activity with new banner URL immediately
      setActivity((prev) => (prev ? { ...prev, bannerUrl: newBannerUrl } : null))
    } catch (error) {
      console.error('Banner upload failed:', error)
      toast.error('Failed to upload banner')
    } finally {
      setIsUploadingBanner(false)
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const generateOccations = () => {
    if (!activity?.startDate || !activity?.time || !activity?.noOfOccasions) {
      toast.error('Please set start date, time and number of occasions first')
      return
    }

    const occations: OccationInstance[] = []
    const [hours, minutes] = activity.time.split(':').map(Number)

    for (let i = 0; i < activity.noOfOccasions; i++) {
      const startDateTime = add(new Date(`${activity.startDate}T${activity.time}`), { weeks: i })
      occations.push({
        id: crypto.randomUUID(), // Generate a unique ID for each occasion
        index: i,
        startDate: format(startDateTime, "yyyy-MM-dd'T'HH:mm"),
        endDate: format(add(startDateTime, { minutes: activity.length }), "yyyy-MM-dd'T'HH:mm"),
        instructors: []
      })
    }

    handleInputChange('occations', occations)
    toast.success('Generated occations successfully')
  }

  const handleDeleteOccation = (id: string) => {
    if (!activity?.occations) return
    const newOccations = activity.occations.filter((occation) => occation.id !== id)
    handleInputChange('occations', newOccations)
  }

  const handleUpdateOccation = (id: string, field: keyof OccationInstance, value: string | string[]) => {
    if (!activity?.occations) return
    const newOccations = [...activity.occations]
    const occationIndex = newOccations.findIndex((occation) => occation.id === id)
    if (occationIndex >= 0) {
      newOccations[occationIndex] = { ...newOccations[occationIndex], [field]: value }
      handleInputChange('occations', newOccations)
    }
  }

  const handleOccationSelect = (occation: OccationInstance) => {
    setSelectedOccation(occation) // Store the occation directly
  }

  const handleUpdateSelectedOccation = (field: keyof OccationInstance, value: any) => {
    if (!selectedOccation || !activity?.occations) return

    const id = selectedOccation.id
    const newOccations = [...activity.occations]
    const occationIndex = newOccations.findIndex((occation) => occation.id === id)

    if (occationIndex >= 0) {
      newOccations[occationIndex] = { ...newOccations[occationIndex], [field]: value }
      handleInputChange('occations', newOccations)

      // Update the selected occation
      setSelectedOccation({ ...newOccations[occationIndex] })
    }
  }

  const getFieldError = (fieldName: string): string | undefined => {
    return fieldErrors[fieldName]
  }

  const hasFieldError = (fieldName: string): boolean => {
    return !!fieldErrors[fieldName]
  }

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true)
  }

  const handleDeleteConfirm = async () => {
    if (!activity) return

    try {
      setIsSaving(true)

      const updatedActivity = {
        ...activity,
        status: ActivityStatus.Deleted
      }

      await upsertActivity(updatedActivity)
      saveRecentActivity(updatedActivity)

      setOpenDeleteDialog(false)

      toast.success(`${getFigmaText(Texts.adminGeneralInfoTextsDeletedActivity)}`, {
        duration: 2000,
        position: 'top-center'
      })

      handleReturn()
    } catch (err) {
      toast.error('Failed to delete activity!', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false)
  }

  if (isLoading) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          Loading...
        </Box>
      </MainContainer>
    )
  }

  if (!activity) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {getFigmaText(Texts.adminGeneralErrorNoActivityFound)}
        </Box>
      </MainContainer>
    )
  }
  if (error) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {error}
        </Box>
      </MainContainer>
    )
  }

  return (
    <MainContainer fullHeight fullWidth>
      <PageContainer fullWidth>
        <Box position="relative" fullWidth>
          {isSaving && (
            <SpinnerOverlay fullWidth>
              <CircularProgress />
            </SpinnerOverlay>
          )}

          <form>
            <Box direction="row" fullWidth justify="space-between" align="center" top bottom>
              <ArrowBackIcon onClick={handleReturn} style={{ cursor: 'pointer', color: 'white' }} />

              <Box>
                <Button
                  href={`https://app.coursely.se/activity/${activity.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<OpenInNewIcon />}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'rgba(255, 255, 255, 0.1)',
                    color: White,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    margin: '8px 0',
                    gap: '8px',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.3)',
                      color: White
                    }
                  }}
                >
                  {getFigmaText(Texts.adminGeneralCtactaOpenPublicActivityPage)}
                </Button>
              </Box>
            </Box>
            <Box id="SectionContainer" fullWidth gap="16px">
              <Section id="Section: Banner" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionBanner} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <ImageContainer direction="column" fullWidth>
                    {(bannerDownloadUrl || activity.bannerUrl) && (
                      <BannerImage src={bannerDownloadUrl || activity.bannerUrl} alt="Activity Banner" />
                    )}
                  </ImageContainer>

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleBannerUpload}
                  />

                  <Box direction="row" fullWidth gap="16px">
                    {isUploadingBanner && <CircularProgress />}
                    <Box direction="column" justify="flex-end">
                      <UploadButton variant="contained" color="primary" onClick={handleUploadClick}>
                        {getFigmaText(Texts.registrationCtactaUploadNewImage)}
                      </UploadButton>
                      <FigmaText textKey={Texts.adminGeneralInfoTextsBannerImageRecommendation} />
                    </Box>
                  </Box>
                </Box>
              </Section>
              <Section id="Section: General Info" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionGeneral} />
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityTitel)}
                    value={activity ? activity.name : ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    error={hasFieldError('name')}
                    helperText={getFieldError('name')}
                  />
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralStatusLabel)}
                    value={activity?.status || ''}
                    onChange={(e) => handleInputChange('status', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value={ActivityStatus.Active}>
                      {getFigmaText(Texts.adminGeneralEnumsStatusStatusActive)}
                    </MenuItem>
                    <MenuItem value={ActivityStatus.Draft}>
                      {getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft)}
                    </MenuItem>
                    <MenuItem value={ActivityStatus.Cancelled}>
                      {getFigmaText(Texts.adminGeneralEnumsStatusStatusCancelled)}
                    </MenuItem>
                    <MenuItem value={ActivityStatus.Archived}>
                      {getFigmaText(Texts.adminGeneralEnumsStatusStatusArchived)}
                    </MenuItem>
                  </CustomTextField>

                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityType)}
                    value={activity?.type || ''}
                    onChange={(e) => handleInputChange('type', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value="Occasion">
                      {client?.typeTranslations?.Occasion || getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)}
                    </MenuItem>
                    <MenuItem value="Course">
                      {client?.typeTranslations?.Course || getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)}
                    </MenuItem>
                    <MenuItem value="Other">
                      {client?.typeTranslations?.Other || getFigmaText(Texts.adminGeneralEnumsActivityTypeOther)}
                    </MenuItem>
                  </CustomTextField>

                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityRegistrationStatus)}
                    value={activity?.enrollmentStatus || ''}
                    onChange={(e) => handleInputChange('enrollmentStatus', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value={ActivityEnrollmentStatus.Open}>
                      {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusOpen)}
                    </MenuItem>
                    <MenuItem value={ActivityEnrollmentStatus.Closed} disabled>
                      {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusClosed)}
                    </MenuItem>
                    <MenuItem value={ActivityEnrollmentStatus.ClosedForFollowers} disabled>
                      {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusFullFollowers)}
                    </MenuItem>
                    <MenuItem value={ActivityEnrollmentStatus.ClosedForLeaders} disabled>
                      {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusFullLeaders)}
                    </MenuItem>
                    <MenuItem value={ActivityEnrollmentStatus.Full} disabled>
                      {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusFull)}
                    </MenuItem>
                  </CustomTextField>
                </Box>
                <Box className="editorWrapper" fullWidth>
                  <Box left spacing="14px">
                    <InputLabel size="small" style={{ fontSize: '14px' }}>
                      {getFigmaText(Texts.adminGeneralInputFieldsActivityDescription)}
                    </InputLabel>
                  </Box>

                  <JoditEditor
                    ref={editorRef}
                    value={activity?.description || ''}
                    config={editorConfig}
                    onBlur={(newContent) => handleInputChange('description', newContent)}
                  />
                </Box>
              </Section>
              <Section id="Section: Schedule And Duration" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionScheduleAndDuration} />
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralPeriodLabel)}
                    value={activity?.period || ''}
                    onChange={(e) => handleInputChange('period', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                    error={hasFieldError('period')}
                    helperText={getFieldError('period')}
                  >
                    <MenuItem value="VT 2025">VT 2025</MenuItem>
                    <MenuItem value="VT 2025">HT 2025</MenuItem>
                  </CustomTextField>
                </Box>
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityCity)}
                    value={activity?.city || ''}
                    onChange={(e) => handleInputChange('city', e.target.value)}
                  />
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityLocation)}
                    value={activity?.locationId || ''}
                    onChange={(e) => handleInputChange('locationId', e.target.value)}
                  >
                    {client?.locations.map((location) => (
                      <MenuItem id={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityPlace)}
                    value={activity?.place || ''}
                    onChange={(e) => handleInputChange('place', e.target.value)}
                  />
                </Box>
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityStartdate)}
                    type="date"
                    value={activity?.startDate}
                    onChange={(e) => handleInputChange('startDate', e.target.value)}
                    slotProps={{
                      inputLabel: { shrink: true } // Ensures the label moves up when there's a value
                    }}
                    error={hasFieldError('startDate')}
                    helperText={getFieldError('startDate')}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityTime)}
                    type="time"
                    value={activity?.time}
                    onChange={(e) => {
                      const timePattern = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
                      if (e.target.value === '' || timePattern.test(e.target.value)) {
                        handleInputChange('time', e.target.value)
                      }
                    }}
                    slotProps={{
                      inputLabel: { shrink: true } // Ensures the label moves up when there's a value
                    }}
                    error={hasFieldError('time')}
                    helperText={getFieldError('time')}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityLength)}
                    type="number"
                    value={activity?.length === undefined ? '' : activity.length}
                    onChange={(e) => {
                      const value = e.target.value === '' ? undefined : parseInt(e.target.value)
                      handleInputChange('length', value)
                    }}
                    error={hasFieldError('length')}
                    helperText={getFieldError('length')}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityNoOfOccations)}
                    type="number"
                    value={activity?.noOfOccasions === undefined ? '' : activity.noOfOccasions}
                    onChange={(e) => {
                      const value = e.target.value === '' ? undefined : parseInt(e.target.value)
                      handleInputChange('noOfOccasions', value)
                    }}
                    error={hasFieldError('noOfOccasions')}
                    helperText={getFieldError('noOfOccasions')}
                  />
                </Box>
                <Box direction="column" gap="10px" fullWidth align="flex-end">
                  {!activity?.occations || activity.occations.length === 0 ? (
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={generateOccations}
                      disabled={!activity?.startDate || !activity?.time || !activity?.noOfOccasions}
                    >
                      {getFigmaText(Texts.adminGeneralCtactaGenerateWeeklyOccations)}
                    </Button>
                  ) : (
                    <Box direction="row" gap="10px" fullWidth top changeDirectionOnMobile>
                      <ListSection fullWidth>
                        <InputList>
                          {[...activity.occations]
                            .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                            .map((occation) => (
                              <OccationItem
                                key={occation.id}
                                onClick={() => handleOccationSelect(occation)}
                                isSelected={selectedOccation?.id === occation.id}
                              >
                                {format(new Date(occation.startDate), 'yyyy-MM-dd HH:mm (EEE)', { locale: sv })}
                              </OccationItem>
                            ))}
                        </InputList>
                      </ListSection>

                      <Box direction="column" fullWidth>
                        {selectedOccation && (
                          <>
                            <Box direction="row" justify="space-between" align="center" fullWidth>
                              <CustomTextField
                                type="datetime-local"
                                label="Start Time"
                                value={selectedOccation.startDate}
                                onChange={(e) => handleUpdateSelectedOccation('startDate', e.target.value)}
                                fullWidth
                              />
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleDeleteOccation(selectedOccation.id)}
                                size="small"
                                sx={{ height: '40px' }}
                              >
                                {getFigmaText(Texts.adminGeneralCtactaDelete)}
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Section>
              <Box direction="row" gap="10px" fullWidth className="boxColumn">
                <Section id="Section: Target Audience" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralSectionsSectionTargetAudience} />
                  <Box direction="row" gap="10px" fullWidth className="boxColumn">
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityStyle)}
                      value={activity ? activity.style : ''}
                      onChange={(e) => handleInputChange('style', e.target.value)}
                    />
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityLevel)}
                      value={activity ? activity.level : ''}
                      onChange={(e) => handleInputChange('level', e.target.value)}
                    />
                  </Box>
                </Section>
              </Box>
              <Box direction="row" fullWidth className="boxColumn">
                <Section id="Section: Pricing and Discounts" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralSectionsSectionPricingAndDiscounts} />
                  <Box direction="row" gap="10px" fullWidth className="boxColumn">
                    <Box direction="column" fullWidth className="boxColumn">
                      {client?.discounts && (
                        <DiscountSelector
                          discounts={client.discounts}
                          selectedDiscountIds={activity?.discountIds || []}
                          onDiscountToggle={handleDiscountToggle}
                        />
                      )}
                    </Box>
                    <Box direction="column" gap="10px" fullWidth className="boxColumn" spacing="15px" top>
                      <CustomTextField
                        label={getFigmaText(Texts.adminGeneralInputFieldsActivityPrice)}
                        type="number"
                        value={activity?.price === undefined ? '' : activity.price / 100}
                        error={hasFieldError('price')}
                        helperText={getFieldError('price')}
                        onChange={(e) => {
                          const value = e.target.value === '' ? undefined : parseFloat(e.target.value) * 100
                          handleInputChange('price', value)
                        }}
                      />
                      <CustomTextField
                        label={getFigmaText(Texts.adminGeneralInputFieldsActivityPriceAtDoor)}
                        type="number"
                        value={activity?.priceAtDoor === undefined ? '' : activity.priceAtDoor / 100}
                        onChange={(e) => {
                          const value = e.target.value === '' ? undefined : parseFloat(e.target.value) * 100
                          handleInputChange('priceAtDoor', value)
                        }}
                      />
                      <CustomTextField
                        select
                        label={getFigmaText(Texts.adminGeneralInputFieldsActivityVat)}
                        value={activity?.vat || 25}
                        onChange={(e) => handleInputChange('vat', e.target.value)}
                        sx={{ maxWidth: '100px' }}
                      >
                        {vatValues.map((vat) => (
                          <MenuItem value={vat} selected={(activity?.vat || 25) == vat} key={`vat${vat}`}>
                            {vat}%
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </Box>
                  </Box>
                </Section>
              </Box>
              <Box direction="row" gap="10px" fullWidth className="boxColumn">
                <Section id="Section: Capacity and Enrollment" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralSectionsSectionCapacityAndEnrollment} />
                  <Box direction="row" gap="10px" fullWidth className="boxColumn">
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityMaxRegistrations)}
                      type="number"
                      value={activity?.maxAllowedParticipants === undefined ? '' : activity.maxAllowedParticipants}
                      onChange={(e) => {
                        const value = e.target.value === '' ? undefined : parseInt(e.target.value)
                        handleInputChange('maxAllowedParticipants', value)
                      }}
                      error={hasFieldError('maxAllowedParticipants')}
                      helperText={getFieldError('maxAllowedParticipants')}
                    />
                    {/* Hide balance discrepency if activity has single registration as it is not relevant */}
                    {!activity?.singleRegistration && (
                      <CustomTextField
                        label={getFigmaText(Texts.adminGeneralInputFieldsActivityMaxDiscrepency)}
                        type="number"
                        value={activity?.maxAllowedUnbalance === undefined ? '' : activity.maxAllowedUnbalance}
                        onChange={(e) => {
                          const value = e.target.value === '' ? undefined : parseInt(e.target.value)
                          handleInputChange('maxAllowedUnbalance', value)
                        }}
                      />
                    )}
                  </Box>
                  <Box direction="row" gap="10px" fullWidth align="center">
                    <Checkbox
                      checked={activity?.approvalRequired || false}
                      onChange={(e) => handleInputChange('approvalRequired', e.target.checked)}
                    />
                    <CheckBoxLabel textKey={Texts.adminGeneralInputFieldsActivityRequireEnrollmentApproval} />
                  </Box>
                  <Box direction="row" gap="10px" fullWidth align="center">
                    <Checkbox
                      checked={activity?.singleRegistration || false}
                      onChange={(e) => handleInputChange('singleRegistration', e.target.checked)}
                    />
                    <CheckBoxLabel textKey={Texts.adminGeneralInputFieldsActivitySingleRegistration} />
                  </Box>
                </Section>
                <Section id="Section: Grouping and Metadata" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralSectionsSectionGroupingAndMetadata} />

                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityGroup)}
                    value={activity.group}
                    onChange={(e) => handleInputChange('group', e.target.value)}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityRelatedActivitiesCode)}
                    value={activity.groupingCode}
                    onChange={(e) => handleInputChange('groupingCode', e.target.value)}
                  />
                </Section>
              </Box>
            </Box>
          </form>
          <StickyFooter direction="row" fullWidth gap="10px" justify="space-between">
            <Box direction="row" gap="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave(false)}
                disabled={!userCanSave || isSaving}
              >
                {getFigmaText(Texts.adminGeneralCtactaSave)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave(true)}
                disabled={!userCanSave || isSaving}
              >
                {getFigmaText(Texts.adminGeneralCtactaSaveAndClose)}
              </Button>
            </Box>
            <Button
              variant="text"
              sx={{
                color: 'white',
                background: '#D32525',
                '&:hover': {
                  background: '#E44B4B' // Lighter red on hover
                }
              }}
              onClick={handleDeleteClick}
              disabled={!userCanSave || isSaving}
            >
              {getFigmaText(Texts.adminGeneralCtactaDelete)}
            </Button>
          </StickyFooter>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDeleteDialog}
            onClose={handleDeleteCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {getFigmaText(Texts.adminGeneralDialogsDeletionVerifyHeader)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {getFigmaText(Texts.adminGeneralDialogsDeletionVerifyText) + ' ' + activity.name}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={handleDeleteCancel} color="primary">
                {getFigmaText(Texts.adminGeneralCtactaCancel)}
              </CancelButton>
              <DeleteButton onClick={handleDeleteConfirm} color="error" autoFocus>
                {getFigmaText(Texts.adminGeneralCtactaDelete)}
              </DeleteButton>
            </DialogActions>
          </Dialog>
        </Box>
      </PageContainer>
    </MainContainer>
  )
}

const CancelButton = styled(Button)`
  color: black;
`

const DeleteButton = styled(Button)`
  color: red;
`

const ListSection = styled(Box)``

const InputList = styled.ul`
  list-style: none;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
`

const OccationItem = styled(Box)<{ isSelected?: boolean }>`
  padding: 8px;
  color: ${White};
  cursor: pointer;
  background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
  border-radius: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export default EditActivityPage
