import * as SlackHelper from './SlackHelper'
import { getAuth } from 'firebase/auth'

const IGNORED_ERRORS = [
  'Connection to Indexed Database server lost. Refresh the page to try again',
  'Failed to get document because the client is offline',
  'UnknownError Connection to Indexed Database server lost. Refresh the page to try again',
  'Pending promise was never set'
]

export const captureException = async (err) => {
  if (IGNORED_ERRORS.some((text) => err.message.includes(text))) return

  const auth = getAuth()
  const user = auth.currentUser
  SlackHelper.error(err, user)
}
