import React, { useEffect, useState } from 'react'
import '../resources/css/Dashboard.css'
import DashboardCard from '../components/DashboardCard'
import userIcon from '../resources/images/User.png' // Adjust the path as necessary
import moneyIcon from '../resources/images/Money.png' // Adjust the path as necessary
import ActivityList from '../components/ActivityList'
import ActivityListV2 from '../components/ActivityListV2'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import { ViewList } from '@mui/icons-material'

import { getActivitiesForClient } from '../libs/DBApiHandler'
import { Activity, Enrollment } from '../../../pure-js/types/GrooverTypes'
import useAppState from '../hooks/useAppState'
import {
  formatNumberWithDots,
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getPaidRegistrationRatio,
  getRegistrationsPerDay
} from '../utils/enrollmentUtils'
import Box from '../components/Box'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from '../components/FigmaText'
import QRButton from '../components/QRButton'
import { hasAccess } from '../libs/SignInHelper'
import { MainContainer, PageContainer } from '../styles/AdminPageStyles'
import { useClient } from '../context/ClientContext'
import { useEnrollmentsForClient } from '../hooks/useEnrollmentsForClient'
import { getFigmaText, formatText } from '../libs/TextRepository'
import { FormControlLabel, Switch } from '@mui/material'
import styled from '@emotion/styled'

const Dashboard: React.FC = () => {
  const { state } = useAppState()
  const { clientId, clientData } = useClient()
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [activitiesLoaded, setActivitiesLoaded] = useState<boolean>(false)
  const [useV2List, setUseV2List] = useState<boolean>(() => {
    return localStorage.getItem('useActivityListV2') === 'true'
  })

  // BAD CODE! replace with proper localStorage observer, probably in useClient
  if (!clientId && localStorage.getItem('selectedClientId')) {
    window.location.reload()
  }

  // this is not fetching data now. It just sets connection and observe the database
  // check activities.isLoading and activities.status to see if data is fetched
  // then you can use activities.data
  const _activities = getActivitiesForClient(clientId)
  const _enrollments = useEnrollmentsForClient(clientId)

  // regular hook to fetch data
  const client = useClient

  useEffect(() => {
    if (!clientId) return
    if (!_activities.isLoading && _activities.status == 'success') {
      setActivities(_activities.data as Activity[])
      setActivitiesLoaded(true)
    }
  }, [clientId, _activities])

  useEffect(() => {
    if (!clientId) return
    if (!_enrollments.data || _enrollments.status !== 'success') return
    setEnrollments(_enrollments.data)
  }, [clientId, _enrollments.data])

  useEffect(() => {
    setLoading(_activities.isLoading || _enrollments.isLoading)
  }, [_activities.isLoading, _enrollments.isLoading])

  const handleToggleView = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setUseV2List(isChecked)
    localStorage.setItem('useActivityListV2', isChecked ? 'true' : 'false')

    // Reset filter options if switching between views to avoid potential issues
    if (!isChecked) {
      localStorage.removeItem('activityFilterText')
      localStorage.removeItem('activityStatusFilter')
    }
  }

  const registrationsPerDay = enrollments.length > 0 ? getRegistrationsPerDay(enrollments) : []

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth>
        {loading ? (
          <Box fullWidth align="center" fullHeight style={{ paddingTop: '50px' }}>
            <div className="spinner"></div>
          </Box>
        ) : !hasAccess(state) ? (
          <Box
            id="NoAccessView"
            fullWidth
            align="center"
            direction="column"
            gap="24px"
            justify="center"
            style={{ minHeight: '80vh' }}
          >
            <FigmaText textKey={Texts.adminGeneralNoAccessHeader} />
            <FigmaText textKey={Texts.adminGeneralNoAccessText} />
            <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
          </Box>
        ) : (
          <div className="dashboard-container">
            <div className="dashboard-header">
              <div className="dashboard-period">
                <span>Period</span>
                <select>
                  <option>VT 2025</option>
                </select>
              </div>
              <Box>
                <a
                  href={`https://app.coursely.se/activities/${clientId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <ViewList />
                  <FigmaText textKey={Texts.adminGeneralOpenPublicActivityPage} />
                </a>
              </Box>
            </div>

            {loading ? (
              <Box fullWidth align="center">
                <div className="spinner"></div>
              </Box>
            ) : (
              <>
                <Box fullWidth direction="row" gap="1rem" changeDirectionOnMobile>
                  <DashboardCard
                    icon={<img src={userIcon} alt="User Icon" />}
                    title={getFigmaText(Texts.componentsKpIsKpiRegistrationLabel)}
                    value={formatNumberWithDots(getAllEnrollmentsCount(enrollments))}
                    chartData={registrationsPerDay}
                  />
                  <DashboardCard
                    icon={<img src={moneyIcon} alt="Money Icon" />}
                    title={getFigmaText(Texts.componentsKpIsKpiTotalValue)}
                    value={`${formatNumberWithDots(getAllRevenueForRegistration(enrollments, true))} SEK`}
                    subtitle={`${formatText(getFigmaText(Texts.componentsKpIsKpiSecondaryNotApprovedValue), [formatNumberWithDots(getAllRevenueForRegistration(enrollments, false))])} SEK`}
                  />
                  <DashboardCard
                    icon={<img src={moneyIcon} alt="Money Icon" />}
                    title={getFigmaText(Texts.componentsKpIsKpiPaidLabel)}
                    value={`${formatNumberWithDots(getAllPaidRevenueForRegistration(enrollments))} SEK`}
                    subtitle={`${formatText(getFigmaText(Texts.componentsKpIsKpiUnpaidRatio), [getPaidRegistrationRatio(enrollments)])}`}
                  />
                </Box>

                <Box fullWidth direction="row" justify="flex-end" align="center" style={{ margin: '16px 0' }}>
                  <StyledFormControlLabel
                    control={<Switch checked={useV2List} onChange={handleToggleView} color="primary" />}
                    label="Använd kompaktläge (beta)"
                  />
                </Box>

                <div className="coursesContainer">
                  {useV2List ? (
                    <ActivityListV2
                      activities={activities as Activity[]}
                      activitiesLoaded={activitiesLoaded}
                      enrollments={enrollments as Enrollment[]}
                      clientData={clientData}
                    />
                  ) : (
                    <ActivityList
                      activities={activities as Activity[]}
                      activitiesLoaded={activitiesLoaded}
                      enrollments={enrollments as Enrollment[]}
                      clientData={clientData}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </PageContainer>

      <QRButton />
    </MainContainer>
  )
}

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }

  & .MuiSwitch-track {
    background-color: rgba(255, 255, 255, 0.3);ß
  }

  & .MuiSwitch-thumb {
    background-color: white;
  }
ß
  & .Mui-checked + .MuiSwitch-track {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  & .Mui-checked .MuiSwitch-thumb {
    background-color:rgb(255, 255, 255);
  }
`

export default Dashboard
