import React, { useEffect, useState } from 'react'
import { MainContainer, PageContainer } from '../styles/AdminPageStyles'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import useAppState from '../hooks/useAppState'
import { Enrollment } from '../../../pure-js/types/GrooverTypes'
import '../../../pure-js/libs/ArrayHelper'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material'
import { hasAccess } from '../libs/SignInHelper'
import DashboardCard from '../components/DashboardCard'
import moneyIcon from '../resources/images/Money.png'
import { getFigmaText } from '../libs/TextRepository'
import { useNavigate } from 'react-router-dom'
import { useClient } from '../context/ClientContext'
import { exportToExcel as exportToExcelUtil } from '../libs/ExportUtils'
import { useEnrollmentsForClient } from '../hooks/useEnrollmentsForClient'

// Simplified column configuration
enum TableColumnId {
  CreatedAt = 'createdAt',
  PaymentStatus = 'paymentStatus',
  ActivityName = 'activityName',
  UserName = 'userName',
  FinalPrice = 'finalPrice',
  PriceExclVAT = 'priceExclVAT',
  VAT = 'vat',
  VATRate = 'vatRate',
  PaymentMethod = 'paymentMethod'
}

// Add enum for payment status filter
enum PaymentStatusFilter {
  Paid = 'paid',
  Unpaid = 'unpaid',
  All = 'all'
}

// Add enum for payment method filter
enum PaymentMethodFilter {
  All = 'all',
  Swish = 'swish',
  Stripe = 'stripe',
  Manual = 'manual',
  Other = 'other'
}

// Add enum for period options
enum PeriodFilter {
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  AllTime = 'allTime',
  Custom = 'custom'
}

interface TableColumn {
  id: TableColumnId
  width: string
  label: string
  getValue: (detail: SimplifiedPriceDetail) => string | number
}

// Simplified price detail interface
interface SimplifiedPriceDetail {
  id: string
  enrollmentId: string
  createdAt: string
  paymentStatus: string
  formattedPaymentStatus: string // Add formatted payment status
  activityName: string
  userName: string
  finalPrice: number
  priceExclVAT: number
  vat: number
  vatRate: number
  paymentMethod: string
}

// Financial summary interface
interface FinancialSummary {
  totalAmount: number
  totalCount: number
  paymentMethods: {
    swish: {
      count: number
      amount: number
    }
    manual: {
      count: number
      amount: number
    }
    other: {
      count: number
      amount: number
    }
  }
}

const TABLE_CONFIG: Record<TableColumnId, TableColumn> = {
  [TableColumnId.CreatedAt]: {
    id: TableColumnId.CreatedAt,
    width: '100px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderDateOfRegistration),
    getValue: (pd) => pd.createdAt
  },
  [TableColumnId.PaymentStatus]: {
    id: TableColumnId.PaymentStatus,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPaymentStatus),
    getValue: (pd) => pd.formattedPaymentStatus // Use formatted payment status
  },
  [TableColumnId.ActivityName]: {
    id: TableColumnId.ActivityName,
    width: '300px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderActivitites),
    getValue: (pd) => pd.activityName
  },
  [TableColumnId.UserName]: {
    id: TableColumnId.UserName,
    width: '160px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderAttendee),
    getValue: (pd) => pd.userName
  },
  [TableColumnId.FinalPrice]: {
    id: TableColumnId.FinalPrice,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderAmount),
    getValue: (pd) => pd.finalPrice.toFixed(2)
  },
  [TableColumnId.PriceExclVAT]: {
    id: TableColumnId.PriceExclVAT,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPriceExVat), // Add translation if available
    getValue: (pd) => pd.priceExclVAT.toFixed(2)
  },
  [TableColumnId.VAT]: {
    id: TableColumnId.VAT,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVat), // Add translation if available
    getValue: (pd) => pd.vat.toFixed(2)
  },
  [TableColumnId.VATRate]: {
    id: TableColumnId.VATRate,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVatPercentage), // Add translation if available
    getValue: (pd) => `${(pd.vatRate * 100).toFixed(0)}%`
  },
  [TableColumnId.PaymentMethod]: {
    id: TableColumnId.PaymentMethod,
    width: '150px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPaymentMethod),
    getValue: (pd) => pd.paymentMethod
  }
}

const COLUMN_ORDER = [
  TableColumnId.CreatedAt,
  TableColumnId.PaymentStatus,
  TableColumnId.ActivityName,
  TableColumnId.UserName,
  TableColumnId.FinalPrice,
  TableColumnId.PriceExclVAT,
  TableColumnId.VAT,
  TableColumnId.VATRate,
  TableColumnId.PaymentMethod
]

// Add this constant to identify value columns that should be right-aligned
const VALUE_COLUMNS = [TableColumnId.FinalPrice, TableColumnId.PriceExclVAT, TableColumnId.VAT, TableColumnId.VATRate]

const FinancialDetailsPage: React.FC = () => {
  const { state } = useAppState()
  const { clientId: contextClientId } = useClient()
  const [priceDetails, setPriceDetails] = useState<SimplifiedPriceDetail[]>([])
  const [allPriceDetails, setAllPriceDetails] = useState<SimplifiedPriceDetail[]>([]) // Store all price details
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [financialSummary, setFinancialSummary] = useState<FinancialSummary>({
    totalAmount: 0,
    totalCount: 0,
    paymentMethods: {
      swish: { count: 0, amount: 0 },
      manual: { count: 0, amount: 0 },
      other: { count: 0, amount: 0 }
    }
  })
  const navigate = useNavigate()

  // Separate period states for creation date and payment date
  const [creationDatePeriod, setCreationDatePeriod] = useState<PeriodFilter>(PeriodFilter.ThisMonth)
  const [paymentDatePeriod, setPaymentDatePeriod] = useState<PeriodFilter>(PeriodFilter.AllTime)

  const [paymentStatusFilter, setPaymentStatusFilter] = useState<PaymentStatusFilter>(PaymentStatusFilter.All)
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<PaymentMethodFilter>(PaymentMethodFilter.All)

  const _enrollments = useEnrollmentsForClient(contextClientId)

  // Set default date values to start and end of current month
  const getDefaultDates = () => {
    const now = new Date()
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    // Format dates as YYYY-MM-DD for input[type="date"]
    const formatDate = (date: Date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    return {
      start: formatDate(startOfMonth),
      end: formatDate(endOfMonth)
    }
  }

  const defaultDates = getDefaultDates()

  // Creation date filters
  const [creationStartDate, setCreationStartDate] = useState<string>(defaultDates.start)
  const [creationEndDate, setCreationEndDate] = useState<string>(defaultDates.end)

  // Payment date filters
  const [paymentStartDate, setPaymentStartDate] = useState<string>('')
  const [paymentEndDate, setPaymentEndDate] = useState<string>('')

  // Format date function
  const formatDate = (dateString: string): string => {
    if (!dateString || dateString === '-') return '-'

    const date = new Date(dateString)
    if (isNaN(date.getTime())) return '-'

    return date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }

  // Calculate VAT from brutto price (price including VAT)
  const getVatFromBruttoPrice = (price: number, vatRate: number): number => {
    if (vatRate <= 0) return 0
    return price - price / (1 + vatRate)
  }

  // Process enrollments to extract simplified price details
  const processPriceDetails = (enrollments: Enrollment[]): SimplifiedPriceDetail[] => {
    const simplifiedDetails: SimplifiedPriceDetail[] = []

    enrollments
      // Filter out deleted enrollments
      .filter((enrollment) => enrollment.status !== 'deleted')
      .forEach((enrollment) => {
        // Get enrollment data
        const createdAt = formatDate(enrollment.createdAt || '-')
        const paymentStatus = enrollment.paymentStatus || 'unpaid'

        // Format payment status using getFigmaText
        const formattedPaymentStatus =
          paymentStatus === 'paid'
            ? getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)
            : getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)

        // Get payment method (merge all unique payment methods)
        const paymentMethodsArr =
          enrollment.payments && enrollment.payments.length > 0
            ? enrollment.payments.reduce<string[]>((acc, curr) => {
                acc.push(curr.source)
                return acc
              }, [])
            : ['Unpaid']
        const paymentMethod = paymentMethodsArr.onlyUniqueJoin(true)

        // Process price details if they exist
        if (enrollment.priceDetails && enrollment.priceDetails.length > 0) {
          enrollment.priceDetails.forEach((priceDetail, index) => {
            // Convert finalPrice from öre to SEK
            const finalPrice = (priceDetail.finalPrice || 0) / 100

            // Find activity name and VAT
            let activityName = '-'
            let vatRate = 0
            if (priceDetail.activityId) {
              const activity = enrollment.activities.find((a) => a.id === priceDetail.activityId)
              if (activity) {
                activityName = activity.name || '-'
                // Get VAT from activity if available, default to 0
                vatRate = activity.vat !== undefined ? activity.vat / 100 : 0
              }
            }

            // Calculate VAT amount using the getVatFromBruttoPrice method
            const vat = getVatFromBruttoPrice(finalPrice, vatRate)

            // Calculate price excluding VAT
            const priceExclVAT = finalPrice - vat

            // Determine user name based on userId
            let userName =
              priceDetail.appliesTo === 'mainUser'
                ? `${enrollment.mainUser?.name || '-'}${enrollment.mainUser?.ssn ? ` (${enrollment.mainUser.ssn})` : ''}`
                : `${enrollment.partnerUser?.name || '-'}${enrollment.partnerUser?.ssn ? ` (${enrollment.partnerUser.ssn})` : ''}`

            // Create unique ID
            const uniqueId = `${enrollment.id}-${index}`

            simplifiedDetails.push({
              id: uniqueId,
              enrollmentId: enrollment.id,
              createdAt,
              paymentStatus,
              formattedPaymentStatus, // Add formatted payment status
              activityName,
              userName,
              finalPrice,
              priceExclVAT,
              vat,
              vatRate,
              paymentMethod
            })
          })
        }
      })

    return simplifiedDetails.sort((a, b) => {
      // Sort by creation date (newest first)
      const dateA = new Date(a.createdAt).getTime() || 0
      const dateB = new Date(b.createdAt).getTime() || 0
      return dateB - dateA
    })
  }

  // Filter price details based on selected filters
  const filterPriceDetails = () => {
    let filtered = [...allPriceDetails]

    // Apply payment status filter
    if (paymentStatusFilter !== PaymentStatusFilter.All) {
      filtered = filtered.filter((detail) => detail.paymentStatus === paymentStatusFilter)
    }

    // Apply payment method filter
    if (paymentMethodFilter !== PaymentMethodFilter.All) {
      if (paymentMethodFilter === PaymentMethodFilter.Swish) {
        filtered = filtered.filter((detail) => detail.paymentMethod.toLowerCase().includes('swish'))
      } else if (paymentMethodFilter === PaymentMethodFilter.Stripe) {
        filtered = filtered.filter((detail) => detail.paymentMethod.toLowerCase().includes('stripe'))
      } else if (paymentMethodFilter === PaymentMethodFilter.Manual) {
        filtered = filtered.filter(
          (detail) =>
            detail.paymentMethod.toLowerCase().includes('manual') ||
            detail.paymentMethod.toLowerCase().includes('bank') ||
            detail.paymentMethod.toLowerCase().includes('invoice') ||
            detail.paymentMethod.toLowerCase() === 'unpaid'
        )
      } else if (paymentMethodFilter === PaymentMethodFilter.Other) {
        filtered = filtered.filter(
          (detail) =>
            !detail.paymentMethod.toLowerCase().includes('swish') &&
            !detail.paymentMethod.toLowerCase().includes('stripe') &&
            !detail.paymentMethod.toLowerCase().includes('manual') &&
            !detail.paymentMethod.toLowerCase().includes('bank') &&
            !detail.paymentMethod.toLowerCase().includes('invoice') &&
            detail.paymentMethod.toLowerCase() !== 'unpaid'
        )
      }
    }

    // Apply creation date range filter
    if (creationStartDate || creationEndDate) {
      filtered = filtered.filter((detail) => {
        // Parse the start and end dates for filtering
        const start = creationStartDate ? new Date(creationStartDate) : null
        const end = creationEndDate ? new Date(creationEndDate) : null

        // Set end date to end of day for inclusive filtering
        if (end) {
          end.setHours(23, 59, 59, 999)
        }

        // Check created date
        if (detail.createdAt && detail.createdAt !== '-') {
          const createDate = new Date(detail.createdAt)
          const afterStart = !start || createDate >= start
          const beforeEnd = !end || createDate <= end
          return afterStart && beforeEnd
        }

        // If no valid creation date, exclude the detail
        return false
      })
    }

    // Apply payment method filter based on payment date
    if (paymentStartDate || paymentEndDate) {
      filtered = filtered.filter((detail) => {
        // If payment status is unpaid, keep it
        if (detail.paymentStatus === 'unpaid') {
          return true
        }

        // For details with valid payment dates, apply the date range filter
        const start = paymentStartDate ? new Date(paymentStartDate) : null
        const end = paymentEndDate ? new Date(paymentEndDate) : null

        // Set end date to end of day for inclusive filtering
        if (end) {
          end.setHours(23, 59, 59, 999)
        }

        // Assume payment date is creation date for simplicity
        // (this is a limitation since we don't have the actual payment date in the simplified model)
        const payDate = new Date(detail.createdAt)
        const afterStart = !start || payDate >= start
        const beforeEnd = !end || payDate <= end
        return afterStart && beforeEnd
      })
    }

    setPriceDetails(filtered)
  }

  // Export to Excel
  const exportToExcel = () => {
    const columns = COLUMN_ORDER.map((columnId) => {
      const column = TABLE_CONFIG[columnId]
      return {
        key: columnId,
        header: column.label,
        getValue: (detail: SimplifiedPriceDetail) => {
          if (
            columnId === TableColumnId.FinalPrice ||
            columnId === TableColumnId.VAT ||
            columnId === TableColumnId.PriceExclVAT
          ) {
            if (columnId === TableColumnId.FinalPrice) return detail.finalPrice
            if (columnId === TableColumnId.VAT) return detail.vat
            return detail.priceExclVAT
          } else if (columnId === TableColumnId.VATRate) {
            return detail.vatRate
          } else {
            return column.getValue(detail)
          }
        }
      }
    })

    exportToExcelUtil(priceDetails, columns, 'financial-details-simplified')
  }

  // Calculate financial summary
  const calculateFinancialSummary = (details: SimplifiedPriceDetail[]): FinancialSummary => {
    return details.reduce(
      (summary, detail) => {
        // Total amount and count calculations
        const newSummary = {
          ...summary,
          totalAmount: summary.totalAmount + detail.finalPrice,
          totalCount: summary.totalCount + 1
        }

        // Payment method calculations
        const paymentMethod = detail.paymentMethod.toLowerCase()
        if (paymentMethod.includes('swish')) {
          newSummary.paymentMethods.swish.count += 1
          newSummary.paymentMethods.swish.amount += detail.finalPrice
        } else if (paymentMethod.includes('stripe')) {
          newSummary.paymentMethods.stripe.count += 1
          newSummary.paymentMethods.stripe.amount += detail.finalPrice
        } else if (
          paymentMethod.includes('manual') ||
          paymentMethod.includes('bank') ||
          paymentMethod.includes('invoice') ||
          paymentMethod === 'unpaid'
        ) {
          newSummary.paymentMethods.manual.count += 1
          newSummary.paymentMethods.manual.amount += detail.finalPrice
        } else {
          newSummary.paymentMethods.other.count += 1
          newSummary.paymentMethods.other.amount += detail.finalPrice
        }

        return newSummary
      },
      {
        totalAmount: 0,
        totalCount: 0,
        paymentMethods: {
          swish: { count: 0, amount: 0 },
          stripe: { count: 0, amount: 0 },
          manual: { count: 0, amount: 0 },
          other: { count: 0, amount: 0 }
        }
      }
    )
  }

  // Get dates for last month
  const getLastMonthDates = () => {
    const now = new Date()
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)

    // Format dates as YYYY-MM-DD for input[type="date"]
    const formatDate = (date: Date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    return {
      start: formatDate(startOfLastMonth),
      end: formatDate(endOfLastMonth)
    }
  }

  // Handle creation date period selection
  const handleCreationPeriodChange = (event: SelectChangeEvent) => {
    const period = event.target.value as PeriodFilter
    setCreationDatePeriod(period)

    switch (period) {
      case PeriodFilter.ThisMonth:
        const { start, end } = getDefaultDates()
        setCreationStartDate(start)
        setCreationEndDate(end)
        break
      case PeriodFilter.LastMonth:
        const lastMonth = getLastMonthDates()
        setCreationStartDate(lastMonth.start)
        setCreationEndDate(lastMonth.end)
        break
      case PeriodFilter.AllTime:
        setCreationStartDate('')
        setCreationEndDate('')
        break
      case PeriodFilter.Custom:
        // Don't change dates, allow user to set them manually
        break
    }
  }

  // Handle payment date period selection
  const handlePaymentPeriodChange = (event: SelectChangeEvent) => {
    const period = event.target.value as PeriodFilter
    setPaymentDatePeriod(period)

    switch (period) {
      case PeriodFilter.ThisMonth:
        const { start, end } = getDefaultDates()
        setPaymentStartDate(start)
        setPaymentEndDate(end)
        break
      case PeriodFilter.LastMonth:
        const lastMonth = getLastMonthDates()
        setPaymentStartDate(lastMonth.start)
        setPaymentEndDate(lastMonth.end)
        break
      case PeriodFilter.AllTime:
        setPaymentStartDate('')
        setPaymentEndDate('')
        break
      case PeriodFilter.Custom:
        // Don't change dates, allow user to set them manually
        break
    }
  }

  // Handler for payment status filter
  const handlePaymentStatusChange = (event: SelectChangeEvent) => {
    setPaymentStatusFilter(event.target.value as PaymentStatusFilter)
  }

  // Handler for payment method filter
  const handlePaymentMethodChange = (event: SelectChangeEvent) => {
    setPaymentMethodFilter(event.target.value as PaymentMethodFilter)
  }

  // Custom style for date inputs
  const dateInputStyle = {
    width: '200px',
    '& .MuiSvgIcon-root': { color: 'white !important' },
    '& .MuiInputAdornment-root .MuiButtonBase-root': { color: 'white !important' },
    '& svg': { color: 'white !important' },
    '& input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)' // This inverts the calendar icon color
    }
  }

  // Format number with thousand separator (point)
  const formatNumber = (num: number): string => {
    return Math.floor(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  // Apply filters when filter criteria changes
  useEffect(() => {
    if (allPriceDetails.length > 0) {
      filterPriceDetails()
    }
  }, [
    creationStartDate,
    creationEndDate,
    paymentStartDate,
    paymentEndDate,
    paymentStatusFilter,
    paymentMethodFilter,
    allPriceDetails
  ])

  // Add this state for column totals
  const [columnTotals, setColumnTotals] = useState<Record<TableColumnId, number | string>>({
    [TableColumnId.CreatedAt]: '',
    [TableColumnId.PaymentStatus]: '',
    [TableColumnId.ActivityName]: '',
    [TableColumnId.UserName]: '',
    [TableColumnId.FinalPrice]: 0,
    [TableColumnId.PriceExclVAT]: 0,
    [TableColumnId.VAT]: 0,
    [TableColumnId.VATRate]: '',
    [TableColumnId.PaymentMethod]: ''
  })

  // Update financial summary when filtered data changes
  useEffect(() => {
    const summary = calculateFinancialSummary(priceDetails)
    setFinancialSummary(summary)

    // Calculate column totals for the summary row
    const totals = calculateColumnTotals(priceDetails)
    setColumnTotals(totals)
  }, [priceDetails])

  useEffect(() => {
    setLoading(_enrollments.isLoading)
  }, [_enrollments.isLoading])

  useEffect(() => {
    if (!_enrollments.data || _enrollments.status !== 'success') return
    const processed = processPriceDetails(_enrollments.data)
    setAllPriceDetails(processed)
    setPriceDetails(processed)
  }, [_enrollments.data])

  // Handle row click
  const handleRowClick = (enrollmentId: string) => {
    window.open(`/enrollment/${enrollmentId}`, '_blank')
  }

  // Add this function to calculate column totals
  const calculateColumnTotals = (details: SimplifiedPriceDetail[]): Record<TableColumnId, number | string> => {
    const totals: Record<TableColumnId, number | string> = {
      [TableColumnId.CreatedAt]: '',
      [TableColumnId.PaymentStatus]: '',
      [TableColumnId.ActivityName]: '',
      [TableColumnId.UserName]: '',
      [TableColumnId.FinalPrice]: 0,
      [TableColumnId.PriceExclVAT]: 0,
      [TableColumnId.VAT]: 0,
      [TableColumnId.VATRate]: '',
      [TableColumnId.PaymentMethod]: ''
    }

    details.forEach((detail) => {
      totals[TableColumnId.FinalPrice] = Number(totals[TableColumnId.FinalPrice]) + detail.finalPrice
      totals[TableColumnId.PriceExclVAT] = Number(totals[TableColumnId.PriceExclVAT]) + detail.priceExclVAT
      totals[TableColumnId.VAT] = Number(totals[TableColumnId.VAT]) + detail.vat
    })

    return totals
  }

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth gap="20px">
        <Box direction="row" fullWidth justify="space-between" align="center" style={{ marginBottom: '20px' }}>
          <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFinancialReportDetailsHeader} />
          <Button variant="contained" onClick={exportToExcel}>
            {getFigmaText(Texts.adminGeneralCtactaDownloadXls)}
          </Button>
        </Box>

        {/* Filter Controls - Grouped */}
        <Box direction="column" gap="16px" style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
          {/* Creation Date Filter Group */}
          <FilterSection direction="row">
            <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFilterRegistrationDateSection} />
            <FormControl>
              <InputLabel id="creation-period-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
              </InputLabel>
              <Select
                labelId="creation-period-select-label"
                id="creation-period-select"
                value={creationDatePeriod}
                onChange={handleCreationPeriodChange}
                label={getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '& .MuiSvgIcon-root': { color: 'white' }
                }}
              >
                <MenuItem value={PeriodFilter.ThisMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodThisMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.LastMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodLastMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.AllTime}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodAllPeriod)}
                </MenuItem>
                <MenuItem value={PeriodFilter.Custom}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodCustom)}
                </MenuItem>
              </Select>
            </FormControl>
            {creationDatePeriod !== PeriodFilter.AllTime && (
              <>
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialStartDate)}
                  type="date"
                  value={creationStartDate}
                  onChange={(e) => {
                    setCreationStartDate(e.target.value)
                    setCreationDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialEndDate)}
                  type="date"
                  value={creationEndDate}
                  onChange={(e) => {
                    setCreationEndDate(e.target.value)
                    setCreationDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
              </>
            )}
          </FilterSection>

          {/* Payment Date Filter Group */}
          <FilterSection direction="row">
            <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFilterPaymentDateSection} />
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel id="payment-period-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
              </InputLabel>
              <Select
                labelId="payment-period-select-label"
                id="payment-period-select"
                value={paymentDatePeriod}
                onChange={handlePaymentPeriodChange}
                label={getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '& .MuiSvgIcon-root': { color: 'white' }
                }}
              >
                <MenuItem value={PeriodFilter.ThisMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodThisMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.LastMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodLastMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.AllTime}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodAllPeriod)}
                </MenuItem>
                <MenuItem value={PeriodFilter.Custom}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodCustom)}
                </MenuItem>
              </Select>
            </FormControl>

            {paymentDatePeriod !== PeriodFilter.AllTime && (
              <>
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialStartDate)}
                  type="date"
                  value={paymentStartDate}
                  onChange={(e) => {
                    setPaymentStartDate(e.target.value)
                    setPaymentDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialEndDate)}
                  type="date"
                  value={paymentEndDate}
                  onChange={(e) => {
                    setPaymentEndDate(e.target.value)
                    setPaymentDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
              </>
            )}
          </FilterSection>

          {/* Status Filters Group */}
          <FilterSection>
            <Box direction="row" gap="12px" style={{ flexWrap: 'wrap', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="payment-status-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                </InputLabel>
                <Select
                  labelId="payment-status-select-label"
                  id="payment-status-select"
                  value={paymentStatusFilter}
                  onChange={handlePaymentStatusChange}
                  label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }}
                >
                  <MenuItem value={PaymentStatusFilter.Paid}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)}
                  </MenuItem>
                  <MenuItem value={PaymentStatusFilter.Unpaid}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)}
                  </MenuItem>
                  <MenuItem value={PaymentStatusFilter.All}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateBoth)}
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="payment-method-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentMethodSelectLabel)}
                </InputLabel>
                <Select
                  labelId="payment-method-select-label"
                  id="payment-method-select"
                  value={paymentMethodFilter}
                  onChange={handlePaymentMethodChange}
                  label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentMethodSelectLabel)}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }}
                >
                  <MenuItem value={PaymentMethodFilter.All}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentAll)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Swish}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentMethodSwish)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Stripe}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentMethodCard)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Manual}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentMethodBank)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Other}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentOther)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </FilterSection>
        </Box>

        {/* Table section */}
        {!hasAccess(state) ? (
          <Box fullWidth align="center">
            <Alert severity="error">No access</Alert>
          </Box>
        ) : loading ? (
          <Box fullWidth align="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box fullWidth align="center">
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : (
          <TableContainer component={StyledPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  {COLUMN_ORDER.map((columnId) => (
                    <TableCell
                      key={columnId}
                      style={{ width: TABLE_CONFIG[columnId].width }}
                      align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}
                    >
                      {TABLE_CONFIG[columnId].label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {priceDetails.map((detail) => (
                  <TableRow
                    key={detail.id}
                    onClick={() => handleRowClick(detail.enrollmentId)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                      transition: 'background-color 0.2s',
                      backgroundColor: detail.paymentStatus === 'unpaid' ? 'rgba(255, 152, 0, 0.1)' : 'transparent'
                    }}
                  >
                    {COLUMN_ORDER.map((columnId) => (
                      <TableCell key={columnId} align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}>
                        {TABLE_CONFIG[columnId].getValue(detail)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                {/* Add Summary Row */}
                {priceDetails.length > 0 && (
                  <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', fontWeight: 'bold' }}>
                    {COLUMN_ORDER.map((columnId) => (
                      <TableCell
                        key={`total-${columnId}`}
                        align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '0.9rem'
                        }}
                      >
                        {columnId === TableColumnId.ActivityName
                          ? getFigmaText(Texts.adminGeneralPagesFinancialReportTableTotalRowHeader) || 'TOTAL'
                          : columnId === TableColumnId.FinalPrice ||
                              columnId === TableColumnId.PriceExclVAT ||
                              columnId === TableColumnId.VAT
                            ? formatNumber(Number(columnTotals[columnId])) // Using the formatNumber function with thousand separator
                            : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                {priceDetails.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={COLUMN_ORDER.length} align="center">
                      {getFigmaText(Texts.adminGeneralPagesFinancialReportEmptyState)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PageContainer>
    </MainContainer>
  )
}

const FilterSection = styled(Box)({
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  alignItems: 'center'
})

const StyledPaper = styled(Paper)({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  '& .MuiTableCell-root': {
    color: 'white',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '2px 8px' // Further reduced padding for all table cells
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '4px 8px' // Slightly more padding for header cells for better readability
  },
  '& .MuiTableBody-root .MuiTableRow-root': {
    height: '36px' // Reduced height for body rows to make them more compact
  }
})

export default FinancialDetailsPage
