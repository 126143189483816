import { StyledEngineProvider, ThemeProvider, Box, styled } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { FeatureFlagsProviderForAdminweb } from '../../user-web/src/libs/FeatureFlags'
import { BrowserRouter } from 'react-router-dom'
import { Context, useAppStateContext } from './hooks/useAppState'
import './libs/Fonts'
import theme from './libs/theme'
import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import { Toaster } from 'react-hot-toast'
import { useEffect, useState } from 'react'

import { Helmet } from './components/Helmet'

import PrivateRoutes from './components/NavigationPrivateRoutes'
import PublicRoutes from './components/NavigationPublicRoutes'
import { OnMountApp } from './hooks/useOnMountApp'
import { SNACK_BAR_PROPS } from '../../pure-js/libs/Consts'
import Sidebar from './components/SideBar'
import { hasAccess, setupTokenRefresher } from './libs/SignInHelper'
import NoAccessPage from './pages/NoAccessPage'
import { ClientProvider } from './context/ClientContext'
import { QueryClient, QueryClientProvider } from 'react-query'

const MainLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%'
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  marginLeft: theme.spacing(7),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0
  }
}))

const App = () => {
  const appContext = useAppStateContext()
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

  useEffect(() => {
    // for logged in users only
    if (appContext.state.isLoggedIn && appContext.state.firebaseUser) {
      // Initialize the token refresher and store the unsubscribe function
      const unsubscribe = setupTokenRefresher()

      // Clean up the listener when the app unmounts
      return () => unsubscribe()
    }
  }, [appContext.state.isLoggedIn, appContext.state.firebaseUser])

  const [clientId, setClientId] = useState<string>(() => {
    return localStorage.getItem('selectedClientId') || ''
  })

  const handleClientChange = (newClientId: string) => {
    setClientId(newClientId)
    localStorage.setItem('selectedClientId', newClientId)
  }

  useEffect(() => {
    if (!clientId && appContext.state.user?.authCustomClaims?.clientIds?.length) {
      const newClientId = appContext.state.user.authCustomClaims.clientIds[0]
      handleClientChange(newClientId)
    }
  }, [clientId, appContext.state.user?.authCustomClaims])

  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <SnackbarProvider {...SNACK_BAR_PROPS}>
          <FeatureFlagsProviderForAdminweb>
            <Context.Provider value={appContext}>
              <QueryClientProvider client={queryClient}>
                <ClientProvider>
                  <BrowserRouter>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <Helmet titleTemplate="%s - Groover" />
                        <OnMountApp />
                        <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />
                        {!appContext?.state.isLoggedIn ? (
                          <PublicRoutes />
                        ) : appContext?.state.isLoggedIn && !hasAccess(appContext?.state) ? (
                          <NoAccessPage />
                        ) : (
                          <MainLayout>
                            <Sidebar onClientChange={handleClientChange} />
                            <ContentWrapper>
                              <PrivateRoutes />
                            </ContentWrapper>
                          </MainLayout>
                        )}
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </BrowserRouter>
                </ClientProvider>
              </QueryClientProvider>
            </Context.Provider>
          </FeatureFlagsProviderForAdminweb>
        </SnackbarProvider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
