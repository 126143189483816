import React, { useEffect, useState } from 'react'
import { MainContainer, PageContainer } from '../styles/AdminPageStyles'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import RoutePath from '../../../pure-js/libs/RoutePath'
import {
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'
import { Link } from 'react-router-dom'
import BarChartIcon from '@mui/icons-material/BarChart'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DashboardCard from '../components/DashboardCard'
import moneyIcon from '../resources/images/Money.png'
import { useClient } from '../context/ClientContext'
import { useEnrollmentsForClient } from '../hooks/useEnrollmentsForClient'
import { calculateEnrollmentVATTotal } from '../../../pure-js/libs/EnrollmentHelper'
import useAppState from '../hooks/useAppState'
import { hasAccess } from '../libs/SignInHelper'
import {
  formatNumber,
  PeriodFilter,
  getDefaultDates,
  getLastMonthDates,
  formatDisplayDate,
  isDateInRange
} from '../utils/financialHelper'

// Financial summary interface
interface FinancialSummary {
  totalAmount: number
  totalVAT: number
  paymentMethods: {
    swish: { count: number; amount: number }
    manual: { count: number; amount: number }
    other: { count: number; amount: number }
  }
}

// Simplified enrollment data structure needed for summary calculation
interface SimplifiedEnrollment {
  id: string
  amount: number
  vat: number
  paymentMethod: string
  originalPaymentDate: string
  formattedPaymentDate: string
  isPaid: boolean
}

const FinancialsOverviewPage: React.FC = () => {
  const { state } = useAppState()
  const { clientId: contextClientId } = useClient()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // Payment date period state
  const [period, setPeriod] = useState<PeriodFilter>(PeriodFilter.ThisMonth)
  const defaultDates = getDefaultDates()
  const [startDate, setStartDate] = useState<string>(defaultDates.start)
  const [endDate, setEndDate] = useState<string>(defaultDates.end)

  // Enrollment data states
  const [allEnrollments, setAllEnrollments] = useState<SimplifiedEnrollment[]>([])
  const [filteredEnrollments, setFilteredEnrollments] = useState<SimplifiedEnrollment[]>([])

  // Financial summary state
  const [financialSummary, setFinancialSummary] = useState<FinancialSummary>({
    totalAmount: 0,
    totalVAT: 0,
    paymentMethods: {
      swish: { count: 0, amount: 0 },
      manual: { count: 0, amount: 0 },
      other: { count: 0, amount: 0 }
    }
  })

  // Get enrollment data
  const enrollmentsQuery = useEnrollmentsForClient(contextClientId)

  // Process enrollment data to extract only what we need for the financial summary
  const processEnrollmentData = (enrollments: any[]): SimplifiedEnrollment[] => {
    return enrollments
      .filter((enrollment) => enrollment.status !== 'deleted') // Filter out deleted enrollments
      .filter((enrollment) => enrollment.paymentStatus === 'paid') // Only include paid enrollments
      .map((enrollment) => {
        const lastPayment =
          enrollment.payments && enrollment.payments.length > 0
            ? enrollment.payments[enrollment.payments.length - 1]
            : null

        let amount = 0
        // For paid enrollments, use the payment amount
        if (lastPayment) {
          amount = Number(((lastPayment.totalAmount || 0) / 100).toFixed(2))
        } else {
          // This should not happen since we're filtering for paid enrollments,
          // but we'll calculate from priceDetails as a fallback
          if (enrollment.priceDetails && enrollment.priceDetails.length > 0) {
            amount = enrollment.priceDetails.reduce((sum: number, detail: any) => {
              return sum + (detail.finalPrice || 0) / 100
            }, 0)
          }
        }

        // Get payment date
        const paymentDate = lastPayment?.paymentDate || '-'
        const formattedPaymentDate = formatDisplayDate(paymentDate)

        return {
          id: enrollment.id,
          amount,
          vat: calculateEnrollmentVATTotal(enrollment),
          paymentMethod: lastPayment?.paymentMethod || 'Unpaid',
          originalPaymentDate: paymentDate,
          formattedPaymentDate,
          isPaid: true // Always true since we're filtering for paid enrollments
        }
      })
      .filter((enrollment) => enrollment.amount > 0) // Remove zero-amount enrollments
  }

  // Filter enrollments based on payment date using the shared utility
  const filterEnrollmentsByPaymentDate = () => {
    // If no dates are provided, return all enrollments
    if (!startDate && !endDate) {
      setFilteredEnrollments(allEnrollments)
      return
    }

    const filtered = allEnrollments.filter((enrollment) => {
      // Only filter paid enrollments with valid payment dates
      if (!enrollment.originalPaymentDate || enrollment.originalPaymentDate === '-') {
        return false // Exclude enrollments without a payment date
      }

      // Use shared date range checker
      return isDateInRange(enrollment.originalPaymentDate, startDate, endDate)
    })

    setFilteredEnrollments(filtered)
  }

  // Calculate financial summary from filtered enrollments
  const calculateFinancialSummary = (enrollments: SimplifiedEnrollment[]): FinancialSummary => {
    return enrollments.reduce(
      (summary, enrollment) => {
        // Total amount and VAT calculations
        const newSummary = {
          ...summary,
          totalAmount: summary.totalAmount + enrollment.amount,
          totalVAT: summary.totalVAT + enrollment.vat
        }

        // Payment method calculations
        const paymentMethod = enrollment.paymentMethod.toLowerCase()
        if (paymentMethod.includes('swish')) {
          newSummary.paymentMethods.swish.count += 1
          newSummary.paymentMethods.swish.amount += enrollment.amount
        } else if (
          paymentMethod.includes('manual') ||
          paymentMethod.includes('bank') ||
          paymentMethod.includes('invoice') ||
          paymentMethod === 'unpaid'
        ) {
          newSummary.paymentMethods.manual.count += 1
          newSummary.paymentMethods.manual.amount += enrollment.amount
        } else {
          newSummary.paymentMethods.other.count += 1
          newSummary.paymentMethods.other.amount += enrollment.amount
        }

        return newSummary
      },
      {
        totalAmount: 0,
        totalVAT: 0,
        paymentMethods: {
          swish: { count: 0, amount: 0 },
          manual: { count: 0, amount: 0 },
          other: { count: 0, amount: 0 }
        }
      }
    )
  }

  // Handle period change using shared utilities
  const handlePeriodChange = (event: SelectChangeEvent) => {
    const selectedPeriod = event.target.value as PeriodFilter
    setPeriod(selectedPeriod)

    switch (selectedPeriod) {
      case PeriodFilter.ThisMonth:
        const thisMonth = getDefaultDates()
        setStartDate(thisMonth.start)
        setEndDate(thisMonth.end)
        break
      case PeriodFilter.LastMonth:
        const lastMonth = getLastMonthDates()
        setStartDate(lastMonth.start)
        setEndDate(lastMonth.end)
        break
      case PeriodFilter.AllTime:
        setStartDate('')
        setEndDate('')
        break
      case PeriodFilter.Custom:
        // Don't change dates, allow user to set them manually
        break
    }
  }

  // Update loading status
  useEffect(() => {
    setLoading(enrollmentsQuery.isLoading)
  }, [enrollmentsQuery.isLoading])

  // Process enrollment data when it's available
  useEffect(() => {
    if (!enrollmentsQuery.data || enrollmentsQuery.status !== 'success') return

    const simplifiedEnrollments = processEnrollmentData(enrollmentsQuery.data)
    setAllEnrollments(simplifiedEnrollments)

    // Initial filtering will happen in the next effect
  }, [enrollmentsQuery.data, enrollmentsQuery.status])

  // Apply filters when filter criteria or enrollment data changes
  useEffect(() => {
    if (allEnrollments.length > 0) {
      filterEnrollmentsByPaymentDate()
    }
  }, [startDate, endDate, allEnrollments])

  // Update financial summary when filtered enrollments change
  useEffect(() => {
    const summary = calculateFinancialSummary(filteredEnrollments)
    setFinancialSummary(summary)
  }, [filteredEnrollments])

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth gap="20px">
        {/* Header Section */}
        <Box direction="row" fullWidth justify="space-between" align="center" style={{ marginBottom: '20px' }}>
          <FigmaText textKey={Texts.adminGeneralPagesFinancialOverviewFinancialDashboardHeader} />
        </Box>

        {/* Filter Controls - Only Payment Date */}
        <Box direction="column" gap="16px" style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
          <FilterSection direction="row">
            <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFilterPaymentDateSection} />
            <FormControl>
              <InputLabel id="period-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
              </InputLabel>
              <Select
                labelId="period-select-label"
                id="period-select"
                value={period}
                onChange={handlePeriodChange}
                label={getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '& .MuiSvgIcon-root': { color: 'white' }
                }}
              >
                <MenuItem value={PeriodFilter.ThisMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodThisMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.LastMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodLastMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.AllTime}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodAllPeriod)}
                </MenuItem>
                <MenuItem value={PeriodFilter.Custom}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodCustom)}
                </MenuItem>
              </Select>
            </FormControl>
            {period !== PeriodFilter.AllTime && (
              <>
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialStartDate)}
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                    setPeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    width: '200px',
                    '& .MuiSvgIcon-root': { color: 'white !important' },
                    '& .MuiInputAdornment-root .MuiButtonBase-root': { color: 'white !important' },
                    '& svg': { color: 'white !important' },
                    '& input::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)'
                    }
                  }}
                />
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialEndDate)}
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                    setPeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    width: '200px',
                    '& .MuiSvgIcon-root': { color: 'white !important' },
                    '& .MuiInputAdornment-root .MuiButtonBase-root': { color: 'white !important' },
                    '& svg': { color: 'white !important' },
                    '& input::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)'
                    }
                  }}
                />
              </>
            )}
          </FilterSection>
        </Box>

        {/* Financial Summary Dashboard Cards */}
        {!hasAccess(state) ? (
          <Box fullWidth align="center">
            <Alert severity="error">No access</Alert>
          </Box>
        ) : loading ? (
          <Box fullWidth align="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box fullWidth align="center">
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : (
          <Box direction="row" fullWidth gap="16px" style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
            {/* Total Amount Card */}
            <DashboardCard
              icon={<img src={moneyIcon} alt="Money Icon" />}
              title={getFigmaText(Texts.adminGeneralPagesFinancialOverviewKpiHeaderPaidTotal)}
              value={`${formatNumber(financialSummary.totalAmount)} SEK`}
            />

            {/* Swish Payment Card */}
            <DashboardCard
              icon={<img src={moneyIcon} alt="Money Icon" />}
              title={getFigmaText(Texts.adminGeneralPagesFinancialOverviewKpiHeaderPaidThroughSwish)}
              value={`${formatNumber(financialSummary.paymentMethods.swish.amount)} SEK`}
              subtitle={`${financialSummary.paymentMethods.swish.count} ${getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentsDef)}`}
            />

            {/* Manual Payment Card */}
            <DashboardCard
              icon={<img src={moneyIcon} alt="Money Icon" />}
              title={getFigmaText(Texts.adminGeneralPagesFinancialOverviewKpiHeaderPaidThroughBank)}
              value={`${formatNumber(financialSummary.paymentMethods.manual.amount)} SEK`}
              subtitle={`${financialSummary.paymentMethods.manual.count} ${getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentsDef)}`}
            />
          </Box>
        )}

        {/* Report Links Section */}
        <Box direction="column" fullWidth gap="20px">
          <FigmaText textKey={Texts.adminGeneralPagesFinancialOverviewFinancialOverviewReportsHeader} />
          <Box direction="row" fullWidth gap="20px" changeDirectionOnMobile>
            <Box fullWidth>
              <ReportLink to={RoutePath.FINANCIALS}>
                <ReportIconContainer>
                  <BarChartIcon sx={{ fontSize: 40 }} />
                </ReportIconContainer>
                <ReportContent>
                  <ReportTitle>
                    {getFigmaText(Texts.adminGeneralPagesFinancialOverviewFinancialOverviewHeader)}
                  </ReportTitle>
                  <ReportDescription>
                    {getFigmaText(Texts.adminGeneralPagesFinancialOverviewFinancialOverviewDescription)}
                  </ReportDescription>
                  <ViewReportButton>
                    <Typography variant="button" sx={{ mr: 1 }}>
                      {getFigmaText(Texts.adminGeneralPagesFinancialOverviewCtaOpenReport)}
                    </Typography>
                    <ArrowForwardIcon fontSize="small" />
                  </ViewReportButton>
                </ReportContent>
              </ReportLink>
            </Box>
            <Box fullWidth>
              <ReportLink to={RoutePath.FINANCIALS_DETAILS}>
                <ReportIconContainer>
                  <ReceiptIcon sx={{ fontSize: 40 }} />
                </ReportIconContainer>
                <ReportContent>
                  <ReportTitle>
                    {getFigmaText(Texts.adminGeneralPagesFinancialOverviewFinancialDetailsHeader)}
                  </ReportTitle>
                  <ReportDescription>
                    {getFigmaText(Texts.adminGeneralPagesFinancialOverviewFinancialDetailsDescription)}
                  </ReportDescription>
                  <ViewReportButton>
                    <Typography variant="button" sx={{ mr: 1 }}>
                      {getFigmaText(Texts.adminGeneralPagesFinancialOverviewCtaOpenReport)}
                    </Typography>
                    <ArrowForwardIcon fontSize="small" />
                  </ViewReportButton>
                </ReportContent>
              </ReportLink>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </MainContainer>
  )
}

// Styled components - using the same styles as FinancialDetails
const FilterSection = styled(Box)({
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  alignItems: 'center'
})

// Report link styled components
const ReportLink = styled(Link)({
  display: 'flex',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '12px',
  padding: '24px',
  transition: 'all 0.3s ease',
  textDecoration: 'none',
  color: 'inherit',
  height: '100%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)'
  }
})

const ReportIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 123, 255, 0.2)',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  marginRight: '20px',
  color: '#4fc3f7'
})

const ReportContent = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column'
})

const ReportTitle = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 600,
  marginBottom: '8px',
  color: 'white'
})

const ReportDescription = styled(Typography)({
  fontSize: '0.95rem',
  marginBottom: '16px',
  color: 'rgba(255, 255, 255, 0.7)',
  lineHeight: 1.6
})

const ViewReportButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 'auto',
  color: '#4fc3f7',
  transition: 'color 0.2s',
  '&:hover': {
    color: '#81d4fa'
  }
})

export default FinancialsOverviewPage
