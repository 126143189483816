import { NavigateFunction } from 'react-router-dom'
import { Activity, ActivityEnrollmentStatus, ActivityStatus, Client } from '../../../pure-js/types/GrooverTypes'
import { upsertActivity } from '../libs/DBApiHandler'
import toast from 'react-hot-toast'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

export const createNewActivity = async (clientData: Client | null, navigate: NavigateFunction) => {
  try {
    const newActivityId = crypto.randomUUID()
    const newActivity: Activity = {
      id: newActivityId,
      createdAt: new Date().toISOString(),
      type: 'Course',
      style: 'Generell',
      name: 'New Activity',
      clientId: clientData?.id || '',
      startDate: '',
      description: 'New activity',
      activityType: 'Couple',
      price: 0,
      approvalRequired: false,
      allowDropin: false,
      status: ActivityStatus.Draft,
      length: 60,
      discountIds: [],
      maxAllowedParticipants: 30,
      period: '',
      noOfOccasions: 1,
      time: '',
      vat: clientData?.defaultVAT || 25,
      enrollmentStatus: ActivityEnrollmentStatus.Open
    }
    await upsertActivity(newActivity)
    toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess), {
      duration: 2000,
      position: 'top-center'
    })
    navigate(`/editActivity/${newActivityId}`)
  } catch (error) {
    toast.error(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivityFail), {
      duration: 2000,
      position: 'top-center'
    })
    console.error('error creating activity', error)
  }
}

// Format date function for the table
export const getStartDateAndTime = (dateString: string, timeString: string) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  const weekdays = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
  const weekday = weekdays[date.getDay()]
  const d = date.getDate()
  const month = months[date.getMonth()]

  // Get time from timeString if available, otherwise use date
  let hours = '00'
  let minutes = '00'
  if (timeString) {
    const timeParts = timeString.split(':')
    hours = timeParts[0].padStart(2, '0')
    minutes = timeParts[1].padStart(2, '0')
  } else {
    hours = date.getHours().toString().padStart(2, '0')
    minutes = date.getMinutes().toString().padStart(2, '0')
  }

  return `${weekday} ${d} ${month} ${hours}:${minutes}`
}

// Add status translation function
export const getStatusTranslation = (status: string): string => {
  const translations: Record<string, string> = {
    [ActivityStatus.Active]: getFigmaText(Texts.adminGeneralEnumsStatusStatusActive || 'Active'),
    [ActivityStatus.Completed]: getFigmaText(Texts.adminGeneralEnumsStatusStatusCompleted || 'Completed'),
    [ActivityStatus.Archived]: getFigmaText(Texts.adminGeneralEnumsStatusStatusArchived || 'Archived'),
    [ActivityStatus.Draft]: getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft || 'Draft'),
    [ActivityStatus.Cancelled]: getFigmaText(Texts.adminGeneralEnumsStatusStatusCancelled || 'Cancelled')
    // Add other status translations as needed
  }

  return translations[status] || status
}

// Get only statuses that have translations
export const getAvailableStatusOptions = (): string[] => {
  const translations: Record<string, string> = {
    [ActivityStatus.Active]: getFigmaText(Texts.adminGeneralEnumsStatusStatusActive || 'Active'),
    [ActivityStatus.Completed]: getFigmaText(Texts.adminGeneralEnumsStatusStatusCompleted || 'Completed'),
    [ActivityStatus.Archived]: getFigmaText(Texts.adminGeneralEnumsStatusStatusArchived || 'Archived'),
    [ActivityStatus.Draft]: getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft || 'Draft'),
    [ActivityStatus.Cancelled]: getFigmaText(Texts.adminGeneralEnumsStatusStatusCancelled || 'Cancelled')
    // Add other status translations as needed
  }

  // Return only statuses that have translations defined
  return Object.keys(translations)
}

export const getLocalizedActivityType = (type: string | undefined, client: Client | null): string => {
  if (!type) return '-'

  switch (type) {
    case 'Occasion':
      return client?.typeTranslations?.Occasion || getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
    case 'Course':
      return client?.typeTranslations?.Course || getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)
    case 'Other':
      return client?.typeTranslations?.Other || getFigmaText(Texts.adminGeneralEnumsActivityTypeOther)
    default:
      return type
  }
}
