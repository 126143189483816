export interface Coordinates {
  lat: number
  lng: number
}

export const extractMarkerLatLng = (url: string): Coordinates | null => {
  try {
    if (!url || !url.includes('google')) return null

    // Handle various Google Maps URL formats in priority order
    const patterns = [
      // Place ID coordinates (most accurate)
      /!8m2!3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/,
      // Place URLs with ll= parameter
      /ll=(-?\d+\.\d+),(-?\d+\.\d+)/,
      // Embedded maps
      /q=(-?\d+\.\d+),(-?\d+\.\d+)/,
      // Standard map URL with @lat,lng format (least accurate, usually viewport)
      /@(-?\d+\.\d+),(-?\d+\.\d+)/,
      // Simple coordinates in URL
      /\/(-?\d+\.\d+),(-?\d+\.\d+)/
    ]

    for (const pattern of patterns) {
      const match = url.match(pattern)
      if (match) {
        const lat = parseFloat(match[1])
        const lng = parseFloat(match[2])

        // Validate coordinates
        if (isValidLatLng(lat, lng)) {
          return { lat, lng }
        }
      }
    }

    return null
  } catch (error) {
    console.error('Error extracting coordinates:', error)
    return null
  }
}

const isValidLatLng = (lat: number, lng: number): boolean => {
  return !isNaN(lat) && !isNaN(lng) && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180
}
