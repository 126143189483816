import React from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

interface ParticipantData {
  name: string
  value: number
  color: string
}

interface ParticipantDonutChartProps {
  data: ParticipantData[]
  totalParticipants: number
  maxParticipants?: number
}

const ParticipantLineChart: React.FC<ParticipantDonutChartProps> = ({ data, totalParticipants, maxParticipants }) => {
  // Filter out any data items with zero value to avoid rendering issues
  const validData = data.filter((item) => item.value > 0)

  // Add free slots if maxParticipants is provided
  let chartData = [...validData]
  if (maxParticipants && maxParticipants > totalParticipants) {
    const freeSlots = maxParticipants - totalParticipants
    chartData.push({
      name: 'Free Slots',
      value: freeSlots,
      color: 'rgba(100, 100, 100, 0.5)' // Darker gray with more opacity for better contrast
    })
  }

  // If no valid data, show just the total
  if (validData.length === 0) {
    return (
      <Box>
        <TotalValue>{maxParticipants ? `${totalParticipants}/${maxParticipants}` : totalParticipants}</TotalValue>
        <TotalLabel>Total</TotalLabel>
      </Box>
    )
  }

  // Calculate the width percentage for each segment
  const total = chartData.reduce((sum, item) => sum + item.value, 0)

  return (
    <Box direction="column" align="center" fullWidth>
      <TotalValue>{maxParticipants ? `${totalParticipants}/${maxParticipants}` : totalParticipants}</TotalValue>
      {/* Horizontal Bar Chart */}
      <BarChartContainer>
        <BarSegmentsContainer>
          {chartData.map((entry, index) => (
            <BarSegment
              key={`segment-${index}`}
              style={{
                backgroundColor: entry.color,
                width: `${(entry.value / total) * 100}%`
              }}
            />
          ))}
        </BarSegmentsContainer>
      </BarChartContainer>
      <LegendContainer direction="row" gap="10px">
        {validData.map((entry, index) => (
          <LegendItem key={`legend-${index}`}>
            <ColoredNumberBadge style={{ backgroundColor: entry.color }}>{entry.value}</ColoredNumberBadge>
            <LegendText>{entry.name}</LegendText>
          </LegendItem>
        ))}
        {maxParticipants && maxParticipants > totalParticipants && (
          <LegendItem>
            <ColoredNumberBadge style={{ backgroundColor: 'rgba(100, 100, 100, 0.5)' }}>
              {maxParticipants - totalParticipants}
            </ColoredNumberBadge>
            <LegendText>{getFigmaText(Texts.adminGeneralStateAndTypesTypeFreeSlots)}</LegendText>
          </LegendItem>
        )}
      </LegendContainer>
    </Box>
  )
}

const BarChartContainer = styled.div`
  width: 100%;
  height: 32px; // Increased to accommodate taller bar segments
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BarSegmentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 24px; // Increased to match BarSegment height
  border-radius: 8px; // Adjusted border radius for the new height
  overflow: hidden;
`

const BarSegment = styled.div`
  height: 100%;
  min-width: 2px; /* Ensure even small segments are visible */
  transition: width 0.3s ease;
`

const TotalValue = styled(Box)`
  font-size: 24px;
  margin-top: 10px;
  font-weight: bold;
  color: white;
`

const TotalLabel = styled(Box)`
  font-size: 14px;
  color: #ccc;
`

const LegendContainer = styled(Box)`
  background: rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
`

const ColoredNumberBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7); // Stronger text shadow for better readability
`

const LegendText = styled.span`
  color: white;
  font-size: 14px;
`

export default ParticipantLineChart
