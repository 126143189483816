import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { useZxing } from 'react-zxing'
import { checkQRCode } from '../../../pure-js/libs/QRHelper'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from '../components/Box'
import { getFigmaText } from '../libs/TextRepository'

const QRCodeScannerPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const returnUrl = location.state?.returnUrl || RoutePath.DASHBOARD
  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = () => {
    navigate(returnUrl)
  }

  const { ref } = useZxing({
    onDecodeResult: (result) => {
      const text = result.getText()
      console.log(text, 'QR code')
      if (!isLoading) {
        handleQR(text)
      }
    }
  })

  const handleQR = async (text) => {
    let handled = false
    ref.current?.pause()
    setIsLoading(true)

    try {
      const qrResult = checkQRCode(text)
      if (qrResult.success) {
        switch (qrResult.type) {
          case 'visit': {
            if (!qrResult.id) {
              break
            }

            handled = true
            const redirect =
              RoutePath.CHECKIN.replace(':enrollmentId', qrResult.id).replace(':activityId?', '') + '?source=qr'
            console.log('redirect', redirect)
            navigate(redirect)
            break
          }
        }
      }

      if (handled) {
        toast.success('QR code is valid')
      } else {
        toast.error('QR code is unknown or not valid')
        ref.current?.play()
        setIsLoading(false)
      }
    } catch (error) {
      console.log('qr error', error)
      ref.current?.play()
      setIsLoading(false)
    }
  }

  return (
    <MainContainer>
      <video ref={ref} />
      <Button variant="contained" color="primary" onClick={handleCancel}>
        {getFigmaText(Texts.adminGeneralCtactaCancel)}
      </Button>
    </MainContainer>
  )
}

const MainContainer = styled(Box)`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
`

export default QRCodeScannerPage
