import React, { FC, JSX } from 'react'
import Box from './Box'
import styled from '@emotion/styled'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Activity, Enrollment } from '../../../pure-js/types/GrooverTypes'
import { NavigateFunction } from 'react-router-dom'

interface ActivityListFilteredEnrollmentsProps {
  filteredEnrollments: Enrollment[]
  highlightText: (text: string, highlight: string) => JSX.Element | string
  filterText: string
  handleRegistrationClick: (navigate: NavigateFunction, activity: Activity) => void
  navigate: NavigateFunction
}

const ActivityListFilteredEnrollments: FC<ActivityListFilteredEnrollmentsProps> = ({
  filteredEnrollments,
  highlightText,
  filterText,
  handleRegistrationClick,
  navigate
}) => (
  <Box fullWidth>
    <h2>Anmälningar</h2>
    {filteredEnrollments.map((enrollment) => (
      <Box direction="column" fullWidth key={enrollment.id} className="enrollment-item">
        <Box direction="row" fullWidth>
          {enrollment.activityIds?.map((aId) => (
            <ActivityLink key={aId} onClick={() => handleRegistrationClick(navigate, { id: aId } as Activity)}>
              {highlightText(aId, filterText)}
              <OpenInNew />
            </ActivityLink>
          ))}
        </Box>
        <Box direction="row" fullWidth>
          <p className="enrollment-title">{highlightText(enrollment.mainUser.name, filterText)}</p>
          <p className="enrollment-email">{highlightText(enrollment.mainUser.email, filterText)}</p>
        </Box>
        {enrollment.partnerUser && (
          <Box direction="row" fullWidth>
            <p className="enrollment-title">{highlightText(enrollment.partnerUser.name, filterText)}</p>
            <p className="enrollment-email">{highlightText(enrollment.partnerUser.email, filterText)}</p>
          </Box>
        )}
      </Box>
    ))}
  </Box>
)

const ActivityLink = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  margin: 8px 4px;
  gap: 8px;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

export default ActivityListFilteredEnrollments
