import crypto from 'crypto'
import { Activity, Enrollment } from '../types/GrooverTypes'

const BASE62_CHARS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

const encodeBase62 = (buffer: Buffer): string => {
  let num = BigInt('0x' + buffer.toString('hex')) // Convert buffer to a big integer
  let result = ''
  const base = BigInt(BASE62_CHARS.length)
  do {
    result = BASE62_CHARS[Number(num % base)] + result
    num = num / base
  } while (num > 0)
  return result
}

export const getEnrollmentUrl = (enrollment: Enrollment): string =>
  `https://app.coursely.se/register/${enrollment.activities[0].id}/${enrollment.id}`

export const getUrlShort = (hash: string): string => `https://app.coursely.se/r/${hash}`

export const getActivityUrl = (activity: Activity): string => `https://app.coursely.se/activity/${activity.id}`

export function getUrlHash(url: string, attempt: number): string {
  // Generate a hash of the path
  const hashBuffer = crypto
    .createHash('sha256')
    .update(url + (attempt > 0 ? `-${attempt}` : ''))
    .digest() // get raw hash bytes
  const hash = encodeBase62(hashBuffer).slice(0, 8) // Encode in Base62 and truncate to 8 characters
  return hash
}
