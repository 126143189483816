import React, { useMemo } from 'react'
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Typography } from '@mui/material'
import Box from '../Box'
import { SelectChangeEvent } from '@mui/material'
import JoditEditor from 'jodit-react'
import { styled } from '@mui/material/styles'
import { Section, CustomTextField, CustomSelectField } from '../../styles/AdminPageStyles'
import { EmailTemplate } from '../../../../pure-js/types/GrooverTypes'
import { getFigmaText, formatText } from '../../libs/TextRepository'
import { Texts } from '../../../../pure-js/libs/Texts'
import toast from 'react-hot-toast'

const TemplateSelection = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
`

const WYSIWIGContainer = styled(Box)`
  padding: 10px;
`

const PlaceHolderSection = styled(Box)`
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
`

interface EmailEditorSectionProps {
  emailTemplates: EmailTemplate[]
  emailTemplateSelected: EmailTemplate
  templateBody: string
  templateSubject: string
  templateReplyToEmail: string
  templateReplyToName: string
  availablePlaceholders: string
  editorRef: React.MutableRefObject<any>
  MenuProps: any
  setTemplateBody: (value: string) => void
  setTemplateSubject: (value: string) => void
  setTemplateReplyToEmail: (value: string) => void
  setTemplateReplyToName: (value: string) => void
  handleEmailTemplateChange: (event: SelectChangeEvent<string>) => void
  goToNextStep: () => void
}

const EmailEditorSection: React.FC<EmailEditorSectionProps> = ({
  emailTemplates,
  emailTemplateSelected,
  templateBody,
  templateSubject,
  templateReplyToEmail,
  templateReplyToName,
  availablePlaceholders,
  editorRef,
  MenuProps,
  setTemplateBody,
  setTemplateSubject,
  setTemplateReplyToEmail,
  setTemplateReplyToName,
  handleEmailTemplateChange,
  goToNextStep
}) => {
  const editorConfig = useMemo(
    () => ({
      height: 300,
      buttons: [
        'source',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'link',
        'image',
        'video',
        '|',
        'align',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'fullsize'
      ],
      imageDefaultWidth: 500,
      uploader: {
        insertImageAsBase64URI: true,
        processPastedFiles: true,
        processDropFiles: true,
        defaultHandlerSuccess: function (this: any, resp: any) {
          const jodit = this.jodit || this
          if (resp.files && resp.files.length) {
            resp.files.forEach((file: string) => {
              jodit.selection.insertImage(file, {
                style: {
                  'max-width': '500px',
                  height: 'auto'
                }
              })
            })
          }
        },
        processFileName: (fileName: string) => fileName,
        isSuccess: (resp: any) => resp.success,
        getMessage: (resp: any) => resp.message,
        process: (resp: any) => resp.files || [],
        error: (e: any) => {
          console.error('Image upload error:', e)
          toast.error('Failed to upload image')
        }
      },
      enableDragAndDropFileToEditor: true,
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      style: {
        background: '#ffffff',
        color: '#000000'
      },
      controls: {
        video: {
          icon: 'video',
          tooltip: 'Insert YouTube',
          popup: (editor: any, current: any, control: any, close: () => void) => {
            const form = editor.create.fromHTML(`
              <form class="jodit-form">
                <input required type="url" placeholder="Enter YouTube URL"/>
                <button type="submit">Insert</button>
              </form>
            `)

            form.addEventListener('submit', (e: Event) => {
              e.preventDefault()
              const input = form.querySelector('input')
              if (input) {
                const url = input.value
                const videoId = url.match(
                  /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i
                )?.[1]
                if (videoId) {
                  const embedHtml = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                  editor.selection.insertHTML(embedHtml)
                }
              }
              close()
            })

            return form
          }
        }
      }
    }),
    []
  )

  return (
    <>
      <Section id="Section: Email Template" fullWidth>
        {emailTemplates.length > 0 && (
          <TemplateSelection fullWidth>
            <FormControl>
              <>
                <InputLabel id="template-label-id">
                  {getFigmaText(Texts.adminGeneralPagesEmailCommunicationInputSelectTemplate)}
                </InputLabel>
                <CustomSelectField
                  labelId="template-label-id"
                  id="templates"
                  value={emailTemplateSelected.id}
                  onChange={(event: SelectChangeEvent<unknown>, child: React.ReactNode) =>
                    handleEmailTemplateChange(event as SelectChangeEvent<string>)
                  }
                  input={
                    <OutlinedInput label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationInputSelectTemplate)} />
                  }
                  renderValue={() => emailTemplateSelected?.name}
                  MenuProps={MenuProps}
                >
                  {emailTemplates.map((a) => (
                    <MenuItem key={a.id} value={a.id}>
                      {a.name}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </>
            </FormControl>
          </TemplateSelection>
        )}
      </Section>

      <Section id="Section: Email Content" fullWidth>
        <Box width={'100%'} direction="row" gap="10px" className="boxColumn">
          <CustomTextField
            label={'From Name'}
            value={templateReplyToName}
            onChange={(e) => setTemplateReplyToName(e.target.value)}
          />
          <CustomTextField
            label={'From Email'}
            value={templateReplyToEmail}
            onChange={(e) => setTemplateReplyToEmail(e.target.value)}
          />
        </Box>
        <Box width={'100%'} direction="row" top>
          <CustomTextField
            label={'Subject'}
            value={templateSubject}
            onChange={(e) => setTemplateSubject(e.target.value)}
            fullWidth
          />
        </Box>
        <WYSIWIGContainer fullWidth>
          <JoditEditor
            ref={editorRef}
            value={templateBody}
            config={editorConfig}
            onChange={(newContent) => setTemplateBody(newContent)}
          />
        </WYSIWIGContainer>

        <PlaceHolderSection fullWidth>
          <Typography variant="body1">
            {formatText(getFigmaText(Texts.adminGeneralPagesEmailCommunicationPlaceholderTextForEmail), [
              availablePlaceholders
            ])}
          </Typography>
        </PlaceHolderSection>

        <Box direction="row" fullWidth top>
          <Button
            variant="contained"
            color="primary"
            onClick={goToNextStep}
            disabled={!templateReplyToName || !templateReplyToEmail || !templateSubject || !templateBody}
            sx={{ mt: 2 }}
          >
            {getFigmaText(Texts.registrationCtactaContinue)}
          </Button>
        </Box>
      </Section>
    </>
  )
}

export default EmailEditorSection
