import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import defaultUserIcon from '../resources/images/default_user.png' // Adjust the path as necessary
import { useLogout } from '../hooks/useLogout'
import useAppState from '../hooks/useAppState'
import Box from './Box'
import { getClient } from '../libs/DBApiHandler'
import { useDeepLink } from '../hooks/useDeepLink'
import { useNavigate } from '../hooks/useNavigate'
import { clearAndNavigateOut } from '../utils/loginHelper'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { useClient } from '../context/ClientContext'

interface UserMenuProps {
  onClientIdSelect: (clientId: string) => void
  initialClientId?: string
}

const UserMenu: React.FC<UserMenuProps> = ({ onClientIdSelect, initialClientId }) => {
  const { state } = useAppState()
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [userIcon, setUserIcon] = useState<string>(defaultUserIcon)
  const [clientDetails, setClientDetails] = useState<{ id: string; name: string }[]>([])
  const [selectedClientId, setSelectedClientId] = useState<string>(
    initialClientId || localStorage.getItem('selectedClientId') || ''
  )
  const clientSelectRef = useRef<HTMLSelectElement>(null)
  const navigate = useNavigate()
  const { clearDeepLink } = useDeepLink()
  const { setClientId, triggerReload } = useClient()
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const stateFirebaseUser = state.firebaseUser
  const user = state.user

  const toggleUserMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsUserMenuOpen(!isUserMenuOpen)
    // Always close the select when toggling the menu
    setIsSelectOpen(false)
  }

  const handleClientSelect = (clientId: string) => {
    setSelectedClientId(clientId)
    onClientIdSelect(clientId)
    localStorage.setItem('selectedClientId', clientId)

    // Update the client context
    setClientId(clientId)

    // Trigger reload in any components that need it
    triggerReload()

    // Get current path to maintain the same page instead of redirecting to dashboard
    const currentPath = window.location.pathname
    // Ensure we have a valid path, fallback to dashboard if needed
    const navigateTo = currentPath && currentPath !== '/' ? currentPath : '/dashboard'
    navigate(navigateTo)
  }

  const handleClientSelectClick = (e: React.MouseEvent) => {
    // Stop propagation to prevent the user menu from closing
    e.stopPropagation()
  }

  const logout = useLogout()

  const onLogOut = () => {
    clearAndNavigateOut(clearDeepLink, navigate)
    logout()
  }

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    toggleUserMenu(e)
  }

  const handleSelectChange = (clientId: string) => {
    handleClientSelect(clientId)
    // Don't close the menu after selection
  }

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const toggleClientList = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsSelectOpen(!isSelectOpen)
  }

  useEffect(() => {
    setUserIcon(stateFirebaseUser?.photoURL || defaultUserIcon)
  }, [state.firebaseUser])

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (user?.authCustomClaims?.clientIds) {
        const clientPromises = user.authCustomClaims.clientIds.map(async (clientId: string) => {
          const client = await getClient(clientId) // Assume getClient fetches the client by ID
          return client ? { id: clientId, name: client.name } : { id: clientId, name: 'Unknown' }
        })

        const details = await Promise.all(clientPromises)
        setClientDetails(details)
      }
    }

    fetchClientDetails().catch((error) => console.error('Error fetching client details:', error))
  }, [user])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node
      // If clicked outside the entire menu structure, close everything
      if (isUserMenuOpen && menuRef.current && !menuRef.current.contains(target)) {
        setIsUserMenuOpen(false)
        setIsSelectOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    // Add touch events for better mobile support
    document.addEventListener('touchend', handleClickOutside as any)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside as any)
    }
  }, [isUserMenuOpen])

  useEffect(() => {
    const storedClientId = localStorage.getItem('selectedClientId')
    if (storedClientId && !initialClientId) {
      setSelectedClientId(storedClientId)
      onClientIdSelect(storedClientId)
    }
  }, []) // Empty dependency array means this runs once on mount

  return (
    <UserContainer id="user-container" ref={menuRef}>
      <UserIconWrapper onClick={handleIconClick}>
        <img src={userIcon} alt="User Icon" className="user-icon" />
      </UserIconWrapper>

      {isUserMenuOpen && (
        <MenuContainer direction="column" position="absolute" onClick={handleMenuClick}>
          <Box direction="row" gap="12px" align="center">
            <img src={userIcon} alt="User Icon" className="user-icon" />
            <Box direction="column" gap="0px">
              <p style={{ fontSize: '14px', margin: 0, color: 'white' }}>{user?.displayName}</p>
              <p style={{ fontSize: '12px', margin: 0, color: 'white' }}>{user?.email}</p>
            </Box>
          </Box>
          <StyledLine />

          {clientDetails.length > 0 ? (
            <ClientSelectorContainer>
              <ClientSelectorHeader onClick={toggleClientList}>
                {clientDetails.find((client) => client.id === selectedClientId)?.name || 'Select Client'}
                <DropdownArrow>{isSelectOpen ? '▲' : '▼'}</DropdownArrow>
              </ClientSelectorHeader>

              {isSelectOpen && (
                <ClientList>
                  {clientDetails.map(({ id, name }) => (
                    <ClientItem
                      key={id}
                      selected={id === selectedClientId}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleSelectChange(id)
                        setIsSelectOpen(false)
                      }}
                    >
                      {name}
                    </ClientItem>
                  ))}
                </ClientList>
              )}
            </ClientSelectorContainer>
          ) : (
            <p>{getFigmaText(Texts.adminGeneralBusyText)}</p>
          )}

          <StyledLine />
          <Logout
            onClick={(e) => {
              e.stopPropagation()
              onLogOut()
            }}
          >
            Logga ut
          </Logout>
        </MenuContainer>
      )}
    </UserContainer>
  )
}

// New styled components for cleaner UI and better event handling
const UserIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ClientSelectorContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 5px 0;
`

const ClientSelectorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #2c2755;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: #3c3765;
  }
`

const DropdownArrow = styled.span`
  margin-left: 8px;
  font-size: 10px;
`

const ClientList = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100%; /* Changed from top: 100% to bottom: 100% */
  left: 0;
  background-color: #171231;
  border-radius: 4px;
  margin-bottom: 4px; /* Changed from margin-top to margin-bottom */
  z-index: 10000;
  max-height: 400px; /* Reduced max-height for better UX */
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`

const ClientItem = styled.div<{ selected: boolean }>`
  padding: 12px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#3c3765' : 'transparent')};
  color: white;
  font-size: 14px;

  &:hover {
    background-color: #3c3765;
  }
`

// Keep existing styled components
const Logout = styled.p`
  cursor: pointer;
  color: #f44336;
  font-size: 14px;
  border-radius: 4px;
  margin: 0;
  padding: 8px;
  font-size: 14px;

  &:hover {
    background: #f44336;
    color: white;
  }
`

const StyledLine = styled.hr`
  width: 100%;
  border-color: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
  height: 2px;
`

const MenuContainer = styled(Box)`
  z-index: 9999; /* Higher value ensures it stays on top */
  display: flex;
  width: auto;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  background: #171231;
  bottom: 60px;
  left: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
`

const UserContainer = styled(Box)`
  position: fixed;
  bottom: 20px;
  left: 5px;
  cursor: pointer;
  z-index: 9999;
`

export default UserMenu
