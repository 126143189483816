import QRCode from 'react-qr-code'
import Box from './Box'
import styled from '@emotion/styled'

type Props = {
  value: string
  showText?: boolean
}

const QRCodeElement = ({ value, showText }: Props) => {
  return (
    <QRWrapper fullWidth direction="column" justify="space-between" align="center">
      <QRCode value={value} />
      {showText && <span>{value}</span>}
    </QRWrapper>
  )
}

const QRWrapper = styled(Box)`
  border: 1px solid #fff;
`

export default QRCodeElement
