export enum CloudFunction {
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  inviteUsers = 'inviteUsers',
  signUpUser = 'signUpUser',
  joinTeam = 'joinTeam',
  deleteUser = 'deleteUser',
  triggerReflectionEmail = 'triggerReflectionEmail',
  setCustomUserClaimsForLoggedInUser = 'setCustomUserClaimsForLoggedInUser',
  createStripePaymentIntent = 'createStripePaymentIntent',
  getActivitiesForClient = 'getActivitiesForClient',
  upsertEnrollment = 'upsertEnrollment',
  getEnrollmentsForPeriod = 'getEnrollmentsForPeriod',
  sendEnrollmentEmail = 'sendEnrollmentEmail',
  getObfuscatedEnrollment = 'getObfuscatedEnrollment',
  getEnrollmentData = 'getEnrollmentData',
  saveUserRegistration = 'saveUserRegistration',
  saveUserRegistrationV2 = 'saveUserRegistrationV2',
  createSwishPaymentIntent = 'createSwishPaymentIntent',
  cancelSwishPaymentIntent = 'cancelSwishPaymentIntent',
  getActivePeriodsForUser = 'getActivePeriodsForUser',
  getEnrollmentUsersForClient = 'getEnrollmentUsersForClient',
  storeEnrollmentActivityCheckin = 'storeEnrollmentActivityCheckin',
  getEmailRecipientsForActivities = 'getEmailRecipientsForActivities',
  addSsnForEnrollmentUser = 'addSsnForEnrollmentUser',
  createMailing = 'createMailing',
  onTaskMailingItem = 'onTaskMailingItem',
  setExpoPushNotificationsTokenForUser = 'setExpoPushNotificationsTokenForUser',
  createCustomTokenForIdToken = 'createCustomTokenForIdToken'
}
