import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from '../libs/TextRepository'

export interface ParticipantTypeConfig {
  color: string
  text: string
}

export const participantTypeConfig: Record<string, ParticipantTypeConfig> = {
  leader: { color: '#002BC1', text: getFigmaText(Texts.adminGeneralStateAndTypesTypeLeader) },
  follower: { color: '#B55152', text: getFigmaText(Texts.adminGeneralStateAndTypesTypeFollower) },
  both: { color: '#D4A017', text: getFigmaText(Texts.adminGeneralEnumsActivityUserTypeBoth) },
  single: { color: '#578A0F', text: getFigmaText(Texts.adminGeneralStateAndTypesTypeSingle) }
}
