import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import { debounce } from 'lodash' // Import debounce from lodash
import { Check, Dashboard, Edit, Link, OpenInNew, Info } from '@mui/icons-material'
import '../resources/css/ActivityList.css' // Import CSS
import {
  Activity,
  ActivityEnrollmentStatus,
  ActivityFilterOption,
  ActivityStatus,
  Client,
  Enrollment,
  GroupByOption
} from '../../../pure-js/types/GrooverTypes'
import Box from './Box'
import { getEnrollmentCountForActivity, getPendingEnrollmentCountForActivity } from '../utils/enrollmentUtils'
import ActivityListHeader from './ActivityListHeader'
import ActivityListFilteredEnrollments from './ActivityListFilteredEnrollments'
import ActivityListEmptyState from './ActivityListEmptyState'
import { Menu, MenuItem, IconButton, TextField } from '@mui/material'
import { getStatusTextKey, handleRegistrationClick, highlightText } from '../libs/ActivityListHelper'
import styled from '@emotion/styled'

import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import ButtonBlack from './ButtonBlack'
import { upsertActivity } from '../libs/DBApiHandler'
import toast from 'react-hot-toast'
import { AccessTime, CalendarToday, LocationOn, ControlPointDuplicate } from '@mui/icons-material'
import FigmaText from './FigmaText'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StatusChip from './StatusChip'
import { getRecentActivityIds } from '../utils/recentActivitiesStorage'
import ActivityDetailModal from './ActivityDetailModal'
import { createNewActivity } from '../utils/DashboardUtils'

interface ActivityListProps {
  activities: Activity[]
  activitiesLoaded: boolean
  enrollments: Enrollment[]
  clientData: Client | null
}

/* Main Component */
const ActivityList: React.FC<ActivityListProps> = ({ activities, activitiesLoaded, enrollments, clientData }) => {
  const [filterText, setFilterText] = useState('')
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>(activities)
  const [filteredEnrollments, setFilteredEnrollments] = useState<Enrollment[]>(enrollments)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null)
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [activityFilter, setActivityFilter] = useState<ActivityFilterOption>(
    () => (localStorage.getItem('activityListFilter') as ActivityFilterOption) || ActivityFilterOption.Active
  )
  const [groupBy, setGroupBy] = useState<GroupByOption>(
    () => (localStorage.getItem('activityListGroupBy') as GroupByOption) || GroupByOption.Style
  )
  const [detailActivity, setDetailActivity] = useState<Activity | null>(null)
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLElement | null>(null)

  const navigate = useNavigate() // Initialize useNavigate

  // Save filter selections to localStorage when they change
  useEffect(() => {
    localStorage.setItem('activityListFilter', activityFilter)
  }, [activityFilter])

  useEffect(() => {
    localStorage.setItem('activityListGroupBy', groupBy)
  }, [groupBy])

  // Filter activities based on status and start date
  const getFilteredActivities = useCallback(
    (acts: Activity[]) => {
      if (activityFilter === ActivityFilterOption.All) {
        return acts
      }

      if (activityFilter === ActivityFilterOption.Draft) {
        return acts.filter((activity) => activity.status === ActivityStatus.Draft)
      }

      if (activityFilter === ActivityFilterOption.Archived) {
        return acts.filter((activity) => activity.status === ActivityStatus.Archived)
      }

      return acts.filter((activity) => activity.status === ActivityStatus.Active)
    },
    [activityFilter]
  )

  const debouncedFilter = useCallback(
    debounce((text: string) => {
      // Apply text filter first
      const textFilteredActivities = activities.filter((registration) =>
        registration.name.toLowerCase().includes(text.toLowerCase())
      )

      // Then apply status/date filter
      setFilteredActivities(getFilteredActivities(textFilteredActivities))

      const filteredEnrs = enrollments.filter(
        (enr) =>
          enr.mainUser.name.toLowerCase().includes(text.toLowerCase()) ||
          enr.mainUser.email.toLowerCase().includes(text.toLowerCase()) ||
          enr.partnerUser?.name?.toLowerCase().includes(text.toLowerCase()) ||
          enr.partnerUser?.email?.toLowerCase().includes(text.toLowerCase())
      )
      setFilteredEnrollments(text ? filteredEnrs : [])
      setLoading(false)
    }, 300),
    [activities, enrollments, getFilteredActivities]
  )

  // Apply filters when activities change or filter changes
  useEffect(() => {
    setLoading(true)
    if (activitiesLoaded) {
      debouncedFilter(filterText)
    }
  }, [filterText, activities, activityFilter, activitiesLoaded, debouncedFilter])

  useEffect(() => {
    localStorage.setItem('activityListGroupBy', groupBy)
  }, [groupBy])

  const formatDateSwedish = (date: string) => {
    const formatted = new Date(date).toLocaleString('sv-SE', { month: 'long', year: 'numeric' })
    return formatted.charAt(0).toUpperCase() + formatted.slice(1)
  }

  const getLocalizedActivityType = (type: string) => {
    switch (type) {
      case 'Course':
        return getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)
      case 'Occasion':
        return getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
      default:
        return '-'
    }
  }

  const groupActivityKeys = useMemo(() => {
    const keys = Object.keys(
      filteredActivities.reduce((acc: { [key: string]: Activity[] }, activity) => {
        let groupKey = activity[groupBy] || '-'
        if (groupBy === GroupByOption.StartDate) {
          groupKey = activity.startDate ? formatDateSwedish(activity.startDate) : '-'
        } else if (groupBy === GroupByOption.Type) {
          groupKey = getLocalizedActivityType(activity.type)
        } else if (groupBy === GroupByOption.Status) {
          groupKey = getStatusTextKey(activity.status)
        }
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(activity)
        return acc
      }, {})
    )

    // Don't sort dates alphabetically, but sort everything else
    if (groupBy === GroupByOption.StartDate) {
      return keys
    }
    return keys.sort((a, b) => a.localeCompare(b))
  }, [filteredActivities, groupBy])

  const hasResults = groupActivityKeys.length > 0 || filteredEnrollments.length > 0

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, activity: Activity) => {
    setAnchorEl(event.currentTarget)
    setSelectedActivity(activity)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedActivity(null)
  }

  const handleDuplicate = async () => {
    if (selectedActivity && !isDuplicating) {
      setIsDuplicating(true)
      try {
        const newActivityId = crypto.randomUUID()
        const duplicatedActivity = {
          ...selectedActivity,
          id: newActivityId,
          createdAt: new Date().toISOString(),
          name: `${selectedActivity.name} (Copy)`
        }
        await upsertActivity(duplicatedActivity)
        // Update the activities list with the new activity
        setFilteredActivities((prev) => [...prev, duplicatedActivity])
        toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        handleMenuClose()
        navigate(`/editActivity/${newActivityId}`)
      } catch (error) {
        toast.error(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        console.error('error duplicating activity', error)
      } finally {
        setIsDuplicating(false)
      }
    }
  }

  const copyRegistrationPage = () => {
    if (selectedActivity) {
      navigator.clipboard.writeText(`https://app.coursely.se/register/${selectedActivity.id}`)
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageCopyToClipboard))
      handleMenuClose()
    }
  }

  // Handle info icon hover
  const handleInfoOpen = (event: React.MouseEvent<HTMLElement>, activity: Activity) => {
    setInfoAnchorEl(event.currentTarget)
    setDetailActivity(activity)
  }

  const handleInfoClose = () => {
    setInfoAnchorEl(null)
    setDetailActivity(null)
  }

  const recentIds = getRecentActivityIds()
  const recentActivities = filteredActivities.filter((a) => recentIds.includes(a.id))

  const QuickActionEnrollmentCount = styled(Box)`
    background: rgba(255, 255, 255, 0.15);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    color: white;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.25);
      transform: scale(1.1);
      border-color: rgba(255, 255, 255, 0.4);
    }
  `

  const renderActivityRow = (activity: Activity) => {
    return (
      <ActivityRow key={activity.id} direction="column" fullWidth type={activity.type} gap="8px">
        <QuickActions className="quick-actions" direction="row">
          <QuickActionButton
            onClick={() => navigate(`/editActivity/${activity.id}`)}
            title={getFigmaText(Texts.adminGeneralCtactaEditActivity)}
          >
            <Edit sx={{ fontSize: 20 }} />
          </QuickActionButton>
          <QuickActionEnrollmentCount
            onClick={() => handleRegistrationClick(navigate, activity)}
            title={getFigmaText(Texts.adminGeneralCtactaOpenRegistrations)}
          >
            {getEnrollmentCountForActivity(enrollments, activity.id)}
          </QuickActionEnrollmentCount>
        </QuickActions>
        <div className="activity-content">
          <Box direction="row" align="center" fullWidth justify="space-between">
            <Box direction="row" align="center">
              {highlightText(activity.name, filterText)}
              <EditIconWrapper>
                <EditIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/editActivity/${activity.id}`)
                  }}
                />
                <EditTooltip>{getFigmaText(Texts.adminGeneralCtactaEditActivity)}</EditTooltip>
              </EditIconWrapper>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              classes={{ paper: 'menu-custom' }}
              onClose={handleMenuClose}
            >
              {[
                {
                  icon: <Edit />,
                  text: getFigmaText(Texts.adminGeneralCtactaEditActivity),
                  onClick: () => {
                    handleMenuClose()
                    navigate(`/editActivity/${selectedActivity?.id}`)
                  }
                },
                {
                  icon: <OpenInNew />,
                  text: getFigmaText(Texts.adminGeneralCtactaOpenPublicActivityPage),
                  onClick: () => {
                    handleMenuClose()
                    window.open(`https://app.coursely.se/activity/${selectedActivity?.id}`, '_blank')
                  }
                },
                {
                  icon: <ControlPointDuplicate />,
                  text: getFigmaText(Texts.adminGeneralCtactaDuplicateActivity),
                  onClick: handleDuplicate
                },
                {
                  icon: <Link />,
                  text: getFigmaText(Texts.adminGeneralCtactaCopyRegPage),
                  onClick: copyRegistrationPage
                }
              ].map(({ icon, text, onClick }, index) => (
                <MenuItem
                  key={index}
                  onClick={onClick}
                  disabled={text === getFigmaText(Texts.adminGeneralCtactaDuplicateActivity) && isDuplicating}
                >
                  {React.cloneElement(icon, {
                    style: { marginRight: '8px', width: '20px', height: '20px', fill: 'black' }
                  })}
                  {text}
                </MenuItem>
              ))}
            </Menu>
            <Box direction="row" gap="4px" spacing="4px" align="center">
              <StatusChip status={activity.status} />
              <IconButton onClick={(e) => handleMenuOpen(e, activity)} sx={{ color: 'white' }}>
                <WhiteIcon />
              </IconButton>
            </Box>
          </Box>
          <Box fullWidth align="center" direction="row" justify="space-between" className="boxColumn" gap="8px">
            <Box direction="row" gap="8px">
              <OpenEnrollmentCTA direction="row" align="center">
                <EnrollmentCount
                  className="activity-enrollment-count"
                  align="center"
                  onClick={() => handleRegistrationClick(navigate, activity)}
                >
                  {getEnrollmentCountForActivity(enrollments, activity.id)}
                </EnrollmentCount>
              </OpenEnrollmentCTA>

              <ActivityEnrollmentStats direction="row" gap="8px" align="center">
                {(() => {
                  // Calculate all enrollment counts once
                  const leaderCount = getEnrollmentCountForActivity(enrollments, activity.id, 'leader')
                  const followerCount = getEnrollmentCountForActivity(enrollments, activity.id, 'follower')
                  const bothCount = getEnrollmentCountForActivity(enrollments, activity.id, 'both')
                  const singleCount = getEnrollmentCountForActivity(enrollments, activity.id, 'single')

                  return (
                    <>
                      {leaderCount > 0 && (
                        <>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeLeader} />
                          <EnrollmentTypeCount>{leaderCount}</EnrollmentTypeCount>
                        </>
                      )}
                      {followerCount > 0 && (
                        <>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeFollower} />
                          <EnrollmentTypeCount>{followerCount}</EnrollmentTypeCount>
                        </>
                      )}
                      {bothCount > 0 && (
                        <>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeBoth} />
                          <EnrollmentTypeCount>{bothCount}</EnrollmentTypeCount>
                        </>
                      )}
                      {singleCount > 0 && (
                        <>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeSingle} />
                          <EnrollmentTypeCount>{singleCount}</EnrollmentTypeCount>
                        </>
                      )}
                    </>
                  )
                })()}
              </ActivityEnrollmentStats>

              {(() => {
                const pendingEnrollmentsCount = getPendingEnrollmentCountForActivity(enrollments, activity.id)

                return pendingEnrollmentsCount > 0 ? (
                  <ActionNotifications>
                    <FigmaText
                      textKey={Texts.adminGeneralPagesEnrollmentListNotificationsEnrollmentsToApprove}
                      text={formatText(
                        getFigmaText(Texts.adminGeneralPagesEnrollmentListNotificationsEnrollmentsToApprove),
                        [pendingEnrollmentsCount]
                      )}
                    />
                  </ActionNotifications>
                ) : null
              })()}
            </Box>

            <ActivityMetadataContainer direction="row" gap="8px">
              {[
                {
                  icon: Check,
                  value: activity.approvalRequired
                    ? getFigmaText(Texts.adminGeneralInfoTextsActivityApprovalRequired)
                    : '',
                  hideOnMobile: false
                },
                { icon: Dashboard, value: activity.groupingCode, hideOnMobile: true },
                { icon: CalendarToday, value: activity.startDate },
                { icon: AccessTime, value: activity.time },
                { icon: LocationOn, value: activity.city, hideOnMobile: true }
              ]
                .filter((item) => item.value)
                .map((item, index) => {
                  const IconComponent = item.icon
                  const Wrapper = item.hideOnMobile ? ActivityMetadataHideOnMobile : ActivityMetadata

                  return (
                    <Wrapper direction="row" key={index}>
                      <IconComponent
                        sx={{
                          color: 'rgba(255, 255, 255, 0.4)',
                          height: '16px'
                        }}
                      />
                      {item.value}
                    </Wrapper>
                  )
                })}

              <InfoIconWrapper onMouseEnter={(e) => handleInfoOpen(e, activity)} onMouseLeave={handleInfoClose}>
                <InfoIcon
                  sx={{
                    color: 'rgba(255, 255, 255, 0.6)',
                    height: '18px',
                    cursor: 'pointer'
                  }}
                />
              </InfoIconWrapper>
            </ActivityMetadataContainer>
          </Box>
        </div>
      </ActivityRow>
    )
  }

  return (
    <div className="activity-list">
      <Box fullWidth direction="row" justify="space-between" gap="16px" align="center" className="boxColumn">
        <ActivityListHeader
          filterText={filterText}
          setFilterText={setFilterText}
          hasResults={hasResults}
          isLoading={loading}
        />
        <Box direction="row" fullWidth align="center" justify="flex-end" gap="8px" changeDirectionOnMobile>
          <CustomTextField
            select
            label={getFigmaText(Texts.adminGeneralInputFieldsFilterByLabel)}
            value={activityFilter}
            onChange={(e) => setActivityFilter(e.target.value as ActivityFilterOption)}
          >
            <MenuItem value={ActivityFilterOption.Active}>
              {getFigmaText(Texts.adminGeneralEnumsFilterFilterShowActive)}
            </MenuItem>
            <MenuItem value={ActivityFilterOption.Draft}>
              {getFigmaText(Texts.adminGeneralEnumsFilterFilterDraft)}
            </MenuItem>
            <MenuItem value={ActivityFilterOption.Archived}>
              {getFigmaText(Texts.adminGeneralEnumsFilterFilterArchived)}
            </MenuItem>
            <MenuItem value={ActivityFilterOption.All}>{getFigmaText(Texts.adminGeneralEnumsFilterFilterAll)}</MenuItem>
          </CustomTextField>
          <CustomTextField
            select
            label={getFigmaText(Texts.adminGeneralInputFieldsGroupByLabel)}
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value as GroupByOption)}
          >
            <MenuItem value={GroupByOption.Style}>
              {getFigmaText(Texts.adminGeneralEnumsGroupByGroupByDanceType)}
            </MenuItem>
            <MenuItem value={GroupByOption.Type}>
              {getFigmaText(Texts.adminGeneralEnumsGroupByGroupByActivityType)}
            </MenuItem>
            <MenuItem value={GroupByOption.City}>{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByCity)}</MenuItem>
            <MenuItem value={GroupByOption.StartDate}>
              {getFigmaText(Texts.adminGeneralEnumsGroupByGroupByMonth)}
            </MenuItem>
            <MenuItem value={GroupByOption.Status}>
              {getFigmaText(Texts.adminGeneralEnumsGroupByGroupByStatus)}
            </MenuItem>
          </CustomTextField>

          <StyledButton onClick={() => createNewActivity(clientData, navigate)}>
            {getFigmaText(Texts.adminGeneralCtactaCreateNewActivity)}
          </StyledButton>
        </Box>
      </Box>

      {recentActivities.length > 0 && (
        <LatestEditedSection fullWidth bottom>
          <div className="activity-section recent-activities">
            <h2>{getFigmaText(Texts.adminGeneralDashboardGroupHeaderLatestEdited)}</h2>
            {recentActivities.map((activity) => renderActivityRow(activity))}
          </div>
        </LatestEditedSection>
      )}
      <ActivityListEmptyState loading={loading} hasResults={hasResults} />
      {groupActivityKeys.length > 0
        ? groupActivityKeys.map((key) => (
            <div key={key}>
              <h2>{key}</h2>
              {filteredActivities
                .filter((activity) => {
                  if (groupBy === GroupByOption.StartDate) {
                    const activityMonth = activity.startDate ? formatDateSwedish(activity.startDate) : '-'
                    return activityMonth === key
                  }
                  if (groupBy === GroupByOption.Type) {
                    return getLocalizedActivityType(activity.type) === key
                  }
                  if (groupBy === GroupByOption.Status) {
                    return getStatusTextKey(activity.status) === key
                  }
                  return activity[groupBy] === key
                })
                .map(renderActivityRow)}
            </div>
          ))
        : null}
      {filteredEnrollments.length > 0 ? (
        <ActivityListFilteredEnrollments
          filteredEnrollments={filteredEnrollments}
          highlightText={highlightText}
          filterText={filterText}
          handleRegistrationClick={handleRegistrationClick}
          navigate={navigate}
        />
      ) : null}

      {/* Activity Detail Modal */}
      <ActivityDetailModal
        activity={detailActivity}
        anchorEl={infoAnchorEl}
        onClose={handleInfoClose}
        clientId={clientData?.id || ''}
      />
    </div>
  )
}

const ActionNotifications = styled(Box)`
  background: #b46000;
  border: 1px solid #653806;
  border-radius: 4px;
  padding: 4px 8px;
`

const LatestEditedSection = styled(Box)`
  border: 3px dashed rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  padding: 16px;
`

const WhiteIcon = styled(MoreVertIcon)`
  color: white;
`

const EditIcon = styled(Edit)`
  color: rgba(255, 255, 255, 0.4);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.2s ease;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`

const EditIconWrapper = styled(Box)`
  position: relative;
  display: none; // Hide by default
  align-items: center;

  @media (max-width: 768px) {
    display: inline-flex; // Only show on mobile
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`

const EditTooltip = styled.span`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
`

const StyledButton = styled(ButtonBlack)`
  background: #4300bf;
  border: 1px solid #fff;
  padding: 8px 16px;
  width: 100%;
  min-width: 150px;
`

const ActivityEnrollmentStats = styled(Box)``

const EnrollmentTypeCount = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`

const activityThemes: {
  [key: string]: { background: string; hoverBackground: string; border: string; hoverBorder: string }
} = {
  Course: {
    background: 'linear-gradient(to right, #2b1055, #7596de1c)',
    hoverBackground: 'linear-gradient(to right, #3c1577, #2b4cad)',
    border: '#7617d0',
    hoverBorder: '#ae7df7'
  },
  Occasion: {
    background: 'linear-gradient(to right, rgba(7, 103, 18, 0.54), rgba(9, 111, 21, 0.35))',
    hoverBackground: 'linear-gradient(to right, rgba(7, 103, 18, 0.60), rgba(9, 111, 21, 0.60))',
    border: '#176d17',
    hoverBorder: '#7df77d'
  },
  default: {
    background: 'linear-gradient(to right, #2b1055, #7596de1c)',
    hoverBackground: 'linear-gradient(to right, #3c1577, #2b4cad)',
    border: '#7617d0',
    hoverBorder: '#ae7df7'
  }
}

const CustomTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  min-width: 150px;
  margin: 12px 8px;

  @media (min-width: 769px) {
    max-width: 200px;
  }

  & .MuiFormHelperText-root {
    padding-left: 20px;
  }
`

const ActivityRow = styled(Box)<{ type?: string }>`
  align-items: left;
  background: ${({ type }) =>
    type && activityThemes[type] ? activityThemes[type].background : activityThemes.default.background};
  border: 1px solid
    ${({ type }) => (type && activityThemes[type] ? activityThemes[type].border : activityThemes.default.border)};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  padding: 12px;
  transition: all 0.3s ease;
  position: relative; // Add this
  padding: 12px; // Remove the left padding we added before

  &:hover {
    background: ${({ type }) =>
      type && activityThemes[type] ? activityThemes[type].hoverBackground : activityThemes.default.hoverBackground};
    border: 1px solid
      ${({ type }) =>
        type && activityThemes[type] ? activityThemes[type].hoverBorder : activityThemes.default.hoverBorder};

    .quick-actions {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
`

const QuickActions = styled(Box)`
  position: absolute;
  left: 0px;
  padding: 16px 16px;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row; // Explicitly set horizontal direction
  align-items: center;
  justify-content: center;
  gap: 24px; // Increased gap between buttons
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  z-index: 2;
`

const QuickActionButton = styled(IconButton)`
  background: rgba(255, 255, 255, 0.15) !important;
  color: white !important;
  width: 44px;
  height: 44px;
  transition: all 0.2s ease;
  border: 2px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background: rgba(255, 255, 255, 0.25) !important;
    transform: scale(1.1);
    border-color: rgba(255, 255, 255, 0.4);
  }
`

const EnrollmentCount = styled(Box)`
  border-radius: 50%;
  border: 1px solid white;
  color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0 4px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
  cursor: pointer;

  @media (max-width: 768px) {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.4);
    }
  }
`

const OpenEnrollmentCTA = styled(Box)`
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-radius: 4px;
    color: rgb(255, 170, 0);

    ${EnrollmentCount} {
      border: 2px solid rgb(199, 139, 20);
    }
  }
`

const ActivityMetadataContainer = styled(Box)`
  border-radius: 4px;
  font-size: 14px;
`

const ActivityMetadata = styled(Box)`
  align-items: center;
`

const ActivityMetadataHideOnMobile = styled.p`
  margin: 0px;
  margin: 0px 8px;
  @media (max-width: 768px) {
    display: none;
  }
`

const InfoIcon = styled(Info)`
  transition: color 0.2s ease;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`

const InfoIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
`

export default ActivityList
