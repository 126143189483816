import { getAuth } from 'firebase/auth'
import Cookies from 'js-cookie'
import { PreferredLoginMethod, SignInState, SignInSteps } from '../../../pure-js/types/SignInTypes'
import { State } from '../hooks/useAppState'

export type SignInPageProps = {
  onLogin?: (state: State) => unknown
  signInState?: Partial<SignInState>
}

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    email: ''
  },
  preferredLoginMethod: PreferredLoginMethod.MAIL,
  step: SignInSteps.LANDING
}

export const hasAccess = (state: State) => {
  if (!state) return false
  const clientIds = state.user?.authCustomClaims?.clientIds ?? []
  return clientIds.length > 0 && clientIds.every((id) => typeof id === 'string' && id.trim() !== '')
}

export const setAuthCookie = (accessToken: string, expirationDays: number) => {
  Cookies.set('access_token', accessToken, {
    secure: true, // Ensures the cookie is only sent over HTTPS
    httpOnly: false, // Cookies accessible by JavaScript (set to true if server-side only)
    sameSite: 'Strict', // Prevents cross-site access
    expires: expirationDays // Days
  })
}

export const setupTokenRefresher = () => {
  const auth = getAuth()

  // Listen for token changes
  return auth.onIdTokenChanged(async (user) => {
    if (user) {
      // Get fresh token
      const token = await user.getIdToken()
      // Update cookie
      setAuthCookie(token, 30)
    }
  })
}
