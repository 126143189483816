import { DanceRoles } from './DanceRoles'
import { Discount, DiscountUserInputV1, DiscountUserInputV2 } from './DiscountTypes'
import { StripeEnvironment } from './StripeTypes'
import { SwishConfigEnvironment } from './SwishTypes'
import { BaseObject } from './types'

// Add import for DanceRoles

// Define VAT rates
export type VATRate = 0 | 6 | 12 | 25

export type InternalCustomerData = BaseObject & {
  clientId: string
  stripeCustomerId: string
}

export enum PaymentMethod {
  Stripe = 'Stripe',
  BankTransfer = 'BankTransfer',
  Swish = 'Swish',
  Cash = 'Cash',
  Klarna = 'Klarna'
}

// Convert string literal types to proper enums
export enum GroupByOption {
  Style = 'style',
  Type = 'type',
  City = 'city',
  StartDate = 'startdate',
  Status = 'status'
}

export enum ActivityStatus {
  Active = 'active',
  Draft = 'draft',
  Planned = 'planned',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Archived = 'archived',
  Deleted = 'deleted'
}

export enum ActivityEnrollmentStatus {
  Open = 'open',
  Full = 'full',
  Closed = 'closed',
  ClosedForLeaders = 'closedForLeaders',
  ClosedForFollowers = 'closedForFollowers'
}

export enum EnrollmentStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Active = 'active',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Refunded = 'refunded'
}

// Convert to enum
export enum ActivityFilterOption {
  Active = 'active',
  Draft = 'draft',
  Archived = 'archived',
  All = 'all'
}

export type PaymentMethodFee = {
  method: PaymentMethod
  fee: number
}

export type ClientSwishConfig = {
  operator: string
  environment: SwishConfigEnvironment
}

export type ClientStipeConfig = {
  operator: string
  environment: StripeEnvironment
  paymentMethods: string[]
}

export type Client = BaseObject & {
  name: string
  address: string
  contactEmail: string
  bankGiro: string
  homepage: string
  orgNo: string
  logoUrl: string // deprecated, will use logoFullUrl
  logoFullUrl: string
  locations: Location[]
  typeTranslations?: Record<string, string>
  defaultVAT: VATRate
  sendSMSonRegistration: boolean

  showSsnInRegistration?: boolean
  status: 'active' | 'inactive' | undefined
  paymentMethodFees: PaymentMethodFee[]
  discounts: Discount[]
  swishEnvironment?: SwishConfigEnvironment // deprecated, will use "swish" object
  swish?: ClientSwishConfig
  stripe?: ClientStipeConfig
  hideDiscountsInPublicPage?: boolean
  themeConfig?: {
    backgroundColor: string
    borderColor: string
    textColor: string
    primaryColor: string
    secondaryColor: string
    activityBoxBorder: string
    activityBoxBackground: string
    activityHover: string
    courseBGColor: string
    occationBGColor: string
    otherBGColor: string
  }
  openActivityInNewTab?: boolean
  showFilters?: boolean
  allowBothForCouples?: boolean
  emailAlias?: string
}

export type UpsertClientParams = Pick<Client, 'id'> & Partial<Omit<Client, 'id'>>

export type Location = BaseObject & {
  googleUrl: string
  name: string
  adress: string
  center: {
    lat: number
    lng: number
  }
}

export type ClientPrivate = {
  clientId: string
  stripe?: {
    payment_method_types: string[]
    public_key?: string
    private_key?: string
  }
}

// An activity is a course or an one-time event
export type Activity = BaseObject & {
  // MANDATORY
  clientId: string // The school that uses the system
  type: 'Course' | 'Occasion' | 'Other'
  status: ActivityStatus
  name: string
  description: string
  length: number // In minutes ex. 55 min
  price: number
  activityType: 'Couple' | 'Single'
  approvalRequired: boolean
  style: string
  discountIds: string[] // Discount ids (Discounts that apply to this activity; points to client's discounts)
  maxAllowedParticipants: number // How many activities can be made before the course is full
  period: string // ex: “Q4 2024”
  startDate: string // precise date (YYYY-MM-DD) when the course starts
  noOfOccasions: number // 10 for a course
  time: string
  vat: VATRate

  // OPTIONAL
  enrollmentStatus?: ActivityEnrollmentStatus
  occations?: OccationInstance[]
  ageGroup?: string // ex. “18+”
  level?: string
  VAT?: number
  currency?: string
  discounts?: Discount[] // DEPRECATED Discounts that apply to this activity

  place?: string // Text for now
  city?: string
  locationId?: string // Reference to Location object
  maxAllowedUnbalance?: number // How many leaders/followers can be unbalanced
  singleRegistration?: boolean // If true, only one person can register
  substyle?: string
  colorCode?: string
  reserveListActive?: boolean
  allowDropin?: boolean
  group?: string // group similar courses (ie. Salsa1 for period1 and period2); also allows
  groupingCode?: string // Reference to other activities
  priceAtDoor?: number
  bannerUrl?: string
}

export type OccationInstance = {
  id: string
  startDate: string // ex. 2024-10-01 17:00
  endDate: string // ex. 2024-12-01 18:00
  instructors?: string[] // List of teacher ids e.g userIds
  index: number // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
}

export type Checkin = BaseObject & {
  activityId: string
  enrollmentId: string
  userId: string
  occurenceNo: number
}

// used to send user data from frontend to backend
export type EnrollmentUserRequest = {
  name: string
  email: string
  phone: string
  type: DanceRoles
  ssn?: string
}

export type EnrollmentPaymentInputRequest = {
  user: 'mainUser' | 'partnerUser'
  method: PaymentMethod
}

// TODO: this is obsolete, use EnrollmentRequestV2
export type EnrollmentRequest = {
  clientId: string
  mainUser: EnrollmentUserRequest
  partnerUser?: EnrollmentUserRequest
  activityIds: string[]
  discountUserInput: DiscountUserInputV1
  paymentInput: EnrollmentPaymentInputRequest[]
}

export type EnrollmentRequestV2 = {
  clientId: string
  mainUser: EnrollmentUserRequest
  partnerUser?: EnrollmentUserRequest
  activityIds: string[]
  comment?: string
  discountUserInput: DiscountUserInputV2
  paymentInput: EnrollmentPaymentInputRequest[]
  env: string
}

export type EnrollmentRequestResponseV2 = {
  enrollmentId: string
}

export type UserEnrollmentPeriod = {
  id: string
  name: string
  period: string
  activityType: string
}

// database representation of a user in a course
export type EnrollmentUser = BaseObject &
  Omit<EnrollmentUserRequest, 'type'> & {
    stripeCustomerId?: Record<string, string>
  }

export enum EnrollmentPaymentStatus {
  Unpaid = 'unpaid',
  PartlyPaid = 'partlyPaid',
  Paid = 'paid'
}

export type Enrollment = BaseObject & {
  version?: number
  clientId: string
  mainUser: EnrollmentUserRequest & { id: string }
  partnerUser?: EnrollmentUserRequest & { id: string }
  activities: Activity[]
  activityIds?: string[] // this is populated automatically from activities in enrollmentUpsert(); makes db queries faster
  status: EnrollmentStatus
  ocr: string
  discountUserInput: DiscountUserInputV1 | DiscountUserInputV2
  payments: Payment[] // payments are future operations when doing payments or marking as paid
  paymentStatus: EnrollmentPaymentStatus
  priceDetails: PriceDetails[] // Breakdown of the price
  checkins?: Checkin[]
  comment?: string
}

export type EnrollmentPaymentDetails = {
  source: 'stripe' | 'swish' | 'manual'
  paymentDate: string
  paymentMethod: string
  paymentRef: string
  paymentOperator: string
  paymentEnvironment?: string
  paymentInfo?: string
}

export type EnrollmentWithPayment = Enrollment & {
  paymentDetails: EnrollmentPaymentDetails
}

// keep only data safe to publish in public
export type EnrollmentPublic = Pick<Enrollment, 'id' | 'createdAt' | 'paymentStatus' | 'status' | 'ocr'>

// if refund, value is negative
export type Payment = {
  currency: string
  totalAmount: number // Including VAT
  paymentDate?: string // TODO remove, use stripe payment object instead
  paymentRef?: string // ex. Stripe payment id
  paymentMethod: string // PaymentMethod
  paymentInfo?: string // ex. Stripe card last4 digits
  source: 'stripe' | 'swish' | 'manual'
  type: 'pay' | 'refund'
  paymentOperator?: string
  paymentEnvironment?: string
}

export type PriceDetails = {
  activityId: string
  originalPrice: number // price before discount; course price
  totalDiscountPrice: number // discount price from all discounts
  finalPrice: number // originalPrice - totalDiscountPrice
  paymentMethod: PaymentMethod
  administrationFee: number // derives from the payment method selected
  discounts?: Discount[] // Discounts used
  discountIds?: string[] // id of discounts used
  appliesTo: string // which user the price applies to (mainUser or partnerUser)
}

export type EmailTemplate = BaseObject & {
  clientId: string
  name: string
  subject: string
  body: string
  replyToEmail: string
  replyToName: string
}

export type VatData = Record<number, number>

export type UrlShortHash = {
  url: string
  createdAt: string
}
