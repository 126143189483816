import { useEffect, useState } from 'react'
import { Client } from '../../../pure-js/types/GrooverTypes'
import { getClient } from '../libs/DBApiHandler'

export const useClient = (clientId: string | undefined) => {
  const [client, setClient] = useState<Client | undefined>()

  useEffect(() => {
    const fetchClient = async () => {
      if (clientId) {
        const clientData = await getClient(clientId)
        if (clientData) setClient(clientData)
      }
    }
    fetchClient()
  }, [clientId])

  return client
}
