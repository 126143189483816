import styled from '@emotion/styled'
import { ArrowBack, CalendarMonth, KeyboardArrowDown, KeyboardArrowRight, Place, QrCode2 } from '@mui/icons-material'
import {
  Checkbox,
  Collapse,
  collapseClasses,
  Modal,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { White } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, Enrollment, EnrollmentUserRequest } from '../../../pure-js/types/GrooverTypes'
import Box from '../components/Box'
import EnrollmentType from '../components/EnrollmentType'
import FigmaText from '../components/FigmaText'
import PaymentStatus from '../components/PaymentStatus'
import QRButton from '../components/QRButton'
import QRCodeElement from '../components/QRCodeElement'
import useAppState from '../hooks/useAppState'
import { getEnrollmentData, storeEnrollmentActivityCheckin } from '../libs/CloudFunctionsApiHandler'
import { getFigmaText } from '../libs/TextRepository'
import { PageContainer } from '../styles/AdminPageStyles'

type CheckinsState = Record<string, Array<{ userId: string; occurenceNo: number }>>

const EnrollmentCheckinPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [enrollment, setEnrollment] = useState<Enrollment | null>(null)
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null)
  const [checkinsState, setCheckinsState] = useState<CheckinsState>({})
  const [showQRCode, setShowQRCode] = useState<string | null>(null)
  const [occasionRowSelected, setOccasionRowSelected] = useState<string | null>(null)

  const { enrollmentId, activityId } = useParams<{ enrollmentId: string; activityId?: string }>()
  const [searchParams] = useSearchParams()
  const source = searchParams.get('source')
  const navigate = useNavigate()

  const state = useAppState()

  const fetchEnrollment = async () => {
    if (!enrollmentId) return

    setIsLoading(true)
    try {
      // get enrollment data by id (function)
      const req = { enrollmentId: enrollmentId }
      const enrollment = await getEnrollmentData(req, state.state)
      if (enrollment) {
        // check enrollment client id against logged user permissions
        if (state.state.guardAppForClient(enrollment.clientId, state.state)) {
          prepareCheckinState(enrollment)
          setEnrollment(enrollment)

          if (!activityId && enrollment.activities.length == 1) {
            handleActivityClick(enrollment.activities[0])
          }
        } else {
          toast.error('No permission to view')
          navigate(RoutePath.DASHBOARD)
        }
      }
    } catch (_) {
      setError('Error fetching enrollment')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchEnrollment()
  }, [enrollmentId])

  useEffect(() => {
    if (!enrollment) return

    if (activityId) {
      const activity = enrollment?.activities.find((activity) => activity.id === activityId)
      activity && handleActivityClick(activity)
    }

    // auto checkin if coming from QR scanner page
    if (source == 'qr') autoCheckin()
  }, [enrollment, activityId])

  const dateFormat = 'YYYY-MM-DD'
  const activityOccasionDay = (activity: Activity, occasionNo: number) =>
    dayjs(activity.startDate)
      .add(7 * occasionNo, 'day')
      .format(dateFormat)
  const occassionRowKey = (activityId: string, occasionNo: number) => `${activityId}-${occasionNo}`

  const prepareCheckinState = (enrollment: Enrollment) => {
    if (!enrollment) return

    // flatten enrollment.activities[].checkins to local state
    enrollment.activities.forEach((activity) => {
      const activityCheckins = enrollment.checkins?.filter((checkin) => checkin.activityId === activity.id)
      const mainUserId = enrollment.mainUser.id
      const partnerUserId = enrollment.partnerUser?.id

      let checkins: Array<{ userId: string; occurenceNo: number }> = []

      for (let i = 0; i < activity.noOfOccasions; i++) {
        if (activityCheckins?.some((checkin) => checkin.userId === mainUserId && checkin.occurenceNo === i)) {
          checkins.push({ userId: mainUserId, occurenceNo: i })
        }

        if (
          partnerUserId &&
          activityCheckins?.some((checkin) => checkin.userId === partnerUserId && checkin.occurenceNo === i)
        ) {
          checkins.push({ userId: partnerUserId, occurenceNo: i })
        }
      }

      checkinsState[activity.id] = checkins
    })

    setCheckinsState({ ...checkinsState })
  }

  const handleReturn = () => {
    if (enrollment) {
      navigate(`/enrollments/${enrollment.clientId}/${activityId}`)
    } else {
      navigate(-1)
    }
  }

  const handleActivityClick = (activity: Activity) => {
    if (selectedActivity === activity) {
      setSelectedActivity(null)
      return
    }
    setSelectedActivity(activity)
  }

  const handleCheckin = async (activityId: string, occurenceNo: number, userId: string, currState: boolean) => {
    if (!enrollment) return
    setIsSaving(true)

    try {
      const req = {
        enrollmentId: enrollment.id,
        activityId: activityId,
        occurenceNo: occurenceNo,
        userId: userId,
        newState: currState
      }
      updateCheckinState(activityId, occurenceNo, userId, currState)
      await storeEnrollmentActivityCheckin(req, state.state)
      toast.success('Checkin saved')
    } catch (_) {
      toast.error('Error saving checkin')
    }

    setIsSaving(false)
  }

  const updateCheckinState = (activityId: string, occurenceNo: number, userId: string, newState: boolean) => {
    let activityCheckins = checkinsState[activityId]
    if (newState) {
      const activity = enrollment?.activities.find((a) => a.id === activityId)
      if (!activity) return

      if (!activityCheckins.some((checkin) => checkin.userId === userId && checkin.occurenceNo === occurenceNo)) {
        activityCheckins.push({ userId, occurenceNo })
      }
    } else {
      activityCheckins = activityCheckins.filter(
        (checkin) => checkin.userId !== userId || checkin.occurenceNo !== occurenceNo
      )
    }
    checkinsState[activityId] = activityCheckins
    setCheckinsState({ ...checkinsState })
  }

  const autoCheckin = () => {
    if (!enrollment) return

    // only for 1 activity and main user only
    if (enrollment.activities.length !== 1 || enrollment.partnerUser) return

    // check if any activity occasion is today
    const activity = enrollment.activities[0]
    const today = dayjs().format(dateFormat)
    const todayOccassionNo = Array.from({ length: activity.noOfOccasions }, (_, i) => i).reduce((x, i) => {
      return activityOccasionDay(activity, i) === today ? i : x
    }, -1)

    if (todayOccassionNo >= 0) {
      console.log('Auto checkin for today')
      setOccasionRowSelected(occassionRowKey(activity.id, todayOccassionNo))
      handleCheckin(activity.id, todayOccassionNo, enrollment.mainUser.id, true)
    }
  }

  const QRCodeModal = () => {
    if (!showQRCode) return null

    return (
      <Modal open={!!showQRCode} onClose={() => setShowQRCode(null)}>
        <ModalContainer>
          <CloseButton onClick={() => setShowQRCode(null)}>×</CloseButton>
          <QRCodeContent>
            <QRCodeElement value={showQRCode} />
            <QRText>{getFigmaText(Texts.adminGeneralCheckinPageQrScanInfoToRegister)}</QRText>
          </QRCodeContent>
        </ModalContainer>
      </Modal>
    )
  }

  if (isLoading) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          Loading...
        </Box>
      </MainContainer>
    )
  }
  if (!enrollment) {
    console.log('No enrollment found')
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {getFigmaText(Texts.adminGeneralErrorNoActivityFound)}
        </Box>
      </MainContainer>
    )
  }
  if (error) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {error}
        </Box>
      </MainContainer>
    )
  }

  const renderUserInfo = (enrollmentUser: EnrollmentUserRequest) => {
    return (
      <Box direction="row" fullWidth justify="space-between" align="center">
        <EnrollerTitle> {enrollmentUser.name} </EnrollerTitle>
        <EnrollmentType partnerType={enrollmentUser.type} />
      </Box>
    )
  }

  const renderActivityInfo = (enrollment: Enrollment, index: number, checkins: CheckinsState) => {
    const activity = enrollment.activities[index]
    const activityOccasions: Array<string> = []
    const activityOcassionCheckins: { mainUser: boolean[]; partnerUser: boolean[] } = { mainUser: [], partnerUser: [] }
    const startDate = activity.startDate
    const RowIcon = selectedActivity === activity ? KeyboardArrowDown : KeyboardArrowRight
    const mainUserId = enrollment.mainUser.id
    const partnerUserId = enrollment.partnerUser?.id

    for (let i = 0; i < activity.noOfOccasions; i++) {
      activityOccasions.push(
        dayjs(startDate)
          .add(7 * i, 'day')
          .format('YYYY-MM-DD')
      )

      // checkin status for main user
      const mainUserCheckin =
        checkins[activity.id].some((checkin) => checkin.userId === mainUserId && checkin.occurenceNo === i) || false
      activityOcassionCheckins.mainUser.push(mainUserCheckin)

      // checkin status for partner user
      if (partnerUserId) {
        const partnerUserCheckin =
          checkins[activity.id].some((checkin) => checkin.userId === partnerUserId && checkin.occurenceNo === i) ||
          false
        activityOcassionCheckins.partnerUser.push(partnerUserCheckin)
      }
    }

    return (
      <ActivityRowWrapper key={activity.id} fullWidth>
        <ActivityRow
          fullWidth
          align="flex-start"
          key={enrollment.activities[index].id}
          direction="row"
          onClick={() => handleActivityClick(activity)}
        >
          <Box direction="column" width="30px">
            <RowIcon style={{ paddingTop: '10px', height: 'fit-content' }} />
          </Box>
          <Box direction="row" justify="flex-start" align="center" fullWidth>
            <Box direction="column" fullWidth>
              <Box direction="row" align="flex-start" justify="space-between">
                <ActivityName>{activity.name}</ActivityName>
              </Box>
              <Box direction="row" align="flex-start" fullWidth justify="space-between">
                <ActivityMetadataContainer direction="row" align="center" gap="4px">
                  {[
                    { icon: Place, value: activity.city },
                    { icon: CalendarMonth, value: dayjs(activity.startDate).format('YYYY-MM-DD') }
                  ].map((item, index) => {
                    const IconComponent = item.icon
                    return (
                      <ActivityDetailSmall key={index}>
                        <IconComponent />
                        <span>{item.value}</span>
                      </ActivityDetailSmall>
                    )
                  })}
                </ActivityMetadataContainer>
              </Box>
            </Box>
            <QrCode2
              sx={{ fontSize: 40, cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                setShowQRCode(`https://app.coursely.se/register/${activity.id}`)
              }}
            />
          </Box>
        </ActivityRow>
        <Collapse in={selectedActivity === activity}>
          <ActivityOcassion fullWidth align="flex-start" gap="8px">
            <TableClean>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>{enrollment.mainUser.name}</TableCell>
                  {enrollment.partnerUser && <TableCell>{enrollment.partnerUser.name}</TableCell>}
                </TableRow>
                {activityOccasions.map((date, index) => (
                  <OccasionTableRow
                    key={index}
                    className={occasionRowSelected == occassionRowKey(activity.id, index) ? 'flash' : ''}
                  >
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={activityOcassionCheckins.mainUser[index]}
                        onChange={(e) => handleCheckin(activity.id, index, enrollment.mainUser.id, e.target.checked)}
                      />
                    </TableCell>
                    {enrollment.partnerUser && (
                      <TableCell>
                        <Checkbox
                          checked={activityOcassionCheckins.partnerUser[index]}
                          onChange={(e) =>
                            enrollment.partnerUser &&
                            handleCheckin(activity.id, index, enrollment.partnerUser.id, e.target.checked)
                          }
                        />
                      </TableCell>
                    )}
                  </OccasionTableRow>
                ))}
              </TableBody>
            </TableClean>
          </ActivityOcassion>
        </Collapse>
      </ActivityRowWrapper>
    )
  }

  return (
    <MainContainer fullWidth fullHeight>
      <QRCodeModal />
      <PageContainer fullWidth>
        <div className="dashboard-container">
          <Box direction="row" gap="16px" fullWidth>
            {activityId && <ArrowBack onClick={handleReturn} style={{ cursor: 'pointer' }} />}
            <Box direction="row" align="center">
              <ActivityText>{getFigmaText(Texts.adminGeneralCheckinPagePageHeader)}</ActivityText>
            </Box>
          </Box>
          {/* -- Payment details */}
          <PaymentInfoContainer fullWidth fullPadding spacing="16px">
            <PaymentStatus enrollment={enrollment} activity={enrollment.activities[0]} />
          </PaymentInfoContainer>
          {/* -- Users -- */}
          <Box direction="column" align="flex-start" gap="16px" fullWidth>
            {renderUserInfo(enrollment.mainUser)}
            {enrollment.partnerUser && renderUserInfo(enrollment.partnerUser)}
          </Box>
          {/*- Activities */}
          <Box direction="row" fullWidth>
            <FigmaText
              textKey={Texts.adminGeneralCheckinPageCheckinsHeader}
              text={getFigmaText(Texts.adminGeneralCheckinPageCheckinsHeader)}
            />
          </Box>
          {enrollment.activities.map((activity, index) => renderActivityInfo(enrollment, index, checkinsState))}
        </div>
      </PageContainer>
      <QRButton />
    </MainContainer>
  )
}

const ActivityText = styled.h4`
  margin-right: 16px;
`

const ActivityRowWrapper = styled(Box)`
  & .${collapseClasses.root}: {
    min-height: unset !important;
  }
`

const ActivityRow = styled(Box)`
  margin: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background-color: rgba(51, 58, 156, 0.4);
  padding: 8px 16px 8px 8px;
  cursor: pointer;
`

const ActivityName = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 4px;
`

const ActivityDetailSmall = styled(Box)`
  flex-direction: row;
  font-size: 0.8rem;
  margin: 0;
  padding: 0 4px 0 0;
  & svg {
    color: rgba(255, 255, 255, 0.4);
    padding-right: 4px;
  }
  & span {
    padding-top: 3px;
  }
`

const ActivityOcassion = styled(Box)`
  font-size: 1rem;
`

const ActivityMetadataContainer = styled(Box)`
  border-radius: 4px;
  font-size: 14px;
`

const EnrollerTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  padding: 0px 8px;
  @media (min-width: 768px) {
    min-width: min(20vw, 200px);
    max-width: min(20vw, 200px);
  }
`

const PaymentInfoContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
`

const MainContainer = styled(Box)`
  padding: 60px;
  background: radial-gradient(circle at 20% 30%, #2e004d, transparent 90%),
    radial-gradient(circle at 80% 20%, #0f4873, transparent 92%),
    radial-gradient(circle at 80% 80%, rgb(8, 53, 87), transparent 96%);
  background-color: #000000; /* Black fallback for vibrancy */
  color: #ffffff;
  overflow: hidden;
  animation: gradient-animation 5s ease infinite;

  @media (max-width: 600px) {
    padding: 4px;
  }
`

const TableClean = styled(Table)`
  & .${tableCellClasses.root} {
    border-bottom: none;
    padding: 2px;
    color: #ffffff;
  }
`

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
`

const QRCodeContent = styled.div`
  text-align: center;
  padding: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
`

const QRText = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 1.2rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`

// flash background x3
const OccasionTableRow = styled(TableRow)`
  &.flash {
    background-color: rgba(255, 255, 255, 0.1);
    animation: flash 0.5s ease-in-out;
    animation-iteration-count: 5;
    @keyframes flash {
      0% {
        background-color: rgba(255, 255, 255, 0.2); // initial state
      }
      50% {
        background-color: rgba(255, 255, 255, 0.6); // halfway point
      }
      100% {
        background-color: rgba(255, 255, 255, 0.2); // final state
      }
    }
  }
`

export default EnrollmentCheckinPage
