import { collection, query, orderBy, where } from 'firebase/firestore'
import { db } from '../libs/Firebase.js'
import { useFirestoreQuery } from './useFirestoreQuery.js'
import { Collections, Subcollections } from '../../../pure-js/libs/Collections.js'
import { CommunicationLog } from '../../../pure-js/types/CommunicationLogTypes.js'
import { Enrollment } from '../../../pure-js/types/GrooverTypes.js'

export const useCommunicationLogForEnrollment = (enrollment: Enrollment | null) => {
  return useFirestoreQuery<CommunicationLog[]>(
    query(
      collection(db, `${Collections.CLIENTS}/${enrollment?.clientId}/${Subcollections.COMMUNICATION_LOG}`),
      where('enrollmentId', '==', enrollment?.id),
      orderBy('createdAt', 'desc')
    ),
    {
      fnName: 'useCommunicationLogForEnrollmentAndEnrollentUser',
      enabled: !!enrollment
    }
  )
}
