import React, { useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import FeatureFlagsAdminPage from '../../../user-web/src/pages/FeatureFlagsAdminPage'
import useAppState from '../hooks/useAppState'
import { useDeepLink } from '../hooks/useDeepLink'
import QRCodeScannerPage from '../pages/QRCodeScannerPage'
import { RootPage } from '../pages/SignInPage'

const NavigationPublicRoutes: React.FC = () => {
  const { state } = useAppState()
  const location = useLocation()
  const { getDeepLink, saveDeepLink } = useDeepLink()

  const path = location.pathname
  const search = location.search

  const FeatureFlagsPage = () => {
    return <FeatureFlagsAdminPage storageKey={'featureFlagsAdmin'} />
  }

  if (!getDeepLink() && !state?.isLoggedIn && path && path.split('/').length > 2) {
    saveDeepLink(path, search)
  }

  return (
    <>
      <Routes>
        <Route path={RoutePath.ROOT} Component={RootPage} />
        <Route path={RoutePath.BAR_CODE_SCANNER} Component={QRCodeScannerPage} />
        <Route path={RoutePath.FEATURES} Component={FeatureFlagsPage} />
        <Route path="*" element={<Navigate to={RoutePath.ROOT} />} />
      </Routes>
    </>
  )
}

export default NavigationPublicRoutes
