import React, { useEffect, useState } from 'react'
import Box from '../components/Box'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from '../components/FigmaText'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import { Button } from '@mui/material'
import { clearAndNavigateOut } from '../utils/loginHelper'
import { useLogout } from '../hooks/useLogout'
import { useDeepLink } from '../hooks/useDeepLink'
import { useNavigate } from '../hooks/useNavigate'
import useCursorLoading from '../hooks/useLoadingCursor'

const NoAccessPage: React.FC = () => {
  // Move hooks inside component
  const { clearDeepLink } = useDeepLink()
  const navigate = useNavigate()
  const logout = useLogout()
  const [isLoading, setIsloading] = useState(true)

  const onLogOut = () => {
    clearAndNavigateOut(clearDeepLink, navigate)
    logout()
  }

  useCursorLoading(isLoading)

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false)
    }, 3000)
  }, [isLoading])

  if (isLoading) {
    return (
      <Box fullWidth align="center" fullHeight style={{ paddingTop: '50px' }}>
        <div className="spinner"></div>
      </Box>
    )
  }
  return (
    <Box
      id="NoAccessView"
      fullWidth
      align="center"
      direction="column"
      gap="24px"
      justify="center"
      style={{ minHeight: '80vh' }}
    >
      <FigmaText textKey={Texts.adminGeneralNoAccessHeader} />
      <FigmaText textKey={Texts.adminGeneralNoAccessText} />
      <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
      <Button
        onClick={onLogOut}
        variant="contained"
        style={{
          backgroundColor: '#D32F2F',
          color: 'white',
          marginTop: '16px'
        }}
      >
        <FigmaText textKey={Texts.componentsUserMenuUserMenuLogout} />
      </Button>
    </Box>
  )
}

export default NoAccessPage
