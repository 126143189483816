import { Divider, Drawer, IconButton, Box } from '@mui/material'
import { DRAWER_WIDTH, DrawerHeader, MenuItemType } from '../utils/sidebarHelper'
import { SidebarMobileMenuList } from './SidebarMobileMenuList'
import { ChevronLeft } from '@mui/icons-material'

// Helper: Mobile Sidebar Component
export const SidebarMobile: React.FC<{
  isOpen: boolean
  menuItems: MenuItemType[]
  currentPath: string
  onClose: () => void
}> = ({ isOpen, menuItems, currentPath, onClose }) => (
  <Drawer
    variant="temporary"
    open={isOpen}
    onClose={onClose}
    ModalProps={{
      keepMounted: true // Better mobile performance
    }}
    sx={{
      display: { xs: 'block', md: 'none' },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: DRAWER_WIDTH,
        backgroundColor: 'rgb(68, 1, 75)',
        overflow: 'visible' // Allow dropdown to overflow outside the drawer
      }
    }}
  >
    <DrawerHeader>
      <IconButton onClick={onClose} sx={{ color: '#fff' }}>
        <ChevronLeft />
      </IconButton>
    </DrawerHeader>
    <Divider sx={{ backgroundColor: '#12172b' }} />
    <Box sx={{ overflow: 'auto', maxWidth: '100%' }}>
      <SidebarMobileMenuList menuItems={menuItems} currentPath={currentPath} onItemClick={onClose} />
    </Box>
  </Drawer>
)
