import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

interface ParticipantData {
  name: string
  value: number
  color: string
}

interface CompactParticipantLineChartProps {
  data: ParticipantData[]
  totalParticipants: number
  maxParticipants?: number
}

const CompactParticipantLineChart: React.FC<CompactParticipantLineChartProps> = ({
  data,
  totalParticipants,
  maxParticipants
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const chartRef = useRef<HTMLDivElement>(null)

  // Filter out any data items with zero value to avoid rendering issues
  const validData = data.filter((item) => item.value > 0)

  // Add free slots if maxParticipants is provided
  let chartData = [...validData]
  if (maxParticipants && maxParticipants > totalParticipants) {
    const freeSlots = maxParticipants - totalParticipants
    chartData.push({
      name: 'Free Slots',
      value: freeSlots,
      color: 'rgba(100, 100, 100, 0.5)' // Darker gray with more opacity for better contrast
    })
  }

  // If no valid data, show just the total
  if (validData.length === 0) {
    return (
      <Box>
        <CompactTotalValue>
          {maxParticipants ? `${totalParticipants}/${maxParticipants}` : totalParticipants}
        </CompactTotalValue>
      </Box>
    )
  }

  // Calculate the width percentage for each segment
  const total = chartData.reduce((sum, item) => sum + item.value, 0)

  // Function to generate unique gradient IDs
  const getGradientId = (index: number): string => {
    return `gradient-${index}-${Math.random().toString(36).substring(2, 9)}`
  }

  // Function to calculate and update tooltip position
  const updateTooltipPosition = () => {
    if (chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect()

      setTooltipPosition({
        // Use viewport coordinates for fixed positioning
        top: rect.bottom,
        left: rect.left
      })
    }
  }

  // Update tooltip position when showing or on scroll
  useEffect(() => {
    if (showTooltip) {
      // Set initial position
      updateTooltipPosition()

      // Update position on scroll and resize
      window.addEventListener('scroll', updateTooltipPosition, true)
      window.addEventListener('resize', updateTooltipPosition)

      return () => {
        // Clean up listeners
        window.removeEventListener('scroll', updateTooltipPosition, true)
        window.removeEventListener('resize', updateTooltipPosition)
      }
    }
  }, [showTooltip])

  return (
    <ChartContainer
      ref={chartRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={() => setShowTooltip(true)}
      onTouchEnd={() => setShowTooltip(false)}
    >
      <Box direction="row" align="center" gap="10px" fullWidth>
        <CompactBarChartContainer>
          <BarSegmentsContainer>
            <SVGGradientDefs>
              {chartData.map((entry, index) => {
                const gradientId = getGradientId(index)
                const color = entry.color
                const lighterColor = entry.name === 'Free Slots' ? 'rgba(130, 130, 130, 0.5)' : lightenColor(color, 0.2)

                return (
                  <linearGradient key={`gradient-${index}`} id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor={lighterColor} />
                    <stop offset="100%" stopColor={color} />
                  </linearGradient>
                )
              })}
            </SVGGradientDefs>

            {chartData.map((entry, index) => {
              const gradientId = getGradientId(index)
              return (
                <BarSegment
                  key={`segment-${index}`}
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grad' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='${encodeURIComponent(lightenColor(entry.color, 0.2))}' /%3E%3Cstop offset='100%25' stop-color='${encodeURIComponent(entry.color)}' /%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23grad)' /%3E%3C/svg%3E")`,
                    width: `${(entry.value / total) * 100}%`
                  }}
                />
              )
            })}
          </BarSegmentsContainer>
        </CompactBarChartContainer>
        <CompactTotalValue>
          {maxParticipants ? `${totalParticipants}/${maxParticipants}` : totalParticipants}
        </CompactTotalValue>
      </Box>

      {showTooltip && (
        <TooltipContainer
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`
          }}
          onClick={(e) => e.stopPropagation()} // Prevent clicks on tooltip from triggering parent's click
        >
          <LegendContainer direction="row" gap="10px">
            {validData.map((entry, index) => (
              <LegendItem key={`legend-${index}`}>
                <ColoredNumberBadge style={{ backgroundColor: entry.color }}>{entry.value}</ColoredNumberBadge>
                <LegendText>{entry.name}</LegendText>
              </LegendItem>
            ))}
            {maxParticipants && maxParticipants > totalParticipants && (
              <LegendItem>
                <ColoredNumberBadge style={{ backgroundColor: 'rgba(100, 100, 100, 0.5)' }}>
                  {maxParticipants - totalParticipants}
                </ColoredNumberBadge>
                <LegendText>{getFigmaText(Texts.adminGeneralStateAndTypesTypeFreeSlots)}</LegendText>
              </LegendItem>
            )}
          </LegendContainer>
        </TooltipContainer>
      )}
    </ChartContainer>
  )
}

// Helper function to lighten colors for gradients
const lightenColor = (color: string, amount: number): string => {
  // Handle rgba format
  if (color.startsWith('rgba')) {
    const rgbaMatch = color.match(/rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([0-9.]+)\s*\)/)
    if (rgbaMatch) {
      const r = Math.min(255, parseInt(rgbaMatch[1]) + amount * 255)
      const g = Math.min(255, parseInt(rgbaMatch[2]) + amount * 255)
      const b = Math.min(255, parseInt(rgbaMatch[3]) + amount * 255)
      const a = parseFloat(rgbaMatch[4])
      return `rgba(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}, ${a})`
    }
  }

  // Handle hex format
  if (color.startsWith('#')) {
    let r = parseInt(color.substr(1, 2), 16)
    let g = parseInt(color.substr(3, 2), 16)
    let b = parseInt(color.substr(5, 2), 16)

    r = Math.min(255, Math.round(r + amount * 255))
    g = Math.min(255, Math.round(g + amount * 255))
    b = Math.min(255, Math.round(b + amount * 255))

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
  }

  // Default case
  return color
}

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`

const CompactBarChartContainer = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BarSegmentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 18px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`

const SVGGradientDefs = styled.svg`
  width: 0;
  height: 0;
  position: absolute;
`

const BarSegment = styled.div`
  height: 100%;
  min-width: 2px; /* Ensure even small segments are visible */
  transition: width 0.3s ease;
`

const CompactTotalValue = styled(Box)`
  font-size: 16px;
  font-weight: bold;
  color: white;
  white-space: nowrap;
`

const TooltipContainer = styled.div`
  position: fixed;
  z-index: 1000;
  margin-top: 8px;
  width: fit-content;
  max-width: 400px;
  animation: fadeIn 0.2s ease-in-out;
  pointer-events: none; // Ensuring tooltip doesn't interfere with mouse events
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.4));

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const LegendContainer = styled(Box)`
  background: rgba(0, 0, 0, 0.9);
  flex-wrap: wrap;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 200px;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
`

const ColoredNumberBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
`

const LegendText = styled.span`
  color: white;
  font-size: 14px;
`

export default CompactParticipantLineChart
