import { Button, Checkbox, Select, TextField } from '@mui/material'
import styled from '@emotion/styled'
import FigmaText from '../components/FigmaText'
import Box from '../components/Box'
import { Paper } from '@mui/material'

export const ImageContainer = styled(Box)`
  width: 70%;
`

export const BannerImage = styled.img`
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
`

export const CustomSelectField = styled(Select)`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;

  @media (max-width: 600px) {
    margin: 8px 4px;
  }

  // Generic styling for all icons
  .MuiSvgIcon-root {
    color: white;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: black;
  }
  &.Mui-checked {
    color: black;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`

export const CustomTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  margin: 12px 8px;

  @media (max-width: 600px) {
    margin: 8px 4px;
  }

  // Generic styling for all icons
  .MuiSvgIcon-root {
    color: white;
  }

  // Specific styling for time picker
  input[type='time']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-clock-picker-indicator,
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`

export const CheckBoxLabel = styled(FigmaText)`
  margin: 0;
  top: 0;
`

export const SectionHeader = styled(FigmaText)`
  padding-bottom: 12px;
  width: 100%;
`

export const Section = styled(Box)`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 16px 16px;
  margin-top: 16px;

  @media (max-width: 600px) {
    padding: 2px 4px;
  }
`

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  align-items: center;
  background: radial-gradient(circle at 20% 30%, #2e004d, transparent 90%),
    radial-gradient(circle at 80% 20%, #0f4873, transparent 92%),
    radial-gradient(circle at 80% 80%, rgb(8, 53, 87), transparent 96%);
`

export const PageContainer = styled(Box)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  margin-bottom: 60px; //To make space for the sticky footer

  @media (max-width: 1232px) {
    padding: 6px;
    padding-top: 60px; //To make space for menu
  }
`

export const SpinnerOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
`

export const StickyFooter = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;

  background-color: rgba(201, 201, 201, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 16px;
  z-index: 1000;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media (min-width: 600px) {
    left: 56px;
    padding-right: 64px;
  }
`

export const UploadButton = styled(Button)`
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
`

export const ProgressBar = styled.progress`
  width: 100%;
  height: 8px;
  margin: 10px 0;

  &::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-progress-value {
    background-color: #1976d2;
    border-radius: 4px;
  }
`

export const StyledPaper = styled(Paper)`
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  width: 100%;
  color: white;
`

export const StyledInfoBox = styled(Box)`
  background-color: rgba(255, 255, 255, 0.03);
  padding: 12px 16px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
`

export const StyledActivityBox = styled(Box)`
  background-color: rgba(255, 255, 255, 0.03);
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  color: white;
`
