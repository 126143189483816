import React, { useEffect, useState } from 'react'
import { CustomTextField, MainContainer, PageContainer, StyledInfoBox, StyledPaper } from '../styles/AdminPageStyles'
import Box from '../components/Box'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Enrollment, PriceDetails, VatData } from '../../../pure-js/types/GrooverTypes'
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import useAppState from '../hooks/useAppState'
import { getEnrollmentData, upsertEnrollment } from '../libs/CloudFunctionsApiHandler'
import { Save } from '@mui/icons-material'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText, formatText } from '../libs/TextRepository'
import { participantTypeConfig } from '../utils/participantTypeConfig'
import { formatDateTime, hasNonManualPayments } from '../utils/enrollmentUtils'
import { calculateEnrollmentVATGroups } from '../../../pure-js/libs/EnrollmentHelper'
import { getEnrollmentUrl } from '../../../pure-js/libs/UrlHelper'
import { CommunicationLog } from '../../../pure-js/types/CommunicationLogTypes'
import { useCommunicationLogForEnrollment } from '../hooks/useCommunicationLogForEnrollment'

interface GroupedPriceDetail extends PriceDetails {
  userId: string
}

// Updated group function to use appliesTo field for identification
const groupPriceDetailsByUser = (
  enrollment: Enrollment | null
): {
  mainUserDetails: PriceDetails[]
  partnerUserDetails: PriceDetails[]
} => {
  if (!enrollment || !enrollment.priceDetails) {
    return { mainUserDetails: [], partnerUserDetails: [] }
  }

  const mainUserDetails: PriceDetails[] = []
  const partnerUserDetails: PriceDetails[] = []

  enrollment.priceDetails.forEach((detail) => {
    if (detail.appliesTo === 'partnerUser') {
      partnerUserDetails.push(detail)
    } else {
      // Default to mainUser if not specified
      mainUserDetails.push(detail)
    }
  })

  return { mainUserDetails, partnerUserDetails }
}

const EnrollmentDetailPage: React.FC = () => {
  const { enrollmentId } = useParams<{ enrollmentId: string }>()
  const navigate = useNavigate()
  const { state } = useAppState()

  const [enrollment, setEnrollment] = useState<Enrollment | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [editedPriceDetails, setEditedPriceDetails] = useState<PriceDetails[]>([])
  const [enrollmentVatData, setEnrollmentVatData] = useState<VatData>({})
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isDateEditMode, setIsDateEditMode] = useState<boolean>(false)
  const [editedPaymentDate, setEditedPaymentDate] = useState<string>('')
  const [isSavingDate, setIsSavingDate] = useState<boolean>(false)
  const [communicationLogs, setCommunicationLogMainUser] = useState<CommunicationLog[]>([])

  const _communicationLogs = useCommunicationLogForEnrollment(enrollment)

  useEffect(() => {
    if (_communicationLogs.data) {
      console.log(`_communicationLogMainUser for ${enrollment?.mainUser.id}}`, _communicationLogs.data, enrollment)
      setCommunicationLogMainUser(_communicationLogs.data)
    }
  }, [_communicationLogs.data, _communicationLogs.status])

  useEffect(() => {
    const fetchEnrollment = async () => {
      if (!enrollmentId || !state.user) {
        setError('Invalid enrollment ID or user not logged in')
        setLoading(false)
        return
      }

      try {
        const data = await getEnrollmentData({ enrollmentId }, state)
        if (!data) {
          throw new Error('Enrollment not found')
        }
        setEnrollment(data)
        if (data.priceDetails) {
          setEnrollmentVatData(calculateEnrollmentVATGroups(data))
          setEditedPriceDetails([...data.priceDetails])
        }
        // Set the initial payment date if it exists
        if (data.payments && data.payments.length > 0) {
          const lastPayment = data.payments[data.payments.length - 1]
          if (lastPayment.paymentDate) {
            setEditedPaymentDate(lastPayment.paymentDate)
          }
        }
      } catch (err) {
        console.error('Error fetching enrollment:', err)
        setError(`Failed to load enrollment: ${err instanceof Error ? err.message : String(err)}`)
      } finally {
        setLoading(false)
      }
    }

    fetchEnrollment()
  }, [enrollmentId, state.user])

  const handleGoBack = () => {
    navigate(-1)
  }

  const calculateTotalPrice = (priceDetails: PriceDetails[]) => {
    return priceDetails?.reduce((total, detail) => total + (detail.finalPrice || 0), 0) / 100 || 0
  }

  const handlePriceChange = (index: number, newPrice: number) => {
    const updatedPriceDetails = [...editedPriceDetails]
    // Convert from SEK to cents (öre)
    updatedPriceDetails[index].finalPrice = Math.round(newPrice * 100)
    setEditedPriceDetails(updatedPriceDetails)
  }

  const handleSaveChanges = async () => {
    if (!enrollment) return

    setIsSaving(true)
    try {
      const updatedEnrollment: Enrollment = {
        ...enrollment,
        priceDetails: editedPriceDetails
      }

      // Update payment date if in date edit mode
      if (isDateEditMode && enrollment.payments && enrollment.payments.length > 0) {
        const updatedPayments = [...enrollment.payments]
        updatedPayments[updatedPayments.length - 1].paymentDate = editedPaymentDate
        updatedEnrollment.payments = updatedPayments
      }

      await upsertEnrollment(updatedEnrollment, state)
      setEnrollment(updatedEnrollment)
      setIsEditMode(false)
      setIsDateEditMode(false)
    } catch (err) {
      console.error('Error saving enrollment:', err)
      setError(`Failed to save changes: ${err instanceof Error ? err.message : String(err)}`)
    } finally {
      setIsSaving(false)
    }
  }

  const handlePaymentDateChange = async (newDate: string) => {
    if (!enrollment || !enrollment.payments || enrollment.payments.length === 0) return

    // Format with time part
    const timePart = editedPaymentDate.includes('T') ? editedPaymentDate.split('T')[1] : '00:00:00.000Z'
    const fullDate = `${newDate}T${timePart}`

    setEditedPaymentDate(fullDate)
    setIsSavingDate(true)

    try {
      const updatedEnrollment = { ...enrollment }
      const updatedPayments = [...updatedEnrollment.payments]
      updatedPayments[updatedPayments.length - 1].paymentDate = fullDate
      updatedEnrollment.payments = updatedPayments

      await upsertEnrollment(updatedEnrollment, state)
      setEnrollment(updatedEnrollment)
    } catch (err) {
      console.error('Error saving payment date:', err)
      setError(`Failed to save payment date: ${err instanceof Error ? err.message : String(err)}`)
    } finally {
      setIsSavingDate(false)
    }
  }

  const getLastPayment = () => {
    if (!enrollment?.payments || enrollment.payments.length === 0) return null
    return enrollment.payments[enrollment.payments.length - 1]
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }

  // Find activity name by ID
  const getActivityName = (activityId: string): string => {
    if (!enrollment || !enrollment.activities) return 'Unknown Activity'

    const activity = enrollment.activities.find((a) => a.id === activityId)
    return activity ? activity.name : 'Unknown Activity'
  }

  // Get activity VAT rate by ID
  const getActivityVatRate = (activityId: string): number => {
    if (!enrollment || !enrollment.activities) return 0

    const activity = enrollment.activities.find((a) => a.id === activityId)
    return activity && activity.vat !== undefined ? activity.vat / 100 : 0
  }

  // Calculate VAT amount from brutto price (price including VAT)
  const getVatFromBruttoPrice = (price: number, vatRate: number): number => {
    if (vatRate <= 0) return 0
    return price - price / (1 + vatRate)
  }

  // Get activity link path
  const getActivityLinkPath = (activityId: string): string => {
    return `/enrollments/${enrollment?.clientId}/${activityId}`
  }

  // Get the appropriate date to display
  const getRelevantDate = (): { label: string; date: string; isEditable: boolean } => {
    if (enrollment?.paymentStatus === 'paid' && lastPayment?.paymentDate) {
      return {
        label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderDateOfPayment),
        date: formatDate(lastPayment.paymentDate),
        isEditable: true
      }
    } else {
      return {
        label: getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsRegistrationDate),
        date: enrollment?.createdAt ? formatDate(enrollment.createdAt) : 'N/A',
        isEditable: false
      }
    }
  }

  // Function to get participant type color and text
  const getParticipantTypeInfo = (type: string | undefined) => {
    if (!type || !participantTypeConfig[type]) return { color: 'grey', text: 'Unknown' }
    return participantTypeConfig[type]
  }

  // Function to get user information from enrollmentUserId
  const getUserInfoFromEnrollmentUserId = (enrollmentUserId: string | undefined) => {
    if (!enrollment || !enrollmentUserId) return 'N/A'

    if (enrollment.mainUser && enrollment.mainUser.id === enrollmentUserId) {
      const { name, email, phone } = enrollment.mainUser
      return `${name || 'N/A'} - ${email || 'N/A'} - ${phone || 'N/A'}`
    }

    if (enrollment.partnerUser && enrollment.partnerUser.id === enrollmentUserId) {
      const { name, email, phone } = enrollment.partnerUser
      return `${name || 'N/A'} - ${email || 'N/A'} - ${phone || 'N/A'}`
    }

    return 'Unknown User'
  }

  if (loading) {
    return (
      <MainContainer>
        <PageContainer gap="20px" align="center" justify="center">
          <CircularProgress />
        </PageContainer>
      </MainContainer>
    )
  }

  if (error || !enrollment) {
    return (
      <MainContainer>
        <PageContainer gap="20px">
          <IconButton onClick={handleGoBack} sx={{ alignSelf: 'flex-start' }}>
            <ArrowBackIcon />
          </IconButton>
          <Alert severity="error" sx={{ width: '100%' }}>
            {error || getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsEmptyStateNoEnrollment)}
          </Alert>
        </PageContainer>
      </MainContainer>
    )
  }

  const lastPayment = getLastPayment()
  const isPaid = enrollment.paymentStatus === 'paid'
  const { mainUserDetails, partnerUserDetails } = groupPriceDetailsByUser(enrollment)
  const relevantDate = getRelevantDate()

  // Create a price details table for a specific user
  const renderPriceDetailsTable = (details: PriceDetails[], userName: string) => {
    // Define column configuration
    const columns = [
      {
        id: 'activity',
        label: getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsActivityListColumnMain),
        align: 'left' as const,
        render: (detail: PriceDetails) => {
          const activityName = getActivityName(detail.activityId)
          const activityLink = getActivityLinkPath(detail.activityId)
          return <StyledLink to={activityLink}>{activityName}</StyledLink>
        }
      },
      {
        id: 'originalPrice',
        label: getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsActivityListColumnOrdPrice),
        align: 'right' as const,
        render: (detail: PriceDetails) => (
          <Typography sx={{ opacity: 0.5, fontSize: '14px' }}>{(detail.originalPrice / 100).toFixed(2)} SEK</Typography>
        )
      },

      {
        id: 'vatRate',
        label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVatPercentage),
        align: 'right' as const,
        render: (detail: PriceDetails) => {
          const vatRate = getActivityVatRate(detail.activityId)
          return `${(vatRate * 100).toFixed(0)}%`
        }
      },
      {
        id: 'vatAmount',
        label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVat),
        align: 'right' as const,
        render: (detail: PriceDetails) => {
          const vatRate = getActivityVatRate(detail.activityId)
          const priceInSEK = (detail.finalPrice || 0) / 100
          const vatAmount = getVatFromBruttoPrice(priceInSEK, vatRate)
          return `${vatAmount.toFixed(2)} SEK`
        }
      },
      {
        id: 'nettoValue',
        label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPriceExVat),
        align: 'right' as const,
        render: (detail: PriceDetails) => {
          const vatRate = getActivityVatRate(detail.activityId)
          const priceInSEK = (detail.finalPrice || 0) / 100
          const vatAmount = getVatFromBruttoPrice(priceInSEK, vatRate)
          const nettoValue = priceInSEK - vatAmount
          return `${nettoValue.toFixed(2)} SEK`
        }
      },
      {
        id: 'finalPrice',
        label: getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsActivityListColumnPrice),
        align: 'right' as const,
        render: (detail: PriceDetails) => {
          const editIndex = enrollment.priceDetails.findIndex((pd) => pd === detail)
          return isEditMode ? (
            <TextField
              type="number"
              value={editedPriceDetails[editIndex]?.finalPrice / 100 || 0}
              onChange={(e) => handlePriceChange(editIndex, parseFloat(e.target.value) || 0)}
              size="small"
              InputProps={{
                endAdornment: <Typography variant="caption">SEK</Typography>
              }}
              sx={{ width: '110px' }}
            />
          ) : (
            `${(detail.finalPrice / 100).toFixed(2)} SEK`
          )
        }
      }
    ]

    return (
      <Box direction="column" gap="16px" width="100%">
        <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsActivitySectionCssUserName} text={userName} />
        <StyledTable>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableHeadCell key={column.id} align={column.align}>
                  {column.label}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((detail, index) => (
              <StyledTableRow key={index}>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.render(detail)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}

            {/* User subtotal row */}
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: 'bold' }} colSpan={2}>
                {formatText(getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsTotalForUser), [userName])}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}></StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}></StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}></StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {isEditMode
                  ? (
                      details.reduce((sum, detail) => {
                        const editIndex = enrollment.priceDetails.findIndex((pd) => pd === detail)
                        return sum + (editedPriceDetails[editIndex]?.finalPrice || 0)
                      }, 0) / 100
                    ).toFixed(2)
                  : (details.reduce((sum, detail) => sum + detail.finalPrice, 0) / 100).toFixed(2)}{' '}
                SEK
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </StyledTable>
      </Box>
    )
  }

  // Add this to the user information section
  const renderUserWithParticipantType = (user: any, label: string, participantType?: string) => {
    const typeInfo = getParticipantTypeInfo(participantType)

    return (
      <StyledInfoBox>
        <Typography variant="body2" sx={{ opacity: 0.7 }}>
          {label}
        </Typography>
        <Box direction="row" align="center" gap="8px">
          <Typography variant="body1" fontWeight="bold">
            {user?.name || 'N/A'} {user?.phone ? `(${user.phone})` : ''}
          </Typography>
          {participantType && (
            <Chip
              label={typeInfo.text}
              size="small"
              sx={{
                backgroundColor: typeInfo.color,
                color: '#fff',
                fontWeight: 'bold',
                ml: 1
              }}
            />
          )}
        </Box>
      </StyledInfoBox>
    )
  }

  return (
    <MainContainer>
      <PageContainer gap="24px">
        {/* Header with back button */}
        <Box direction="row" align="center" gap="16px">
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsEnrollmentDetailsHeader} />
        </Box>

        {/* New CTA button to open enrollment page in new tab */}
        <Box direction="row" justify="flex-end" fullWidth>
          <Button
            variant="outlined"
            href={`${getEnrollmentUrl(enrollment)}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ maxWidth: '300px' }}
          >
            {getFigmaText(Texts.adminGeneralCtactaCopyEnrollmentLink)}
          </Button>
        </Box>
        {/* Status and Payment Info */}
        <StyledPaper>
          <Box direction="column" gap="16px" fullWidth>
            <Box direction="row" justify="space-between" align="center" fullWidth>
              <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsSectionHeaderUsers} />
              <Chip
                label={
                  isPaid
                    ? getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)
                    : getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)
                }
                color={isPaid ? 'success' : 'default'}
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: isPaid ? 'rgba(21, 129, 44, 0.86)' : 'rgba(255, 255, 255, 0.82)'
                }}
              />
            </Box>

            <Box direction="column" gap="8px" fullWidth>
              {/* Always show date (either payment date or creation date) */}
              <StyledInfoBox>
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  {relevantDate.label}
                </Typography>
                {isPaid && relevantDate.isEditable ? (
                  <Box direction="row" align="center" gap="8px">
                    <CustomTextField
                      type="date"
                      value={editedPaymentDate.split('T')[0]} // Extract only the date part
                      onChange={(e) => handlePaymentDateChange(e.target.value)}
                      size="small"
                      sx={{ width: '160px' }}
                      disabled={isSavingDate}
                      InputProps={{
                        readOnly: hasNonManualPayments(enrollment)
                      }}
                    />
                    {isSavingDate && <CircularProgress size={16} />}
                  </Box>
                ) : (
                  <Typography variant="body1" fontWeight="bold">
                    {relevantDate.date}
                  </Typography>
                )}
              </StyledInfoBox>

              {/* Show payment details only if paid */}
              {isPaid && lastPayment && (
                <>
                  <StyledInfoBox>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      {getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPaymentMethod)}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {lastPayment.paymentMethod || 'N/A'}
                    </Typography>
                  </StyledInfoBox>

                  <StyledInfoBox>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsPaymentTableReferenceHeader)}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {lastPayment.paymentRef || 'N/A'}
                    </Typography>
                  </StyledInfoBox>

                  <StyledInfoBox>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      {getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderAmount)}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {((lastPayment.totalAmount || 0) / 100).toFixed(2)} SEK
                    </Typography>
                  </StyledInfoBox>
                </>
              )}

              {/* Add save button if in date edit mode */}
              {isDateEditMode && (
                <Button variant="contained" onClick={handleSaveChanges} disabled={isSaving} startIcon={<Save />}>
                  {isSaving ? 'Saving...' : getFigmaText(Texts.adminGeneralCtactaSave)}
                </Button>
              )}
            </Box>
          </Box>
        </StyledPaper>

        {/* User Information */}
        <StyledPaper>
          <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsSectionHeaderUsers} />
          <Box direction="column" gap="8px" fullWidth>
            {renderUserWithParticipantType(
              enrollment.mainUser,
              getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsMainUserNameLabel),
              enrollment.mainUser.type
            )}

            <StyledInfoBox>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>
                {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsMainUserEmailLabel)}
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {enrollment.mainUser?.email || 'N/A'}
              </Typography>
            </StyledInfoBox>

            {enrollment.partnerUser &&
              renderUserWithParticipantType(
                enrollment.partnerUser,
                getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsPartnerUserNameLabel),
                enrollment.partnerUser.type
              )}

            {/* Comment section */}
            {enrollment.comment && (
              <StyledInfoBox>
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  {'Comment'}
                </Typography>
                <Typography variant="body1">{enrollment.comment || 'N/A'}</Typography>
              </StyledInfoBox>
            )}
          </Box>
        </StyledPaper>

        {/* Activities and Price Details */}
        <StyledPaper>
          <Box direction="row" justify="space-between" align="center" fullWidth>
            <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsSectionActivities} />
            {!isPaid && (
              <Button
                variant="outlined"
                onClick={() => setIsEditMode(!isEditMode)}
                startIcon={isEditMode ? <Save /> : null}
              >
                {isEditMode
                  ? getFigmaText(Texts.adminGeneralCtactaCancel)
                  : getFigmaText(Texts.adminGeneralCtactaEditPrices)}
              </Button>
            )}
          </Box>

          {enrollment.priceDetails && enrollment.priceDetails.length > 0 ? (
            <>
              {mainUserDetails.length > 0 &&
                renderPriceDetailsTable(mainUserDetails, enrollment.mainUser?.name || 'Main User')}

              {partnerUserDetails.length > 0 &&
                renderPriceDetailsTable(partnerUserDetails, enrollment.partnerUser?.name || 'Partner')}

              {/* Grand Total */}
              <Box direction="row" justify="space-between" fullWidth>
                <Box direction="column">
                  <Typography variant="h6" sx={{ opacity: 0.7 }}>
                    {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsGrandTotal)}
                  </Typography>
                </Box>
                <Box direction="column" align="flex-end">
                  <Typography variant="h6" fontWeight="bold">
                    {calculateTotalPrice(isEditMode ? editedPriceDetails : enrollment.priceDetails).toFixed(2)} SEK
                  </Typography>
                </Box>
              </Box>

              {isEditMode && (
                <Button variant="contained" onClick={handleSaveChanges} disabled={isSaving} sx={{ mt: 3 }}>
                  {isSaving ? 'Saving...' : getFigmaText(Texts.adminGeneralCtactaSave)}
                </Button>
              )}
            </>
          ) : (
            <Alert severity="info">No price details available for this enrollment.</Alert>
          )}
        </StyledPaper>

        {/* Communication Logs Table - Only show if there are logs to display */}
        {communicationLogs.length > 0 && (
          <StyledPaper>
            <Box direction="row" justify="space-between" align="center" fullWidth>
              <FigmaText textKey={Texts.adminGeneralPagesEnrollmentDetailsCommunicationLogHeader} />
            </Box>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell>
                    {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsPaymentTableDateHeader)}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsCommunicationLogColumnChannel)}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>Event</StyledTableHeadCell>
                  <StyledTableHeadCell>
                    {getFigmaText(Texts.adminGeneralPagesEnrollmentDetailsCommunicationLogContactInfo)}
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Combine and sort both communication log arrays */}
                {communicationLogs.map((log, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{formatDateTime(log.createdAt)}</StyledTableCell>
                    <StyledTableCell>{log.channel || 'N/A'}</StyledTableCell>
                    <StyledTableCell>{log.event}</StyledTableCell>
                    <StyledTableCell>{getUserInfoFromEnrollmentUserId(log.enrollmentUserId)}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledPaper>
        )}
      </PageContainer>
    </MainContainer>
  )
}

// Styled components for the table
const StyledTable = styled(Table)({
  minWidth: 300
})

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.common.white,
  fontWeight: 'bold'
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
}))

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    borderBottom: 0
  }
})

const StyledLink = styled(Link)({
  color: '#ffffff',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: '#bbdefb'
  }
})

export default EnrollmentDetailPage
