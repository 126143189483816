import React, { useState, useEffect } from 'react'
import Box from '../components/Box'
import { MenuItem, Button } from '@mui/material'
import useAppState from '../hooks/useAppState'
import { PageContainer, MainContainer, CustomTextField, Section, SectionHeader } from '../styles/AdminPageStyles'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from '../components/FigmaText'
import toast from 'react-hot-toast'
import { useClient } from '../context/ClientContext'

const ActivityIframeConfigurator = () => {
  const { state } = useAppState()
  const { clientId } = useClient() // Use ClientContext instead of localStorage
  const [filter, setFilter] = useState('')
  const [cityName, setCityName] = useState('')
  const [danceStyle, setDanceStyle] = useState('')
  const [group, setGroup] = useState('type')
  const [viewMode, setViewMode] = useState('grid')
  const [hideFilters, setHideFilters] = useState('true')
  const [groupSorting, setGroupSorting] = useState('startdate')
  const [activityType, setActivityType] = useState('Course')
  const [iframeSrc, setIframeSrc] = useState('')
  const [iframeCode, setIframeCode] = useState('')

  useEffect(() => {
    const newSrc = `https://app.coursely.se/activities/${clientId}?filter=${filter}&group=${group}&cityName=${cityName}&danceStyle=${danceStyle}&activityType=${activityType}&groupSorting=${groupSorting}&hideFilter=${hideFilters}&viewMode=${viewMode}`

    setIframeSrc(newSrc)
    setIframeCode(
      `<div id="container-iframe" width="100%" style="border: none; border-radius: 4px;" data-client-id="${clientId}" data-filter="${filter}" data-city-name="${cityName}" data-dance="${danceStyle}" data-group="${group}" data-group-sorting="${groupSorting}" data-activity-type="${activityType}" data-hide-filter="${hideFilters}" allowfullscreen=""></div>`
    )
  }, [clientId, filter, cityName, danceStyle, group, viewMode, hideFilters, groupSorting, activityType])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode).then(() => {
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsSaveSuccesful), {
        duration: 2000,
        position: 'top-center'
      })
    })
  }

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth>
        <Box direction="column" justify="center" align="center" gap="16px" fullWidth top spacing="100px">
          <Box direction="row" fullWidth>
            <FigmaText textKey={Texts.adminGeneralPagesiframeIntegrationHomepageIntegrationHeaderText} />
          </Box>
          <Section fullWidth>
            <Box direction="row" gap="10px" fullWidth bottom className="boxColumn">
              <CustomTextField label="Filter" value={filter} onChange={(e) => setFilter(e.target.value)} fullWidth />
              <CustomTextField
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationCityInputLabel)}
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                fullWidth
              />
              <CustomTextField
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationDanceStyleInputLabel)}
                value={danceStyle}
                onChange={(e) => setDanceStyle(e.target.value)}
                fullWidth
              />
            </Box>
            <Box direction="row" gap="10px" fullWidth bottom className="boxColumn">
              <CustomTextField
                select
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationGroupSortingLabel)}
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                fullWidth
              >
                <MenuItem value="type">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByActivityType)}</MenuItem>
                <MenuItem value="style">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByDanceType)}</MenuItem>
                <MenuItem value="city">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByCity)}</MenuItem>
                <MenuItem value="startdate">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByMonth)}</MenuItem>
              </CustomTextField>

              <CustomTextField
                select
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationViewModeLabel)}
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                fullWidth
              >
                <MenuItem value="grid">{getFigmaText(Texts.externalActivityPageOptionGridViewLabel)}</MenuItem>
                <MenuItem value="list">{getFigmaText(Texts.externalActivityPageOptionListViewLabel)}</MenuItem>
              </CustomTextField>

              <CustomTextField
                select
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationHideFiltersLabel)}
                value={hideFilters}
                onChange={(e) => setHideFilters(e.target.value)}
                fullWidth
              >
                <MenuItem value="true">{getFigmaText(Texts.adminGeneralEnumsTrue)}</MenuItem>
                <MenuItem value="false">{getFigmaText(Texts.adminGeneralEnumsFalse)}</MenuItem>
              </CustomTextField>

              <CustomTextField
                select
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationGroupSortingLabel)}
                value={groupSorting}
                onChange={(e) => setGroupSorting(e.target.value)}
                fullWidth
              >
                <MenuItem value="startdate">{getFigmaText(Texts.adminGeneralEnumsSortBySortByStartDate)}</MenuItem>
                <MenuItem value="name">{getFigmaText(Texts.adminGeneralEnumsSortBySortByName)}</MenuItem>
                <MenuItem value="level">{getFigmaText(Texts.adminGeneralEnumsSortBySortByLevel)}</MenuItem>
              </CustomTextField>

              <CustomTextField
                select
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationActivityType)}
                value={activityType}
                onChange={(e) => setActivityType(e.target.value)}
                fullWidth
              >
                <MenuItem value="Course">{getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)}</MenuItem>
                <MenuItem value="Occasion">{getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)}</MenuItem>
                <MenuItem value="Other">{getFigmaText(Texts.adminGeneralEnumsActivityTypeOther)}</MenuItem>
              </CustomTextField>
            </Box>
          </Section>

          <Section fullWidth>
            <Box direction="column" fullWidth top>
              <CustomTextField
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationGeneratedDivLabel)}
                value={iframeCode}
                multiline
                fullWidth
                rows={4}
              />
              <Box alignSelf="flex-end">
                <Button variant="contained" color="primary" onClick={copyToClipboard}>
                  {getFigmaText(Texts.adminGeneralCtactaCopyToClipboard)}
                </Button>
              </Box>
            </Box>
            <Box direction="column" fullWidth>
              <CustomTextField
                label={getFigmaText(Texts.adminGeneralPagesiframeIntegrationCopyHeadCode)}
                value={'<script defer src="https://app.coursely.se/scripts/activityLoader.js"></script>'}
                multiline
                fullWidth
                rows={1}
              />
              <Box alignSelf="flex-end">
                <Button variant="contained" color="primary" onClick={copyToClipboard}>
                  {getFigmaText(Texts.adminGeneralCtactaCopyToClipboard)}
                </Button>
              </Box>
            </Box>
          </Section>

          <Section fullWidth>
            <Box top direction="row" gap="40px" align="center" justify="center">
              <FigmaText textKey={Texts.adminGeneralPagesiframeIntegrationPreviewHeaderLabel} />
              <Button variant="contained" color="primary" onClick={() => setIframeSrc(iframeSrc)}>
                {getFigmaText(Texts.adminGeneralCtactaRefresh)}
              </Button>
            </Box>
            <Box fullWidth top>
              <iframe
                src={iframeSrc}
                id="dynamic-iframe"
                style={{ border: 'none', display: 'block', width: '100%', minHeight: '700px' }}
                title="Activity Iframe"
              />
            </Box>
          </Section>
        </Box>
      </PageContainer>
    </MainContainer>
  )
}

export default ActivityIframeConfigurator
