import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import JoditEditor from 'jodit-react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Badge
} from '@mui/material'
import { FilterList, ArrowRight } from '@mui/icons-material'
import { CustomTextField, MainContainer, PageContainer, Section, CustomSelectField } from '../styles/AdminPageStyles'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { getActivitiesForClient, useEmailTemplatesForClient } from '../libs/DBApiHandler'
import { useClient as useSelectedClient } from '../context/ClientContext'
import { Activity, EmailTemplate } from '../../../pure-js/types/GrooverTypes'
import useAppState from '../hooks/useAppState'
import {
  CreateMailingRequest,
  GetEmailRecipientsForActivitiesResponseItem
} from '../../../pure-js/types/QueryParamTypes'
import { useEmailRecipientsForActivities } from '../libs/ReactQueryHooks'
import Loader from '../components/Loader'
import { createMailing } from '../libs/CloudFunctionsApiHandler'
import { getFigmaText, formatText } from '../libs/TextRepository'
import { useClient } from '../hooks/useClient'
import { getMailingTokens, replaceTokens } from '../../../pure-js/libs/MailingHelper'
import RecipientSection from '../components/communication/RecipientSection'
import EmailEditorSection from '../components/communication/EmailEditorSection'
import { useActivityFiltering } from '../hooks/useActivityFiltering'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const availablePlaceholders = '{{@user}}'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      fullWidth
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box fullWidth>{children}</Box>}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const dummyEmailTemplate = {
  id: '',
  clientId: '',
  name: '',
  body: '',
  subject: '',
  replyToEmail: '',
  replyToName: '',
  createdAt: ''
}

const CommunicationPage: React.FC = () => {
  const { state } = useAppState()
  const { clientId } = useSelectedClient()
  const [currentTab, setCurrentTab] = useState(0)
  const [activities, setActivities] = useState<Activity[]>([])
  const [activitiesSelected, setActivitiesSelected] = useState<string[]>([])
  const [recipients, setRecipients] = useState<GetEmailRecipientsForActivitiesResponseItem[]>([])
  const [isRecipientsLoading, setIsRecipientsLoading] = useState<boolean>(false)
  const [recipientsSelected, setRecipientsSelected] = useState<string[]>([])
  const [tab2enabled, setTab2Enabled] = useState(false)
  const [tab3enabled, setTab3Enabled] = useState(false)
  const [tab4enabled, setTab4Enabled] = useState(false)
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([])
  const [emailTemplateSelected, setEmailTemplateSelected] = useState<EmailTemplate>(dummyEmailTemplate)
  const editorRef = useRef<any>(null)
  const editorPreviewRef = useRef<any>(null)
  const [templateBody, setTemplateBody] = useState<string>('')
  const [templateSubject, setTemplateSubject] = useState<string>('')
  const [templateReplyToEmail, setTemplateReplyToEmail] = useState<string>('')
  const [templateReplyToName, setTemplateReplyToName] = useState<string>('')
  const [isFormSubmiting, setIsFormSubmiting] = useState(false)
  const [willSendCopyToMe, setWillSendCopyToMe] = useState<boolean>(true)
  const [emailStats, setEmailStats] = useState<any>({})
  const [previewSubject, setPreviewSubject] = useState<string>('')
  const [previewBody, setPreviewBody] = useState<string>('')
  const [sendCopyEmail, setSendCopyEmail] = useState<string>('')

  // Filter state variables
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [activeFilterOption, setActiveFilterOption] = useState<string | null>(null)

  // Use our new custom filtering hook
  const {
    filteredActivities,
    filters,
    filterOptions,
    handleFilterToggle,
    clearFilter,
    getTotalActiveFiltersCount,
    clearAllFilters
  } = useActivityFiltering({ activities })

  // Add theme and media query check for responsive design
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const client = useClient(clientId)

  const customSort = (a: string, b: string) => {
    const justTextA = a.replace(/[^a-zA-Z0-9]/g, '')
    const justTextB = b.replace(/[^a-zA-Z0-9]/g, '')
    return justTextA.localeCompare(justTextB)
  }

  const customSortByName = (a: { name: string }, b: { name: string }) => customSort(a.name, b.name)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleMobileTabChange = (event: SelectChangeEvent<number>) => {
    setCurrentTab(Number(event.target.value))
  }

  const handleActivitySelectChange = (event: SelectChangeEvent<typeof activitiesSelected>) => {
    setActivitiesSelected(event.target.value as string[])
  }

  const handleRecipientCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id
    if (recipientsSelected.includes(id)) {
      setRecipientsSelected(recipientsSelected.filter((r) => r !== id))
    } else {
      setRecipientsSelected([...recipientsSelected, id])
    }
  }

  const handleSelectAllRecipients = () => {
    setRecipientsSelected(recipients.map((r) => r.id))
  }

  const handleUnselectAllRecipients = () => {
    setRecipientsSelected([])
  }

  const handleSendCopyToMe = () => {
    setWillSendCopyToMe(!willSendCopyToMe)
  }

  const handleFormSubmitted = async () => {
    setIsFormSubmiting(true)
    toast.loading(getFigmaText(Texts.adminGeneralPagesEmailCommunicationPreparingEmails))

    const data: CreateMailingRequest = {
      clientId,
      subject: templateSubject,
      replyToEmail: templateReplyToEmail,
      replyToName: templateReplyToName,
      body: templateBody,
      recipients: recipientsSelected,
      activities: activitiesSelected
    }
    // Add copy to me if needed
    if (willSendCopyToMe && state.firebaseUser?.email) data.sendCopyToEmail = sendCopyEmail

    try {
      await createMailing(data, state)
    } catch (error) {
      toast.error('Error when creating emails...')
      console.error('createMailing', error)
    } finally {
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsCommunicationNotificationStarted))
      setIsFormSubmiting(false)
      setTab4Enabled(true)
      setCurrentTab(3)
    }
  }

  const handleEmailTemplateChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value
    const selectedTemplate = emailTemplates.find((template) => template.id === selectedValue)
    setEmailTemplateSelected(selectedTemplate || dummyEmailTemplate)
    setTemplateBody(selectedTemplate?.body || '')
    setTemplateSubject(selectedTemplate?.subject || '')
    setTemplateReplyToEmail(selectedTemplate?.replyToEmail || '')
    setTemplateReplyToName(selectedTemplate?.replyToName || '')
  }

  const handlePreviewForUser = (enrollmentUserId: string) => {
    const recipient = recipients.find((r) => r.id === enrollmentUserId)
    if (!recipient) return toast.error('Recipient data not found')
    const recipientTokens = getMailingTokens({ enrollmentUser: recipient, activities: [] })
    setPreviewSubject(replaceTokens(templateSubject, recipientTokens))
    setPreviewBody(replaceTokens(templateBody, recipientTokens))
  }

  const handleSelectAllActivities = () => {
    // Select only filtered activities that have recipients
    const activitiesWithRecipients = filteredActivities
      .filter((a) => getReciepientsForActivity(a.id) > 0)
      .map((a) => a.id)
    setActivitiesSelected(activitiesWithRecipients)
  }

  const handleUnselectAllActivities = () => {
    setActivitiesSelected([])
  }

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuAnchorEl(event.currentTarget)
  }

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null)
    setActiveFilterOption(null)
  }

  const handleFilterOptionOpen = (option: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setActiveFilterOption(option === activeFilterOption ? null : option)
  }

  const _activities = getActivitiesForClient(clientId)
  const _emailTemplates = useEmailTemplatesForClient(clientId)
  const _emailRecipients = useEmailRecipientsForActivities({ clientId, activityIds: activitiesSelected }, state)
  const _emailRecipientStats = useEmailRecipientsForActivities(
    { clientId, activityIds: activities.map((a) => a.id), statsOnly: true },
    state
  )

  useEffect(() => {
    if (!client) return
    setTemplateReplyToName(client.name)
    setTemplateReplyToEmail(client.contactEmail)
  }, [client])

  useEffect(() => {
    setTab2Enabled(recipientsSelected.length > 0)
  }, [recipientsSelected])

  useEffect(() => {
    setTab3Enabled(
      templateBody.length > 0 &&
        templateSubject.length > 0 &&
        templateReplyToEmail.length > 0 &&
        templateReplyToName.length > 0
    )
    updateEmailPreview()
  }, [templateBody, templateSubject, templateReplyToEmail, templateReplyToName])

  useEffect(() => {
    if (!clientId) return
    if (!_activities.isLoading && _activities.status == 'success') {
      const sortedActivities = (_activities.data as Activity[]).sort(customSortByName)
      setActivities(sortedActivities)
    }
  }, [clientId, _activities])

  useEffect(() => {
    if (!clientId) return
    if (!_emailTemplates.isLoading && _emailTemplates.status == 'success') {
      setEmailTemplates(_emailTemplates.data as EmailTemplate[])
    }
  }, [clientId, _emailTemplates])

  useEffect(() => {
    if (!clientId) return
    if (!_emailRecipientStats.isLoading && _emailRecipientStats.status == 'success') {
      setEmailStats(_emailRecipientStats.data.stats || {})
    }
  }, [clientId, _emailRecipientStats])

  useEffect(() => {
    if (!clientId) return
    setIsRecipientsLoading(_emailRecipients.isLoading)
    if (!_emailRecipients.isLoading && _emailRecipients.status == 'success') {
      setRecipients(_emailRecipients.data.recipients as GetEmailRecipientsForActivitiesResponseItem[])
    }
  }, [_emailRecipients.isLoading, _emailRecipients.status, _emailRecipients.data, activitiesSelected, clientId])

  useEffect(() => {
    if (!state.firebaseUser?.email) return
    setSendCopyEmail(state.firebaseUser?.email)
  }, [state.firebaseUser?.email])

  const getReciepientsForActivity = (activityId: string) => {
    return emailStats.perActivity?.[activityId] || 0
  }

  const renderValueForActivitiesSelect = (selected: string[]) => {
    if (selected.length > 1) {
      return formatText(getFigmaText(Texts.adminGeneralPagesEmailCommunicationSelectCountSelected), [selected.length])
    }

    return activities
      .filter((a) => selected.includes(a.id))
      .map((a) => a.name)
      .join(', ')
  }

  const updateEmailPreview = () => {
    const firstRecipient = recipients.find((r) => recipientsSelected.includes(r.id))
    if (firstRecipient) {
      handlePreviewForUser(firstRecipient.id)
    }
  }

  const WhiteCheckbox = styled(Checkbox)({
    fill: '#000'
  })

  const StyledCheckbox = styled(Checkbox)({
    color: '#1976d2',
    '.MuiSvgIcon-root': {
      fill: '#000'
    },
    '& css-1b445lq-MuiButtonBase-root-MuiCheckbox-root': {
      padding: '4px'
    }
  })

  const responsiveMenuProps = useMemo(() => {
    return {
      PaperProps: {
        style: {
          color: 'black',
          maxHeight: ITEM_HEIGHT * 10,
          width: isMobile ? '90vw' : 250
        }
      },
      anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const
      },
      transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const
      }
    }
  }, [isMobile])

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth gap="20px">
        <Box direction="row" fullWidth>
          <FigmaText textKey={Texts.adminGeneralPagesCommunicationHeaderText} />
        </Box>
        <Box fullWidth>
          {isMobile ? (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="mobile-tab-select-label" sx={{ color: 'white' }}>
                {getFigmaText(Texts.adminGeneralPagesEmailCommunicationSelectStepLabelForMobile)}
              </InputLabel>
              <Select
                labelId="mobile-tab-select-label"
                id="mobile-tab-select"
                value={currentTab}
                onChange={handleMobileTabChange}
                label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationSelectStepLabelForMobile)}
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.5)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white'
                  },
                  '.MuiSvgIcon-root': {
                    color: 'white'
                  }
                }}
              >
                <MenuItem value={0}>
                  {getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderSelectRecipients)}
                </MenuItem>
                <MenuItem value={1} disabled={!tab2enabled}>
                  {getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderEditMail)}
                </MenuItem>
                <MenuItem value={2} disabled={!tab3enabled}>
                  {getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderVerifyAndSend)}
                </MenuItem>
                <MenuItem value={3} disabled={!tab4enabled}>
                  🎉
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="communication tabs">
              <Tab
                label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderSelectRecipients)}
                {...a11yProps(0)}
                style={{ color: 'white' }}
              />
              <Tab
                label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderEditMail)}
                disabled={!tab2enabled}
                {...a11yProps(1)}
                style={{ color: 'white' }}
              />
              <Tab
                label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderVerifyAndSend)}
                disabled={!tab3enabled}
                {...a11yProps(2)}
                style={{ color: 'white' }}
              />
              <Tab
                label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationTabHeaderResult)}
                disabled={!tab4enabled}
                {...a11yProps(4)}
                style={{ color: 'white' }}
              />
            </Tabs>
          )}

          <CustomTabPanel value={currentTab} index={0}>
            <Section id="Section: Select Activities" fullWidth>
              <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {getFigmaText(Texts.adminGeneralPagesEmailCommunicationInputSelectRecipients)}
                  </InputLabel>
                  <CustomSelectField
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={activitiesSelected}
                    onChange={(event: SelectChangeEvent<unknown>, _child: React.ReactNode) =>
                      handleActivitySelectChange(event as SelectChangeEvent<typeof activitiesSelected>)
                    }
                    input={<OutlinedInput label="Activities" />}
                    renderValue={(selected: unknown) => renderValueForActivitiesSelect(selected as string[])}
                    MenuProps={responsiveMenuProps}
                    sx={{
                      maxWidth: '100%',
                      '& .MuiSelect-select': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  >
                    {filteredActivities
                      .filter((a) => getReciepientsForActivity(a.id) > 0)
                      .map((a) => (
                        <MenuItem
                          key={a.id}
                          value={a.id}
                          sx={{
                            height: 'auto',
                            padding: 0,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            paddingY: 0
                          }}
                        >
                          <StyledCheckbox checked={activitiesSelected.includes(a.id)} />
                          <ListItemText
                            primary={`${a.name} (${getReciepientsForActivity(a.id)})`}
                            primaryTypographyProps={{
                              style: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical'
                              }
                            }}
                          />
                        </MenuItem>
                      ))}
                  </CustomSelectField>
                </FormControl>
                <Tooltip title={getFigmaText(Texts.adminGeneralPagesDashboardShowHideFilter)}>
                  <IconButton
                    onClick={handleFilterMenuOpen}
                    sx={{
                      color: 'white',
                      bgcolor: getTotalActiveFiltersCount() > 0 ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                      '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.3)' }
                    }}
                  >
                    <Badge
                      badgeContent={getTotalActiveFiltersCount()}
                      color="error"
                      invisible={getTotalActiveFiltersCount() === 0}
                    >
                      <FilterList />
                    </Badge>
                  </IconButton>
                </Tooltip>
                {getTotalActiveFiltersCount() > 0 && (
                  <Button
                    size="small"
                    onClick={clearAllFilters}
                    sx={{
                      textDecoration: 'underline',
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      opacity: 1,
                      width: '200px'
                    }}
                  >
                    {getFigmaText(Texts.adminGeneralPagesDashboardClearFilter)}
                  </Button>
                )}
                <FilterContent direction="row" gap="10px">
                  <Button
                    onClick={handleSelectAllActivities}
                    variant="text"
                    size="small"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {getFigmaText(Texts.adminGeneralCtactaSelectAll)}
                  </Button>
                  <Button
                    onClick={handleUnselectAllActivities}
                    variant="text"
                    size="small"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {getFigmaText(Texts.adminGeneralCtactaUnselectAll)}
                  </Button>
                </FilterContent>
              </Box>

              <Menu
                anchorEl={filterMenuAnchorEl}
                open={Boolean(filterMenuAnchorEl)}
                onClose={handleFilterMenuClose}
                PaperProps={{
                  sx: {
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    minWidth: '200px'
                  }
                }}
              >
                {filterOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    id={`${option.id}-filter-option`}
                    onClick={(e) => handleFilterOptionOpen(option.id, e)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      bgcolor: activeFilterOption === option.id ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                      color: '#000000'
                    }}
                  >
                    <span>
                      {option.label} {filters[option.id]?.length > 0 && `(${filters[option.id].length})`}
                    </span>
                    <ArrowRight sx={{ fill: '#000000' }} />
                  </MenuItem>
                ))}
              </Menu>

              {filterOptions.map((option) => (
                <Menu
                  key={option.id}
                  open={Boolean(filterMenuAnchorEl) && activeFilterOption === option.id}
                  onClose={() => setActiveFilterOption(null)}
                  anchorEl={document.getElementById(`${option.id}-filter-option`)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: '#ffffff',
                      color: '#000000',
                      minWidth: '200px',
                      maxHeight: '300px',
                      overflowY: 'auto'
                    }
                  }}
                >
                  {(() => {
                    const availableOptions = option.getAvailableOptions(activities)
                    return availableOptions.length > 0 ? (
                      availableOptions.map((opt) => (
                        <MenuItem
                          key={opt.value}
                          onClick={() => handleFilterToggle(option.id, opt.value)}
                          sx={{ color: '#000000' }}
                        >
                          <StyledCheckbox checked={(filters[option.id] || []).includes(opt.value)} />
                          <ListItemText primary={opt.label} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {getFigmaText(Texts.adminGeneralPagesDashboardNoOptions)}
                      </MenuItem>
                    )
                  })()}
                  <MenuItem
                    onClick={() => clearFilter(option.id)}
                    sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)', marginTop: '8px', color: '#000000' }}
                  >
                    {getFigmaText(Texts.adminGeneralPagesDashboardClearFilter)}
                  </MenuItem>
                </Menu>
              ))}
            </Section>

            {activitiesSelected.length > 0 && (
              <Section id="Section: Select Recipients" fullWidth>
                {isRecipientsLoading ? (
                  <Box direction="row" align="center" gap="10px">
                    <Loader size="20px" />
                    <Typography variant="body1" sx={{ color: 'grey' }}>
                      {getFigmaText(Texts.adminGeneralPagesEmailCommunicationLoadingText)}
                    </Typography>
                  </Box>
                ) : (
                  <FormGroup sx={{ '& .MuiFormControlLabel-root': { marginY: '4px' } }}>
                    {recipients.length > 0 && (
                      <Box>
                        <FigmaText textKey={Texts.adminGeneralPagesEmailCommunicationSelectRecipientsResults} />
                        <FilterContent direction="row" gap="10px">
                          <Button
                            onClick={handleSelectAllRecipients}
                            variant="text"
                            size="small"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {getFigmaText(Texts.adminGeneralCtactaSelectAll)}
                          </Button>
                          <Button
                            onClick={handleUnselectAllRecipients}
                            variant="text"
                            size="small"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {getFigmaText(Texts.adminGeneralCtactaUnselectAll)}
                          </Button>
                        </FilterContent>
                      </Box>
                    )}
                    {recipients.length == 0 && (
                      <Typography variant="body1" color="white">
                        {getFigmaText(Texts.adminGeneralPagesEmailCommunicationEmptyStateNoRecipientsFound)}
                      </Typography>
                    )}
                    <Box direction="column" fullWidth spacing="10px" top>
                      {recipients.sort(customSortByName).map((r) => (
                        <FormControlLabel
                          key={'rflabel_' + r.id}
                          sx={{ height: '15pt' }}
                          control={
                            <WhiteCheckbox
                              id={r.id}
                              onChange={handleRecipientCheckbox}
                              checked={recipientsSelected.includes(r.id)}
                            />
                          }
                          label={
                            <Box direction="row">
                              <Typography
                                sx={{
                                  width: { xs: '150px', sm: '250px' },
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {r.name}
                              </Typography>
                              <Typography
                                sx={{
                                  width: { xs: '150px', sm: '250px' },
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {r.email}
                              </Typography>
                            </Box>
                          }
                        />
                      ))}
                    </Box>
                    <Box direction="row" fullWidth spacing="10px" gap="16px">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setCurrentTab(1)}
                        disabled={recipientsSelected.length == 0}
                        sx={{ mt: 2 }}
                      >
                        {getFigmaText(Texts.registrationCtactaContinue)}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const selectedEmails = recipients
                            .filter((recipient) => recipientsSelected.includes(recipient.id))
                            .map((recipient) => recipient.email)
                            .join('; ')

                          navigator.clipboard
                            .writeText(selectedEmails)
                            .then(() => {
                              toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageCopyToClipboard))
                            })
                            .catch((err) => {
                              console.error('Failed to copy emails:', err)
                              toast.error('Ett fel inträffade')
                            })
                        }}
                        disabled={recipientsSelected.length == 0}
                        sx={{ mt: 2 }}
                      >
                        {getFigmaText(Texts.adminGeneralCtactaCopyEmailToClipboard)}
                      </Button>
                      <Typography variant="body1" color="white" sx={{ ml: 2, pt: 2.5 }}>
                        {formatText(getFigmaText(Texts.adminGeneralPagesEmailCommunicationRecepientCountLabel), [
                          recipientsSelected.length.toString()
                        ])}
                      </Typography>
                    </Box>
                  </FormGroup>
                )}
              </Section>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={currentTab} index={1}>
            <EmailEditorSection
              emailTemplates={emailTemplates}
              emailTemplateSelected={emailTemplateSelected}
              templateBody={templateBody}
              templateSubject={templateSubject}
              templateReplyToEmail={templateReplyToEmail}
              templateReplyToName={templateReplyToName}
              availablePlaceholders={availablePlaceholders}
              editorRef={editorRef}
              MenuProps={MenuProps}
              setTemplateBody={setTemplateBody}
              setTemplateSubject={setTemplateSubject}
              setTemplateReplyToEmail={setTemplateReplyToEmail}
              setTemplateReplyToName={setTemplateReplyToName}
              handleEmailTemplateChange={handleEmailTemplateChange}
              goToNextStep={() => setCurrentTab(2)}
            />
          </CustomTabPanel>

          <CustomTabPanel value={currentTab} index={2}>
            <Section id="Section: Confirmation" fullWidth>
              <Typography variant="h6" color="white" sx={{ mb: 2 }}>
                {getFigmaText(Texts.adminGeneralPagesEmailCommunicationFinalValidationStepHeader)}
              </Typography>

              <RecipientSection
                recipients={recipients}
                recipientsSelected={recipientsSelected}
                willSendCopyToMe={willSendCopyToMe}
                sendCopyEmail={sendCopyEmail}
                isFormSubmiting={isFormSubmiting}
                handlePreviewForUser={handlePreviewForUser}
                handleSendCopyToMe={handleSendCopyToMe}
                setSendCopyEmail={setSendCopyEmail}
                handleFormSubmitted={handleFormSubmitted}
                customSort={customSort}
              />
            </Section>
            <Section id="email-preview" fullWidth>
              <PreviewContainer fullWidth>
                <Box width={'100%'} fullWidth direction="column" spacing="10px" fullPadding>
                  <FigmaText textKey={Texts.adminGeneralPagesEmailCommunicationFinalValidationPreviewHeader} />
                  <FigmaText textKey={Texts.adminGeneralPagesEmailCommunicationFinalValidationPreviewInfo} />
                </Box>
                <Box fullWidth direction="column" gap="10px" top>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralPagesEmailCommunicationInputEmailHeader)}
                    value={previewSubject}
                    disabled
                    style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                  />
                </Box>
                <WYSIWIGContainer fullWidth>
                  <JoditEditor
                    ref={editorPreviewRef}
                    value={previewBody}
                    config={{
                      readonly: true,
                      toolbar: false,
                      showCharsCounter: false,
                      showWordsCounter: false,
                      showXPathInStatusbar: false,
                      height: 400,
                      buttons: []
                    }}
                  />
                </WYSIWIGContainer>
              </PreviewContainer>
            </Section>
          </CustomTabPanel>

          <CustomTabPanel value={currentTab} index={3}>
            <Section id="Section: Success" fullWidth gap="12px">
              <FigmaText textKey={Texts.adminGeneralPagesEmailCommunicationRecepientResultHeader} />
              <Typography variant="body1" color="white">
                {formatText(getFigmaText(Texts.adminGeneralPagesEmailCommunicationFinalSendoutInformation), [
                  recipientsSelected.length.toString()
                ])}
              </Typography>
            </Section>
          </CustomTabPanel>
        </Box>
      </PageContainer>
    </MainContainer>
  )
}

const PreviewContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
`

const WYSIWIGContainer = styled(Box)`
  padding: 10px;
`

const FilterContent = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 4px 8px;
  min-width: 200px;
`

export default CommunicationPage
