import React from 'react'
import styled from '@emotion/styled'
import { Search } from '@mui/icons-material'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'

interface SearchBoxProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  onChange,
  placeholder = getFigmaText(Texts.adminGeneralSearchHelperText)
}) => {
  return (
    <SearchContainer direction="row" align="center" gap="8px" fullPadding spacing="6px" fullWidth>
      <Search sx={{ color: 'rgba(255, 255, 255, 0.5)' }} />
      <SearchInput type="text" placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
    </SearchContainer>
  )
}

const SearchContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`

const SearchInput = styled.input`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  width: 100%;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`

export default SearchBox
