import React from 'react'
import { Activity, Enrollment, EnrollmentStatus } from '../../../pure-js/types/GrooverTypes'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { CheckSharp } from '@mui/icons-material'
import { formatPayment, getEnrollmentPrice, isEnrollmentPaid, getPaymentMethod } from '../utils/enrollmentUtils'
import styled from '@emotion/styled'
import Box from './Box'

interface PaymentStatusProps {
  enrollment: Enrollment
  activity: Activity
}

const PaymentStatus: React.FC<PaymentStatusProps> = ({ enrollment, activity }) => {
  if (enrollment.status === EnrollmentStatus.Confirmed && !isEnrollmentPaid(enrollment)) {
    return (
      <EnrollmentPricePending direction="row" justify="center" gap="8px" align="center">
        {getPaymentMethod(enrollment)}
        <RadioButtonUncheckedIcon style={{ fontSize: '1rem' }} />{' '}
        {' ' + formatPayment(getEnrollmentPrice(enrollment, activity)) + ' SEK'}
      </EnrollmentPricePending>
    )
  } else if (enrollment.status === EnrollmentStatus.Pending) {
    return (
      <EnrollmentPriceNotApproved>
        {formatPayment(getEnrollmentPrice(enrollment, activity)) + ' SEK'}
      </EnrollmentPriceNotApproved>
    )
  }

  if (enrollment.paymentStatus === 'paid') {
    return (
      <EnrollmentPricePaid direction="row" justify="center" gap="8px" align="center">
        {getPaymentMethod(enrollment)}
        <CheckSharp style={{ fontSize: '1rem' }} /> {formatPayment(getEnrollmentPrice(enrollment, activity))}
        {' SEK'}
      </EnrollmentPricePaid>
    )
  }

  return null
}

const EnrollmentPriceNotApproved = styled.p`
  color: #999;
  margin: 0;
`

const EnrollmentPricePending = styled(Box)`
  background: rgba(255, 255, 255, 0.16);
  padding: 4px 8px;
  border-radius: 4px;
`

const EnrollmentPricePaid = styled(Box)`
  background: rgb(29, 179, 32);
  padding: 4px 8px;
  border-radius: 4px;
`

export default PaymentStatus
