/**
 * Calculates the optimal position for a modal based on the anchor element
 * and adjusts for mobile screens or screen boundaries.
 *
 * @param anchorEl The element to position relative to
 * @param modalWidth The width of the modal
 * @returns Position object with top and left coordinates
 */
export const calculateModalPosition = (anchorEl: HTMLElement | null, modalWidth: number = 280) => {
  // Default centered position for mobile or when no anchor
  if (!anchorEl || window.innerWidth <= 768) {
    return {
      top: window.innerHeight / 2 - 125, // Center vertically
      left: window.innerWidth / 2 - modalWidth / 2 // Center horizontally
    }
  }

  // Get the position of the anchor element
  const menuPosition = anchorEl.getBoundingClientRect()

  // Position to the right of the anchor by default
  let position = {
    top: menuPosition.top,
    left: menuPosition.right + 10
  }

  // If it would go off-screen to the right, position to the left instead
  if (menuPosition.right + modalWidth + 10 > window.innerWidth) {
    position.left = menuPosition.left - modalWidth - 10

    // If it would now go off-screen to the left, center it
    if (position.left < 10) {
      position.left = window.innerWidth / 2 - modalWidth / 2
    }
  }

  // Ensure it doesn't go off-screen vertically
  const modalHeight = 250 // Approximate height
  if (position.top + modalHeight > window.innerHeight) {
    position.top = window.innerHeight - modalHeight - 20
  }

  return position
}
