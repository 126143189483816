import React from 'react'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { QrCodeScanner } from '@mui/icons-material'

const QRButton: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    const currentLocation = window.location.pathname
    navigate(RoutePath.BAR_CODE_SCANNER, {
      state: { returnUrl: currentLocation }
    })
  }

  return (
    <StyledButton onClick={handleClick} aria-label="Scan QR Code">
      <QrCodeScanner />
    </StyledButton>
  )
}

const StyledButton = styled('button')(({ theme }) => ({
  position: 'fixed',
  bottom: '10px',
  right: '10px',
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  background: '#007AFF',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  zIndex: 1000,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)'
  }
}))

export default QRButton
