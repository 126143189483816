import React from 'react'
import { Button, Typography, FormControlLabel, FormGroup } from '@mui/material'
import Box from '../Box'
import { styled } from '@mui/material/styles'
import { Checkbox } from '@mui/material'
import { formatText, getFigmaText } from '../../libs/TextRepository'
import { Texts } from '../../../../pure-js/libs/Texts'
import { GetEmailRecipientsForActivitiesResponseItem } from '../../../../pure-js/types/QueryParamTypes'
import toast from 'react-hot-toast'
import { CustomTextField } from '../../styles/AdminPageStyles'

const RecipientList = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
`

const SendMailToMe = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
`

const StyledFormControl = styled(FormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: white;
  }
`

const WhiteCheckbox = styled(Checkbox)({
  fill: '#000'
})

interface RecipientSectionProps {
  recipients: GetEmailRecipientsForActivitiesResponseItem[]
  recipientsSelected: string[]
  willSendCopyToMe: boolean
  sendCopyEmail: string
  isFormSubmiting: boolean
  handlePreviewForUser: (id: string) => void
  handleSendCopyToMe: () => void
  setSendCopyEmail: (email: string) => void
  handleFormSubmitted: () => Promise<void>
  customSort: (a: string, b: string) => number
}

const RecipientSection: React.FC<RecipientSectionProps> = ({
  recipients,
  recipientsSelected,
  willSendCopyToMe,
  sendCopyEmail,
  isFormSubmiting,
  handlePreviewForUser,
  handleSendCopyToMe,
  setSendCopyEmail,
  handleFormSubmitted,
  customSort
}) => {
  const copyAllEmailtoClipboard = (): void => {
    const selectedEmails = recipients
      .filter((recipient) => recipientsSelected.includes(recipient.id))
      .map((recipient) => recipient.email)
      .join('; ')

    navigator.clipboard
      .writeText(selectedEmails)
      .then(() => {
        toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageCopyToClipboard))
      })
      .catch((err) => {
        console.error('Failed to copy emails:', err)
        toast.error('Ett fel inträffade')
      })
  }

  return (
    <>
      {recipientsSelected.length == 0 ? (
        <Typography variant="body1" sx={{ color: 'grey' }}>
          {getFigmaText(Texts.adminGeneralPagesEmailCommunicationEmptyStateNoRecipients)}
        </Typography>
      ) : (
        <FormGroup>
          <Box fullWidth gap="10px" direction="column">
            <RecipientList spacing="10px" bottom fullWidth>
              {recipientsSelected.sort(customSort).map((rid) => {
                const recipient = recipients.find((r) => r.id === rid)
                return (
                  recipient && (
                    <Typography
                      variant="body2"
                      color="white"
                      key={rid}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ':hover': { cursor: 'pointer', fontWeight: 'bold' }
                      }}
                      onClick={() => handlePreviewForUser(recipient.id)}
                    >
                      • {recipient.name + ' - ' + recipient.email}
                    </Typography>
                  )
                )
              })}
            </RecipientList>
            <SendMailToMe direction="column" align="flex-start" fullWidth top>
              <StyledFormControl
                control={<WhiteCheckbox checked={willSendCopyToMe} onClick={handleSendCopyToMe}></WhiteCheckbox>}
                label={`${getFigmaText(Texts.adminGeneralPagesEmailCommunicationAlsoSendToMe)}`}
              />
              <CustomTextField value={sendCopyEmail} onChange={(e) => setSendCopyEmail(e.target.value)} />
            </SendMailToMe>
          </Box>
          <Box direction="row" fullWidth justify="space-between" align="center" top>
            <Box direction="row" gap="16px">
              <Button
                id="confirmAndSend"
                variant="contained"
                onClick={handleFormSubmitted}
                disabled={recipientsSelected.length == 0 || isFormSubmiting}
              >
                {getFigmaText(Texts.registrationCtactaConfirmAndSendEmails)}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={copyAllEmailtoClipboard}
                disabled={recipientsSelected.length == 0}
              >
                {getFigmaText(Texts.adminGeneralCtactaCopyEmailToClipboard)}
              </Button>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ opacity: 0.7, ml: 2 }}>
                {formatText(getFigmaText(Texts.adminGeneralPagesEmailCommunicationRecepientCountLabel), [
                  recipientsSelected.length.toString()
                ])}
              </Typography>
            </Box>
          </Box>
        </FormGroup>
      )}
    </>
  )
}

export default RecipientSection
