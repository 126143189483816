import { SlackChannels } from '../../../pure-js/enums/SlackChannels.js'
import { slack } from '../SlackHelper.js'

export const error = async (error: Error, user) => {
  const pageUrl = window.location.href
  const userString = user ? `${user.uid} ${user.email}` : 'user not logged in'
  const clientId = localStorage.getItem('selectedClientId')
  await slack(
    `page: ${pageUrl}
user: ${userString}
client: ${clientId}
${error.name} ${error.message} ${error.stack}`,
    SlackChannels.OPS_ERRORS
  )
}
