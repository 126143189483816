import * as XLSX from 'xlsx'

/**
 * Generic function to export data to Excel
 * @param data Array of objects to export
 * @param columns Configuration for columns to include in the export
 * @param filename The name of the exported file (without extension)
 */
export const exportToExcel = <T extends Record<string, any>>(
  data: T[],
  columns: {
    key: string
    header: string
    getValue: (item: T) => string | number
  }[],
  filename: string = 'export'
): void => {
  // Transform data according to column configuration
  const worksheetData = data.map((item) => {
    const row: Record<string, any> = {}
    columns.forEach((column) => {
      row[column.header] = column.getValue(item)
    })
    return row
  })

  // Create worksheet from data
  const worksheet = XLSX.utils.json_to_sheet(worksheetData)

  // Create workbook and add worksheet
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')

  // Write to file and trigger download
  XLSX.writeFile(workbook, `${filename}.xlsx`)
}
