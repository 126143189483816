import { ListItem } from '@mui/material'
import { CustomList, MenuItemType, SidebarIcon, SidebarText } from '../utils/sidebarHelper'
import { Link } from 'react-router-dom'

export const SidebarDesktopMenuList: React.FC<{
  menuItems: MenuItemType[]
  isOpen: boolean
  currentPath: string
}> = ({ menuItems, isOpen, currentPath }) => (
  <CustomList>
    {menuItems.map((item, index) => (
      <ListItem
        key={`${item.text}-${index}`}
        component={Link}
        to={item.path}
        sx={{
          minHeight: 48,
          justifyContent: isOpen ? 'initial' : 'center',
          px: 2.5,
          backgroundColor: currentPath === item.path ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          borderRight: currentPath === item.path ? '3px solid #8b5cf6' : 'none',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
          },
          transition: 'background-color 0.2s'
        }}
      >
        <SidebarIcon sx={{ mr: isOpen ? 3 : 'auto' }}>{item.icon}</SidebarIcon>
        {isOpen && <SidebarText primary={item.text} />}
      </ListItem>
    ))}
  </CustomList>
)
