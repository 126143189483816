import React, { useEffect, useState } from 'react'
import { MainContainer, PageContainer } from '../styles/AdminPageStyles'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import useAppState from '../hooks/useAppState'
import { Enrollment } from '../../../pure-js/types/GrooverTypes'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material'
import { hasAccess } from '../libs/SignInHelper'
import DashboardCard from '../components/DashboardCard'
import moneyIcon from '../resources/images/Money.png'
import { getFigmaText } from '../libs/TextRepository'
import { useNavigate } from 'react-router-dom'
import { useClient } from '../context/ClientContext'
import { exportToExcel as exportToExcelUtil } from '../libs/ExportUtils'
import { useEnrollmentsForClient } from '../hooks/useEnrollmentsForClient'
import { calculateEnrollmentVATTotal } from '../../../pure-js/libs/EnrollmentHelper'
import {
  calculateColumnTotals,
  FinancialSummary,
  formatNumber,
  PaymentMethodFilter,
  PaymentStatusFilter,
  ProcessedEnrollment,
  TableColumn,
  TableColumnId,
  PeriodFilter,
  getDefaultDates,
  getLastMonthDates,
  formatDisplayDate,
  isDateInRange
} from '../utils/financialHelper'

const TABLE_CONFIG: Record<TableColumnId, TableColumn> = {
  [TableColumnId.CreatedDate]: {
    id: TableColumnId.CreatedDate,
    width: '100px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderDateOfRegistration),
    getValue: (e) => e.createdDate
  },
  [TableColumnId.PaymentDate]: {
    id: TableColumnId.PaymentDate,
    width: '80px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderDateOfPayment),
    getValue: (e) => e.paymentDate
  },
  [TableColumnId.Participant]: {
    id: TableColumnId.Participant,
    width: '150px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderAttendee),
    getValue: (e) => e.participant
  },
  [TableColumnId.Amount]: {
    id: TableColumnId.Amount,
    width: '60px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderAmount),
    getValue: (e) => e.amount.toFixed(2)
  },
  [TableColumnId.VAT]: {
    id: TableColumnId.VAT,
    width: '60px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVat),
    getValue: (e) => e.vat.toFixed(2)
  },
  [TableColumnId.PaymentMethod]: {
    id: TableColumnId.PaymentMethod,
    width: '100px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderPaymentMethod),
    getValue: (e) => e.paymentMethod
  },
  [TableColumnId.PaymentOperator]: {
    id: TableColumnId.PaymentOperator,
    width: '120px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentOperator),
    getValue: (e) => e.paymentOperator || '-'
  },
  [TableColumnId.ActivityNames]: {
    id: TableColumnId.ActivityNames,
    width: '300px',
    label: getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderActivitites),
    getValue: (e) => e.activityNames
  }
}

const COLUMN_ORDER = Object.values(TableColumnId)

// Add this constant to identify value columns that should be right-aligned
const VALUE_COLUMNS = [TableColumnId.Amount, TableColumnId.VAT]

// Local storage helper functions
const STORAGE_PREFIX = 'financials_filters_'
const saveToLocalStorage = <T,>(key: string, value: T): void => {
  try {
    localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(value))
  } catch (error) {
    console.error(`Error saving ${key} to localStorage:`, error)
  }
}

const loadFromLocalStorage = <T,>(key: string, defaultValue: T): T => {
  try {
    const storedValue = localStorage.getItem(`${STORAGE_PREFIX}${key}`)
    return storedValue ? JSON.parse(storedValue) : defaultValue
  } catch (error) {
    console.error(`Error loading ${key} from localStorage:`, error)
    return defaultValue
  }
}

const FinancialsPage: React.FC = () => {
  const { state } = useAppState()
  const { clientId: contextClientId, reloadTrigger } = useClient()
  const [clientId, setClientId] = useState<string>(() => localStorage.getItem('selectedClientId') || '')
  const [processedEnrollments, setProcessedEnrollments] = useState<ProcessedEnrollment[]>([])
  const [allEnrollments, setAllEnrollments] = useState<ProcessedEnrollment[]>([]) // Store all enrollments
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [financialSummary, setFinancialSummary] = useState<FinancialSummary>({
    totalAmount: 0,
    totalVAT: 0,
    paymentMethods: {
      swish: { count: 0, amount: 0 },
      manual: { count: 0, amount: 0 },
      other: { count: 0, amount: 0 }
    }
  })

  // Separate period states for creation date and payment date - load from localStorage
  const [creationDatePeriod, setCreationDatePeriod] = useState<PeriodFilter>(
    loadFromLocalStorage('creationDatePeriod', PeriodFilter.ThisMonth)
  )
  const [paymentDatePeriod, setPaymentDatePeriod] = useState<PeriodFilter>(
    loadFromLocalStorage('paymentDatePeriod', PeriodFilter.AllTime)
  )

  const [paymentStatusFilter, setPaymentStatusFilter] = useState<PaymentStatusFilter>(
    loadFromLocalStorage('paymentStatusFilter', PaymentStatusFilter.All)
  )
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<PaymentMethodFilter>(
    loadFromLocalStorage('paymentMethodFilter', PaymentMethodFilter.All)
  )
  const [uniquePaymentOperators, setUniquePaymentOperators] = useState<string[]>([])
  const [paymentOperatorFilter, setPaymentOperatorFilter] = useState<string>(
    loadFromLocalStorage('paymentOperatorFilter', 'all')
  )
  const navigate = useNavigate()

  const _enrollments = useEnrollmentsForClient(contextClientId)

  // Add state for column totals
  const [columnTotals, setColumnTotals] = useState<Record<TableColumnId, number | string>>({
    [TableColumnId.CreatedDate]: '',
    [TableColumnId.PaymentDate]: '',
    [TableColumnId.Participant]: '',
    [TableColumnId.Amount]: 0,
    [TableColumnId.VAT]: 0,
    [TableColumnId.PaymentMethod]: '',
    [TableColumnId.PaymentOperator]: '',
    [TableColumnId.ActivityNames]: ''
  })

  // Get default dates using the shared utility
  const defaultDates = getDefaultDates()

  // Creation date filters - load from localStorage
  const [creationStartDate, setCreationStartDate] = useState<string>(
    loadFromLocalStorage('creationStartDate', defaultDates.start)
  )
  const [creationEndDate, setCreationEndDate] = useState<string>(
    loadFromLocalStorage('creationEndDate', defaultDates.end)
  )

  // Payment date filters - load from localStorage
  const [paymentStartDate, setPaymentStartDate] = useState<string>(
    loadFromLocalStorage('paymentStartDate', defaultDates.start)
  )
  const [paymentEndDate, setPaymentEndDate] = useState<string>(loadFromLocalStorage('paymentEndDate', defaultDates.end))

  // Save filter states to localStorage whenever they change
  useEffect(() => {
    saveToLocalStorage('creationDatePeriod', creationDatePeriod)
  }, [creationDatePeriod])

  useEffect(() => {
    saveToLocalStorage('paymentDatePeriod', paymentDatePeriod)
  }, [paymentDatePeriod])

  useEffect(() => {
    saveToLocalStorage('paymentStatusFilter', paymentStatusFilter)
  }, [paymentStatusFilter])

  useEffect(() => {
    saveToLocalStorage('paymentMethodFilter', paymentMethodFilter)
  }, [paymentMethodFilter])

  useEffect(() => {
    saveToLocalStorage('paymentOperatorFilter', paymentOperatorFilter)
  }, [paymentOperatorFilter])

  useEffect(() => {
    saveToLocalStorage('creationStartDate', creationStartDate)
  }, [creationStartDate])

  useEffect(() => {
    saveToLocalStorage('creationEndDate', creationEndDate)
  }, [creationEndDate])

  useEffect(() => {
    saveToLocalStorage('paymentStartDate', paymentStartDate)
  }, [paymentStartDate])

  useEffect(() => {
    saveToLocalStorage('paymentEndDate', paymentEndDate)
  }, [paymentEndDate])

  // Use the shared formatDisplayDate function for date formatting
  const processEnrollmentData = (enrollments: Enrollment[]): ProcessedEnrollment[] => {
    const processedData = enrollments
      // Filter out deleted enrollments
      .filter((enrollment) => enrollment.status !== 'deleted')
      .map((enrollment) => {
        const lastPayment =
          enrollment.payments && enrollment.payments.length > 0
            ? enrollment.payments[enrollment.payments.length - 1]
            : null
        const participantCount = enrollment.partnerUser ? 2 : 1
        const totalActivities = (enrollment.activityIds?.length || 0) * participantCount

        let amount = 0
        const isPaid = enrollment.paymentStatus === 'paid'

        // Calculate amount based on payment status
        if (isPaid && lastPayment) {
          // For paid enrollments, use the payment amount
          amount = Number(((lastPayment.totalAmount || 0) / 100).toFixed(2))
        } else {
          // For unpaid enrollments, calculate from priceDetails
          if (enrollment.priceDetails && enrollment.priceDetails.length > 0) {
            amount = enrollment.priceDetails.reduce((sum, detail) => {
              return sum + (detail.finalPrice || 0) / 100
            }, 0)
          }
        }

        // Format dates consistently using our shared formatter
        const paymentDate = lastPayment?.paymentDate || '-'
        const formattedPaymentDate = formatDisplayDate(paymentDate)
        const createdDate = enrollment.createdAt || '-'
        const formattedCreatedDate = formatDisplayDate(createdDate)

        // Get participant names safely
        const getParticipantNames = (enrollment: Enrollment) => {
          const names: string[] = []

          if (enrollment.mainUser?.name) {
            names.push(enrollment.mainUser.name)
          }

          if (enrollment.partnerUser?.name) {
            names.push(enrollment.partnerUser.name)
          }

          return names.join('\n') || '-'
        }

        // Get activity names
        const getActivityNames = (enrollment: Enrollment) => {
          if (!enrollment.activities || enrollment.activities.length === 0) {
            return '-'
          }

          // Filter out activities without names and get unique activity names
          const uniqueActivityNames = [
            ...new Set(
              enrollment.activities
                .filter((activity) => activity.name && activity.name.trim() !== '')
                .map((activity) => activity.name)
            )
          ]

          return uniqueActivityNames.join('\n') || '-'
        }

        return {
          id: enrollment.id,
          createdDate: formattedCreatedDate,
          originalCreatedDate: createdDate,
          paymentDate: formattedPaymentDate,
          originalPaymentDate: paymentDate,
          participant: getParticipantNames(enrollment),
          amount: amount,
          vat: calculateEnrollmentVATTotal(enrollment),
          paymentMethod: lastPayment?.paymentMethod || 'Unpaid',
          paymentOperator: lastPayment?.paymentOperator || '-',
          activities: totalActivities,
          activityNames: getActivityNames(enrollment),
          reference: lastPayment?.paymentRef || '-',
          paymentStatus: enrollment.paymentStatus || 'unpaid'
        }
      })
      // Filter out enrollments with zero amounts
      .filter((enrollment) => enrollment.amount > 0)
      .sort((a, b) => {
        // Sort by creation date, newest first
        const dateA = a.originalCreatedDate === '-' ? 0 : new Date(a.originalCreatedDate).getTime()
        const dateB = b.originalCreatedDate === '-' ? 0 : new Date(b.originalCreatedDate).getTime()
        return dateB - dateA
      })

    // Extract unique payment operators from the processed data
    const operators = Array.from(
      new Set(
        processedData.map((enrollment) => enrollment.paymentOperator).filter((operator) => operator && operator !== '-')
      )
    ) as string[]

    // Sort operators alphabetically
    operators.sort()

    // Set the unique payment operators
    setUniquePaymentOperators(operators)

    return processedData
  }

  // Updated to filter by creation date, payment date, payment status, payment method, and payment operator
  const filterEnrollments = () => {
    let filtered = [...allEnrollments]

    // Apply payment status filter
    if (paymentStatusFilter !== PaymentStatusFilter.All) {
      filtered = filtered.filter((enrollment) => enrollment.paymentStatus === paymentStatusFilter)
    }

    // Apply payment method filter
    if (paymentMethodFilter !== PaymentMethodFilter.All) {
      if (paymentMethodFilter === PaymentMethodFilter.Swish) {
        filtered = filtered.filter((enrollment) => enrollment.paymentMethod.toLowerCase().includes('swish'))
      } else if (paymentMethodFilter === PaymentMethodFilter.Manual) {
        filtered = filtered.filter(
          (enrollment) =>
            enrollment.paymentMethod.toLowerCase().includes('manual') ||
            enrollment.paymentMethod.toLowerCase().includes('bank') ||
            enrollment.paymentMethod.toLowerCase().includes('invoice') ||
            enrollment.paymentMethod.toLowerCase() === 'unpaid' // Include 'Unpaid' in Manual/Bank category
        )
      } else if (paymentMethodFilter === PaymentMethodFilter.Other) {
        filtered = filtered.filter(
          (enrollment) =>
            !enrollment.paymentMethod.toLowerCase().includes('swish') &&
            !enrollment.paymentMethod.toLowerCase().includes('manual') &&
            !enrollment.paymentMethod.toLowerCase().includes('bank') &&
            !enrollment.paymentMethod.toLowerCase().includes('invoice') &&
            enrollment.paymentMethod.toLowerCase() !== 'unpaid' // Exclude 'Unpaid' from Other category
        )
      }
    }

    // Apply payment operator filter - using the actual operator value
    if (paymentOperatorFilter !== 'all') {
      if (paymentOperatorFilter === 'none') {
        filtered = filtered.filter((enrollment) => !enrollment.paymentOperator || enrollment.paymentOperator === '-')
      } else {
        filtered = filtered.filter((enrollment) => enrollment.paymentOperator === paymentOperatorFilter)
      }
    }

    // Apply creation date range filter using the shared utility
    if (creationStartDate || creationEndDate) {
      filtered = filtered.filter((enrollment) =>
        isDateInRange(enrollment.originalCreatedDate, creationStartDate, creationEndDate)
      )
    }

    // Apply payment date range filter using the shared utility
    if (paymentStartDate || paymentEndDate) {
      filtered = filtered.filter((enrollment) => {
        // If payment date is null, undefined, or '-', keep the enrollment
        if (!enrollment.originalPaymentDate || enrollment.originalPaymentDate === '-') {
          return true
        }

        // For enrollments with valid payment dates, use the shared date range checker
        return isDateInRange(enrollment.originalPaymentDate, paymentStartDate, paymentEndDate)
      })
    }

    setProcessedEnrollments(filtered)
  }

  const exportToExcel = () => {
    const columns = COLUMN_ORDER.map((columnId) => {
      const column = TABLE_CONFIG[columnId]
      return {
        key: columnId,
        header: column.label,
        getValue: (enrollment: ProcessedEnrollment) => {
          // For amount and VAT columns, return the raw numeric value instead of formatted string
          if (columnId === TableColumnId.Amount) {
            return enrollment.amount
          } else if (columnId === TableColumnId.VAT) {
            return enrollment.vat
          } else {
            return column.getValue(enrollment)
          }
        }
      }
    })

    exportToExcelUtil(processedEnrollments, columns, 'financial-report')
  }

  useEffect(() => {
    // Apply filters when any filter criteria changes
    if (allEnrollments.length > 0) {
      filterEnrollments()
    }
  }, [
    creationStartDate,
    creationEndDate,
    paymentStartDate,
    paymentEndDate,
    paymentStatusFilter,
    paymentMethodFilter,
    paymentOperatorFilter,
    allEnrollments
  ])

  useEffect(() => {
    setLoading(_enrollments.isLoading)
  }, [_enrollments.isLoading])

  useEffect(() => {
    if (!clientId) return
    if (!_enrollments.data || _enrollments.status !== 'success') return
    setAllEnrollments(processEnrollmentData(_enrollments.data))
    setProcessedEnrollments(processEnrollmentData(_enrollments.data))
  }, [contextClientId, _enrollments.data])

  // Reset creation date to default dates (first and last day of current month)
  const resetCreationToDefaultDates = () => {
    const { start, end } = getDefaultDates()
    setCreationStartDate(start)
    setCreationEndDate(end)
  }

  // Reset payment date filters
  const resetPaymentDates = () => {
    setPaymentStartDate('')
    setPaymentEndDate('')
  }

  // Handle creation date period selection using shared utilities
  const handleCreationPeriodChange = (event: SelectChangeEvent) => {
    const period = event.target.value as PeriodFilter
    setCreationDatePeriod(period)

    switch (period) {
      case PeriodFilter.ThisMonth:
        const thisMonth = getDefaultDates()
        setCreationStartDate(thisMonth.start)
        setCreationEndDate(thisMonth.end)
        break
      case PeriodFilter.LastMonth:
        const lastMonth = getLastMonthDates()
        setCreationStartDate(lastMonth.start)
        setCreationEndDate(lastMonth.end)
        break
      case PeriodFilter.AllTime:
        setCreationStartDate('')
        setCreationEndDate('')
        break
      case PeriodFilter.Custom:
        // Don't change dates, allow user to set them manually
        break
    }
  }

  // Handle payment date period selection using shared utilities
  const handlePaymentPeriodChange = (event: SelectChangeEvent) => {
    const period = event.target.value as PeriodFilter
    setPaymentDatePeriod(period)

    switch (period) {
      case PeriodFilter.ThisMonth:
        const thisMonth = getDefaultDates()
        setPaymentStartDate(thisMonth.start)
        setPaymentEndDate(thisMonth.end)
        break
      case PeriodFilter.LastMonth:
        const lastMonth = getLastMonthDates()
        setPaymentStartDate(lastMonth.start)
        setPaymentEndDate(lastMonth.end)
        break
      case PeriodFilter.AllTime:
        setPaymentStartDate('')
        setPaymentEndDate('')
        break
      case PeriodFilter.Custom:
        // Don't change dates, allow user to set them manually
        break
    }
  }

  // Handler for payment method filter
  const handlePaymentMethodChange = (event: SelectChangeEvent) => {
    setPaymentMethodFilter(event.target.value as PaymentMethodFilter)
  }

  // Handler for payment operator filter change
  const handlePaymentOperatorChange = (event: SelectChangeEvent) => {
    setPaymentOperatorFilter(event.target.value)
  }

  // Update creation period when creation dates change
  useEffect(() => {
    // Check if current dates match any predefined period
    const thisMonthDates = getDefaultDates()
    const lastMonthDates = getLastMonthDates()

    if (creationStartDate === thisMonthDates.start && creationEndDate === thisMonthDates.end) {
      setCreationDatePeriod(PeriodFilter.ThisMonth)
    } else if (creationStartDate === lastMonthDates.start && creationEndDate === lastMonthDates.end) {
      setCreationDatePeriod(PeriodFilter.LastMonth)
    } else if (creationStartDate === '' && creationEndDate === '') {
      setCreationDatePeriod(PeriodFilter.AllTime)
    } else {
      // If dates don't match any preset, it's a custom range
      setCreationDatePeriod(PeriodFilter.Custom)
    }
  }, [creationStartDate, creationEndDate])

  // Update payment period when payment dates change
  useEffect(() => {
    // Check if current dates match any predefined period
    const thisMonthDates = getDefaultDates()
    const lastMonthDates = getLastMonthDates()

    if (paymentStartDate === thisMonthDates.start && paymentEndDate === thisMonthDates.end) {
      setPaymentDatePeriod(PeriodFilter.ThisMonth)
    } else if (paymentStartDate === lastMonthDates.start && paymentEndDate === lastMonthDates.end) {
      setPaymentDatePeriod(PeriodFilter.LastMonth)
    } else if (paymentStartDate === '' && paymentEndDate === '') {
      setPaymentDatePeriod(PeriodFilter.AllTime)
    } else {
      // If dates don't match any preset, it's a custom range
      setPaymentDatePeriod(PeriodFilter.Custom)
    }
  }, [paymentStartDate, paymentEndDate])

  // Find the earliest and latest dates in the data (considering both created and payment dates)
  const getDateRange = (enrollments: ProcessedEnrollment[]) => {
    if (!enrollments.length) return { earliest: '', latest: '' }

    let earliest = new Date()
    let latest = new Date(0) // Jan 1, 1970

    enrollments.forEach((enrollment) => {
      // Check created date
      if (enrollment.originalCreatedDate && enrollment.originalCreatedDate !== '-') {
        const createDate = new Date(enrollment.originalCreatedDate)
        if (!isNaN(createDate.getTime())) {
          if (createDate < earliest) earliest = createDate
          if (createDate > latest) latest = createDate
        }
      }

      // Check payment date
      if (enrollment.originalPaymentDate && enrollment.originalPaymentDate !== '-') {
        const payDate = new Date(enrollment.originalPaymentDate)
        if (!isNaN(payDate.getTime())) {
          if (payDate < earliest) earliest = payDate
          if (payDate > latest) latest = payDate
        }
      }
    })

    // Format dates as YYYY-MM-DD for input[type="date"]
    const formatDate = (date: Date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    return {
      earliest: formatDate(earliest),
      latest: formatDate(latest)
    }
  }

  // Clear all filters
  const clearFilters = () => {
    if (!allEnrollments.length) {
      setCreationStartDate('')
      setCreationEndDate('')
      return
    }

    // Get the full date range
    const { earliest, latest } = getDateRange(allEnrollments)
    setCreationStartDate(earliest)
    setCreationEndDate(latest)
  }

  // Add a custom style for date inputs
  const dateInputStyle = {
    width: '200px',
    '& .MuiSvgIcon-root': { color: 'white !important' },
    '& .MuiInputAdornment-root .MuiButtonBase-root': { color: 'white !important' },
    '& svg': { color: 'white !important' },
    '& input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)' // This inverts the calendar icon color
    }
  }

  // Enhanced summary calculation to include payment methods
  const calculateFinancialSummary = (enrollments: ProcessedEnrollment[]): FinancialSummary => {
    return enrollments.reduce(
      (summary, enrollment) => {
        // Total amount and VAT calculations
        const newSummary = {
          ...summary,
          totalAmount: summary.totalAmount + enrollment.amount,
          totalVAT: summary.totalVAT + enrollment.vat
        }

        // Payment method calculations
        const paymentMethod = enrollment.paymentMethod.toLowerCase()
        if (paymentMethod.includes('swish')) {
          newSummary.paymentMethods.swish.count += 1
          newSummary.paymentMethods.swish.amount += enrollment.amount
        } else if (
          paymentMethod.includes('manual') ||
          paymentMethod.includes('bank') ||
          paymentMethod.includes('invoice') ||
          paymentMethod === 'unpaid'
        ) {
          newSummary.paymentMethods.manual.count += 1
          newSummary.paymentMethods.manual.amount += enrollment.amount
        } else {
          newSummary.paymentMethods.other.count += 1
          newSummary.paymentMethods.other.amount += enrollment.amount
        }

        return newSummary
      },
      {
        totalAmount: 0,
        totalVAT: 0,
        paymentMethods: {
          swish: { count: 0, amount: 0 },
          manual: { count: 0, amount: 0 },
          other: { count: 0, amount: 0 }
        }
      }
    )
  }

  // Update financial summary when filtered data changes
  useEffect(() => {
    const summary = calculateFinancialSummary(processedEnrollments)
    setFinancialSummary(summary)

    // Calculate column totals for the summary row
    const totals = calculateColumnTotals(processedEnrollments)
    setColumnTotals(totals)
  }, [processedEnrollments])

  // Handle payment status filter change
  const handlePaymentStatusChange = (event: SelectChangeEvent) => {
    setPaymentStatusFilter(event.target.value as PaymentStatusFilter)
  }

  // Add function to handle row click
  const handleRowClick = (enrollmentId: string) => {
    console.log(`enrollment/${enrollmentId}`)
    navigate(`/enrollment/${enrollmentId}`)
  }

  return (
    <MainContainer fullWidth>
      <PageContainer fullWidth gap="20px">
        <Box direction="row" fullWidth justify="space-between" align="center" style={{ marginBottom: '20px' }}>
          <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFinancialReportHeader} />
          <Button variant="contained" onClick={exportToExcel}>
            {getFigmaText(Texts.adminGeneralCtactaDownloadXls)}
          </Button>
        </Box>

        {/* Financial Summary Dashboard Cards */}
        <Box direction="row" fullWidth gap="16px" style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
          {/* Total Amount Card */}
          <DashboardCard
            icon={<img src={moneyIcon} alt="Money Icon" />}
            title={getFigmaText(Texts.adminGeneralPagesFinancialReportKpiHeaderTotalSum)}
            value={`${formatNumber(financialSummary.totalAmount)} SEK`}
            subtitle={`${getFigmaText(Texts.adminGeneralPagesFinancialReportTableCellHeaderVat)}: ${formatNumber(financialSummary.totalVAT)}  SEK`}
          />

          {/* Swish Payment Card */}
          <DashboardCard
            icon={<img src={moneyIcon} alt="Money Icon" />}
            title={getFigmaText(Texts.adminGeneralPagesFinancialReportKpiHeaderSwishPayment)}
            value={`${formatNumber(financialSummary.paymentMethods.swish.amount)} SEK`}
            subtitle={`${financialSummary.paymentMethods.swish.count} ${getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentsDef)}`}
          />

          {/* Manual Payment Card */}
          <DashboardCard
            icon={<img src={moneyIcon} alt="Money Icon" />}
            title={getFigmaText(Texts.adminGeneralPagesFinancialReportKpiHeaderBankPayment)}
            value={`${formatNumber(financialSummary.paymentMethods.manual.amount)} SEK`}
            subtitle={`${financialSummary.paymentMethods.manual.count} ${getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentsDef)}`}
          />
        </Box>

        {/* Filter Controls - Grouped */}
        <Box direction="column" gap="16px" style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
          {/* Creation Date Filter Group */}
          <FilterSection direction="row">
            <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFilterRegistrationDateSection} />
            <FormControl>
              <InputLabel id="creation-period-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
              </InputLabel>
              <Select
                labelId="creation-period-select-label"
                id="creation-period-select"
                value={creationDatePeriod}
                onChange={handleCreationPeriodChange}
                label={getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '& .MuiSvgIcon-root': { color: 'white' }
                }}
              >
                <MenuItem value={PeriodFilter.ThisMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodThisMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.LastMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodLastMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.AllTime}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodAllPeriod)}
                </MenuItem>
                <MenuItem value={PeriodFilter.Custom}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodCustom)}
                </MenuItem>
              </Select>
            </FormControl>
            {creationDatePeriod !== PeriodFilter.AllTime && (
              <>
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialStartDate)}
                  type="date"
                  value={creationStartDate}
                  onChange={(e) => {
                    setCreationStartDate(e.target.value)
                    setCreationDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialEndDate)}
                  type="date"
                  value={creationEndDate}
                  onChange={(e) => {
                    setCreationEndDate(e.target.value)
                    setCreationDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
              </>
            )}
          </FilterSection>

          {/* Payment Date Filter Group */}
          <FilterSection direction="row">
            <FigmaText textKey={Texts.adminGeneralPagesFinancialReportFilterPaymentDateSection} />
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel id="payment-period-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
              </InputLabel>
              <Select
                labelId="payment-period-select-label"
                id="payment-period-select"
                value={paymentDatePeriod}
                onChange={handlePaymentPeriodChange}
                label={getFigmaText(Texts.adminGeneralInputFieldsFinancialPeriod)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '& .MuiSvgIcon-root': { color: 'white' }
                }}
              >
                <MenuItem value={PeriodFilter.ThisMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodThisMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.LastMonth}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodLastMonth)}
                </MenuItem>
                <MenuItem value={PeriodFilter.AllTime}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodAllPeriod)}
                </MenuItem>
                <MenuItem value={PeriodFilter.Custom}>
                  {getFigmaText(Texts.adminGeneralEnumsPeriodPeriodCustom)}
                </MenuItem>
              </Select>
            </FormControl>

            {paymentDatePeriod !== PeriodFilter.AllTime && (
              <>
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialStartDate)}
                  type="date"
                  value={paymentStartDate}
                  onChange={(e) => {
                    setPaymentStartDate(e.target.value)
                    setPaymentDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
                <TextField
                  label={getFigmaText(Texts.adminGeneralInputFieldsFinancialEndDate)}
                  type="date"
                  value={paymentEndDate}
                  onChange={(e) => {
                    setPaymentEndDate(e.target.value)
                    setPaymentDatePeriod(PeriodFilter.Custom)
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={dateInputStyle}
                />
              </>
            )}
          </FilterSection>

          {/* Status Filters Group */}
          <FilterSection>
            <Box direction="row" gap="12px" style={{ flexWrap: 'wrap', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="payment-status-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                </InputLabel>
                <Select
                  labelId="payment-status-select-label"
                  id="payment-status-select"
                  value={paymentStatusFilter}
                  onChange={handlePaymentStatusChange}
                  label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }}
                >
                  <MenuItem value={PaymentStatusFilter.Paid}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)}
                  </MenuItem>
                  <MenuItem value={PaymentStatusFilter.Unpaid}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)}
                  </MenuItem>
                  <MenuItem value={PaymentStatusFilter.All}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateBoth)}
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="payment-method-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentMethodSelectLabel)}
                </InputLabel>
                <Select
                  labelId="payment-method-select-label"
                  id="payment-method-select"
                  value={paymentMethodFilter}
                  onChange={handlePaymentMethodChange}
                  label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentMethodSelectLabel)}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }}
                >
                  <MenuItem value={PaymentMethodFilter.All}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentAll)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Swish}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentMethodSwish)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Manual}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentMethodBank)}
                  </MenuItem>
                  <MenuItem value={PaymentMethodFilter.Other}>
                    {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentOther)}
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Add dynamic Payment Operator Filter */}
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="payment-operator-select-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentOperator) || 'Payment Operator'}
                </InputLabel>
                <Select
                  labelId="payment-operator-select-label"
                  id="payment-operator-select"
                  value={paymentOperatorFilter}
                  onChange={handlePaymentOperatorChange}
                  label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentOperator) || 'Payment Operator'}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }}
                >
                  <MenuItem value="all">{getFigmaText(Texts.adminGeneralEnumsPaymentPaymentAll) || 'All'}</MenuItem>
                  <MenuItem value="none">{'None'}</MenuItem>
                  {uniquePaymentOperators.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                      {operator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </FilterSection>
        </Box>

        {/* Table section continues with the existing code */}
        {!hasAccess(state) ? (
          <Box fullWidth align="center">
            <Alert severity="error">No access</Alert>
          </Box>
        ) : loading ? (
          <Box fullWidth align="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box fullWidth align="center">
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : (
          <TableContainer component={StyledPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  {COLUMN_ORDER.map((columnId) => (
                    <TableCell
                      key={columnId}
                      style={{ width: TABLE_CONFIG[columnId].width }}
                      align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}
                    >
                      {TABLE_CONFIG[columnId].label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {processedEnrollments.map((enrollment) => (
                  <TableRow
                    key={enrollment.id}
                    onClick={() => handleRowClick(enrollment.id)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                      transition: 'background-color 0.2s',
                      backgroundColor: enrollment.paymentStatus === 'unpaid' ? 'rgba(255, 152, 0, 0.1)' : 'transparent'
                    }}
                  >
                    {COLUMN_ORDER.map((columnId) => (
                      <TableCell key={columnId} align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}>
                        {columnId === TableColumnId.ActivityNames || columnId === TableColumnId.Participant ? (
                          // Handle line breaks by splitting on \n and adding <br> tags
                          <React.Fragment>
                            {String(TABLE_CONFIG[columnId].getValue(enrollment))
                              .split('\n')
                              .map((line, i, arr) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < arr.length - 1 && <br />}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        ) : (
                          TABLE_CONFIG[columnId].getValue(enrollment)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                {/* Add Summary Row */}
                {processedEnrollments.length > 0 && (
                  <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', fontWeight: 'bold' }}>
                    {COLUMN_ORDER.map((columnId) => (
                      <TableCell
                        key={`total-${columnId}`}
                        align={VALUE_COLUMNS.includes(columnId) ? 'right' : 'left'}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '0.9rem'
                        }}
                      >
                        {columnId === TableColumnId.Participant
                          ? getFigmaText(Texts.adminGeneralPagesFinancialReportTableTotalRowHeader) || 'TOTAL'
                          : columnId === TableColumnId.Amount || columnId === TableColumnId.VAT
                            ? formatNumber(Number(columnTotals[columnId]))
                            : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                {processedEnrollments.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={COLUMN_ORDER.length} align="center">
                      {getFigmaText(Texts.adminGeneralPagesFinancialReportEmptyState)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PageContainer>
    </MainContainer>
  )
}

const FilterSection = styled(Box)({
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  alignItems: 'center'
})

const StyledPaper = styled(Paper)({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  '& .MuiTableCell-root': {
    color: 'white',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  }
})

export default FinancialsPage
