import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  IconButton,
  Menu,
  Tooltip,
  Badge
} from '@mui/material'
import {
  Edit,
  Link,
  OpenInNew,
  ControlPointDuplicate,
  MoreVert,
  FilterList,
  ArrowRight,
  ViewColumn
} from '@mui/icons-material'
import styled from '@emotion/styled'
import { Activity, ActivityStatus, Client, Enrollment } from '../../../pure-js/types/GrooverTypes'
import {
  getAllEnrollmentsCount,
  getParticipantTypeChartDataForActivity,
  getPendingEnrollmentCountForActivity
} from '../utils/enrollmentUtils'
import StatusChip from './StatusChip'
import { handleRegistrationClick } from '../libs/ActivityListHelper'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import ButtonBlack from './ButtonBlack'
import toast from 'react-hot-toast'
import { upsertActivity } from '../libs/DBApiHandler'
import {
  createNewActivity,
  getAvailableStatusOptions,
  getLocalizedActivityType,
  getStartDateAndTime,
  getStatusTranslation
} from '../utils/DashboardUtils'
import Box from './Box'
import { CustomSelectField, StyledCheckbox } from '../styles/AdminPageStyles'
import CompactParticipantLineChart from './CompactParticipantLineChart'
import FigmaText from './FigmaText'

interface ActivityListV2Props {
  activities: Activity[]
  activitiesLoaded: boolean
  enrollments: Enrollment[]
  clientData: Client | null
}

interface ColumnConfig {
  id: string
  label: string
  minWidth?: number
  align?: 'right' | 'left' | 'center'
  format?: (value: any) => string
  optional?: boolean
  defaultVisible?: boolean
}

interface FilterOption {
  id: string
  label: string
  getAvailableOptions: (activities: Activity[], clientData: Client | null) => { value: string; label: string }[]
  getStorageKey: () => string
  filterPredicate: (activity: Activity, selectedValues: string[]) => boolean
}

const defaultColumns: ColumnConfig[] = [
  { id: 'name', label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderName), minWidth: 200 },
  { id: 'status', label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderStatus), minWidth: 100 },
  {
    id: 'participantChart',
    label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderStartEnrollments),
    minWidth: 200,
    align: 'left'
  },
  { id: 'queue', label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderQueue), minWidth: 60 },
  {
    id: 'type',
    label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderType),
    minWidth: 100,
    optional: true,
    defaultVisible: true
  },
  {
    id: 'city',
    label: getFigmaText(Texts.adminGeneralInputFieldsActivityCity),
    minWidth: 100,
    optional: true,
    defaultVisible: false
  },
  {
    id: 'startDate',
    label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderStartDateTime),
    minWidth: 140
  },
  {
    id: 'actions',
    label: getFigmaText(Texts.adminGeneralPagesDashboardActivityListHeaderStartOperations),
    minWidth: 80,
    align: 'right'
  }
]

const ActivityListV2: React.FC<ActivityListV2Props> = ({ activities, activitiesLoaded, enrollments, clientData }) => {
  const [filterText, setFilterText] = useState(() => {
    return localStorage.getItem('activityFilterText') || ''
  })
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>(activities)
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState<ColumnConfig[]>(() => {
    const savedColumnVisibility = localStorage.getItem('activityColumnsVisibility')
    let initialColumns = [...defaultColumns]

    if (savedColumnVisibility) {
      const savedSettings = JSON.parse(savedColumnVisibility)
      initialColumns = initialColumns.map((col) => {
        if (col.optional && savedSettings.hasOwnProperty(col.id)) {
          return { ...col, defaultVisible: savedSettings[col.id] }
        }
        return col
      })
    }

    return initialColumns
  })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
    return columns.filter((col) => !col.optional || col.defaultVisible).map((col) => col.id)
  })
  const [statusFilter, setStatusFilter] = useState<string[]>(() => {
    const savedFilter = localStorage.getItem('activityStatusFilter')
    return savedFilter ? JSON.parse(savedFilter) : [ActivityStatus.Active]
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null)
  const [isDuplicating, setIsDuplicating] = useState(false)

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [activeFilterOption, setActiveFilterOption] = useState<string | null>(null)

  const [filters, setFilters] = useState<Record<string, string[]>>(() => {
    return {
      city: JSON.parse(localStorage.getItem('activityCityFilters') || '[]'),
      type: JSON.parse(localStorage.getItem('activityTypeFilters') || '[]')
    }
  })

  const [columnMenuAnchorEl, setColumnMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth)

  const filterOptions: FilterOption[] = useMemo(
    () => [
      {
        id: 'city',
        label: getFigmaText(Texts.adminGeneralInputFieldsActivityCity),
        getAvailableOptions: (activities) => {
          const cities = activities
            .filter((activity) => activity.city)
            .map((activity) => activity.city)
            .filter((city, index, self) => city && self.indexOf(city) === index) as string[]
          return cities.map((city) => ({ value: city, label: city }))
        },
        getStorageKey: () => 'activityCityFilters',
        filterPredicate: (activity, selectedValues) =>
          selectedValues.length === 0 || (!!activity.city && selectedValues.includes(activity.city))
      },
      {
        id: 'type',
        label: getFigmaText(Texts.adminGeneralInputFieldsActivityType),
        getAvailableOptions: (activities, clientData) => {
          const types = activities
            .map((activity) => activity.type)
            .filter((type, index, self) => type && self.indexOf(type) === index)
          return types.map((type) => ({
            value: type,
            label: getLocalizedActivityType(type, clientData)
          }))
        },
        getStorageKey: () => 'activityTypeFilters',
        filterPredicate: (activity, selectedValues) =>
          selectedValues.length === 0 || selectedValues.includes(activity.type)
      }
    ],
    []
  )

  useEffect(() => {
    localStorage.setItem('activityFilterText', filterText)
  }, [filterText])

  useEffect(() => {
    localStorage.setItem('activityStatusFilter', JSON.stringify(statusFilter))
  }, [statusFilter])

  useEffect(() => {
    Object.entries(filters).forEach(([key, value]) => {
      const option = filterOptions.find((opt) => opt.id === key)
      if (option) {
        localStorage.setItem(option.getStorageKey(), JSON.stringify(value))
      }
    })
  }, [filters, filterOptions])

  useEffect(() => {
    const visibilitySettings = columns.reduce(
      (acc, col) => {
        if (col.optional) {
          acc[col.id] = visibleColumns.includes(col.id)
        }
        return acc
      },
      {} as Record<string, boolean>
    )

    localStorage.setItem('activityColumnsVisibility', JSON.stringify(visibilitySettings))
  }, [visibleColumns, columns])

  const debouncedFilter = useCallback(
    debounce((text: string, statusFilters: string[], filterValues: Record<string, string[]>) => {
      let filtered = activities

      if (text) {
        filtered = filtered.filter((activity) => activity.name.toLowerCase().includes(text.toLowerCase()))
      }

      if (statusFilters.length > 0) {
        filtered = filtered.filter((activity) => statusFilters.includes(activity.status))
      }

      filterOptions.forEach((option) => {
        const selectedValues = filterValues[option.id] || []
        if (selectedValues.length > 0) {
          filtered = filtered.filter((activity) => option.filterPredicate(activity, selectedValues))
        }
      })

      setFilteredActivities(filtered)
      setLoading(false)
    }, 300),
    [activities, filterOptions]
  )

  useEffect(() => {
    setLoading(true)
    if (activitiesLoaded) {
      debouncedFilter(filterText, statusFilter, filters)
    }
  }, [filterText, statusFilter, filters, activities, activitiesLoaded, debouncedFilter])

  const handleFilterToggle = (filterId: string, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[filterId] || []
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value]

      return { ...prev, [filterId]: newValues }
    })
  }

  const clearFilter = (filterId: string) => {
    setFilters((prev) => ({ ...prev, [filterId]: [] }))
  }

  const getTotalActiveFiltersCount = () => {
    return Object.values(filters).reduce((count, values) => count + (values.length > 0 ? 1 : 0), 0)
  }

  const navigate = useNavigate()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, activity: Activity) => {
    setAnchorEl(event.currentTarget)
    setSelectedActivity(activity)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedActivity(null)
  }

  const handleDuplicate = async () => {
    if (selectedActivity && !isDuplicating) {
      setIsDuplicating(true)
      try {
        const newActivityId = crypto.randomUUID()
        const duplicatedActivity = {
          ...selectedActivity,
          id: newActivityId,
          createdAt: new Date().toISOString(),
          name: `${selectedActivity.name} (Copy)`
        }
        await upsertActivity(duplicatedActivity)
        setFilteredActivities((prev) => [...prev, duplicatedActivity])
        toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        handleMenuClose()
        navigate(`/editActivity/${newActivityId}`)
      } catch (error) {
        toast.error(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        console.error('error duplicating activity', error)
      } finally {
        setIsDuplicating(false)
      }
    }
  }

  const copyRegistrationPage = () => {
    if (selectedActivity) {
      navigator.clipboard.writeText(`https://app.coursely.se/register/${selectedActivity.id}`)
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageCopyToClipboard))
      handleMenuClose()
    }
  }

  const openEnrollmentsPage = (e: React.MouseEvent, activity: Activity) => {
    e.stopPropagation()
    handleRegistrationClick(navigate, activity)
  }

  const statusOptions = getAvailableStatusOptions()

  const getActivityBannerUrl = (activity: Activity): string | null => {
    return activity.bannerUrl || null
  }

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuAnchorEl(event.currentTarget)
  }

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null)
    setActiveFilterOption(null)
  }

  const handleFilterOptionOpen = (option: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setActiveFilterOption(option === activeFilterOption ? null : option)
  }

  const handleColumnMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setColumnMenuAnchorEl(event.currentTarget)
  }

  const handleColumnMenuClose = () => {
    setColumnMenuAnchorEl(null)
  }

  const getResponsiveVisibleColumns = useCallback(() => {
    const essentialColumnsForSmallScreen = ['name', 'participantChart', 'actions']

    if (windowWidth < 950) {
      return essentialColumnsForSmallScreen
    } else {
      return columns.filter((col) => !col.optional || col.defaultVisible).map((col) => col.id)
    }
  }, [columns, windowWidth])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setVisibleColumns(getResponsiveVisibleColumns())
  }, [windowWidth, columns, getResponsiveVisibleColumns])

  const toggleColumnVisibility = (columnId: string) => {
    setColumns((prev) => {
      const newColumns = prev.map((col) => {
        if (col.id === columnId) {
          return { ...col, defaultVisible: !col.defaultVisible }
        }
        return col
      })
      return newColumns
    })
  }

  return (
    <Box fullWidth gap="16px">
      <Box direction="row" gap="16px" fullWidth>
        <Box direction="row" gap="16px" fullWidth>
          <StyledTextField
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            placeholder={getFigmaText(Texts.adminGeneralInputFieldsSearchInputHelper)}
            sx={{ minWidth: 200 }}
          />

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="status-filter-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {getFigmaText(Texts.adminGeneralStatusLabel)}
            </InputLabel>
            <CustomSelectField
              labelId="status-filter-label"
              id="status-filter"
              multiple
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value as string[])}
              input={<OutlinedInput label="" />}
              renderValue={(selected) =>
                (selected as string[]).map((status) => getStatusTranslation(status)).join(', ')
              }
            >
              {statusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  <StyledCheckbox
                    checked={statusFilter.indexOf(status) > -1}
                    sx={{ '& .MuiSvgIcon-root': { fill: 'black' } }}
                  />
                  <ListItemText primary={getStatusTranslation(status)} />
                </MenuItem>
              ))}
            </CustomSelectField>
          </FormControl>

          <Tooltip title={getFigmaText(Texts.adminGeneralPagesDashboardShowHideFilter)}>
            <IconButton
              onClick={handleFilterMenuOpen}
              sx={{
                color: 'white',
                bgcolor: getTotalActiveFiltersCount() > 0 ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.3)' }
              }}
            >
              <Badge
                badgeContent={getTotalActiveFiltersCount()}
                color="error"
                invisible={getTotalActiveFiltersCount() === 0}
              >
                <FilterList />
              </Badge>
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={filterMenuAnchorEl}
            open={Boolean(filterMenuAnchorEl)}
            onClose={handleFilterMenuClose}
            PaperProps={{
              sx: {
                backgroundColor: '#ffffff',
                color: '#000000',
                minWidth: '200px'
              }
            }}
          >
            {filterOptions.map((option) => (
              <MenuItem
                key={option.id}
                id={`${option.id}-filter-option`}
                onClick={(e) => handleFilterOptionOpen(option.id, e)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  bgcolor: activeFilterOption === option.id ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                  color: '#000000'
                }}
              >
                <span>
                  {option.label} {filters[option.id]?.length > 0 && `(${filters[option.id].length})`}
                </span>
                <ArrowRight sx={{ fill: '#000000' }} />
              </MenuItem>
            ))}
          </Menu>

          {filterOptions.map((option) => (
            <Menu
              key={option.id}
              open={Boolean(filterMenuAnchorEl) && activeFilterOption === option.id}
              onClose={() => setActiveFilterOption(null)}
              anchorEl={document.getElementById(`${option.id}-filter-option`)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              PaperProps={{
                sx: {
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  minWidth: '200px',
                  maxHeight: '300px',
                  overflowY: 'auto'
                }
              }}
            >
              {(() => {
                const availableOptions = option.getAvailableOptions(activities, clientData)
                return availableOptions.length > 0 ? (
                  availableOptions.map((opt) => (
                    <MenuItem
                      key={opt.value}
                      onClick={() => handleFilterToggle(option.id, opt.value)}
                      sx={{ color: '#000000' }}
                    >
                      <StyledCheckbox checked={(filters[option.id] || []).includes(opt.value)} />
                      <ListItemText primary={opt.label} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {getFigmaText(Texts.adminGeneralPagesDashboardNoOptions)}
                  </MenuItem>
                )
              })()}
              <MenuItem
                onClick={() => clearFilter(option.id)}
                sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)', marginTop: '8px', color: '#000000' }}
              >
                {getFigmaText(Texts.adminGeneralPagesDashboardClearFilter)}
              </MenuItem>
            </Menu>
          ))}
        </Box>

        <StyledButton onClick={() => createNewActivity(clientData, navigate)}>
          {getFigmaText(Texts.adminGeneralCtactaCreateNewActivity)}
        </StyledButton>
      </Box>

      {loading ? (
        <Box align="center" fullWidth>
          <div className="spinner"></div>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 1150,
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            width: '100%'
          }}
        >
          <Table stickyHeader aria-label="activity table">
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => visibleColumns.includes(column.id))
                  .map((column) => (
                    <StyledTableHeadCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.id === 'actions' ? (
                        <Box direction="row" justify="flex-end" align="center" fullWidth>
                          <span>{column.label}</span>
                          <div
                            style={{
                              height: '20px',
                              width: '1px',
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              margin: '0 8px'
                            }}
                          />
                          <Tooltip title={getFigmaText(Texts.adminGeneralPagesDashboardShowHideColumns)}>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation()
                                handleColumnMenuOpen(e)
                              }}
                              sx={{ color: 'white' }}
                            >
                              <ViewColumn fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        column.label
                      )}
                    </StyledTableHeadCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredActivities.map((activity) => (
                <StyledTableRow key={activity.id} onClick={() => navigate(`/editActivity/${activity.id}`)}>
                  {visibleColumns.includes('name') && (
                    <StyledTableCell>
                      <Box direction="row" gap="10px" align="center">
                        {getActivityBannerUrl(activity) ? (
                          <BannerThumbnailWrapper>
                            <BannerThumbnail src={getActivityBannerUrl(activity)!} alt={activity.name} />
                            <BannerFullPreview>
                              <img src={getActivityBannerUrl(activity)!} alt={activity.name} />
                            </BannerFullPreview>
                          </BannerThumbnailWrapper>
                        ) : (
                          <BannerPlaceholder />
                        )}
                        <span>{activity.name}</span>
                      </Box>
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes('status') && (
                    <StyledTableCell>
                      <StatusChip status={activity.status} />
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes('participantChart') && (
                    <StyledTableCell align="center" onClick={(e) => openEnrollmentsPage(e, activity)}>
                      <CompactParticipantLineChart
                        data={getParticipantTypeChartDataForActivity(enrollments, activity.id)}
                        totalParticipants={getAllEnrollmentsCount(enrollments, activity.id)}
                        maxParticipants={activity?.maxAllowedParticipants}
                      />
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes('queue') && (
                    <StyledTableCell>
                      {(() => {
                        const pendingEnrollmentsCount = getPendingEnrollmentCountForActivity(enrollments, activity.id)
                        return pendingEnrollmentsCount > 0 ? (
                          <ActionNotifications onClick={(e) => openEnrollmentsPage(e, activity)}>
                            <span>
                              {formatText(
                                getFigmaText(Texts.adminGeneralPagesEnrollmentListNotificationsEnrollmentsToApprove),
                                [pendingEnrollmentsCount]
                              )}
                            </span>
                          </ActionNotifications>
                        ) : null
                      })()}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes('type') && (
                    <StyledTableCell>{getLocalizedActivityType(activity.type, clientData)}</StyledTableCell>
                  )}

                  {visibleColumns.includes('city') && <StyledTableCell>{activity.city || '-'}</StyledTableCell>}

                  {visibleColumns.includes('startDate') && (
                    <StyledTableCell>{getStartDateAndTime(activity.startDate, activity.time)}</StyledTableCell>
                  )}

                  {visibleColumns.includes('actions') && (
                    <StyledTableCell align="right">
                      <Tooltip title={getFigmaText(Texts.adminGeneralCtactaEditActivity)}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/editActivity/${activity.id}`)
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleMenuOpen(e, activity)
                        }}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
              {filteredActivities.length === 0 && (
                <TableRow>
                  <TableCell colSpan={visibleColumns.length} align="center" sx={{ py: 3 }}>
                    <p style={{ color: 'white' }}>{getFigmaText(Texts.adminGeneralErrorNoActivityFound)}</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} classes={{ paper: 'menu-custom' }} onClose={handleMenuClose}>
        {[
          {
            icon: <Edit />,
            text: getFigmaText(Texts.adminGeneralCtactaEditActivity),
            onClick: () => {
              handleMenuClose()
              navigate(`/editActivity/${selectedActivity?.id}`)
            }
          },
          {
            icon: <OpenInNew />,
            text: getFigmaText(Texts.adminGeneralCtactaOpenPublicActivityPage),
            onClick: () => {
              handleMenuClose()
              window.open(`https://app.coursely.se/activity/${selectedActivity?.id}`, '_blank')
            }
          },
          {
            icon: <ControlPointDuplicate />,
            text: getFigmaText(Texts.adminGeneralCtactaDuplicateActivity),
            onClick: handleDuplicate
          },
          {
            icon: <Link />,
            text: getFigmaText(Texts.adminGeneralCtactaCopyRegPage),
            onClick: copyRegistrationPage
          }
        ].map(({ icon, text, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={onClick}
            disabled={text === getFigmaText(Texts.adminGeneralCtactaDuplicateActivity) && isDuplicating}
          >
            {React.cloneElement(icon, {
              style: { marginRight: '8px', width: '20px', height: '20px', fill: 'black' }
            })}
            {text}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={columnMenuAnchorEl}
        open={Boolean(columnMenuAnchorEl)}
        onClose={handleColumnMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: '#ffffff',
            color: '#000000',
            minWidth: '200px'
          }
        }}
      >
        <MenuItem disabled sx={{ color: '#000000', fontWeight: 'bold' }}>
          {getFigmaText(Texts.adminGeneralPagesDashboardShowHideColumns)}
        </MenuItem>

        {columns
          .filter((col) => col.optional)
          .map((column) => (
            <MenuItem
              key={`col-${column.id}`}
              onClick={() => toggleColumnVisibility(column.id)}
              sx={{
                color: '#000000',
                opacity: windowWidth < 950 ? 0.5 : 1
              }}
              disabled={windowWidth < 950}
            >
              <StyledCheckbox
                checked={columns.find((col) => col.id === column.id)?.defaultVisible || false}
                sx={{ '& .MuiSvgIcon-root': { fill: 'black' } }}
              />
              <ListItemText primary={column.label} secondary={windowWidth < 950 ? '' : null} />
            </MenuItem>
          ))}
        {windowWidth < 950 && (
          <MenuItem disabled sx={{ color: '#000000', fontSize: '12px', fontStyle: 'italic' }}>
            ""
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

const StyledTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.7);
  }

  .MuiOutlinedInput-root {
    color: white;
    fieldset {
      border-color: rgba(255, 255, 255, 0.3);
    }
    &:hover fieldset {
      border-color: rgba(255, 255, 255, 0.5);
    }
    &.Mui-focused fieldset {
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
`

const StyledButton = styled(ButtonBlack)`
  background: #4300bf;
  border: 1px solid #fff;
  padding: 8px 16px;
  min-width: 150px;
`

const StyledTableHeadCell = styled(TableCell)`
  background-color: #2b1055;
  color: white;
  font-weight: 600;
  padding: 12px 8px;
`

const StyledTableCell = styled(TableCell)`
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.03);
  &:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:last-child td,
  &:last-child th {
    border-bottom: 0;
  }
`

const BannerThumbnailWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    & > div {
      opacity: 1;
      visibility: visible;
    }
  }
`

const BannerThumbnail = styled.img`
  width: 40px;
  height: 28px;
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
`

const BannerFullPreview = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 8px;
  background-color: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  z-index: 1000;

  img {
    max-width: 300px;
    max-height: 200px;
    display: block;
    object-fit: contain;
  }
`

const BannerPlaceholder = styled.div`
  width: 40px;
  height: 28px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  flex-shrink: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
`

const ActionNotifications = styled.div`
  background-color: rgb(255, 183, 2);
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #faa307;
  }
`

export default ActivityListV2
