import styled from '@emotion/styled'
import { participantTypeConfig } from '../utils/participantTypeConfig'

interface EnrollmentTypeProps {
  partnerType: 'leader' | 'follower' | 'both' | 'single'
}

const EnrollmentType: React.FC<EnrollmentTypeProps> = ({ partnerType }) => {
  const config = participantTypeConfig[partnerType]

  return <EnrollerType style={{ backgroundColor: config.color }}>{config.text}</EnrollerType>
}

const EnrollerType = styled.p`
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 0.8rem;
  margin: 0;
  height: fit-content;
`

export default EnrollmentType
