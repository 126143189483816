export enum Collections {
  USERS = 'users',
  CLIENTS = 'clients',
  CLIENTS_PRIVATE = 'clients_private',
  COURSE_ADMINISTRATORS = 'course_administrators',
  ENROLLMENT_USERS = 'enrollment_users',
  ENROLLMENTS = 'enrollments',
  ENROLLMENTS_PUBLIC = 'enrollments_public',
  EVENTS = 'events',
  ACTIVITIES = 'activities',
  SWISH_PAYMENT_INTENT_STATUS = 'swish_payment_intent_status',
  SWISH_CONFIG = 'swish_config',
  STRIPE_CONFIG = 'stripe_config',
  QR_CODE_SCANNER = 'qrcode',
  EMAIL_TEMPLATES = 'email_templates',
  MAILING = 'mailing',
  URL_SHORT = 'url_short'
}

export enum Subcollections {
  COMMUNICATION_LOG = 'communication_log'
}
