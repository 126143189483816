enum RoutePath {
  INCORRECT_ROUTE = '*',
  ROOT = '/',
  SANDBOX = '/sandbox',
  IFRAME = '/iframe',
  DEMOPAGE = '/demo',
  PAY = '/pay/:enrollmentId',
  SWISH_PAY = '/swish/:enrollmentId',
  PAY_SUCCESS = '/pay-success',
  REGISTER = '/register/:activityId',
  DASHBOARD = '/dashboard',
  ENROLLMENTS = '/enrollments/:clientId/:activityId',
  PROFILE = '/profile',
  INVITE = '/invite',
  EDIT_ACTIVITY = '/editActivity/:activityId',
  ACTIVITY_DETAIL = '/activity/:activityId',
  EDIT_CLIENT = '/editClient/',
  PUBLIC_ACTIVITIES = '/activities/:clientId',
  BAR_CODE_SCANNER = '/qrcode-scanner',
  SETTINGS = '/settings',
  SETTINGS_EMAILS = '/settings/emails',
  ALLENROLLMENTS = '/all-enrollments',
  COMMUNICATION = '/communication',
  CHECKIN = '/checkin/:enrollmentId/:activityId?',
  FEATURES = '/_features',
  TEST = '/TEST',
  NO_ACCESS_PAGE = '/no-access',
  REGISTER_OBFUSCATED = '/register/:activityId/:enrollmentId',
  ACTIVITY_IFRAME_CONFIGURATOR = '/iframe-configurator',
  FINANCIALS = '/financials',
  FINANCIALS_DETAILS = '/financialsDetails',
  FINANCIALS_OVERVIEW = '/financialsOverview',
  ENROLLMENT_DETAIL = '/enrollment/:enrollmentId',
  URL_SHORT_REDIRECT = '/r/:hash' // see: UrlHelper.getUrlShort() pattern
}

export default RoutePath
