import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Box from '../Box'
import { CustomTextField } from '../../styles/AdminPageStyles'
import { getFigmaText } from '../../libs/TextRepository'
import { Texts } from '../../../../pure-js/libs/Texts'

interface DatePickerModalProps {
  position: { top: number; left: number }
  paymentDate: string
  onDateChange: (date: string) => void
  onCancel: () => void
  onConfirm: () => void
}

const DatePickerModal: React.FC<DatePickerModalProps> = ({
  position,
  paymentDate,
  onDateChange,
  onCancel,
  onConfirm
}) => {
  return (
    <DatePickerAbsoluteModal
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 1500,
        maxWidth: '90vw'
      }}
      className="date-picker-modal"
    >
      <Box direction="column" gap="16px" style={{ padding: '16px' }} fullWidth>
        <CustomTextField
          type="date"
          label={getFigmaText(Texts.adminGeneralInputFieldsEnrollmentPaymentDateModal)}
          value={paymentDate}
          onChange={(e) => onDateChange(e.target.value)}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />

        <Box direction="row" justify="space-between" fullWidth style={{ marginTop: '8px' }}>
          <Button onClick={onCancel} variant="text" size="small">
            {getFigmaText(Texts.adminGeneralCtactaCancel) || 'Cancel'}
          </Button>
          <Button onClick={onConfirm} variant="contained" color="primary" size="small">
            {getFigmaText(Texts.adminGeneralCtactaSave)}
          </Button>
        </Box>
      </Box>
    </DatePickerAbsoluteModal>
  )
}

// Styled component for the date picker modal
const DatePickerAbsoluteModal = styled(Box)`
  background-color: rgba(3, 7, 20, 0.79);
  border-radius: 8px;
  width: 280px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  color: white;

  /* Mobile adjustments */
  @media (max-width: 768px) {
    width: 280px;
    margin: 0 auto;
    padding: 0;

    h3 {
      font-size: 16px;
    }

    .MuiTextField-root {
      margin-bottom: 12px;
    }

    .MuiButton-root {
      padding: 8px 12px;
      min-width: 80px;
    }
  }
`

export default DatePickerModal
