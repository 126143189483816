// React and routing
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// MUI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Collapse,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

// Local imports
import useAppState from '../hooks/useAppState'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import dayjs from 'dayjs'
import { getFigmaText } from '../libs/TextRepository'
import { getAuth } from 'firebase/auth'
import { hasAccess } from '../libs/SignInHelper'
import { PageContainer, MainContainer } from '../styles/AdminPageStyles'
import { useClient } from '../context/ClientContext'
import { exportToExcel } from '../libs/ExportUtils'
import { renderEnrollmentStatus, isEnrollmentPaid } from '../utils/enrollmentUtils'
import { Enrollment } from '../../../pure-js/types/GrooverTypes'
import { getActivitiesForClient } from '../libs/DBApiHandler'
import { useEnrollmentsForClient } from '../hooks/useEnrollmentsForClient'

// Types
interface Activity {
  id: string
  name: string
}

interface User {
  email: string
  name: string
  phone: string
  stripeCustomerId: string
  enrollments: Enrollment[]
}

// Column configuration
enum TableColumnId {
  LatestEnrollment = 'latestEnrollment',
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  EnrollmentCount = 'enrollmentCount'
}

interface TableColumn {
  id: TableColumnId
  width: string
  textKey: keyof typeof Texts
  showOnMobile: boolean
}

const TABLE_CONFIG: Record<TableColumnId, TableColumn> = {
  [TableColumnId.LatestEnrollment]: {
    id: TableColumnId.LatestEnrollment,
    width: '120px',
    textKey: 'externalAllEnrollmentsHeaderLabelLatest',
    showOnMobile: false
  },
  [TableColumnId.Name]: {
    id: TableColumnId.Name,
    width: '200px',
    textKey: 'externalAllEnrollmentsHeaderLabelName',
    showOnMobile: true
  },
  [TableColumnId.Email]: {
    id: TableColumnId.Email,
    width: '250px',
    textKey: 'externalAllEnrollmentsHeaderLabelEmail',
    showOnMobile: true
  },
  [TableColumnId.Phone]: {
    id: TableColumnId.Phone,
    width: '150px',
    textKey: 'externalAllEnrollmentsHeaderLabelPhone',
    showOnMobile: false
  },
  [TableColumnId.EnrollmentCount]: {
    id: TableColumnId.EnrollmentCount,
    width: '130px',
    textKey: 'externalAllEnrollmentsHeaderLabelNumberEnrollments',
    showOnMobile: false
  }
}

// Column order for the table
const COLUMN_ORDER: TableColumnId[] = [
  TableColumnId.LatestEnrollment,
  TableColumnId.Name,
  TableColumnId.Email,
  TableColumnId.Phone,
  TableColumnId.EnrollmentCount
]

// Enhanced types
interface TableRow extends Record<TableColumnId, string | number> {
  enrollments: Enrollment[]
}

const SearchContainer = styled(Box)({
  marginBottom: '20px',
  width: '100%',
  maxWidth: '850px',
  '& .MuiTextField-root': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.4)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.7)'
      }
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInputAdornment-root': {
      color: 'rgba(255, 255, 255, 0.7)'
    }
  }
})

const FilterContainer = styled(Box)({
  marginBottom: '20px',
  '& .MuiFormControl-root': {
    minWidth: '200px',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.23)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.4)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.7)'
      }
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiSelect-icon': {
      color: 'white'
    }
  }
})

type ClientActivityNamesType = { id: string; name: string }

const AllEnrollments: React.FC = () => {
  const { state } = useAppState()
  const navigate = useNavigate()
  const auth = getAuth()
  const currentUser = auth.currentUser
  const { clientId: contextClientId, reloadTrigger } = useClient()

  // Update clientId initialization to match Dashboard
  const [clientId, setClientId] = useState<string>(() => localStorage.getItem('selectedClientId') || '')
  const [enrolledUsers, setEnrolledUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [expandedRow, setExpandedRow] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [paymentFilter, setPaymentFilter] = useState<string>('all')
  const [clientActivityNames, setClientActivityNames] = useState<ClientActivityNamesType[]>([])

  const _activities = getActivitiesForClient(clientId)
  const _enrollments = useEnrollmentsForClient(clientId)

  useEffect(() => {
    if (_activities.data) {
      setClientActivityNames(
        (_activities.data as Activity[]).map((a) => {
          return { id: a.id, name: a.name }
        })
      )
    }
  }, [_activities.data])

  useEffect(() => {
    setLoading(_activities.isLoading || _enrollments.isLoading)
  }, [_activities.isLoading, _enrollments.isLoading])

  useEffect(() => {
    if (!clientId) return
    if (!_enrollments.data || _enrollments.status !== 'success') return
    if (_enrollments.data) {
      setEnrolledUsers(processEnrollmentData(_enrollments.data))
    }
  }, [clientId, _enrollments.data])

  // Helper functions
  const processEnrollmentData = (data: any[]) => {
    const userMap: Record<string, User> = {}

    data
      .filter((x) => x.status !== 'deleted')
      .forEach((enrollment) => {
        const { mainUser, partnerUser } = enrollment

        const addUserEnrollment = (user: any, userType: 'mainUser' | 'partnerUser') => {
          if (!user) return
          const { email, name, phone, stripeCustomerId } = user

          if (!userMap[phone]) {
            userMap[phone] = {
              email,
              name,
              phone,
              stripeCustomerId,
              enrollments: []
            }
          }
          userMap[phone].enrollments.push({ ...enrollment, userType })
        }

        addUserEnrollment(mainUser, 'mainUser')
        addUserEnrollment(partnerUser, 'partnerUser')
      })

    return Object.values(userMap)
  }

  const getLatestEnrollmentDate = (enrollments: Enrollment[]) => {
    if (!enrollments.length) return '-'
    const latestDate = Math.max(...enrollments.map((e) => new Date(e.createdAt).getTime()))
    return dayjs(latestDate).format('YYYY-MM-DD')
  }

  const getLatestEnrollmentTimestamp = (enrollments: Enrollment[]) => {
    if (!enrollments.length) return 0
    return Math.max(...enrollments.map((e) => new Date(e.createdAt).getTime()))
  }

  const handleRowClick = (phone: string) => {
    setExpandedRow(expandedRow === phone ? null : phone)
  }

  const handleActivityClick = (activityId: string, e: React.MouseEvent) => {
    e.stopPropagation()
    navigate(`/enrollments/${clientId}/${activityId}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const handlePaymentFilterChange = (event) => {
    setPaymentFilter(event.target.value)
  }

  // Update the filterUsers function to include payment status filtering
  const filterUsers = (users: User[]): User[] => {
    // Start with search term filtering
    let filteredUsers = users

    if (searchTerm) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm) ||
          user.phone.toLowerCase().includes(searchTerm)
      )
    }

    // Apply payment filter if not 'all'
    if (paymentFilter !== 'all') {
      filteredUsers = filteredUsers.filter((user) => {
        // Check if any enrollment matches the payment filter
        const hasMatchingPaymentStatus = user.enrollments.some((enrollment) => {
          const isPaid = isEnrollmentPaid(enrollment)
          return paymentFilter === 'paid' ? isPaid : !isPaid
        })
        return hasMatchingPaymentStatus
      })
    }

    return filteredUsers
  }

  // Listen for client changes from context
  useEffect(() => {
    // If the context client ID changes or a reload is triggered
    const currentClientId = contextClientId || localStorage.getItem('selectedClientId') || ''

    if (currentClientId && currentClientId !== clientId) {
      setClientId(currentClientId)
    }
  }, [contextClientId, reloadTrigger])

  // Add export functionality
  const handleExportToExcel = () => {
    const columns = COLUMN_ORDER.map((columnId) => {
      const column = TABLE_CONFIG[columnId]
      return {
        key: columnId,
        header: getFigmaText(Texts[column.textKey]),
        getValue: (row: TableRow) => row[columnId]
      }
    })

    exportToExcel(buildTableRows(filteredAndSortedUsers), columns, 'enrollments-report')
  }

  // Render helpers
  const renderEnrollmentDetails = (enrollments: Enrollment[]) => (
    <EnrollmentGrid id="enrollmentGrid" fullWidth>
      <Box direction="column" fullWidth>
        {enrollments.map((enrollment, index) => (
          <EnrollmentRow
            className="EnrollmentRow"
            key={index}
            direction="row"
            fullWidth
            align="flex-start"
            changeDirectionOnMobile
            justify="flex-start"
          >
            <Box direction="row">
              <Box style={{ minWidth: '100px' }}>{dayjs(enrollment.createdAt).format('YYYY-MM-DD')}</Box>
              <Box>
                <div style={{ minWidth: '130px' }} className={renderEnrollmentStatus(enrollment.status).className}>
                  {' ' + renderEnrollmentStatus(enrollment.status).text}
                </div>
              </Box>
            </Box>
            <Box direction="row" style={{ flexWrap: 'wrap', gap: '8px' }} align="center">
              {enrollment.activityIds?.map((aId: any, actIndex: number) => (
                <ActivityChip key={actIndex} onClick={(e) => handleActivityClick(aId, e)}>
                  {clientActivityNames.find((a) => a.id === aId)?.name || 'Unknown activity'}
                </ActivityChip>
              ))}
            </Box>
          </EnrollmentRow>
        ))}
      </Box>
    </EnrollmentGrid>
  )

  const filteredAndSortedUsers = React.useMemo(() => {
    const filtered = filterUsers(enrolledUsers)
    return [...filtered].sort(
      (a, b) => getLatestEnrollmentTimestamp(b.enrollments) - getLatestEnrollmentTimestamp(a.enrollments)
    )
  }, [enrolledUsers, searchTerm, paymentFilter]) // Add paymentFilter to dependencies

  const buildTableRows = (users: User[]): TableRow[] => {
    return users.map((user) => ({
      [TableColumnId.LatestEnrollment]: getLatestEnrollmentDate(user.enrollments),
      [TableColumnId.Name]: user.name,
      [TableColumnId.Email]: user.email,
      [TableColumnId.Phone]: user.phone,
      [TableColumnId.EnrollmentCount]: user.enrollments.length,
      enrollments: user.enrollments
    }))
  }

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_ORDER.map((columnId) => {
          const column = TABLE_CONFIG[columnId]
          return (
            <TableCell
              key={column.id}
              sx={{
                width: column.width,
                display: {
                  xs: column.showOnMobile ? 'table-cell' : 'none',
                  sm: 'table-cell'
                }
              }}
            >
              <FigmaText textKey={Texts[column.textKey]} />
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )

  const renderTableRow = (row: TableRow) => (
    <React.Fragment key={row[TableColumnId.Phone]}>
      <TableRow onClick={() => handleRowClick(row[TableColumnId.Phone] as string)}>
        {COLUMN_ORDER.map((columnId) => {
          const column = TABLE_CONFIG[columnId]
          return (
            <TableCell
              key={columnId}
              sx={{
                display: {
                  xs: column.showOnMobile ? 'table-cell' : 'none',
                  sm: 'table-cell'
                }
              }}
            >
              {row[columnId]}
            </TableCell>
          )
        })}
      </TableRow>
      <ExpandedRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={COLUMN_ORDER.length}>
          <Collapse in={expandedRow === row[TableColumnId.Phone]} timeout="auto" unmountOnExit>
            <EnrollmentDetails fullWidth>{renderEnrollmentDetails(row.enrollments)}</EnrollmentDetails>
          </Collapse>
        </TableCell>
      </ExpandedRow>
    </React.Fragment>
  )

  const renderSearchField = () => (
    <Box fullWidth>
      <TextField
        placeholder={getFigmaText(Texts.externalAllEnrollmentsSearchLabel)}
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch} edge="end" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )

  const renderFilters = () => (
    <FilterContainer direction="row" fullWidth gap="16px" changeDirectionOnMobile>
      {renderSearchField()}
      <FormControl size="small">
        <InputLabel id="payment-filter-label">
          {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
        </InputLabel>
        <Select
          labelId="payment-filter-label"
          value={paymentFilter}
          label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
          onChange={handlePaymentFilterChange}
        >
          <MenuItem value="all">{getFigmaText(Texts.adminGeneralEnumsFilterFilterAll)}</MenuItem>
          <MenuItem value="paid">{getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)}</MenuItem>
          <MenuItem value="unpaid">{getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)}</MenuItem>
        </Select>
      </FormControl>
    </FilterContainer>
  )

  const renderNoResults = () => (
    <Box fullWidth align="center" direction="column" style={{ gap: '16px' }}>
      <p>{getFigmaText(Texts.adminGeneralInfoTextsNoResultsFound)}</p>
    </Box>
  )

  const renderTable = () => {
    const rows = buildTableRows(filteredAndSortedUsers)

    return (
      <StyledTableContainer component={Paper}>
        <Table>
          {renderTableHeader()}
          <TableBody>{rows.map(renderTableRow)}</TableBody>
        </Table>
      </StyledTableContainer>
    )
  }

  return (
    <MainContainer fullWidth fullHeight top spacing="60px">
      <PageContainer fullWidth>
        {!hasAccess(state) ? (
          <Box fullWidth align="center">
            <Alert severity="error">No access</Alert>
          </Box>
        ) : loading ? (
          <Box fullWidth align="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box fullWidth align="center">
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : enrolledUsers.length === 0 ? (
          <EmptyStateContainer>
            <img src={EmptyStateImg} alt="No Enrollments" />
            <FigmaText textKey={Texts.adminGeneralInfoTextsEmptyState} />
          </EmptyStateContainer>
        ) : (
          <>
            <Box direction="row" fullWidth justify="space-between" align="center" style={{ marginBottom: '20px' }}>
              <Box>
                <FigmaText textKey={Texts.externalAllEnrollmentsPageHeader} />
              </Box>
              <Button variant="contained" onClick={handleExportToExcel}>
                {getFigmaText(Texts.adminGeneralCtactaDownloadXls)}
              </Button>
            </Box>

            {renderFilters()}

            {filteredAndSortedUsers.length === 0 ? renderNoResults() : renderTable()}
          </>
        )}
      </PageContainer>
    </MainContainer>
  )
}

const EnrollmentRow = styled(Box)({
  padding: '8px 16px'
})

// Styled components
const StyledTableContainer = styled(TableContainer)<{ component?: React.ElementType }>({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  '& .MuiTableCell-root': {
    color: 'white',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer'
  }
})

const EmptyStateContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
  '& img': {
    maxWidth: '300px',
    marginBottom: '1rem'
  }
})

const EnrollmentDetails = styled(Box)({
  padding: '16px',
  margin: '0 16px',
  borderRadius: '4px'
})

const ActivityChip = styled(Box)({
  display: 'inline-block',
  padding: '4px 8px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '16px',
  fontSize: '0.875rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    textDecoration: 'underline'
  }
})

const EnrollmentGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '16px',
  width: '100%'
})

const ExpandedRow = styled(TableRow)`
  background-color: rgba(255, 255, 255, 0.05);
`

export default AllEnrollments
