// @flow
import { createTheme } from '@mui/material/styles'
import { MainColor, SecondayColor, TertiaryColor, White } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(MainColor),
    secondary: createColor(SecondayColor),
    tertiary: createColor(TertiaryColor),
    white: createColor(White)
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        },
        outlined: {
          borderColor: 'rgba(255, 255, 255, 0.3)',
          '&:hover': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          }
        },
        text: {
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          }
        },
        contained: {
          // ...existing contained button styles if any
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': { color: 'white' },
          '& .MuiInputBase-input': { color: 'white' },
          '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
          '& .MuiSvgIcon-root': { color: 'white' },
          '& .MuiInputAdornment-root': { color: 'white' },
          '& .MuiIconButton-root': { color: 'white' },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)'
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'white',
          '& .MuiSvgIcon-root': { color: 'white' },
          '& svg': { color: 'white' }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': { color: 'white' },
          '& svg': { color: 'white' }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': { color: 'white' }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& svg': { color: 'white' }
        }
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: { color: 'white' }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            margin: 0,
            color: '#f44336',
            backgroundColor: 'white',
            padding: '4px 8px',
            borderRadius: '2px',
            display: 'inline-block',
            width: '100%',
            BorderRadixes: BorderRadixes.small
          }
        }
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme
