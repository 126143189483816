import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import FeatureFlagsAdminPage from '../../../user-web/src/pages/FeatureFlagsAdminPage'
import ActivityIframeConfiguratorPage from '../pages/ActivityIframeConfiguratorPage'
import AllEnrollments from '../pages/AllEnrollments'
import EnrollmentCheckinPage from '../pages/CheckinPage'
import Communication from '../pages/Communication'
import Dashboard from '../pages/Dashboard'
import EditActivityPage from '../pages/EditActivityPage'
import EditClientPage from '../pages/EditClientPage'
import EnrollmentDetail from '../pages/EnrollmentDetail'
import EnrollmentsPage from '../pages/EnrollmentsPage'
import Financials from '../pages/Financials'
import QRCodeScannerPage from '../pages/QRCodeScannerPage'
import SettingsPage from '../pages/SettingsPage'
import FinancialDetailsPage from '../pages/FinancialDetails'
import FinancialOverview from '../pages/FinancialsOverview'

const PrivateRoutes: React.FC = () => {
  const FeatureFlagsPage = () => {
    return <FeatureFlagsAdminPage storageKey={'featureFlagsAdmin'} />
  }

  return (
    <Routes>
      <Route path={RoutePath.DASHBOARD} Component={Dashboard} />
      <Route path={RoutePath.ENROLLMENTS} Component={EnrollmentsPage} />
      <Route path={RoutePath.EDIT_ACTIVITY} Component={EditActivityPage} />
      <Route path={RoutePath.EDIT_CLIENT} Component={EditClientPage} />
      <Route path={RoutePath.SETTINGS} Component={SettingsPage} />
      <Route path={RoutePath.ALLENROLLMENTS} Component={AllEnrollments} />
      <Route path={RoutePath.CHECKIN} Component={EnrollmentCheckinPage} />
      <Route path={RoutePath.BAR_CODE_SCANNER} Component={QRCodeScannerPage} />
      <Route path={RoutePath.FEATURES} Component={FeatureFlagsPage} />
      <Route path={RoutePath.COMMUNICATION} Component={Communication} />
      <Route path={RoutePath.ACTIVITY_IFRAME_CONFIGURATOR} Component={ActivityIframeConfiguratorPage} />
      <Route path={RoutePath.FINANCIALS} Component={Financials} />
      <Route path={RoutePath.FINANCIALS_DETAILS} Component={FinancialDetailsPage} />
      <Route path={RoutePath.ENROLLMENT_DETAIL} Component={EnrollmentDetail} />
      <Route path={RoutePath.FINANCIALS_OVERVIEW} Component={FinancialOverview} />
      <Route path="*" element={<Navigate to={RoutePath.DASHBOARD} />} />
    </Routes>
  )
}

export default PrivateRoutes
