import qs from 'query-string'

export const getFeatureFlagsFromUrl = () => {
  if (typeof window === 'undefined') return {}
  if (typeof window.location === 'undefined') return {}
  let _text = qs.parse(window.location.search)?.features
  if (!_text) return {}

  if (Array.isArray(_text)) _text = _text[0]
  const text = _text as string
  return text.split(',').reduce(
    (a, flag: string) => {
      a[flag] = true
      return a
    },
    {} as { [flag: string]: boolean }
  )
}

// this makes sure that the object is stringified in a consistent way
export const sortAndStringify = (obj: any): string => {
  if (!obj) return ''

  // Helper function to sort object recursively
  const sortObject = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(sortObject)
    }

    if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj)
        .sort()
        .reduce((result, key) => {
          result[key] = sortObject(obj[key])
          return result
        }, {})
    }

    return obj
  }

  return JSON.stringify(sortObject(obj))
}
