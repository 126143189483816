import { Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Modal, Select, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import { smallSpacing } from '../enums/Spacings'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MoreVert from '@mui/icons-material/MoreVert'
import {
  CheckCircle,
  CheckCircleOutline,
  Checklist,
  Close,
  Details,
  Link,
  NotificationsActive,
  QrCode2
} from '@mui/icons-material'
import styled from '@emotion/styled'
import '@material/data-table/dist/mdc.data-table.css'
import dayjs from 'dayjs'

// Import from pure-js
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, Enrollment, EnrollmentStatus, EnrollmentUserRequest } from '../../../pure-js/types/GrooverTypes'

// Import components
import DatePickerModal from '../components/enrollment/DatePickerModal'
import DashboardCard from '../components/DashboardCard'
import CheckinList from '../components/CheckinList'
import EnrollmentType from '../components/EnrollmentType'
import FigmaText from '../components/FigmaText'
import ParticipantLineChart from '../components/ParticipantLineChart'
import PaymentStatus from '../components/PaymentStatus'
import QRButton from '../components/QRButton'
import QRCodeElement from '../components/QRCodeElement'
import SearchBox from '../components/SearchBox'

// Import hooks, libs, utils
import useAppState from '../hooks/useAppState'
import { calculateModalPosition } from '../utils/modalUtils'
import { sendEnrollmentEmail, storeEnrollmentActivityCheckin, upsertEnrollment } from '../libs/CloudFunctionsApiHandler'
import { getActivity } from '../libs/DBApiHandler'
import { getFigmaText } from '../libs/TextRepository'
import {
  formatNumberWithDots,
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getEnrollmentPrice,
  getPaidRegistrationRatio,
  getParticipantTypeChartData,
  getRegistrationsPerDay,
  hasNonManualPayments,
  isEnrollmentPaid,
  renderEnrollmentStatus
} from '../utils/enrollmentUtils'

// Import resources and styles
import '../resources/css/Dashboard.css'
import '../resources/css/EnrollmentsList.css'
import { MainContainer, PageContainer } from '../styles/AdminPageStyles'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import moneyIcon from '../resources/images/Money.png'
import userIcon from '../resources/images/User.png'
import editIcon from '../resources/svg/editIcon.svg'
import { useEnrollmentsForActivity } from '../hooks/useEnrollmentsForActivity'
import { EmailType } from '../../../pure-js/types/QueryParamTypes'

const EnrollmentsPage: React.FC = () => {
  // State management
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [loading, setLoading] = useState(true)
  const [activity, setActivity] = useState<Activity | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<Enrollment | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>(getFigmaText(Texts.adminGeneralInfoTextsSaveSuccesful))
  const [viewMode, setViewMode] = useState<'payment' | 'checkin'>(() => {
    const savedMode = localStorage.getItem('enrollmentsViewMode')
    return (savedMode as 'payment' | 'checkin') || 'payment'
  })
  const [showQRCode, setShowQRCode] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [paymentFilter, setPaymentFilter] = useState<string>('all')
  const [enrollmentStatusFilter, setEnrollmentStatusFilter] = useState<string>('all')
  const [activities, setActivities] = useState<Record<string, Activity>>({})

  // Payment date modal state
  const [paymentDateModalOpen, setPaymentDateModalOpen] = useState(false)
  const [paymentDateModalPosition, setPaymentDateModalPosition] = useState({ top: 0, left: 0 })
  const [paymentDateData, setPaymentDateData] = useState<{
    enrollment: Enrollment | null
    isMainUser: boolean
    status: EnrollmentStatus
    paymentDate: string
  }>({
    enrollment: null,
    isMainUser: true,
    status: EnrollmentStatus.Confirmed,
    paymentDate: dayjs().format('YYYY-MM-DD')
  })

  const navigate = useNavigate()
  const state = useAppState()
  const { activityId, clientId } = useParams<{ activityId: string; clientId: string }>()

  const _enrollments = useEnrollmentsForActivity(activity)

  useEffect(() => {
    if (_enrollments.data) {
      setEnrollments(_enrollments.data)
    }
  }, [_enrollments.data, _enrollments.status])

  // Menu handlers
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: Enrollment) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  // Navigation handlers
  const handleReturn = () => navigate(-1)
  const redirectToEditActivity = () => navigate(`/editActivity/${activityId}`)
  const redirectToCheckin = async (enrollmentId, activityId) => {
    navigate(RoutePath.CHECKIN.replace(':enrollmentId', enrollmentId).replace(':activityId?', activityId))
  }
  const openEnrollmentDetails = async (enrollmentId) => {
    navigate(RoutePath.ENROLLMENT_DETAIL.replace(':enrollmentId', enrollmentId))
  }
  const openEnrollmentLink = (enrollmentId: string, activityId: string) => {
    const enrollmentLink = `https://app.coursely.se/register/${activityId}/${enrollmentId}`
    window.open(enrollmentLink, '_blank')
    handleMenuClose()
  }

  // Status and payment handlers
  const handleApproveAttendee = () => {
    if (selectedRow) {
      updateAttendeeStatus(EnrollmentStatus.Confirmed)
      handleMenuClose()
    }
  }

  const handleSendReminderPaymentAndCloseMenu = async (enrollment: Enrollment) => {
    if (selectedRow) {
      console.log('Sending payment reminder email for enrollment:', enrollment.id)
      const response = await sendEnrollmentEmail(
        { enrollmentId: enrollment.id, type: EmailType.PAYMENT_REMINDER },
        state.state
      )
      setSnackbarMessage(getFigmaText(Texts.adminGeneralInfoTextsReminderSent) || 'Marked as unpaid')
      setSnackbarOpen(true)

      if (!response.success) {
        console.error('Payment reminder email error', response)
        return
      }
    }
  }

  const saveEnrollment = (enrollment: Enrollment) => {
    upsertEnrollment(enrollment, state.state).catch((error) => {
      console.error('Error updating enrollment status', error)
    })
  }

  const updateAttendeeStatus = async (newStatus: EnrollmentStatus) => {
    if (selectedRow) {
      setLoading(true)
      handleMenuClose()
      selectedRow.status = newStatus

      if (newStatus === EnrollmentStatus.Confirmed) {
        const response = await sendEnrollmentEmail(
          { enrollmentId: selectedRow.id, type: EmailType.REGISTRATION_APPROVED },
          state.state
        )
        if (!response.success) {
          console.error('Payment confirmed email error', response)
          return // don't upsert enrollment with new status
        }
      }

      saveEnrollment(selectedRow)

      // Set appropriate message based on status
      if (newStatus === EnrollmentStatus.Deleted) {
        setSnackbarMessage(getFigmaText(Texts.adminGeneralInfoTextsEnrollmentDeleted) || 'Enrollment deleted')
      } else if (newStatus === EnrollmentStatus.Confirmed) {
        setSnackbarMessage(getFigmaText(Texts.adminGeneralInfoTextsEnrollmentApproved) || 'Enrollment approved')
      } else {
        setSnackbarMessage(getFigmaText(Texts.adminGeneralInfoTextsSaveSuccesful))
      }
      setSnackbarOpen(true)

      // Remove the row from enrollments if status is 'deleted'
      if (newStatus === EnrollmentStatus.Deleted) {
        setEnrollments((prevEnrollments) => prevEnrollments.filter((enrollment) => enrollment.id !== selectedRow.id))
      }

      setLoading(false)
    }
  }

  const handleApprovePayment = async (enrollment, isMainUser: boolean, status: EnrollmentStatus) => {
    // If we're marking as unpaid (Pending), process directly without asking for date
    if (status === EnrollmentStatus.Pending) {
      // Filter out all manual payments
      if (!enrollment.payments) enrollment.payments = []
      enrollment.payments = enrollment.payments.filter((payment) => payment.paymentMethod !== 'manual')

      // Update the enrollments state with the updated payments
      setEnrollments((prevRegistrations) =>
        prevRegistrations.map((registration) =>
          registration.id === enrollment.id ? { ...registration, payments: [...enrollment.payments] } : registration
        )
      )

      setSnackbarMessage(getFigmaText(Texts.adminGeneralCtactaMarkAsUnpaid) || 'Marked as unpaid')
      setSnackbarOpen(true)

      try {
        await upsertEnrollment(enrollment, state.state)
      } catch (error) {
        console.error('Error updating payment status', error)
      }

      return
    }

    // For other statuses, calculate position and open the payment date modal
    setPaymentDateModalPosition(calculateModalPosition(anchorEl))

    setPaymentDateData({
      enrollment,
      isMainUser,
      status,
      paymentDate: dayjs().format('YYYY-MM-DD')
    })
    setPaymentDateModalOpen(true)
  }

  const processPaymentApproval = async () => {
    setPaymentDateModalOpen(false)

    const { enrollment, status, paymentDate } = paymentDateData
    if (!enrollment) return

    const isRefund = status == EnrollmentStatus.Refunded
    const price = isRefund ? -getEnrollmentPrice(enrollment) : getEnrollmentPrice(enrollment)

    setSnackbarMessage(
      isRefund
        ? getFigmaText(Texts.adminGeneralInfoTextsEnrollmentMarkedAsRefunded) || 'Marked as refunded'
        : getFigmaText(Texts.adminGeneralInfoTextsEnrollmentMarkedAsPaid) || 'Marked as paid'
    )
    setSnackbarOpen(true)

    if (!enrollment.payments) enrollment.payments = []
    enrollment.payments.push({
      paymentDate: dayjs(paymentDate).toISOString(),
      currency: 'SEK',
      totalAmount: price,
      paymentMethod: 'manual',
      source: 'manual',
      type: isRefund ? 'refund' : 'pay'
    })

    setEnrollments((prevRegistrations) =>
      prevRegistrations.map((registration) =>
        registration.id === enrollment.id ? { ...registration, payments: [...enrollment.payments] } : registration
      )
    )

    if (status === EnrollmentStatus.Confirmed) {
      console.log('Sending payment confirmed email for enrollment:', enrollment.id)
      const response = await sendEnrollmentEmail(
        { enrollmentId: enrollment.id, type: EmailType.PAYMENT_CONFIRMED },
        state.state
      )
      if (!response.success) {
        console.error('Payment confirmed email error', response)
        return
      }
    }

    try {
      await upsertEnrollment(enrollment, state.state)
    } catch (error) {
      console.error('Error updating payment status', error)
    }
  }

  const handleApprovePaymentAndCloseMenu = (status: EnrollmentStatus) => {
    if (selectedRow) {
      handleApprovePayment(selectedRow, true, status)
      handleMenuClose()
    }
  }

  // Check-in handler
  const handleCheckinChange = async (enrollmentId: string, userId: string, occurenceNo: number, checked: boolean) => {
    try {
      const req = {
        enrollmentId: enrollmentId,
        activityId: activityId || '',
        occurenceNo: occurenceNo,
        userId: userId,
        newState: checked
      }

      await storeEnrollmentActivityCheckin(req, state.state)

      toast.success(
        checked
          ? getFigmaText(Texts.adminGeneralInfoTextsCheckinSaved)
          : getFigmaText(Texts.adminGeneralInfoTextsCheckinRemoved)
      )
      return true
    } catch (error) {
      console.error('Error updating check-in status:', error)
      toast.error(getFigmaText(Texts.adminGeneralInfoTextsCheckinUpdateFailed))
      return false
    }
  }

  // Filter and view handlers
  const handleViewModeChange = () => {
    const newMode = viewMode === 'payment' ? 'checkin' : 'payment'
    setViewMode(newMode)
    localStorage.setItem('enrollmentsViewMode', newMode)
  }

  const handlePaymentFilterChange = (event) => {
    setPaymentFilter(event.target.value)
  }

  const handleEnrollmentStatusFilterChange = (event) => {
    setEnrollmentStatusFilter(event.target.value)
  }

  // Data loading
  useEffect(() => {
    const fetchCourseUserRegistrations = async () => {
      try {
        // Collect and fetch all activity IDs from enrollments
        const allActivityIds = new Set<string>()
        enrollments.forEach((enrollment) => {
          if (enrollment.activityIds) {
            enrollment.activityIds.forEach((id) => allActivityIds.add(id))
          }
        })

        const activityPromises = Array.from(allActivityIds).map((id) => getActivity(id))
        const activitiesData = await Promise.all(activityPromises)

        const activitiesMap: Record<string, Activity> = {}
        activitiesData.forEach((activity) => {
          if (activity) {
            activitiesMap[activity.id] = activity
          }
        })
        setActivities(activitiesMap)

        setLoading(false)
      } catch (err) {
        console.error('Error fetching course user registrations:', err)
        setLoading(false)
      }
    }

    fetchCourseUserRegistrations()
  }, [state.state, enrollments])

  useEffect(() => {
    const fetchActivity = async () => {
      if (activityId) {
        const _activity = await getActivity(activityId)
        if (_activity) {
          setActivity(_activity)
        }
      }
    }
    fetchActivity()
  }, [activityId])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (paymentDateModalOpen && !target.closest('.date-picker-modal') && !target.closest('.MuiMenu-paper')) {
        setPaymentDateModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [paymentDateModalOpen])

  // Filtered enrollments calculation
  const filteredEnrollments = React.useMemo(() => {
    // Apply search filter
    const searchFiltered = searchQuery
      ? enrollments.filter(
          (enrollment) =>
            enrollment.mainUser.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            enrollment.mainUser.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            enrollment.mainUser.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (enrollment.partnerUser &&
              (enrollment.partnerUser.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                enrollment.partnerUser.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                enrollment.partnerUser.phone?.toLowerCase().includes(searchQuery.toLowerCase())))
        )
      : enrollments

    // Apply status filter
    const statusFiltered =
      enrollmentStatusFilter !== 'all'
        ? searchFiltered.filter((enrollment) => enrollment.status === enrollmentStatusFilter)
        : searchFiltered

    // Apply payment filter if we're in payment view
    if (viewMode === 'payment' && paymentFilter !== 'all') {
      return statusFiltered.filter((enrollment) =>
        paymentFilter === 'paid' ? isEnrollmentPaid(enrollment) : !isEnrollmentPaid(enrollment)
      )
    }

    return statusFiltered
  }, [enrollments, searchQuery, enrollmentStatusFilter, paymentFilter, viewMode])

  // Helper render functions
  const getEnrollmentDate = (enrollment: Enrollment) => (
    <Box>
      {new Date(enrollment.createdAt).toLocaleDateString('sv-SE', {
        day: 'numeric',
        month: 'numeric'
      })}{' '}
      {new Date(enrollment.createdAt).toLocaleTimeString('sv-SE', {
        hour: '2-digit',
        minute: '2-digit'
      })}
    </Box>
  )

  const renderActivities = (enrollment: Enrollment) => {
    if (!enrollment.activityIds || enrollment.activityIds.length <= 1) {
      return null
    }

    return (
      <Box direction="row" gap="4px" style={{ flexWrap: 'wrap' }}>
        {enrollment.activityIds.map((activityId) => {
          const activityName = activities[activityId]?.name || activityId
          return (
            <ActivityTag
              key={activityId}
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/enrollments/${clientId}/${activityId}`)
              }}
            >
              {activityName}
            </ActivityTag>
          )
        })}
      </Box>
    )
  }

  const renderEnrollmentUserInfo = (enrollment: Enrollment, index: number, isMobile: boolean) => {
    const enrollmentRow = (
      <Box direction="row" fullWidth key={`${enrollment.id}-main`}>
        {isMobile ? (
          <Box className="Mobile_EnrollmentUsers" direction="column" fullWidth gap="8px" style={{ cursor: 'pointer' }}>
            <Box className="Mobile_EnrollmentMainUser" direction="row" align="center" justify="space-between" fullWidth>
              <EnrollerTitle> {enrollment.mainUser.name} </EnrollerTitle>
              <EnrollmentType partnerType={enrollment.mainUser.type} />
            </Box>
            {enrollment.partnerUser && (
              <Box
                className="Mobile_EnrollmentPartnerUser"
                direction="row"
                align="center"
                justify="space-between"
                fullWidth
              >
                <EnrollerTitle> {enrollment.partnerUser.name} </EnrollerTitle>
                <EnrollmentType partnerType={enrollment.partnerUser.type} />
              </Box>
            )}
          </Box>
        ) : (
          <Box className="Web_EnrollmentUsers" direction="column" fullWidth gap="8px" style={{ cursor: 'pointer' }}>
            {getEnrollmentContactInfo(enrollment.mainUser)}
            {enrollment.partnerUser && getEnrollmentContactInfo(enrollment.partnerUser)}
          </Box>
        )}
      </Box>
    )

    return (
      <EnrollmentRow fullWidth align="flex-start" direction="row" key={enrollment.id} changeDirectionOnMobile>
        <Box direction="column" fullWidth gap="8px">
          <Box className="EnrollmentTopRow" direction="row" fullWidth justify="space-between" gap="8px">
            <Box direction="row" gap="8px" align="center">
              {getEnrollmentDate(enrollment)}
              <MetaInfoContainer direction="row" gap="12px">
                <div className={renderEnrollmentStatus(enrollment.status, !isMobile).className}>
                  {' ' + renderEnrollmentStatus(enrollment.status, !isMobile).text}
                </div>
              </MetaInfoContainer>
            </Box>
            <Box className="EnrollmentTopRowRight" direction="row" align="center" gap="8px">
              {activity && <PaymentStatus enrollment={enrollment} activity={activity} />}
              <IconButton
                onClick={(event) => handleMenuOpen(event, enrollment)}
                style={{ margin: '2px', height: 'fit-content', padding: '2px' }}
              >
                <MoreVert style={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>

          {enrollment.activityIds && enrollment.activityIds.length > 1 && (
            <AdditionalActivityContainer>{renderActivities(enrollment)}</AdditionalActivityContainer>
          )}

          {enrollment.comment && (
            <CommentSection fullWidth>
              <FigmaText textKey={Texts.adminGeneralPagesEnrollmentListCommentLabel} />
              {enrollment.comment}
            </CommentSection>
          )}

          {[enrollmentRow]}
        </Box>
      </EnrollmentRow>
    )
  }

  const renderViewToggle = () => (
    <Box direction="row" justify="flex-start" fullWidth style={{ marginBottom: '16px' }}>
      <Button variant="contained" onClick={handleViewModeChange} style={{ color: 'white' }}>
        {viewMode === 'payment'
          ? getFigmaText(Texts.adminGeneralCtactaChangeModeToCheckin)
          : getFigmaText(Texts.adminGeneralCtactaChangeModeToFinancial)}
      </Button>
    </Box>
  )

  // Modal components
  const QRCodeModal = () => {
    if (!showQRCode) return null

    return (
      <Modal open={!!showQRCode} onClose={() => setShowQRCode(null)}>
        <ModalContainer>
          <CloseButton onClick={() => setShowQRCode(null)}>×</CloseButton>
          <QRCodeContent>
            <QRCodeElement value={showQRCode} />
            <QRText>{getFigmaText(Texts.adminGeneralCheckinPageQrScanInfoToRegister)}</QRText>
          </QRCodeContent>
        </ModalContainer>
      </Modal>
    )
  }

  const registrationsPerDay = enrollments.length > 0 ? getRegistrationsPerDay(enrollments) : []

  const getEnrollmentContactInfo = (enrollmentUser: EnrollmentUserRequest) => (
    <Box direction="row" fullWidth align="center" gap="8px">
      <EnrollmentType partnerType={enrollmentUser.type} />
      <EnrollerTitle> {enrollmentUser.name} </EnrollerTitle>

      <EnrollerPhone> {enrollmentUser.phone} </EnrollerPhone>
      <EnrollerEmail
        title={enrollmentUser.email}
        onClick={() => {
          navigator.clipboard.writeText(enrollmentUser.email)
          toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageCopyToClipboard), { duration: 2000 })
        }}
        style={{ cursor: 'pointer' }}
      >
        {enrollmentUser.email}
      </EnrollerEmail>
      <EnrollerSSN> {enrollmentUser.ssn} </EnrollerSSN>
    </Box>
  )

  // Render the main component
  return (
    <MainContainer>
      <QRCodeModal />

      {paymentDateModalOpen && (
        <DatePickerModal
          position={paymentDateModalPosition}
          paymentDate={paymentDateData.paymentDate}
          onDateChange={(date) => setPaymentDateData({ ...paymentDateData, paymentDate: date })}
          onCancel={() => setPaymentDateModalOpen(false)}
          onConfirm={processPaymentApproval}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />

      <PageContainer>
        <div className="dashboard-container" style={{ height: '100vh', overflow: 'auto' }}>
          <Box direction="row" align="center" gap="16px" fullWidth>
            <ArrowBackIcon onClick={handleReturn} style={{ cursor: 'pointer' }} />
            <Box direction="row" align="flex-start" className="boxColumn">
              <ActivityText>{activity?.name}</ActivityText>
              <Button
                startIcon={<img src={editIcon} style={{ width: '1em', height: '1em' }} />}
                onClick={redirectToEditActivity}
                className="button"
                sx={{ minWidth: 'fit-content' }}
              >
                {getFigmaText(Texts.adminGeneralCtactaEditActivity)}
              </Button>
            </Box>
          </Box>

          {loading ? (
            <div className="loading">
              <div className="spinner"></div>
            </div>
          ) : enrollments.length === 0 ? (
            <div className="no-registrations">
              <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
              <FigmaText textKey={Texts.adminGeneralInfoTextsEmptyState} />
            </div>
          ) : (
            <>
              <Box fullWidth direction="row" gap="1rem" changeDirectionOnMobile>
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title={getFigmaText(Texts.componentsKpIsKpiRegistrationLabel)}
                  value={getAllEnrollmentsCount(enrollments, activityId)}
                  chartData={registrationsPerDay}
                  chart={
                    <ParticipantLineChart
                      data={getParticipantTypeChartData(enrollments)}
                      totalParticipants={getAllEnrollmentsCount(enrollments, activityId)}
                      maxParticipants={activity?.maxAllowedParticipants}
                    />
                  }
                />

                {viewMode === 'payment' && (
                  <>
                    <DashboardCard
                      icon={<img src={userIcon} alt="User Icon" />}
                      title={getFigmaText(Texts.componentsKpIsKpiTotalValue)}
                      value={
                        formatNumberWithDots(getAllRevenueForRegistration(enrollments, true, activity || undefined)) +
                        ' SEK'
                      }
                      subtitle={`Inkl. ej godkända: ${formatNumberWithDots(getAllRevenueForRegistration(enrollments, false, activity || undefined))} SEK`}
                    />
                    <DashboardCard
                      icon={<img src={moneyIcon} alt="Money Icon" />}
                      title={getFigmaText(Texts.componentsKpIsKpiPaidLabel)}
                      value={`${formatNumberWithDots(getAllPaidRevenueForRegistration(enrollments, activity || undefined))} SEK`}
                      subtitle={`${getPaidRegistrationRatio(enrollments, activity || undefined)}`}
                    />
                  </>
                )}
              </Box>

              {renderViewToggle()}

              <Box direction="row" fullWidth gap="24px" align="center" changeDirectionOnMobile>
                <SearchBox value={searchQuery} onChange={setSearchQuery} />
                <Box direction="row" fullWidth justify="space-between" align="center">
                  {viewMode === 'payment' && (
                    <Box direction="row" fullWidth changeDirectionOnMobile gap="24px">
                      <FormControl
                        size="small"
                        sx={{
                          minWidth: 150,
                          width: '100%',
                          background: 'rgba(255, 255, 255, 0.05)',
                          borderRadius: '4px'
                        }}
                      >
                        <InputLabel id="payment-filter-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                        </InputLabel>
                        <Select
                          labelId="payment-filter-label"
                          value={paymentFilter}
                          label={getFigmaText(Texts.adminGeneralPagesFinancialReportPaymentStatusSelectLabel)}
                          onChange={handlePaymentFilterChange}
                        >
                          <MenuItem value="all">{getFigmaText(Texts.adminGeneralEnumsFilterFilterAll)}</MenuItem>
                          <MenuItem value="paid">
                            {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStatePaid)}
                          </MenuItem>
                          <MenuItem value="unpaid">
                            {getFigmaText(Texts.adminGeneralEnumsPaymentPaymentStateUnpaid)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        sx={{
                          minWidth: 150,
                          width: '100%',
                          background: 'rgba(255, 255, 255, 0.05)',
                          borderRadius: '4px'
                        }}
                      >
                        <InputLabel id="payment-filter-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {getFigmaText(Texts.adminGeneralInputFieldsEnrollmentStatusFilter)}
                        </InputLabel>
                        <Select
                          labelId="enrollment-status-filter-label"
                          value={enrollmentStatusFilter}
                          label={getFigmaText(Texts.adminGeneralInputFieldsEnrollmentStatusFilter)}
                          onChange={handleEnrollmentStatusFilterChange}
                        >
                          <MenuItem value="all">{getFigmaText(Texts.adminGeneralEnumsFilterFilterAll)}</MenuItem>
                          <MenuItem value={EnrollmentStatus.Confirmed}>
                            {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusApproved)}
                          </MenuItem>
                          <MenuItem value={EnrollmentStatus.Pending}>
                            {getFigmaText(Texts.adminGeneralEnumsEnrollmentStatusEnrollmentStatusNotApproved)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}

                  {activity && (
                    <QrCode2
                      sx={{ fontSize: 40, cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowQRCode(`https://app.coursely.se/register/${activity.id}`)
                      }}
                    />
                  )}
                </Box>
              </Box>

              {viewMode === 'payment' ? (
                <div className="attendee-list">
                  {filteredEnrollments.length === 0 ? (
                    <Box fullWidth align="center" direction="column" style={{ gap: '16px', padding: '20px' }}>
                      <p>{getFigmaText(Texts.adminGeneralInfoTextsNoResultsFound)}</p>
                    </Box>
                  ) : (
                    filteredEnrollments
                      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                      .flatMap((enrollment, index) => renderEnrollmentUserInfo(enrollment, index, isMobile))
                  )}
                </div>
              ) : (
                activity && (
                  <CheckinList
                    enrollments={filteredEnrollments}
                    onUserClick={redirectToCheckin}
                    activity={activity}
                    onCheckinChange={handleCheckinChange}
                  />
                )
              )}
            </>
          )}
        </div>
      </PageContainer>

      <QRButton />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} classes={{ paper: 'menu-custom' }} onClose={handleMenuClose}>
        {selectedRow && (
          <>
            {selectedRow.status !== EnrollmentStatus.Confirmed && (
              <MenuItem onClick={() => handleApproveAttendee()}>
                <CheckCircleOutline style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
                {getFigmaText(Texts.componentsRegListMenuApprove)}{' '}
                {selectedRow.activityIds &&
                  selectedRow.activityIds.length > 1 &&
                  getFigmaText(Texts.componentsRegListMenuApprovalMultipleAct)}
              </MenuItem>
            )}

            {selectedRow.status === EnrollmentStatus.Confirmed && !isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu(EnrollmentStatus.Confirmed)}>
                <CheckCircleOutline style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
                {getFigmaText(Texts.adminGeneralCtactaMarkAsPaid)}
              </MenuItem>
            )}

            {selectedRow.status === EnrollmentStatus.Confirmed && !isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleSendReminderPaymentAndCloseMenu(selectedRow)}>
                <NotificationsActive sx={{ marginRight: '8px', fill: 'black' }} />
                {getFigmaText(Texts.componentsRegListMenuSendReminder)}
              </MenuItem>
            )}

            {/* Only show "Mark as Unpaid" if enrollment is paid AND has no non-manual payments */}
            {isEnrollmentPaid(selectedRow) && !hasNonManualPayments(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu(EnrollmentStatus.Pending)}>
                <CheckCircle style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
                {getFigmaText(Texts.adminGeneralCtactaMarkAsUnpaid)}
              </MenuItem>
            )}

            <div style={{ borderTop: '1px solid lightgray', margin: '8px 0' }}></div>

            {selectedRow.status === EnrollmentStatus.Confirmed && (
              <MenuItem onClick={() => openEnrollmentDetails(selectedRow.id)}>
                <Details style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
                {getFigmaText(Texts.componentsRegListMenuOpenEnrollmentDetails)}
              </MenuItem>
            )}

            <MenuItem onClick={() => redirectToCheckin(selectedRow.id, activityId)}>
              <Checklist style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
              {getFigmaText(Texts.adminGeneralCheckinPageCheckinsHeader)}
            </MenuItem>

            <MenuItem onClick={() => activityId && openEnrollmentLink(selectedRow.id, activityId)}>
              <Link style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'black' }} />
              {getFigmaText(Texts.adminGeneralCtactaCopyEnrollmentLink)}
            </MenuItem>

            <div style={{ borderTop: '1px solid lightgray', margin: '8px 0' }}></div>

            <MenuItem onClick={() => updateAttendeeStatus(EnrollmentStatus.Deleted)} style={{ color: 'red' }}>
              <Close style={{ marginRight: '8px', width: '20px', height: '20px', fill: 'red' }} />
              {getFigmaText(Texts.adminGeneralCtactaDelete)}
            </MenuItem>
          </>
        )}
      </Menu>
    </MainContainer>
  )
}

// Styled components
const CommentSection = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 6px;
  margin: 8px 0;
`

const ActivityText = styled.h4`
  margin-right: 16px;
`

const MetaInfoContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 6px;
`

const AdditionalActivityContainer = styled(Box)``

const EnrollerTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  padding: 0px 8px;
  @media (min-width: 768px) {
    min-width: min(20vw, 200px);
    max-width: min(20vw, 200px);
  }
`

const EnrollerEmail = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0px 8px;
  min-width: min(20vw, 200px);
  max-width: min(20vw, 200px);
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EnrollerPhone = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0px 8px;
  min-width: 100px;
`

const EnrollerSSN = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0px 8px;
  min-width: 100px;
`

const EnrollmentRow = styled(Box)`
  margin: 8px 0;
  background-color: rgba(255, 255, 255, 0.05);
  padding: ${smallSpacing};
  border-radius: 10px;
`

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const QRCodeContent = styled.div`
  text-align: center;
  padding: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
`

const QRText = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 1.2rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`

const ActivityTag = styled.span`
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }
`

export default EnrollmentsPage
