import React, { FC } from 'react'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

interface ActivityListHeaderProps {
  filterText: string
  setFilterText: React.Dispatch<React.SetStateAction<string>>
  hasResults: boolean
  isLoading: boolean
}

const ActivityListHeader: FC<ActivityListHeaderProps> = ({ filterText, setFilterText, hasResults, isLoading }) => (
  <input
    type="text"
    placeholder={getFigmaText(Texts.adminGeneralInputFieldsSearchInputHelper)}
    value={filterText}
    onChange={(e) => setFilterText(e.target.value)}
    className={`filter-input ${!hasResults && !isLoading ? 'no-results-border' : ''}`}
  />
)

export default ActivityListHeader
