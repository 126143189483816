import { createContext, ReactNode, useEffect, useState } from 'react'
import { NODE_ENV_DEVELOPMENT, NODE_ENV_PRODUCTION } from '../../../pure-js/libs/Consts'
import { Language } from '../../../pure-js/types/Antiloop'

export enum FlagNames {
  BETA = 'beta',
  INFO_DEBUG = 'infoDebug',
  LANG_USER = 'langUser'
}

const defaultFlagsForUser: FeatureFlagsConfig = {
  [FlagNames.BETA]: {
    name: 'Beta Features',
    value: true,
    defaultValue: false,
    possibleValues: [true, false],
    description: 'Enables all beta features',
    environment: NODE_ENV_DEVELOPMENT
  },
  [FlagNames.INFO_DEBUG]: {
    name: 'Info Debug',
    value: true,
    defaultValue: false,
    possibleValues: [true, false],
    description: 'Show debug info on the screen',
    environment: NODE_ENV_DEVELOPMENT
  },
  [FlagNames.LANG_USER]: {
    name: 'Language',
    value: 'sv',
    defaultValue: 'sv',
    possibleValues: ['sv', 'en'] as Language[],
    description: 'Language for User-Web',
    environment: NODE_ENV_DEVELOPMENT
  }
}

const defaultFlagsForAdmin: FeatureFlagsConfig = {
  [FlagNames.BETA]: {
    name: 'Beta Features',
    value: true,
    defaultValue: false,
    possibleValues: [true, false],
    description: 'Enables all beta features',
    environment: NODE_ENV_DEVELOPMENT
  },
  [FlagNames.INFO_DEBUG]: {} as FeatureFlag,
  [FlagNames.LANG_USER]: {} as FeatureFlag
}

export interface FeatureFlag<T = any> {
  name: string
  value: T
  defaultValue: T
  possibleValues: T[]
  description?: string
  environment?: 'development' | 'production'
}

export type FeatureFlagsConfig = Record<FlagNames, FeatureFlag>

export interface FeatureContextProps {
  flags: FeatureFlagsConfig
  isFeatureEnabled: (flagName: FlagNames) => boolean
  getFlagValue: <T>(flagName: FlagNames) => T | undefined
  setFlagValue: <T>(flagName: FlagNames, value: T) => void
  getStorageKey: () => string
}

// Create context
export const FeatureFlagsContext = createContext<FeatureContextProps>({
  flags: defaultFlagsForUser,
  isFeatureEnabled: () => false,
  getFlagValue: () => undefined,
  setFlagValue: () => {},
  getStorageKey: () => ''
})

// Provider component
interface FeatureFlagsProviderProps {
  children: ReactNode
  storageKey: string
  defaultFlags: FeatureFlagsConfig
}

const FeatureFlagsProvider = ({ children, storageKey: storageKey, defaultFlags }: FeatureFlagsProviderProps) => {
  const [flags, setFlags] = useState<FeatureFlagsConfig>(defaultFlags)

  useEffect(() => {
    // Load flags from localStorage if available
    const savedFlags = localStorage.getItem(storageKey)
    if (savedFlags) {
      setFlags(JSON.parse(savedFlags))
    }
  }, [])

  const isFeatureEnabled = (flagName: FlagNames): boolean => {
    const flag = flags[flagName]
    if (!flag || !flag.name) return false

    // Check environment-specific rules
    if (flag.environment) {
      const currentEnv = process.env.NODE_ENV
      if (flag.environment === NODE_ENV_PRODUCTION && currentEnv !== NODE_ENV_PRODUCTION) {
        return false
      }
    }

    return flag.value
  }

  const getFlagValue = <T,>(flagName: FlagNames): T | undefined => {
    const flag = flags[flagName]
    if (!flag || !flag.name) return undefined

    // Check environment-specific rules
    if (flag.environment) {
      const currentEnv = process.env.NODE_ENV
      if (flag.environment === NODE_ENV_PRODUCTION && currentEnv !== NODE_ENV_PRODUCTION) {
        return flag.defaultValue as T
      }
    }

    return (flag.value !== undefined ? flag.value : flag.defaultValue) as T
  }

  const setFlagValue = <T,>(flagName: FlagNames, value: T) => {
    const flag = flags[flagName]
    if (!flag || !flag.name) {
      console.warn(`Feature flag "${flagName}" not supported in this context.`)
      return
    }

    setFlags((prevFlags) => {
      const newFlags = {
        ...prevFlags,
        [flagName]: {
          ...prevFlags[flagName],
          value
        }
      }
      // Persist to localStorage
      localStorage.setItem(storageKey, JSON.stringify(newFlags))
      return newFlags
    })
  }

  const getStorageKey = () => storageKey

  return (
    <FeatureFlagsContext.Provider value={{ flags, isFeatureEnabled, getFlagValue, setFlagValue, getStorageKey }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

// HOC to create a provider with a specific storage key
const createFeatureFlagsProvider = (config: { storageKey: string; defaultFlags: FeatureFlagsConfig }) => {
  const ConfiguredProvider = ({ children }: { children: ReactNode }) => (
    <FeatureFlagsProvider storageKey={config.storageKey} defaultFlags={config.defaultFlags}>
      {children}
    </FeatureFlagsProvider>
  )

  // Give the component a more descriptive display name for React DevTools
  ConfiguredProvider.displayName = `FeatureFlagsProvider(${config.storageKey})`

  return ConfiguredProvider
}

export const FeatureFlagsProviderForUserweb = createFeatureFlagsProvider({
  storageKey: 'featureFlags',
  defaultFlags: defaultFlagsForUser
})

export const FeatureFlagsProviderForAdminweb = createFeatureFlagsProvider({
  storageKey: 'featureFlagsAdmin',
  defaultFlags: defaultFlagsForAdmin
})
