import { collection, query, where } from 'firebase/firestore'
import { db } from '../libs/Firebase.js'
import { useFirestoreQuery } from './useFirestoreQuery'
import { Collections } from '../../../pure-js/libs/Collections'
import { Activity, Enrollment, EnrollmentStatus } from '../../../pure-js/types/GrooverTypes'
import { createUniqueId } from '../../../pure-js/libs/Common.js'

export const useEnrollmentsForActivity = (activity: Activity | null) => {
  return useFirestoreQuery<Enrollment[]>(
    query(
      collection(db, `${Collections.CLIENTS}/${activity?.clientId}/${Collections.ENROLLMENTS}`),
      where('activityIds', 'array-contains', activity?.id || createUniqueId()),
      where('status', '!=', EnrollmentStatus.Deleted)
    ),
    {
      fnName: 'useEnrollmentsForActivity',
      enabled: !!activity?.clientId
    }
  )
}
