import React, { useEffect, useState, useMemo, useRef } from 'react'
import { getClient, upsertClient } from '../libs/DBApiHandler'
import Box from '../components/Box'
import useAppState from '../hooks/useAppState'
import useSWR from 'swr'

import { White } from '../../../pure-js/libs/Colors'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import { Client, Location } from '../../../pure-js/types/GrooverTypes'
import { Discount } from '../../../pure-js/types/DiscountTypes'
import { uploadFileToStorage } from '../libs/StorageHelper'
import { COURSELY_PUBLIC_MEDIA_URL } from '../../../pure-js/web'
import {
  SectionHeader,
  Section,
  MainContainer,
  PageContainer,
  UploadButton,
  CustomTextField,
  StickyFooter
} from '../styles/AdminPageStyles'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from '../libs/TextRepository'
import {
  Button,
  CircularProgress,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { CheckSharp, CloseSharp, Checklist } from '@mui/icons-material'
import { extractMarkerLatLng } from '../libs/GoogleMapsHelper'
import { sortAndStringify } from '../libs/Common'
import { useClient } from '../context/ClientContext'

// Add this helper function near the top of the file
const formatDiscountValue = (value: number | undefined, calculationType: string): string => {
  if (value === undefined) return ''
  return calculationType === 'amount' ? (value / 100).toString() : value.toString()
}

// Add these helper functions at the top
const formatAmountForDisplay = (value: number): string => (value / 100).toFixed(0)
const formatAmountForStorage = (value: number): number => Math.round(value * 100)

const POLL_INTERVAL = 10 * 60 * 1000 // 10 minutes in milliseconds

const EditClientPage: React.FC = () => {
  const { state } = useAppState()
  const { clientId: contextClientId, reloadTrigger } = useClient()

  const [client, setClient] = useState<Client | null>(null)
  const [serverClient, setServerClient] = useState<Client | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [userCanSave, setUserCanSave] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const [filePath, setFilePath] = useState<string>('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!client) return
    const file = event.target.files?.[0]
    if (!file) return

    try {
      const result = await uploadFileToStorage(file, `logos/${client?.id}`, (progress) => setUploadProgress(progress))

      setDownloadUrl(result.downloadUrl)
      setFilePath(result.filePath)
      upsertClient({ id: client.id, logoFullUrl: `${COURSELY_PUBLIC_MEDIA_URL}/${result.filePath}` }) // save logo with public URL to clients
    } catch (error) {
      console.error('Upload failed:', error)
    }
  }

  // Update to use clientId from context when available
  const [clientId, setClientId] = useState<string>(() => {
    return localStorage.getItem('selectedClientId') || ''
  })

  const { data, mutate: mutateClient } = useSWR(`client/${clientId}`, () => getClient(clientId), {
    refreshInterval: POLL_INTERVAL,
    onError: (err) => {
      console.error('Failed to fetch client:', err)
      toast.error('Failed to fetch client data', { duration: 2000, position: 'top-center' })
    },
    onSuccess: (newData) => {
      if (!client && newData) {
        setClient(newData)
        setServerClient(newData)
        return
      }

      // Compare the new data with saved server client data
      if (newData && sortAndStringify(newData) !== sortAndStringify(serverClient)) {
        if (isDialogOpen) return // Don't show dialog if it's already open
        showReloadDialog(() => {
          setClient(newData)
          setServerClient(newData)
        })
      }
    }
  })

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const handleInputChange = (field: keyof Client, value: any) => {
    if (client) {
      setClient({ ...client, [field]: value })
    }
  }

  const checkUserCanSave = async () => {
    const userClientIds = (state.user?.authCustomClaims?.clientIds as string[]) || []
    setUserCanSave(userClientIds.includes(clientId))
  }

  const handleSave = async (shouldClose: boolean = false) => {
    if (!client) return

    try {
      setError(null)
      setIsSaving(true)

      await upsertClient(client)
      setServerClient(client) // update local "server" data reference
      await mutateClient() // revalidate the data after saving

      setIsSaving(false)
      toast.success('Sparad!', {
        duration: 2000,
        position: 'top-center'
      })
    } catch (err) {
      toast.error('Misslyckades tyvärr att spara', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    checkUserCanSave()
    setIsLoading(false)
  }, [state, clientId])

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [locationToDelete, setLocationToDelete] = useState<Location | null>(null)

  const showReloadDialog = (onReload: () => void) => {
    if (isDialogOpen) return // Don't show dialog if it's already open

    setIsDialogOpen(true)
    toast.custom(
      (t) => (
        <Dialog open={true}>
          <DialogTitle>{getFigmaText(Texts.adminGeneralInfoTextsDataChangedHeader)}</DialogTitle>
          <DialogContent>{getFigmaText(Texts.adminGeneralInfoTextsDataChangedText)}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsDialogOpen(false)
                toast.dismiss(t.id)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onReload()
                setIsDialogOpen(false)
                toast.dismiss(t.id)
              }}
              variant="contained"
              color="primary"
            >
              Reload
            </Button>
          </DialogActions>
        </Dialog>
      ),
      {
        duration: Infinity,
        position: 'top-center'
      }
    )
  }

  const handleLocationSelect = (location: Location) => {
    setSelectedLocation(location)
  }

  const handleAddLocation = () => {
    if (!client) return
    const newLocation: Location = {
      id: Date.now().toString(), // temporary id
      name: 'Ny plats',
      adress: '',
      googleUrl: '',
      center: { lat: 59.3293, lng: 18.0686 }, // Default to Stockholm coordinates
      createdAt: new Date().toISOString()
    }

    const updatedLocations = [...(client.locations || []), newLocation]
    setClient({ ...client, locations: updatedLocations })
    setSelectedLocation(newLocation)
  }

  const handleRemoveLocation = async () => {
    if (!client || !locationToDelete) return

    try {
      setIsSaving(true)
      const updatedLocations = client.locations?.filter((loc) => loc.id !== locationToDelete.id) || []
      const updatedClient = { ...client, locations: updatedLocations }

      await upsertClient(updatedClient)
      setClient(updatedClient)

      if (selectedLocation?.id === locationToDelete.id) {
        setSelectedLocation(null)
      }

      toast.success('Plats borttagen', {
        duration: 2000,
        position: 'top-center'
      })
    } catch (err) {
      console.error('Misslyckades tyvärr med att ta bort plats', err)
      toast.error('Misslyckades tyvärr med att ta bort plats', {
        duration: 2000,
        position: 'top-center'
      })
    } finally {
      setIsSaving(false)
      setLocationToDelete(null)
    }
  }

  const handleLocationChange = async (field: keyof Location | 'center.lat' | 'center.lng', value: any) => {
    if (!selectedLocation || !client) return

    // If changing Google URL, attempt to extract coordinates
    if (field === 'googleUrl') {
      const coordinates = extractMarkerLatLng(value)
      if (coordinates) {
        const updatedLocation = {
          ...selectedLocation,
          googleUrl: value,
          center: coordinates
        }

        const updatedLocations =
          client.locations?.map((loc) => (loc.id === selectedLocation.id ? updatedLocation : loc)) || []

        setClient({ ...client, locations: updatedLocations })
        setSelectedLocation(updatedLocation)

        toast.success('Koordinater extraherade från Google Maps-länken', {
          duration: 2000,
          position: 'top-center'
        })
      } else {
        toast.error('Kunde inte hitta koordinater i länken. Använd en Google Maps-länk med markör.', {
          duration: 4000,
          position: 'top-center'
        })
      }
      return
    }

    const updateLocation = (location: Location): Location => {
      if (field === 'center.lat' || field === 'center.lng') {
        const [parent, child] = field.split('.')
        return {
          ...location,
          [parent]: {
            ...location[parent],
            [child]: Number(value)
          }
        }
      }
      return {
        ...location,
        [field]: value
      }
    }

    const updatedLocation = updateLocation(selectedLocation)
    const updatedLocations =
      client.locations?.map((loc) => (loc.id === selectedLocation.id ? updatedLocation : loc)) || []

    setClient({ ...client, locations: updatedLocations })
    setSelectedLocation(updatedLocation)
  }

  const [selectedDiscount, setSelectedDiscount] = useState<Discount | null>(null)
  const [discountToDelete, setDiscountToDelete] = useState<Discount | null>(null)

  const handleDiscountSelect = (discount: Discount) => {
    // Create a fresh discount object with only the properties we want
    const cleanDiscount = {
      id: discount.id,
      name: discount.name,
      type: discount.type,
      appliesTo: discount.appliesTo,
      calculationType: discount.calculationType,
      value: discount.value,
      canBeCombined: discount.canBeCombined || false,
      discountGroup: discount.discountGroup || '',
      excludeGroupInCombination: discount.excludeGroupInCombination || '',
      courseDiscounts: discount.courseDiscounts || []
    } as Discount
    setSelectedDiscount(cleanDiscount)
  }

  const handleAddDiscount = () => {
    if (!client) return
    const newDiscount: Discount = {
      id: Date.now().toString(),
      name: 'New Discount',
      type: 'combined',
      appliesTo: 'allParticipants',
      calculationType: 'percentage',
      canBeCombined: true,
      value: 0,
      discountGroup: '', // Add default empty string
      excludeGroupInCombination: '' // Add default empty string
    }

    const updatedDiscounts = [...(client.discounts || []), newDiscount]
    setClient({ ...client, discounts: updatedDiscounts })
    setSelectedDiscount(newDiscount)
  }

  // Update handleDiscountChange to handle undefined values
  const handleDiscountChange = (field: keyof Discount, value: any) => {
    if (!selectedDiscount || !client) return

    if (field === 'value' && selectedDiscount.calculationType === 'amount') {
      value = formatAmountForStorage(parseFloat(value))
    }

    // Handle empty strings for optional fields
    if ((field === 'discountGroup' || field === 'excludeGroupInCombination') && value === '') {
      value = undefined
    }

    // Handle undefined canBeCombined
    if (field === 'canBeCombined' && value === undefined) {
      value = false
    }

    const updatedDiscount = { ...selectedDiscount, [field]: value }
    const updatedDiscounts =
      client.discounts?.map((disc) => (disc.id === selectedDiscount.id ? updatedDiscount : disc)) || []

    setClient({ ...client, discounts: updatedDiscounts })
    setSelectedDiscount(updatedDiscount)
  }

  const handleCourseDiscountChange = (index: number, field: 'course' | 'discount', value: number) => {
    if (!selectedDiscount || !client) return

    const updatedCourseDiscounts = [...(selectedDiscount.courseDiscounts || [])]

    if (field === 'discount' && selectedDiscount.calculationType === 'amount') {
      value = formatAmountForStorage(value)
    }

    updatedCourseDiscounts[index] = {
      ...updatedCourseDiscounts[index],
      [field]: value
    }

    const updatedDiscount = { ...selectedDiscount, courseDiscounts: updatedCourseDiscounts }
    const updatedDiscounts =
      client.discounts?.map((disc) => (disc.id === selectedDiscount.id ? updatedDiscount : disc)) || []

    setClient({ ...client, discounts: updatedDiscounts })
    setSelectedDiscount(updatedDiscount)
  }

  const handleAddCourseDiscount = () => {
    if (!selectedDiscount || !client) return

    const newCourseDiscount = {
      course: 2,
      discount: 0
    }

    const updatedCourseDiscounts = [...(selectedDiscount.courseDiscounts || []), newCourseDiscount]
    const updatedDiscount = { ...selectedDiscount, courseDiscounts: updatedCourseDiscounts }
    const updatedDiscounts =
      client.discounts?.map((disc) => (disc.id === selectedDiscount.id ? updatedDiscount : disc)) || []

    setClient({ ...client, discounts: updatedDiscounts })
    setSelectedDiscount(updatedDiscount)
  }

  const handleRemoveDiscount = async () => {
    if (!client || !discountToDelete) return

    try {
      setIsSaving(true)
      const updatedDiscounts = client.discounts?.filter((disc) => disc.id !== discountToDelete.id) || []
      const updatedClient = { ...client, discounts: updatedDiscounts }

      await upsertClient(updatedClient)
      setClient(updatedClient)

      if (selectedDiscount?.id === discountToDelete.id) {
        setSelectedDiscount(null)
      }

      toast.success('Rabatt borttagen!', {
        duration: 2000,
        position: 'top-center'
      })
    } catch (err) {
      console.error('Misslyckades med att ta bort rabatt:', err)
      toast.error('Misslyckades med att ta bort rabatt', {
        duration: 2000,
        position: 'top-center'
      })
    } finally {
      setIsSaving(true)
      setDiscountToDelete(null)
    }
  }

  // Listen for client changes from context
  useEffect(() => {
    // If the context client ID changes or a reload is triggered
    const currentClientId = contextClientId || localStorage.getItem('selectedClientId') || ''

    if (currentClientId && currentClientId !== clientId) {
      console.log('Client ID changed in EditClientPage:', currentClientId)
      setClientId(currentClientId)

      // Manually trigger a refetch since we're changing the key
      mutateClient()
    }
  }, [contextClientId, reloadTrigger])

  if (isLoading) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {getFigmaText(Texts.adminGeneralBusyText)}
        </Box>
      </MainContainer>
    )
  }

  return (
    <MainContainer fullHeight fullWidth>
      <PageContainer fullWidth>
        <Container fullWidth>
          <form>
            <Box id="SectionContainer" fullWidth gap="16px">
              <Section id="Section: Logo" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionLogo} />
                <Box direction="column" gap="10px" fullWidth>
                  <ClientLogoContainer direction="column" fullWidth>
                    {(downloadUrl || client?.logoFullUrl) && (
                      <ClientLogo src={downloadUrl || client?.logoFullUrl} alt="Client Logo" />
                    )}
                  </ClientLogoContainer>

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileUpload}
                  />
                  <Box direction="row" fullWidth gap="10px" justify="flex-end">
                    {isLoading && <CircularProgress />}
                    <UploadButton variant="contained" color="primary" onClick={handleUploadClick}>
                      {getFigmaText(Texts.registrationCtactaUploadNewImage)}
                    </UploadButton>
                  </Box>
                </Box>
              </Section>

              <Section id="Section: General" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionGeneral} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientName)}
                    value={client ? client.name : ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientOrgNo)}
                    value={client ? client.orgNo : ''}
                    onChange={(e) => handleInputChange('orgNo', e.target.value)}
                  />
                </Box>
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientAdress)}
                    value={client ? client.address : ''}
                    onChange={(e) => handleInputChange('address', e.target.value)}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientContactEmail)}
                    value={client ? client.contactEmail : ''}
                    onChange={(e) => handleInputChange('contactEmail', e.target.value)}
                  />
                </Box>
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientHomepage)}
                    value={client ? client.homepage : ''}
                    onChange={(e) => handleInputChange('homepage', e.target.value)}
                  />
                </Box>
              </Section>

              <Section id="Section: Payment Settings" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionPaymentSettings} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <ListSection fullWidth>
                    <InputLabel size="small" style={{ fontSize: '14px', marginLeft: '15px' }}>
                      {getFigmaText(Texts.adminGeneralInputFieldsClientActivatedPaymentMethods)}
                    </InputLabel>
                    <InputList>
                      {client?.paymentMethodFees?.map((method, index) => (
                        <PaymentMethodItem key={index}>
                          {method.method}: {method.fee} SEK
                        </PaymentMethodItem>
                      ))}
                    </InputList>
                  </ListSection>
                  <Box direction="column" fullWidth>
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsClientBankAccountNo)}
                      value={client ? client.bankGiro : ''}
                      onChange={(e) => handleInputChange('bankGiro', e.target.value)}
                    />
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsClientSwish)}
                      value={client ? client.swish?.operator + ' : ' + client.swish?.environment : ''}
                      slotProps={{ input: { readOnly: true } }}
                    />
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsClientStripe)}
                      value={client ? client.stripe?.operator + ' : ' + client.stripe?.environment : ''}
                      slotProps={{ input: { readOnly: true } }}
                    />
                    <CustomTextField
                      type="number"
                      label={getFigmaText(Texts.adminGeneralInputFieldsClientDefaultVat)}
                      value={client ? client.defaultVAT : 25}
                      onChange={(e) => handleInputChange('defaultVAT', parseFloat(e.target.value))}
                    />
                  </Box>
                </Box>
              </Section>

              <Section id="Section: Locations" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionLocations} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <ListSection fullWidth>
                    <Box direction="row" justify="space-between" align="center" style={{ padding: '0 15px' }}>
                      <InputLabel size="small" style={{ fontSize: '14px' }}>
                        {getFigmaText(Texts.adminGeneralSectionsSectionLocations)}
                      </InputLabel>
                    </Box>
                    <InputList>
                      {client?.locations?.map((location) => (
                        <LocationItem
                          key={location.id}
                          onClick={() => handleLocationSelect(location)}
                          isSelected={selectedLocation?.id === location.id}
                        >
                          {location.name}
                        </LocationItem>
                      ))}
                    </InputList>
                    <Box fullWidth align="flex-end">
                      {' '}
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleAddLocation}
                        style={{ maxWidth: '40px', minWidth: '40px', padding: '4px' }}
                      >
                        +
                      </Button>
                    </Box>
                  </ListSection>
                  <Box direction="column" fullWidth>
                    {selectedLocation && (
                      <>
                        <Box direction="row" justify="space-between" align="center" fullWidth>
                          <CustomTextField
                            label={getFigmaText(Texts.adminGeneralInputFieldsClientPlaceName)}
                            value={selectedLocation.name || ''}
                            onChange={(e) => handleLocationChange('name', e.target.value)}
                          />
                          <DeleteButton
                            variant="contained"
                            color="error"
                            onClick={() => setLocationToDelete(selectedLocation)}
                            style={{ height: '40px' }}
                          >
                            {getFigmaText(Texts.adminGeneralCtactaDelete)}
                          </DeleteButton>
                        </Box>
                        <CustomTextField
                          label={getFigmaText(Texts.adminGeneralInputFieldsClientPlaceAdress)}
                          value={selectedLocation.adress || ''}
                          onChange={(e) => handleLocationChange('adress', e.target.value)}
                        />
                        <CustomTextField
                          label={getFigmaText(Texts.adminGeneralInputFieldsClientPlaceGoogleUrl)}
                          value={selectedLocation.googleUrl || ''}
                          onChange={(e) => handleLocationChange('googleUrl', e.target.value)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Section>

              <Section id="Section: Discounts" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionDiscounts} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <ListSection fullWidth>
                    <Box top spacing="6px">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={client?.hideDiscountsInPublicPage ?? false}
                            onChange={(e) => handleInputChange('hideDiscountsInPublicPage', e.target.checked)}
                            sx={{
                              color: 'white',
                              '&.Mui-checked': {
                                color: 'white'
                              }
                            }}
                          />
                        }
                        label={getFigmaText(Texts.adminGeneralInputFieldsClientHideDiscountInPublicPage)}
                        sx={{ color: 'white', margin: 0 }}
                      />
                    </Box>
                    <Box direction="row" justify="space-between" align="center" style={{ padding: '0 15px' }} fullWidth>
                      <InputLabel size="small" style={{ fontSize: '14px' }}>
                        {getFigmaText(Texts.adminGeneralSectionsSectionDiscounts)}
                      </InputLabel>
                      <InputLabel size="small" style={{ fontSize: '14px' }}>
                        {getFigmaText(Texts.adminGeneralInputFieldsActivityDiscountCanBeCombinedLabel)}
                      </InputLabel>
                    </Box>
                    <InputList>
                      {client?.discounts?.map((discount) => (
                        <div key={discount.id}>
                          <DiscountListItem
                            fullWidth
                            direction="row"
                            onClick={() => handleDiscountSelect(discount)}
                            isSelected={selectedDiscount?.id === discount.id}
                          >
                            <Box direction="row" gap="10px">
                              <DiscountText>
                                <span>{discount.name}</span>
                              </DiscountText>

                              {discount.type !== 'multi_course_matrix' && (
                                <DiscountValue>
                                  {discount.calculationType === 'amount'
                                    ? `${formatAmountForDisplay(discount.value)} SEK`
                                    : `${discount.value}%`}
                                </DiscountValue>
                              )}
                            </Box>
                            <DiscountValue>
                              {discount.canBeCombined ? (
                                discount.excludeGroupInCombination ? (
                                  <Checklist sx={{ color: 'white' }} />
                                ) : (
                                  <CheckSharp sx={{ color: 'white' }} />
                                )
                              ) : (
                                <CloseSharp sx={{ color: 'red' }} />
                              )}
                            </DiscountValue>
                          </DiscountListItem>

                          {discount.type === 'multi_course_matrix' && discount.courseDiscounts && (
                            <SubDiscountList>
                              {discount.courseDiscounts.map((courseDiscount, index) => (
                                <DiscountListItem
                                  fullWidth
                                  direction="row"
                                  key={index}
                                  onClick={() => handleDiscountSelect(discount)}
                                >
                                  <Box direction="row" gap="10px">
                                    <DiscountText>
                                      <span>Kurs: {courseDiscount.course}</span>
                                    </DiscountText>

                                    <DiscountValue>
                                      {discount.calculationType === 'amount'
                                        ? `${formatAmountForDisplay(courseDiscount.discount)} SEK`
                                        : `${courseDiscount.discount}%`}
                                    </DiscountValue>
                                  </Box>
                                </DiscountListItem>
                              ))}
                            </SubDiscountList>
                          )}
                        </div>
                      ))}
                    </InputList>
                    <Box fullWidth align="flex-end">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleAddDiscount}
                        style={{ maxWidth: '40px', minWidth: '40px', padding: '4px' }}
                      >
                        +
                      </Button>
                    </Box>
                  </ListSection>
                  <Box direction="column" fullWidth>
                    {selectedDiscount && (
                      <>
                        <Box direction="row" justify="space-between" align="center" fullWidth>
                          <CustomTextField
                            label={getFigmaText(Texts.adminGeneralInputFieldsClientDiscountName)}
                            value={selectedDiscount.name}
                            onChange={(e) => handleDiscountChange('name', e.target.value)}
                          />
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => setDiscountToDelete(selectedDiscount)}
                            style={{ height: '40px' }}
                          >
                            {getFigmaText(Texts.adminGeneralCtactaDelete)}
                          </Button>
                        </Box>
                        <CustomTextField
                          select
                          label={getFigmaText(Texts.adminGeneralInputFieldsClientDiscountDiscountType)}
                          value={selectedDiscount.type}
                          onChange={(e) => handleDiscountChange('type', e.target.value)}
                          slotProps={{
                            select: {
                              native: true
                            }
                          }}
                        >
                          <option value="combined">
                            {getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountExtended)}
                          </option>
                          <option value="multi_course_matrix">
                            {getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountBulk)}
                          </option>
                          <option value="student">
                            {getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountStudent)}
                          </option>
                          <option value="senior">{getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountSenior)}</option>
                          <option value="couples">
                            {getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountCouples)}
                          </option>
                          <option value="member">{getFigmaText(Texts.adminGeneralEnumsDiscountsDiscountMember)}</option>
                        </CustomTextField>
                        {selectedDiscount.type !== 'multi_course_matrix' && (
                          <>
                            <CustomTextField
                              select
                              label={getFigmaText(Texts.adminGeneralInputFieldsClientDIscountValueType)}
                              value={selectedDiscount.calculationType}
                              onChange={(e) => handleDiscountChange('calculationType', e.target.value)}
                              slotProps={{
                                select: {
                                  native: true
                                }
                              }}
                            >
                              <option value="percentage">
                                {getFigmaText(Texts.adminGeneralEnumsCalculationTypePercentage)}
                              </option>
                              <option value="amount">
                                {getFigmaText(Texts.adminGeneralEnumsCalculationTypeAmount)}
                              </option>
                            </CustomTextField>
                            <CustomTextField
                              label={getFigmaText(Texts.adminGeneralInputFieldsClientDiscountConfigurationValue)}
                              type="number"
                              value={
                                selectedDiscount.calculationType === 'amount'
                                  ? formatAmountForDisplay(selectedDiscount.value)
                                  : selectedDiscount.value || ''
                              }
                              onChange={(e) => handleDiscountChange('value', parseFloat(e.target.value))}
                              inputProps={{
                                step: selectedDiscount.calculationType === 'amount' ? '0.01' : '1'
                              }}
                            />
                          </>
                        )}
                        {selectedDiscount.type === 'multi_course_matrix' && (
                          <>
                            <Box direction="row" gap="10px" fullWidth>
                              <CustomTextField
                                select
                                label={getFigmaText(Texts.adminGeneralInputFieldsClientDIscountValueType)}
                                value={selectedDiscount.calculationType}
                                onChange={(e) => handleDiscountChange('calculationType', e.target.value)}
                                slotProps={{
                                  select: {
                                    native: true
                                  }
                                }}
                              >
                                <option value="percentage">
                                  {getFigmaText(Texts.adminGeneralEnumsCalculationTypePercentage)}
                                </option>
                                <option value="amount">
                                  {getFigmaText(Texts.adminGeneralEnumsCalculationTypeAmount)}
                                </option>
                              </CustomTextField>
                              <CustomTextField
                                select
                                label={getFigmaText(Texts.adminGeneralInputFieldsClientDiscountStragegy)}
                                value={selectedDiscount.strategy || 'max_price'}
                                // @ts-ignore
                                onChange={(e) => handleDiscountChange('strategy', e.target.value)}
                                slotProps={{
                                  select: {
                                    native: true
                                  }
                                }}
                              >
                                <option value="max_price">Max Price</option>
                              </CustomTextField>
                            </Box>

                            <DiscountMatrix direction="column" gap="10px" fullWidth>
                              <MatrixTable>
                                <thead>
                                  <tr>
                                    <MatrixHeaderCell>Aktivitet</MatrixHeaderCell>
                                    <MatrixHeaderCell>Rabatt</MatrixHeaderCell>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedDiscount.courseDiscounts?.map((courseDiscount, index) => (
                                    <tr key={index}>
                                      <MatrixCell>
                                        <input
                                          type="number"
                                          value={courseDiscount.course}
                                          onChange={(e) =>
                                            handleCourseDiscountChange(index, 'course', parseInt(e.target.value))
                                          }
                                        />
                                      </MatrixCell>
                                      <MatrixCell>
                                        <input
                                          type="number"
                                          value={
                                            selectedDiscount.calculationType === 'amount'
                                              ? formatAmountForDisplay(courseDiscount.discount)
                                              : courseDiscount.discount
                                          }
                                          onChange={(e) =>
                                            handleCourseDiscountChange(index, 'discount', parseFloat(e.target.value))
                                          }
                                          step={selectedDiscount.calculationType === 'amount' ? '0.01' : '1'}
                                        />
                                        {selectedDiscount.calculationType === 'percentage' ? '%' : ' SEK'}
                                      </MatrixCell>
                                    </tr>
                                  ))}
                                </tbody>
                              </MatrixTable>
                              <Box fullWidth align="flex-end">
                                {' '}
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={handleAddCourseDiscount}
                                  style={{ maxWidth: '40px', minWidth: '40px', padding: '4px' }}
                                >
                                  +
                                </Button>
                              </Box>
                            </DiscountMatrix>
                          </>
                        )}
                        <Box direction="row" fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDiscount.canBeCombined ?? false}
                                onChange={(e) => handleDiscountChange('canBeCombined', e.target.checked)}
                                sx={{
                                  color: 'white',
                                  '&.Mui-checked': {
                                    color: 'white'
                                  }
                                }}
                              />
                            }
                            label={getFigmaText(Texts.adminGeneralInputFieldsActivityDiscountCanBeCombinedLabel)}
                            sx={{ color: 'white', margin: 0, width: '100%' }}
                          />
                          <CustomTextField
                            label={getFigmaText(
                              Texts.adminGeneralInputFieldsClientDiscountConfigurationCombinedWithGroup
                            )}
                            type="text"
                            value={selectedDiscount.excludeGroupInCombination ?? ''}
                            onChange={(e) => handleDiscountChange('excludeGroupInCombination', e.target.value)}
                          />
                        </Box>
                        <CustomTextField
                          label={getFigmaText(Texts.adminGeneralInputFieldsClientDiscountCombinationGroup)}
                          type="text"
                          value={selectedDiscount.discountGroup ?? ''}
                          onChange={(e) => handleDiscountChange('discountGroup', e.target.value)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Section>

              <Section id="Section: Advanced" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralSectionsSectionAdvanced} />
                <Box direction="row" gap="10px" fullWidth changeDirectionOnMobile>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={client?.allowBothForCouples ?? false}
                        onChange={(e) => handleInputChange('allowBothForCouples', e.target.checked)}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white'
                          }
                        }}
                      />
                    }
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityAllowBothAsType)}
                    sx={{ color: 'white', margin: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={client?.sendSMSonRegistration ?? false}
                        onChange={(e) => handleInputChange('sendSMSonRegistration', e.target.checked)}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white'
                          }
                        }}
                      />
                    }
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientEnableSmsOnRegistration)}
                    sx={{ color: 'white', margin: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={client?.showSsnInRegistration ?? false}
                        onChange={(e) => handleInputChange('showSsnInRegistration', e.target.checked)}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white'
                          }
                        }}
                      />
                    }
                    label={getFigmaText(Texts.adminGeneralInputFieldsClientShowSnrInEnrollment)}
                    sx={{ color: 'white', margin: 0 }}
                  />
                </Box>
              </Section>
            </Box>
          </form>
          <StickyFooter direction="row" fullWidth gap="10px">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave(false)}
              disabled={!userCanSave || isSaving}
            >
              {getFigmaText(Texts.adminGeneralCtactaSave)}
            </Button>
          </StickyFooter>
        </Container>
      </PageContainer>
      <Dialog open={!!discountToDelete} onClose={() => setDiscountToDelete(null)}>
        <DialogTitle>{getFigmaText(Texts.adminGeneralDialogsDeletionVerifyHeader)}</DialogTitle>
        <DialogContent>
          {getFigmaText(Texts.adminGeneralDialogsDeletionVerifyText)} "{discountToDelete?.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDiscountToDelete(null)}>{getFigmaText(Texts.registrationCtactaAbort)}</Button>
          <DeleteButton onClick={handleRemoveDiscount} color="error" variant="contained">
            {getFigmaText(Texts.adminGeneralCtactaDelete)}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <Dialog open={!!locationToDelete} onClose={() => setLocationToDelete(null)}>
        <DialogTitle>{getFigmaText(Texts.adminGeneralDialogsDeletionVerifyHeader)}</DialogTitle>
        <DialogContent>
          {getFigmaText(Texts.adminGeneralDialogsDeletionVerifyText)} "{locationToDelete?.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLocationToDelete(null)}>{getFigmaText(Texts.registrationCtactaAbort)}</Button>
          <DeleteButton onClick={handleRemoveLocation} color="error" variant="contained">
            {getFigmaText(Texts.adminGeneralCtactaDelete)}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </MainContainer>
  )
}

const Container = styled(Box)`
  height: calc(100vh - 80px); /* Adjust for footer height */
  max-width: 1200px;
  align-self: center;
  overflow-y: auto;
  padding-bottom: 80px; /* Add padding for the footer */

  /* Customize scrollbar appearance */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

const DiscountText = styled(Box)`
  min-width: 200px;
`

const ClientLogoContainer = styled(Box)`
  background: white;
  border-radius: 8px;
`

export const ClientLogo = styled.img`
  border-radius: 8px;
  max-width: 300px;
`

const ListSection = styled(Box)`
  margin-top: -14px;
`

const InputList = styled.ul`
  list-style: none;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  border-radius: 4px;
`

const PaymentMethodItem = styled(Box)`
  padding: 8px 0;
  color: ${White};
`

const LocationItem = styled(Box)<{ isSelected?: boolean }>`
  padding: 8px;
  color: ${White};
  cursor: pointer;
  background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
  border-radius: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const DiscountMatrix = styled(Box)`
  padding: 10px;
`

const MatrixTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
`

const MatrixHeaderCell = styled.th`
  color: ${White};
  padding: 12px;
  text-align: left;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

const MatrixCell = styled.td`
  padding: 8px 12px;
  color: ${White};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  input {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: ${White};
    padding: 4px 8px;
    width: 100px;
    text-align: right;

    /* Hide spinner for Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Hide spinner for Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

const DeleteButton = styled(Button)`
  &.MuiButton-containedError {
    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`

const DiscountListItem = styled(Box)<{ isSelected?: boolean }>`
  padding: 8px 12px;
  color: ${White};
  cursor: pointer;
  background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const SubDiscountList = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 12px;
  margin-left: 12px;
  font-size: 14px;
`

const DiscountValue = styled.span`
  color: ${White};
  margin-right: 40px;
  opacity: 0.8;
`

export default EditClientPage
